import React, { useEffect, useState } from 'react';
import { ReactSession } from 'react-client-session';
import axios from 'axios';
import Navbar from "./navbar";
import Header from "./header";

 function Quote() {
    const userId = ReactSession.get("userId");
    const [quoteData, setData] =useState(null);
  

    const fetchData = async () => {
        try {
            const response = await axios.post('https://upvcapi.sninfoserv.com/all-quotes/', { user: userId });
            setData(response.data);
            console.log(response.data);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
      

      
      
  useEffect(() => {
    fetchData();
  }, []);
 
  return (
    <div>
    <Navbar />
    <Header />
    
    <div class="pc-container">
      <div class="pc-content">
        
        <div class="row">
        <div class="col-md-12 col-xxl-12 col-xl-12">
            <div class="card">
             
               
            
              <div class="card-header ">
                <h5>Table</h5>
              </div>
              <div class="card-body">
                  <div class="table-responsive"> 
              <table id="left-right-fix" class="table stripe row-border order-column">
                  <thead>
                    
                    <tr>
                    <th>Date</th>
                      <th>Quote ID</th>
                      <th>Items</th>
                      <th>Client</th>
                      <th>Location</th>
                      <th>Action</th>
                     
                    </tr>
                  </thead>
                  <tbody>
               
                   {quoteData && quoteData.data && quoteData.data.slice(0).reverse().map((quotes, index) => (
                <tr key={index}>
                 <td>{quotes.date}</td>
                  <td>{quotes.quoteId}</td>
                  <td>{quotes.items}</td>
                  <td>{quotes.client.name}</td>
                  <td>{quotes.client.location}</td>
               
            
      <td> <div class="col-6">
                    <a href={`quotesdet?id=${quotes.quoteId}`} class="btn btn-primary w-100" >view Quote</a>
                    </div></td>
    </tr>
    ))}

</tbody>


                </table>
                </div>
              </div>
              <hr />

              


            </div>
          </div>

    </div>
      </div>
    </div>



    </div>
  )
}
export default Quote;