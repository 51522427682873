import React, { useEffect, useState } from 'react';
import { ReactSession } from 'react-client-session';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Navbar from "./navbar";
import Header from "./header";
ReactSession.setStoreType("localStorage");

const App = () => {

  const userId = ReactSession.get("userId");
  const userType = ReactSession.get("userType");
  const userImage = ReactSession.get("Image");
  const navigate = useNavigate();
  const indexPath = '/';
  const user = { "userId": userId };
  const [costData, setCostData] = useState(null);
  
  useEffect(() => {
    if (userId === 0 ) {
      navigate(indexPath);
    }
  }, [userId, navigate]);


  
    const fetchcostData = async () => {
      try {
        const response = await axios.post('https://upvcapi.sninfoserv.com/costs/', user);
        setCostData(response.data.data);
      } catch (error) {
      } 
    };
  useEffect(() => {
    fetchcostData();
  }, []);
 
  
  const [meshPrice, setMeshPrice] = useState(0);
  const [bladePrice, setBladePrice] = useState(0);
  const [meshGasketPrice, setMeshGasketPrice] = useState(0);
  const [proGasketPrice, setProGasketPrice] = useState(0);
  const [wastagePrice, setWastagePrice] = useState(0);
  const [mplPrice, setMplPrice] = useState([]);
  const [hingesPrice, setHingesPrice] = useState([]);
  const [screwPrice, setSrewPrice] = useState(0);
  const [marketingPrice, setMarketingPrice] = useState([]);
  const [transportPrice, setTransportPrice] = useState([]);
  const [labourSPrice, setLabourSPrice] = useState([]);
  const [labourFPrice, setLabourFPrice] = useState([]);
  const [glassPrice, setGlassPrice] = useState([]);
  const [marginPrice, setMarginPrice] = useState([]);
  const [alTrackPrice, setAlTrackPrice] = useState(0);
  const [gRollPrice, setGRollPrice] = useState(0);
  const [meshRollPrice, setMeshRollPrice] = useState(0);
  const [wStripPrice, setWStripPrice] = useState(0);
  const [handlePrice, setHandlePrice] = useState([]);
  const [bArrestorPrice, setBArrestorPrice] = useState(0);
  const [dArrestorPrice, setDArrestorPrice] = useState(0);
  const [jStopperPrice, setJStopperPrice] = useState(0);
  const [boardPrice, setBoardPrice] = useState([]);
  const [steelPrice, setSteelPrice] = useState(0);

  
useEffect(() => {
  setMeshPrice(costData && costData.costData && costData.costData.mesh);
  setBladePrice(costData && costData.costData && costData.costData.blades);
  setMeshGasketPrice(costData && costData.costData && costData.costData.gasket['MeshGasket']);
  setProGasketPrice(costData && costData.costData && costData.costData.gasket['ProfileGasket']);
  setWastagePrice(costData && costData.costData && costData.costData.wastage);
  setMplPrice(costData && costData.costData && costData.costData.mpl);
  setHingesPrice(costData && costData.costData && costData.costData.hinges);
  setSrewPrice(costData && costData.costData && costData.costData.screws);
  setMarketingPrice(costData && costData.costData && costData.costData.marketing);
  setTransportPrice(costData && costData.costData && costData.costData.transport);
  setLabourSPrice(costData && costData.costData && costData.costData.labourSite);
  setLabourFPrice(costData && costData.costData && costData.costData.labourFactory);
  setGlassPrice(costData && costData.costData && costData.costData.glass);
  setMarginPrice(costData && costData.costData && costData.costData.margin);
  setAlTrackPrice(costData && costData.costData && costData.costData.alTrack);
  setGRollPrice(costData && costData.costData && costData.costData.gRoll);
  setMeshRollPrice(costData && costData.costData && costData.costData.meshRoll);
  setWStripPrice(costData && costData.costData && costData.costData.wStrip);
  setHandlePrice(costData && costData.costData && costData.costData.handle);
  setBArrestorPrice(costData && costData.costData && costData.costData.bArrestor);
  setDArrestorPrice(costData && costData.costData && costData.costData.dArrestor);
  setJStopperPrice(costData && costData.costData && costData.costData.jStopper);
  setBoardPrice(costData && costData.costData && costData.costData.board);
  setSteelPrice(costData && costData.costData && costData.costData.Steel);
}, [costData]);


const mplValue = (key, newValue) => {
    setMplPrice(prevPrice => ({
        ...prevPrice,
        [key]: newValue
    }));
};

const hingesValue = (key, newValue) => {
    setHingesPrice(prevPrice => ({
        ...prevPrice,
        [key]: newValue
    }));
};

const marketingValue = (key, newValue) => {
    setMarketingPrice(prevPrice => ({
        ...prevPrice,
        [key]: newValue
    }));
};

const transportValue = (key, newValue) => {
    setTransportPrice(prevPrice => ({
        ...prevPrice,
        [key]: newValue
    }));
};

const labourSValue = (key, newValue) => {
    setLabourSPrice(prevPrice => ({
        ...prevPrice,
        [key]: newValue
    }));
};

const labourFValue = (key, newValue) => {
    setLabourFPrice(prevPrice => ({
        ...prevPrice,
        [key]: newValue
    }));
};

const glassValue = (key, newValue) => {
    setGlassPrice(prevPrice => ({
        ...prevPrice,
        [key]: newValue
    }));
};

const marginValue = (key, newValue) => {
    setMarginPrice(prevPrice => ({
        ...prevPrice,
        [key]: newValue
    }));
};

const handleValue = (key, newValue) => {
    handlePrice(prevPrice => ({
        ...prevPrice,
        [key]: newValue
    }));
};

const boardValue = (key, newValue) => {
    setBoardPrice(prevPrice => ({
        ...prevPrice,
        [key]: newValue
    }));
};

const newData = {
    mesh:meshPrice,
    blades:bladePrice,
    gasket:{
      MeshGasket:meshGasketPrice,
	  ProfileGasket:proGasketPrice
    },
    Steel:steelPrice,
    wastage:wastagePrice,
    mpl:mplPrice,
    hinges:hingesPrice,
    screws:screwPrice,
    marketing:marketingPrice,
    transport:transportPrice,
    labourSite:labourSPrice,
    labourFactory:labourFPrice,
    glass:glassPrice,
    margin:marginPrice,
    alTrack:alTrackPrice,
    gRoll:gRollPrice,
    meshRoll:meshRollPrice,
    wStrip:wStripPrice,
    handle:handlePrice,
    bArrestor:bArrestorPrice,
    dArrestor:dArrestorPrice,
    jStopper:jStopperPrice,
    board:boardPrice
};
    
    const updateData = async () => {
        const sendData = {user: userId, data: newData};
        try {
          const response = await axios.post('https://upvcapi.sninfoserv.com/costs-update/', sendData);
          if(response.data.status === 'success'){
            fetchcostData();
            alert(response.data.message);
          }else{
            alert('Unable to process your request please try again!');
          }
        } catch (error) {
        } 
    };

    return (
    <>
    <Navbar />
    
    <div class="pc-container">
      <div class="pc-content">
        <div class="row">
          <div class="col-md-12 col-xxl-12 col-xl-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                    <div class="col-md-6 col-xxl-2 col-xl-2 mt-3">
                        <label htmlFor="name">Steel Price</label>
                        <input type="text" id="name" name="name" class="form-control mt-1" value={steelPrice} onChange={(event) => setSteelPrice(event.target.value)}/>
                    </div>
                    <div class="col-md-6 col-xxl-2 col-xl-2 mt-3">
                        <label htmlFor="name">Mesh Price</label>
                        <input type="text" id="name" name="name" class="form-control mt-1" value={meshPrice} onChange={(event) => setMeshPrice(event.target.value)} />
                    </div>
                    <div class="col-md-6 col-xxl-2 col-xl-2 mt-3">
                        <label htmlFor="name">Louver Blades Price</label>
                        <input type="text" id="name" name="name" class="form-control mt-1" value={bladePrice} onChange={(event) => setBladePrice(event.target.value)} />
                    </div>
                    <div class="col-md-6 col-xxl-2 col-xl-2 mt-3">
                        <label htmlFor="name">Mesh Gasket Price</label>
                        <input type="text" id="name" name="name" class="form-control mt-1" value={meshGasketPrice} onChange={(event) => setMeshGasketPrice(event.target.value)} />
                    </div>
                    <div class="col-md-6 col-xxl-2 col-xl-2 mt-3">
                        <label htmlFor="name">Profile Gasket Price</label>
                        <input type="text" id="name" name="name" class="form-control mt-1" value={proGasketPrice} onChange={(event) => setProGasketPrice(event.target.value)} />
                    </div>
                    <div class="col-md-6 col-xxl-2 col-xl-2 mt-3">
                        <label htmlFor="name">Wastage %</label>
                        <input type="text" id="name" name="name" class="form-control mt-1" value={wastagePrice} onChange={(event) => setWastagePrice(event.target.value)} />
                    </div>
                    <div class="col-md-6 col-xxl-2 col-xl-2 mt-3">
                        <label htmlFor="name">Screws Price</label>
                        <input type="text" id="name" name="name" class="form-control mt-1" value={screwPrice} onChange={(event) => setSrewPrice(event.target.value)} />
                    </div>
                    <div class="col-md-6 col-xxl-2 col-xl-2 mt-3">
                        <label htmlFor="name">Aluminium Track</label>
                        <input type="text" id="name" name="name" class="form-control mt-1" value={alTrackPrice} onChange={(event) => setAlTrackPrice(event.target.value)} />
                    </div>
                    <div class="col-md-6 col-xxl-2 col-xl-2 mt-3">
                        <label htmlFor="name">Grove Roller Price</label>
                        <input type="text" id="name" name="name" class="form-control mt-1" value={gRollPrice} onChange={(event) => setGRollPrice(event.target.value)} />
                    </div>
                    <div class="col-md-6 col-xxl-2 col-xl-2 mt-3">
                        <label htmlFor="name">Mesh Roller Price</label>
                        <input type="text" id="name" name="name" class="form-control mt-1" value={meshRollPrice} onChange={(event) => setMeshRollPrice(event.target.value)}/>
                    </div>
                    <div class="col-md-6 col-xxl-2 col-xl-2 mt-3">
                        <label htmlFor="name">Weathe Strip Price</label>
                        <input type="text" id="name" name="name" class="form-control mt-1" value={wStripPrice} onChange={(event) => setWStripPrice(event.target.value)}/>
                    </div>
                    <div class="col-md-6 col-xxl-2 col-xl-2 mt-3">
                        <label htmlFor="name">Bump Arrester Price</label>
                        <input type="text" id="name" name="name" class="form-control mt-1" value={bArrestorPrice} onChange={(event) => setBArrestorPrice(event.target.value)} />
                    </div>
                    <div class="col-md-6 col-xxl-2 col-xl-2 mt-3">
                        <label htmlFor="name">Dust Arrester Price</label>
                        <input type="text" id="name" name="name" class="form-control mt-1" value={dArrestorPrice} onChange={(event) => setDArrestorPrice(event.target.value)}/>
                    </div>
                    <div class="col-md-6 col-xxl-2 col-xl-2 mt-3">
                        <label htmlFor="name">Jump Stopper Price</label>
                        <input type="text" id="name" name="name" class="form-control mt-1" value={jStopperPrice} onChange={(event) => setJStopperPrice(event.target.value)} />
                    </div>
                    
                </div>
                <div class="row">
                    <div class="col-md-6 col-xxl-3 col-xl-3 mt-3">
                    <label htmlFor="name">MPL</label>
                    <div class="row">
                    {mplPrice && Object.entries(mplPrice).map(([key, value], index) => (
                      <>
                      <div class="col-md-6 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={key} readOnly />
                      </div>
                      <div class="col-md-6 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={value} onChange={(e) => mplValue(key, e.target.value)}   />
                      </div>
                     </>
                    ))}
                    </div>
                    </div>

                    <div class="col-md-6 col-xxl-3 col-xl-3 mt-3">
                    <label htmlFor="name">Hinges</label>
                    <div class="row">
                    {hingesPrice && Object.entries(hingesPrice).map(([key, value]) => (
                      <>
                      <div class="col-md-6 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={key} readOnly />
                      </div>
                      <div class="col-md-6 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={value} onChange={(e) => hingesValue(key, e.target.value)} />
                      </div>
                     </>
                    ))}
                    </div>
                    </div>

                    <div class="col-md-6 col-xxl-3 col-xl-3 mt-3">
                    <label htmlFor="name">Marketing Overheads</label>
                    <div class="row">
                    {marketingPrice && Object.entries(marketingPrice).map(([key, value]) => (
                      <>
                      <div class="col-md-6 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={key} readOnly />
                      </div>
                      <div class="col-md-6 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={value} onChange={(e) => marketingValue(key, e.target.value)} />
                      </div>
                     </>
                    ))}
                    </div>
                    </div>

                    <div class="col-md-6 col-xxl-3 col-xl-3 mt-3">
                    <label htmlFor="name">Transportation Charges</label>
                    <div class="row">
                    {transportPrice && Object.entries(transportPrice).map(([key, value]) => (
                      <>
                      <div class="col-md-6 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={key} readOnly />
                      </div>
                      <div class="col-md-6 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={value} onChange={(e) => transportValue(key, e.target.value)} />
                      </div>
                     </>
                    ))}
                    </div>
                    </div>

                    <div class="col-md-6 col-xxl-3 col-xl-3 mt-3">
                    <label htmlFor="name">Labour (Site) Charges</label>
                    <div class="row">
                    {labourSPrice && Object.entries(labourSPrice).map(([key, value]) => (
                      <>
                      <div class="col-md-6 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={key} readOnly />
                      </div>
                      <div class="col-md-6 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={value} onChange={(e) => labourSValue(key, e.target.value)} />
                      </div>
                     </>
                    ))}
                    </div>
                    </div>

                    <div class="col-md-6 col-xxl-3 col-xl-3 mt-3">
                    <label htmlFor="name">Labour (Factory) Charges</label>
                    <div class="row">
                    {labourFPrice && Object.entries(labourFPrice).map(([key, value]) => (
                      <>
                      <div class="col-md-6 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={key} readOnly />
                      </div>
                      <div class="col-md-6 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={value} onChange={(e) => labourFValue(key, e.target.value)}/>
                      </div>
                     </>
                    ))}
                    </div>
                    </div>

                    <div class="col-md-6 col-xxl-3 col-xl-3 mt-3">
                    <label htmlFor="name">Glass Price</label>
                    <div class="row">
                    {glassPrice && Object.entries(glassPrice).map(([key, value]) => (
                      <>
                      <div class="col-md-6 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={key} readOnly />
                      </div>
                      <div class="col-md-6 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={value} onChange={(e) => glassValue(key, e.target.value)}/>
                      </div>
                     </>
                    ))}
                    </div>
                    </div>

                    <div class="col-md-6 col-xxl-3 col-xl-3 mt-3">
                    <label htmlFor="name">Profit Margin</label>
                    <div class="row">
                    {marginPrice && Object.entries(marginPrice).map(([key, value]) => (
                      <>
                      <div class="col-md-6 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={key} readOnly />
                      </div>
                      <div class="col-md-6 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={value} onChange={(e) => marginValue(key, e.target.value)} />
                      </div>
                     </>
                    ))}
                    </div>
                    </div>

                    <div class="col-md-6 col-xxl-3 col-xl-3 mt-3">
                    <label htmlFor="name">Handles Changes</label>
                    <div class="row">
                    {handlePrice && Object.entries(handlePrice).map(([key, value]) => (
                      <>
                      <div class="col-md-6 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={key} readOnly />
                      </div>
                      <div class="col-md-6 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={value} onChange={(e) => handleValue(key, e.target.value)} />
                      </div>
                     </>
                    ))}
                    </div>
                    </div>

                    <div class="col-md-6 col-xxl-3 col-xl-3 mt-3">
                    <label htmlFor="name">Board Changes</label>
                    <div class="row">
                    {boardPrice && Object.entries(boardPrice).map(([key, value]) => (
                      <>
                      <div class="col-md-6 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={key} readOnly />
                      </div>
                      <div class="col-md-6 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={value} onChange={(e) => boardValue(key, e.target.value)}/>
                      </div>
                     </>
                    ))}
                    </div>
                    </div>

                    <div class="col-md-12 col-xxl-12 col-xl-12 mt-5" align="center">
                        <button class="btn btn-success w-25" onClick={updateData}>Update Price</button>
                    </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



    </>
    );
};
    
    export default App;