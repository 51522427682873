import React, { useEffect, useState } from 'react';
import { ReactSession } from 'react-client-session';
import axios from 'axios';
import Navbar from './navbar';
import Header from './header';
import numeral from 'numeral';
import left from '../images/handleLeft.png';
import right from '../images/handleRight.png';
import meshimg from '../images/mesh.png';
import Handle from '../images/slideHandle.png';
import Fan from '../images/fan.png';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Image from '../images/casement-door.png';


 function Downlaod({ quotes }) {
    const urlParams = new URLSearchParams(window.location.search);
    const quoteIds = urlParams.get('id');
    const arrayValues = urlParams.get('arrayValues');
    console.log(arrayValues);
    const userId = ReactSession.get('userId');

const Number = ReactSession.get('Number');
const Address = ReactSession.get('Address');
const Name = ReactSession.get('userName');
const Image = ReactSession.get('Image');


    const [quoteData, setQuoteData] = useState(null);
    const [filteredArray, setFilteredArray] = useState([]);
  
    const [bottomFrame, setBottomFrame] = useState('yes');
    const [mesh, setMesh] = useState('no');
    const [panelHeights, setpanelHeight] = useState(0); 
    const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
    const [screen, setScreen] = useState(deviceWidth > 700 ? 40 : 40);
    const [fanTop, setfanTop] = useState('Top');
    const [fanLeft, setfanLeft] = useState('Left');
    const [divider, setDevider] = useState('Vertical');
    const [switchs, setSwitch] = useState('on');
    const [switchFixed, setFixed] = useState('on');
    const [fanType, setfanType] = useState(1);
    const [total, setTotal] = useState(0);
    const [qty, setQty] = useState(0);
    const [totalframearea, setTotalFramearea] = useState(0);
    const [gst, setGst] = useState(0);
    const [average, setAverage] = useState(0);
    const [user, setUser]= useState([]);

  
   
  
    
    
    useEffect(() => {
      const updateDeviceWidth = () => {
        setDeviceWidth(window.innerWidth);
        setScreen(window.innerWidth > 700 ? 40 : 40);
      };
  
      window.addEventListener('resize', updateDeviceWidth);
  
      return () => {
        window.removeEventListener('resize', updateDeviceWidth);
      };
    }, []);


    
   
  
    const fetchData = async () => {
      try {
        const response = await axios.post(`https://upvcapi.sninfoserv.com/all-quotes/`, { user: userId });
        setQuoteData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    useEffect(() => {
      fetchData();
    }, []);
  
    // Ensure quoteData is valid before using filter method
    const filteredQuotes = quoteData && quoteData.data.filter(quote => quote.quoteId === quoteIds);
    console.log(filteredQuotes);


    useEffect(() => {
      if (filteredQuotes && filteredQuotes.length > 0) {
        let sum = 0;
        let qty = 0;
        let totalframe = 0;
        let average = 0;
        filteredQuotes.forEach(quote => {
          quote.quoteDetails.forEach(detail => {
            qty +=(detail.quoteData.qty);
            totalframe +=(detail.quoteData.frameArea*detail.quoteData.qty);
            sum += (detail.quoteData.grandTotal*detail.quoteData.qty);
            
           // console.log(sum);
          });
        });
        average=(sum/totalframe);
        console.log(average);
        setTotal(sum);
        setQty(qty);
        setTotalFramearea(totalframe);
        setGst((18/100)*sum);
        setAverage(average);
      }
    }, [filteredQuotes]);
    
  
    useEffect(() => {
      if (arrayValues) {
        // Split arrayValues string into an array of values
        const valuesArray = arrayValues.split(',');
  
        // Filter quotes based on valuesArray
        const filtered = quotes.filter(quote =>
          valuesArray.every(value => quote.details.includes(value))
        );
  
        setFilteredArray(filtered);
        console.log(filtered);
      }
    }, [arrayValues, quotes]);
  
    
    
  
    
  
    return (
  
      <div >

        <div class="modal fade custom-modal" id="onloadModal" tabindex="-1" aria-labelledby="onloadModalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content" align="center">
               <div class="mt-3 mb-3">
                <h5>Preparing Quote...</h5>
               </div>
                
            </div>
          </div>
        </div>


     
      <div className='hide' id="pdf-content" style={{minWidth: '1000px', maxWidth: '1000px', padding:"20px"}}>        
	    <table width='100%'>
      <th width='40%' style={{textAlign: 'left'}}>
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <img src="assets/images/favicon.svg" width='30%' alt='Logo' style={{ marginRight: '10px' }} />
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <p style={{ fontSize: '15px', margin: 0 , color: '#36454F'}}>
        {Name} <br />
        {Address}<br />
        {Number}
      </p>
    </div>
  </div>
</th>

		 {/* <th width='10%'  style={{textAlign: 'center',}}>
		
        <p> {Name} <br />
		     {Address}<br />
		     {Number}
        </p>
		 </th> */}
     <th width='30%' style={{ textAlign: 'right', verticalAlign: 'middle' }}>
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
    <img src="assets/images/logo.png" width='35%' alt='Logo' style={{ marginBottom: '10px' }} />
    <img src="assets/images/favicon.svg" width='20%' alt='Logo' />
  </div>
</th>

	    </table>
      <hr></hr>
      <table width='100%'>
		 <th width='50%' style={{textAlign: 'centre', paddingBottom:"20px", color: '#36454F'}}>
     {filteredQuotes?.map((quote, index) => (
    <div key={index}>
      <h5 style={{color: '#36454F'}}>Client: {quote.client.name}</h5>
      <h5 style={{color: '#36454F'}}>Location: {quote.client.location}</h5>
      <h5 style={{color: '#36454F'}}>Number: {quote.client.number}</h5>
      
    </div>
  ))}
     </th>
		 <th width='50%'  style={{textAlign: 'right', top:'10px', color:"#36454F"}}>
     {filteredQuotes?.map((quote, index) => (
    <div key={index}>
      <h5 style={{color: '#36454F'}}>Date : {quote.date}</h5>
      <h5 style={{color: '#36454F'}}>Quote No : {quote.quoteId}</h5>
      
    </div>
  ))}
		 </th>
	    </table>
	    <table style={{width:"100%", border:'0.5px solid #666',  borderCollapse: 'collapse'}}>
            <thead >
                <tr>
                   <th style={{border:'0.5px solid #666',  padding:'2px', width:"5%", textAlign:'center', fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>S.No</th>
                   <th style={{border:'0.5px solid #666',  padding:'2px', width:"5%", textAlign:'center', fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>Description</th>
                   <th style={{border:'0.5px solid #666',  padding:'2px', width:"5%", textAlign:'center', fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>Hardware</th>
                   <th style={{border:'0.5px solid #666', padding:'2px', textAlign:'center',fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>Series</th>
                   <th colspan="2" style={{border:'0.5px solid #666', padding:'2px', textAlign:'center',fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', borderBottom: '1px solid #36454F', position: 'relative', color:"#36454F"}}>
  <div style={{ margin: '0', padding: '0'}}>
    Sizes in Feet
  </div>
  <div style={{ borderBottom: '1px solid #36454F', margin: '0', color:"#36454F"}}></div>
  <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px',  verticalAlign: 'middle',color:"#36454F"}}>
    <div style={{flex: '1',  borderRight: '1px solid #36454F', padding: '3px', color:"#36454F"}}>Width</div>
    <div style={{flex: '1',  borderRight: '1px solid #fff', padding: '3px',color:"#36454F"}}>Height</div>
  </div>
</th>
                   <th style={{border:'0.5px solid #666', padding:'2px', textAlign:'center',fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif',color:"#36454F"}}>Area</th>
                    <th style={{border:'0.5px solid #666', padding:'2px', textAlign:'center',fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif',color:"#36454F"}}>Qty</th>
                    <th style={{border:'0.5px solid #666', padding:'2px', textAlign:'center',fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif',color:"#36454F"}}>Total Area</th>
                    <th style={{border:'0.5px solid #666', padding:'2px', textAlign:'center',fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif',color:"#36454F"}}>Amount</th>
                </tr>
            </thead>
            {filteredQuotes?.map((quote, index) => (
    <tbody key={index}>
      {quote.quoteDetails.map((detail, detailIndex) => (
        <tr key={detailIndex}>
          <td style={{border:'0.5px solid #666', padding:'10px', textAlign:'center',fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>{detailIndex+1}</td>
          
                   
                     <td style={{border:'0.5px solid #666', padding:'15px', width:'30%',  textAlign:'center',fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>
                    
          {detail.quoteData.item === 'CasementDoor' && (
           
              
              <div >
  
  <div style={{float:'left', width: detail.quoteData.boxWidth*screen+'px',  height:detail.quoteData.boxHeight*screen+'px', border:'0.5px solid #666', borderBottom: bottomFrame === 'no' ? '1px solid #36454F33350' : '1px solid #36454F'  }}>
  {[...Array(detail.quoteData.numFrames)].map((_, index) => (
  <div style={{float:'left', margin:'5px', border:'0.5px solid #666',width:`calc(${detail.quoteData.boxWidth*screen/detail.quoteData.numFrames+'px'} - 12px)`, height: bottomFrame === 'no' ? `calc(${detail.quoteData.boxHeight*screen+'px'} - 6px)` : `calc(${detail.quoteData.boxHeight*screen+'px'} - 12px)`, } }> 
  {detail.quoteData.newHeights !== 0 ? (
  <>
  
  { detail.quoteData.boxPosition === 'Top' ? (
      <div></div>
    ) : (
      [...Array(detail.quoteData.numFirstInnerBoxes)].map((_, index) => (
  <div style={{width: `calc(${detail.quoteData.boxWidth*screen/detail.quoteData.numFrames+'px'} - 23px)`, height:`calc(${(detail.quoteData.newHeights * screen)}px )`, background: 'lightblue', margin:'5px', border:'0.5px solid #666'}}></div>
  ))
  )}
  
  <div style={{ display: 'flex' }}>
    {[...Array(detail.quoteData.numSecondInnerBoxes)].map((_, index) => (
      <div key={index} style={{ flex: '1', height: bottomFrame === 'no' ? `calc(${(detail.quoteData.boxHeight - detail.quoteData.newHeights) * screen}px - 23px)` : `calc(${(detail.quoteData.boxHeight - detail.quoteData.newHeights) * screen}px - 30px)`, width: `calc(${detail.quoteData.newWidths[index] * screen}px - 5px - 19px)`, backgroundColor: detail.quoteData.panelType === 1 ? 'lightgray' : detail.quoteData.panelType === 2 ? 'lightyellow' : 'lightblue', marginTop: detail.quoteData.boxPosition === 'Top' ? '5px' : '0px', marginRight: index === detail.quoteData.numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #36454F' }}>
       
              <div className="expanding-arrow-line" style={{ 
                position: 'absolute',
                float:'left', 
                width: `calc(${detail.quoteData.newWidths[index] * screen}px - 15px)`,
                bottom:'-10px', 
                top: `calc(${detail.quoteData.boxHeight* screen}px - 25px)`,
                
               }}>
            
              </div>
       
       
        { index === 0 && ( detail.quoteData.boxHandle[index] === 'left' ? (
             <img
             src={left}
             width='15'
             style={{
               float: 'left',
               marginTop: (detail.quoteData.boxHeight) * ((screen/2)-(screen/15))+'px',
               marginLeft: '2px',
               width: screen === 4 ? '13px' : '8px'
             }}
             
             alt="Left Handle"
           />
         ) : (
           <img
             src={right}
             width='15'
             style={{
               float: 'right',
               marginTop: (detail.quoteData.boxHeight) * ((screen/2)-(screen/15))+'px',
               marginRight: '2px',
               width: screen === 4 ? '13px' : '8px'
             }}
             
             alt="Right Handle"
           />
         ) )}
  
  
              { mesh === 'yes' ? (
                <img src={meshimg} style={{width:'50px', float:'left', marginLeft: detail.quoteData.boxHandle[index] === 'left' ? '-15px' : '0px', marginTop: `calc(${((detail.quoteData.boxHeight-detail.quoteData.newHeights)*screen)}px - ${bottomFrame === 'no' ? '76px' : '80px' })`}} />
                ):(<></>)
              }
        
              
  
      </div>
  
    ))}
    
  </div>
  { detail.quoteData.boxPosition === 'Bottom' ? (
      <div></div>
    ) : (
      [...Array(detail.quoteData.numFirstInnerBoxes)].map((_, index) => (
  <div style={{width: `calc(${detail.quoteData.boxWidth*screen/detail.quoteData.numFrames+'px'} - 23px)`, height:`calc(${(detail.quoteData.newHeights * screen)}px )`, background: 'lightgreen', margin:'5px', border:'0.5px solid #666'}}></div>
  ))
  )}
  
  { detail.quoteData.numSecondInnerBoxes === 0 ? (
        <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((detail.quoteData.boxWidth/detail.quoteData.numFrames)* screen)+'px',top:(detail.quoteData.boxHeight-detail.quoteData.newHeights)*screen+'px', }} >
       
      </div>
      ) : (
        <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((detail.quoteData.boxWidth/detail.quoteData.numFrames)* screen)+'px',bottom:'-10px',top:'50px', }} >
         
        </div>
      )}
  </>
  ) : (
  <>
  <div style={{ display: 'flex' }}>
  {[...Array(detail.quoteData.numSecondInnerBoxes)].map((_, index) => (
    panelHeights === 0 ? (
    <div key={index} style={{ flex: '1', height: bottomFrame === 'no' ? `calc(${(detail.quoteData.boxHeight) * screen}px - 18px )` : `calc(${(detail.quoteData.boxHeight) * screen}px - 23px )`, width: `calc(${detail.quoteData.newWidths[index] * screen}px - 5px - 19px)`, backgroundColor: detail.quoteData.panelType === 1 ? 'lightgray' : detail.quoteData.panelType === 2 ? 'lightyellow' : 'lightblue', marginRight: index === detail.quoteData.numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #36454F',marginTop:'5px' }}>
     
     <div className="expanding-arrow-line" style={{ 
                float:'left', 
                width: `calc(${detail.quoteData.newWidths[index] * screen}px - 20px)`,
                bottom:'-10px', 
                top: detail.quoteData.boxHeight * screen + 'px',
               
               }}>
               
              </div>
     
      { index === 0 &&  detail.quoteData.boxHandle[index] === 'left' && (
      
              <img
                src={right}
                width='15'
                style={{
                  float: 'right',
                  marginTop: (detail.quoteData.boxHeight) * ((screen/2)-(screen/15))+'px',
                  marginRight: '2px',
                  width: screen === 4 ? '13px' : '8px'
                }}
                
                alt="Left Handle"
              />
              
  
            )}  
            { index === 1 &&  detail.quoteData.boxHandle[index] === 'right' &&(
           
              <img
                src={left}
                width='15'
                style={{
                  float: 'left',
                  marginTop: (detail.quoteData.boxHeight) * ((screen/2)-(screen/15))+'px',
                  marginLeft: '2px',
                  width: screen === 4 ? '13px' : '8px'
                }}
                
                alt="Right Handle"
              />
              
            )}
  
  
             { mesh === 'yes' ? (
                <img src={meshimg} style={{width:'50px', float:'left', marginLeft: detail.quoteData.boxHandle[index] === 'left' ? '-15px' : '0px', marginTop: `calc(${((detail.quoteData.boxHeight-detail.quoteData.newHeights)*screen)}px - ${bottomFrame === 'no' ? '71px' : '75px' })`}} />
                ):(<></>)
              }
             
              
              
           
    </div>
    ) : (
      <div key={index} style={{  height: panelHeights*screen+'px', minWidth:`calc(${detail.quoteData.newWidths[index] * screen}px - 5px - 19px)`, backgroundColor: 'lightblue', border: '1px solid #36454F',margin:'5px 5px 5px'}}>
    
      <div key={index} style={{ height: bottomFrame === 'no' ? `calc(${(detail.quoteData.boxHeight-panelHeights) * screen}px - 18px )` : `calc(${(detail.quoteData.boxHeight-panelHeights) * screen}px - 30px )`, width: `calc(${detail.quoteData.newWidths[index] * screen}px - 5px - 19px)`, backgroundColor: detail.quoteData.boxColors[index] || 'lightblue',  border: '1px solid #36454F',marginTop: `calc(${panelHeights * screen}px + 5px )` }}>
        {  index === 0 && ( detail.quoteData.boxHandle[index] === 'left' ? (
          <>
                <img
                  src={left}
                  width='15'
                  style={{
                    float: 'left',
                    marginTop: (detail.quoteData.boxHeight) * ((screen/2)-(screen/15))+'px',
                    marginLeft: '2px',
                    width: screen === 4 ? '13px' : '8px'
                  }}
                  
                  alt="Left Handle"
                />
                
                </>
              ) : (
                <>
                <img
                  src={right}
                  width='15'
                  style={{
                    float: 'right',
                    marginTop: (detail.quoteData.boxHeight) * ((screen/2)-(screen/15))+'px',
                    marginRight: '2px',
                    width: screen === 4 ? '13px' : '8px'
                  }}
                  
                  alt="Right Handle"
                />
                
                </>
              ) )}
               { mesh === 'yes' ? (
                <img src={meshimg} style={{width:'50px', float:'left',  bottom:'0px', marginTop:`calc(${(detail.quoteData.boxHeight) * screen}px - 75px )` }} />
               ):(<></>)}
               
                
                <div className="expanding-arrow-line" style={{ 
                  float:'left', 
                  width: `calc(${detail.quoteData.newWidths[index] * screen}px - 25px)`,
                   
                  ...(index === 0 && mesh === 'yes' && { top: detail.quoteData.boxHeight * ((screen/2)-(screen*0.43)) + 'px' }),
                  ...(index === 0 && mesh === 'no' && { top: detail.quoteData.boxHeight * (1.3) + 'px' }),
                  ...(index !== 0 && mesh === 'yes' &&  { top: detail.quoteData.boxHeight * (0.27) + 'px' }),
                  ...(index !== 0 && mesh === 'no' &&  { top: detail.quoteData.boxHeight * ((screen/2)+(screen/2.02)) + 'px' }),
                 }}>
                 
                </div>
             
      </div>
      </div>
    )
  ))}
  </div> 
      { detail.quoteData.numSecondInnerBoxes === 0 ? (
        <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((detail.quoteData.boxWidth/detail.quoteData.numFrames)* screen)+'px', top:detail.quoteData.boxHeight*screen+'px',  }} >
       
      </div>
      ) : (
        <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((detail.quoteData.boxWidth/detail.quoteData.numFrames)* screen)+'px', bottom:'-10px',top:'40px', }} >
         
        </div>
      )}
  </>
  )}
  </div>
   ))}
  </div>
  
  { detail.quoteData.boxPosition === 'Top' ? (
  detail.quoteData.newHeights === 0 ? (
  
    <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
      <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}px)`, top: '0', position: 'relative' }}>
       
      </div>
    </div>
  ) : (  
      <>
        <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
  
          <div className="expanding-arrow-line" style={{order: 1, flex:1, float: 'left', height: `calc(${(detail.quoteData.boxHeight-detail.quoteData.newHeights) * screen}px - 20px)`, top: '0', position: 'relative' }}>
           
          </div>
           <div className="expanding-arrow-line" style={{order: 2, flex:2, float: 'left', height: `calc(${detail.quoteData.newHeights * screen}px `, top: `calc(${(detail.quoteData.boxHeight-detail.quoteData.newHeights) * screen}px - 10px)`, position: 'relative' }}>
           
          </div>
  
        </div>
  
        <div style={{ width: '30px', float: 'left', paddingLeft: '0px', textAlign: 'left' }}>
        <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}px)`, top: '0', position: 'relative' }}>
        
        </div>
      </div>
      </>
  )
  ) : (
  detail.quoteData.newHeights === 0 ? (
  
    <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
      <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}px)`, top: '0', position: 'relative' }}>
       
      </div>
    </div>
  ) : (  
      <>
        <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
          <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${detail.quoteData.newHeights * screen}px + 10px)`, top: '0', position: 'relative' }}>
           
          </div>
          <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight-detail.quoteData.newHeights) * screen}px - 20px)`, top: `calc(${detail.quoteData.newHeights * screen}px + 20px)`, position: 'relative' }}>
          
          </div>
        </div>
        <div style={{ width: '30px', float: 'left', paddingLeft: '0px', textAlign: 'left' }}>
        <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}px)`, top: '0', position: 'relative' }}>
        
        </div>
      </div>
      </>
  )
  )}
    
  </div>
                
              
          )}
           {detail.quoteData.item === 'CasementWindow' && (
           
             
             <div >
  
  <div style={{float:'left', width: detail.quoteData.boxWidth*screen+'px', height:detail.quoteData.boxHeight*screen+'px', border:'0.5px solid #666'}}>
  {[...Array(detail.quoteData.numFrames)].map((_, index) => (
  <div style={{float:'left', margin:'5px', border:'0.5px solid #666',width:`calc(${detail.quoteData.boxWidth*screen/detail.quoteData.numFrames+'px'} - 12px)`, height:`calc(${detail.quoteData.boxHeight*screen+'px'} - 12px)`}}> 
  {detail.quoteData.newHeights !== 0 ? (
  <>
  
  { detail.quoteData.boxPosition === 'Top' ? (
     <div></div>
   ) : (
     [...Array(detail.quoteData.numFirstInnerBoxes)].map((_, index) => (
  <div style={{width: `calc(${detail.quoteData.boxWidth*screen/detail.quoteData.numFrames+'px'} - 23px)`, height:`calc(${(detail.quoteData.newHeights * screen)}px )`, background: 'lightgreen', margin:'5px', border:'0.5px solid #666'}}></div>
  ))
  )}
  
  <div style={{ display: 'flex' }}>
   {[...Array(detail.quoteData.numSecondInnerBoxes)].map((_, index) => (
     <div key={index} style={{ flex: '1', height: `calc(${(detail.quoteData.boxHeight - detail.quoteData.newHeights) * screen}px - 30px)`, width: `calc(${detail.quoteData.newWidths[index] * screen}px - 5px - 19px)`, backgroundColor: detail.quoteData.boxColors[index] || 'lightblue', marginTop: detail.quoteData.boxPosition === 'Top' ? '5px' : '0px', marginRight: index === detail.quoteData.numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #36454F' }}>
      
             <div className="expanding-arrow-line" style={{ 
               position: 'absolute',
               float:'left', 
               width: `calc(${detail.quoteData.newWidths[index] * screen}px - 15px)`,
               bottom:'-10px', 
               top: `calc(${detail.quoteData.boxHeight * screen}px - 25px)` ,
              
              }}>
              
             </div>
  
      
      
       { detail.quoteData.boxNames[index] === 'Shutter' && ( detail.quoteData.boxHandle[index] === 'left' ? (
             <img
               src={left}
               style={{
                 float: 'left',
                 marginTop: `calc(${(((detail.quoteData.boxHeight-detail.quoteData.newHeights)*screen)/2)}px - 15px`,
                 marginLeft: '2px',
                 width: screen === 4 ? '13px' : '8px'
               }}
               
               alt="Left Handle"
             />
           ) : (
             <img
               src={right}
               style={{
                 float: 'right',
                 marginTop: `calc(${(((detail.quoteData.boxHeight-detail.quoteData.newHeights)*screen)/2)}px - 15px`,
                 marginRight: '2px',
                 width: screen === 4 ? '13px' : '8px'
               }}
               
               alt="Right Handle"
             />
             
           ) )}
  
             {mesh === 'yes' && detail.quoteData.boxNames[index] === 'Shutter' && (
               <img src={meshimg} style={{width:'50px', float:'left', marginLeft: detail.quoteData.boxHandle[index] === 'left' ? '-15px' : '0px', marginTop: `calc(${((detail.quoteData.boxHeight-detail.quoteData.newHeights)*screen)}px - 82px)`}} />
               )
             }
           
             
     </div>
  
   ))}
   
  </div>
  { detail.quoteData.boxPosition === 'Bottom' ? (
     <div></div>
   ) : (
     [...Array(detail.quoteData.numFirstInnerBoxes)].map((_, index) => (
  <div style={{width: `calc(${detail.quoteData.boxWidth*screen/detail.quoteData.numFrames+'px'} - 23px)`, height:`calc(${(detail.quoteData.newHeights * screen)}px )`, background: 'lightgreen', margin:'5px', border:'0.5px solid #666'}}></div>
  ))
  )}
  
  { detail.quoteData.numSecondInnerBoxes === 0 ? (
       <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((detail.quoteData.boxWidth/detail.quoteData.numFrames)* screen)+'px',top:(detail.quoteData.boxHeight-detail.quoteData.newHeights)*screen+'px', }} >
      
     </div>
     ) : (
       <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((detail.quoteData.boxWidth/detail.quoteData.numFrames)* screen)+'px',bottom:'-10px',top:'50px', }} >
        
       </div>
     )}
  </>
  ) : (
  <>
  <div style={{ display: 'flex' }}>
  {[...Array(detail.quoteData.numSecondInnerBoxes)].map((_, index) => (
   <div key={index} style={{ flex: '1', height: `calc(${(detail.quoteData.boxHeight) * screen}px - 23px)`, width: `calc(${detail.quoteData.newWidths[index] * screen}px - 5px - 19px)`, backgroundColor: detail.quoteData.boxColors[index] || 'lightblue', marginRight: index === detail.quoteData.numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #36454F',marginTop:'5px' }}>
     
             <div className="expanding-arrow-line" style={{ 
               float:'left', 
               width: `calc(${detail.quoteData.newWidths[index] * screen}px - 15px)`,
               bottom:'-10px', 
               top: detail.quoteData.boxHeight *screen + 'px',
              
              }}>
              
             </div>
     
             
     
     { detail.quoteData.boxNames[index] === 'Shutter' && ( detail.quoteData.boxHandle[index] === 'left' ? (
             <img
               src={left}
               width='15px'
               style={{
                 float: 'left',
                 marginTop: `calc(${((detail.quoteData.boxHeight*screen)/2)}px - 15px`,
                 marginLeft: '2px',
                 width: screen === 4 ? '13px' : '8px'
               }}
               
               alt="Left Handle"
             />
           ) : (
             <img
               src={right}
               width='15px'
               style={{
                 float: 'right',
                 marginTop: `calc(${((detail.quoteData.boxHeight*screen)/2)}px - 15px`,
                 marginRight: '2px',
                 width: screen === 4 ? '13px' : '8px',
                 verticalAlign: 'bottom'
               }}
               
               alt="Right Handle"
             />
           ) )}
  
          
             {mesh === 'yes' && detail.quoteData.boxNames[index] === 'Shutter' && (
               <img src={meshimg} style={{width:'50px', float:'left', marginLeft: detail.quoteData.boxHandle[index] === 'left' ? '-15px' : '0px', marginTop: `calc(${(detail.quoteData.boxHeight*screen)}px - 75px)`}} />
             )}
            
  
             
          
   </div>
  ))}
  </div> 
     { detail.quoteData.numSecondInnerBoxes === 0 ? (
       <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((detail.quoteData.boxWidth/detail.quoteData.numFrames)* screen)+'px', top:detail.quoteData.boxHeight*screen+'px',  }} >
      
     </div>
     ) : (
       <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((detail.quoteData.boxWidth/detail.quoteData.numFrames)* screen)+'px', bottom:'-10px',top:'40px', }} >
        
       </div>
     )}
  </>
  )}
  </div>
  ))}
  </div>
  
  { detail.quoteData.boxPosition === 'Top' ? (
  detail.quoteData.newHeights === 0 ? (
  

   <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
    
   </div>
  ) : (  
     <>
       <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
  
  
       </div>
  
       <div style={{ width: '30px', float: 'left', paddingLeft: '0px', textAlign: 'left' }}>
     
     </div>
     </>
  )
  ) : (
  detail.quoteData.newHeights === 0 ? (
  
   <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
    
   </div>
  ) : (  
     <>
       <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
        
       </div>
       <div style={{ width: '30px', float: 'left', paddingLeft: '0px', textAlign: 'left' }}>
    
     </div>
     </>
  )
  )}
   
  </div>
               
            
          )}
           {detail.quoteData.item === 'SlidingDoor' && (
            
               
               <div >
  
  <div style={{float:'left', width: detail.quoteData.boxWidth*screen+'px', height:detail.quoteData.boxHeight*screen+'px', border:'0.5px solid #666'}}>
  {[...Array(detail.quoteData.numFrames)].map((_, index) => (
   <div style={{float:'left', margin:'5px', border:'0.5px solid #666',width:`calc(${detail.quoteData.boxWidth*screen/detail.quoteData.numFrames+'px'} - 12px)`, height:`calc(${detail.quoteData.boxHeight*screen+'px'} - 12px)`}}> 
   {detail.quoteData.newHeights !== 0 ? (
  <>
   
   { detail.quoteData.boxPosition === 'Top' ? (
       <div></div>
     ) : (
       [...Array(detail.quoteData.numFirstInnerBoxes)].map((_, index) => (
   <div style={{width: `calc(${detail.quoteData.boxWidth*screen/detail.quoteData.numFrames+'px'} - 23px)`, height:`calc(${(detail.quoteData.newHeights * screen)}px )`, background: 'lightgreen', margin:'5px', border:'0.5px solid #666'}}></div>
   ))
   )}
  
   <div style={{ display: 'flex' }}>
     {[...Array(detail.quoteData.numSecondInnerBoxes)].map((_, index) => (
       
       <div key={index} style={{ flex: '1', height: `calc(${(detail.quoteData.boxHeight - detail.quoteData.newHeights) * screen}px - 30px)`, width: `calc(${detail.quoteData.newWidths[index] * screen}px - 5px - 19px)`, backgroundColor: detail.quoteData.panelType === 1 ? 'lightgray' : detail.quoteData.panelType === 2 ? 'lightyellow' : 'lightblue', marginTop: detail.quoteData.boxPosition === 'Top' ? '5px' : '0px', marginRight: index === detail.quoteData.numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #36454F' }}>
       
               <div className="expanding-arrow-line" style={{ 
                 position: 'absolute',
                 float:'left', 
                 width: `calc(${detail.quoteData.newWidths[index] * screen}px - 15px)`,
                 bottom:'-10px', 
                 top: `calc(${detail.quoteData.boxHeight * screen}px - 25px)`,
                }}>
                
               </div> 
  
  
        <div style={{
         height:'5px',
         width: '1px',
         float: 'right',
         right: '0px',
         marginTop: '-6px',
         marginRight: '-1px',
         background: index === (detail.quoteData.numSecondInnerBoxes-1) ? 'transparent' : 'black',
      }}></div>
  
     <div style={{
         height:'7px',
         width: '1px',
         float: 'right',
         right: '0px',
         marginTop: `calc(${(detail.quoteData.boxHeight-detail.quoteData.newHeights) * screen}px - 31px)`,
         marginRight: '-1px',
         background: index === (detail.quoteData.numSecondInnerBoxes-1) ? 'transparent' : 'black',
      }}></div>
        
        
             { detail.quoteData.boxNames[index] !== 'Shutter' ? (<></>) : (
               <img
                 src={Handle}
                 height = {deviceWidth > 700 ? '10px' : '15px'}
                 style={{
                   float: detail.quoteData.boxHandle[index] === 'left' ? 'left' : 'right',
                   marginTop: ((detail.quoteData.boxHeight-detail.quoteData.newHeights) * ((screen/2)-(screen/8)))+'px',
                   marginLeft: detail.quoteData.boxHandle[index] === 'left' ? '2px' : '0px',
                   marginRight: detail.quoteData.boxHandle[index] === 'left' ? '0px' : '4px',
                   width: 'auto'
                 }}
                 
                 alt="Handle"
               />
             )}
  
             {mesh === 'yes' && detail.quoteData.boxNames[index] === 'Shutter' && index % 2 === 0 && (
                 <img src={meshimg} style={{width:'50px', float:'left', marginLeft: detail.quoteData.boxHandle[index] === 'left' ? '-6px' : '0px', marginTop: `calc(${((detail.quoteData.boxHeight-detail.quoteData.newHeights)*screen)}px - 80px)`}} />
                 )
             }
             
               
  
       </div>
  
     ))}
     
   </div>
   { detail.quoteData.boxPosition === 'Bottom' ? (
       <div></div>
     ) : (
       [...Array(detail.quoteData.numFirstInnerBoxes)].map((_, index) => (
   <div style={{width: `calc(${detail.quoteData.boxWidth*screen/detail.quoteData.numFrames+'px'} - 23px)`, height:`calc(${(detail.quoteData.newHeights * screen)}px )`, background: 'lightgreen', margin:'5px', border:'0.5px solid #666'}}></div>
   ))
   )}
  
   { detail.quoteData.numSecondInnerBoxes === 0 ? (
         <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((detail.quoteData.boxWidth/detail.quoteData.numFrames)* screen)+'px',top:(detail.quoteData.boxHeight-detail.quoteData.newHeights)*screen+'px', }} >
        
       </div>
       ) : (
         <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((detail.quoteData.boxWidth/detail.quoteData.numFrames)* screen)+'px',bottom:'-10px',top:'50px', }} >
        
         </div>
       )}
  </>
  ) : (
  <>
  <div style={{ display: 'flex' }}>
   {[...Array(detail.quoteData.numSecondInnerBoxes)].map((_, index) => (
     <>
     
     <div key={index} style={{ flex: '1', height: `calc(${(detail.quoteData.boxHeight) * screen}px - 23px)`, width: `calc(${detail.quoteData.newWidths[index] * screen}px - 5px - 19px)`, backgroundColor: detail.quoteData.panelType === 1 ? 'lightgray' : detail.quoteData.panelType === 2 ? 'lightyellow' : 'lightblue', marginRight: index === detail.quoteData.numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #36454F',marginTop:'5px' }}>
             
              <div className="expanding-arrow-line" style={{ 
                 position: 'absolute',
                 float:'left', 
                 width: `calc(${detail.quoteData.newWidths[index] * screen}px - 15px)`,
                 bottom:'-10px', 
                 top: `calc(${detail.quoteData.boxHeight * screen}px - 35px)`,
                }}>
            
               </div> 
  
  
     <div style={{
         height:'5px',
         width: '1px',
         float: 'right',
         right: '0px',
         marginTop: '-6px',
         marginRight: '-1px',
         background: index === (detail.quoteData.numSecondInnerBoxes-1) ? 'transparent' : 'black',
      }}></div>
  
     <div style={{
         height:'5px',
         width: '1px',
         float: 'right',
         right: '0px',
         marginTop: `calc(${(detail.quoteData.boxHeight) * screen}px - 24px)`,
         marginRight: '-1px',
         background: index === (detail.quoteData.numSecondInnerBoxes-1) ? 'transparent' : 'black',
      }}></div>
          
          
            { detail.quoteData.boxNames[index] !== 'Shutter' ? (<></>) : (
               <img
                 src={Handle}
                 height = {deviceWidth > 700 ? '10px' : '15px'}
                 style={{
                   float: detail.quoteData.boxHandle[index] === 'left' ? 'left' : 'right',
                   marginTop: (detail.quoteData.boxHeight) * ((screen/2)-(screen/13))+'px',
                   marginLeft: detail.quoteData.boxHandle[index] === 'left' ? '2px' : '0px',
                   marginRight: detail.quoteData.boxHandle[index] === 'left' ? '0px' : '4px',
                   width: 'auto'
                 }}
                 
                 alt="Handle"
               />
             )}
              
             {mesh === 'yes' && detail.quoteData.boxNames[index] === 'Shutter' && index % 2 === 0 && (
                 <img src={meshimg} style={{width:'50px', float:'left', marginLeft: detail.quoteData.boxHandle[index] === 'left' ? '-6px' : '0px', marginTop: `calc(${((detail.quoteData.boxHeight-detail.quoteData.newHeights)*screen)}px - 75px)`}} />
                 )
             }
            
     </div>
     </>
   ))}
  </div> 
       { detail.quoteData.numSecondInnerBoxes === 0 ? (
         <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((detail.quoteData.boxWidth/detail.quoteData.numFrames)* screen)+'px', top:detail.quoteData.boxHeight*screen+'px',  }} >
        
       </div>
       ) : (
         <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((detail.quoteData.boxWidth/detail.quoteData.numFrames)* screen)+'px', bottom:'-10px',top:'40px', }} >
         
         </div>
       )}
  </>
   )}
   </div>
    ))}
  </div>
  
  { detail.quoteData.boxPosition === 'Top' ? (
   detail.quoteData.newHeights === 0 ? (
  
     <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
   
     </div>
  ) : (  
       <>
         <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
  
         
  
         </div>
  
         <div style={{ width: '30px', float: 'left', paddingLeft: '0px', textAlign: 'left' }}>
        
       </div>
       </>
  )
  ) : (
  detail.quoteData.newHeights === 0 ? (
  
     <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
     
     </div>
  ) : (  
       <>
         <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
        
         </div>
         <div style={{ width: '30px', float: 'left', paddingLeft: '0px', textAlign: 'left' }}>
       
       </div>
       </>
  )
  )}
     
  </div>
                 
              
          )}
          {detail.quoteData.item === 'SlidingWindow' && (
             
               
               <div >
  
  <div style={{float:'left', width: detail.quoteData.boxWidth*screen+'px', height:detail.quoteData.boxHeight*screen+'px', border:'0.5px solid #666'}}>
  {[...Array(detail.quoteData.numFrames)].map((_, index) => (
   <div style={{float:'left', margin:'5px', border:'0.5px solid #666',width:`calc(${detail.quoteData.boxWidth*screen/detail.quoteData.numFrames+'px'} - 12px)`, height:`calc(${detail.quoteData.boxHeight*screen+'px'} - 12px)`}}> 
   {detail.quoteData.newHeights !== 0 ? (
  <>
   
   { detail.quoteData.boxPosition === 'Top' ? (
       <div></div>
     ) : (
       [...Array(detail.quoteData.numFirstInnerBoxes)].map((_, index) => (
   <div style={{width: `calc(${detail.quoteData.boxWidth*screen/detail.quoteData.numFrames+'px'} - 23px)`, height:`calc(${(detail.quoteData.newHeights * screen)}px )`, background: 'lightgreen', margin:'5px', border:'0.5px solid #666'}}></div>
   ))
   )}
  
   <div style={{ display: 'flex' }}>
     {[...Array(detail.quoteData.numSecondInnerBoxes)].map((_, index) => (
       <div key={index} style={{ flex: '1', height: `calc(${(detail.quoteData.boxHeight - detail.quoteData.newHeights) * screen}px - 30px)`, width: `calc(${detail.quoteData.newWidths[index] * screen}px - 5px - 19px)`, backgroundColor: detail.quoteData.boxColors[index] || 'lightblue', marginTop: detail.quoteData.boxPosition === 'Top' ? '5px' : '0px', marginRight: index === detail.quoteData.numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #36454F' }}>
       
               <div className="expanding-arrow-line" style={{ 
                 position: 'absolute',
                 float:'left', 
                 width: `calc(${detail.quoteData.newWidths[index] * screen}px - 15px)`,
                 bottom:'-10px', 
                 top: `calc(${detail.quoteData.boxHeight * screen}px - 25px)`,
                }}>
               
               </div>
       
        <div style={{
         height:'5px',
         width: '1px',
         float: 'right',
         right: '0px',
         marginTop: '-6px',
         marginRight: '-1px',
         background: index === (detail.quoteData.numSecondInnerBoxes-1) ? 'transparent' : 'black',
      }}></div>
  
     <div style={{
         height:'7px',
         width: '1px',
         float: 'right',
         right: '0px',
         marginTop: `calc(${(detail.quoteData.boxHeight-detail.quoteData.newHeights) * screen}px - 31px)`,
         marginRight: '-1px',
         background: index === (detail.quoteData.numSecondInnerBoxes-1) ? 'transparent' : 'black',
      }}></div>
        
        
             { detail.quoteData.boxNames[index] !== 'Shutter' ? (<></>) : (
               <img
                 src={Handle}
                 height = {deviceWidth > 700 ? '10px' : '15px'}
                 style={{
                   float: detail.quoteData.boxHandle[index] === 'left' ? 'left' : 'right',
                   marginTop: ((detail.quoteData.boxHeight-detail.quoteData.newHeights) * ((screen/2)-(screen/8)))+'px',
                   marginLeft: detail.quoteData.boxHandle[index] === 'left' ? '2px' : '0px',
                   marginRight: detail.quoteData.boxHandle[index] === 'left' ? '0px' : '4px',
                   width: 'auto'
                 }}
                 
                 alt="Handle"
               />
             )}
             
             {mesh === 'yes' && detail.quoteData.boxNames[index] === 'Shutter' && index % 2 === 0 && (
                 <img src={meshimg} style={{width:'50px', float:'left', marginLeft: detail.quoteData.boxHandle[index] === 'left' ? '-6px' : '0px', marginTop: `calc(${((detail.quoteData.boxHeight-detail.quoteData.newHeights)*screen)}px - 82px)`}} />
                 )
             }
  
       </div>
  
     ))}
     
   </div>
   { detail.quoteData.boxPosition === 'Bottom' ? (
       <div></div>
     ) : (
       [...Array(detail.quoteData.numFirstInnerBoxes)].map((_, index) => (
   <div style={{width: `calc(${detail.quoteData.boxWidth*screen/detail.quoteData.numFrames+'px'} - 23px)`, height:`calc(${(detail.quoteData.newHeights * screen)}px )`, background: 'lightgreen', margin:'5px', border:'0.5px solid #666'}}></div>
   ))
   )}
  
   { detail.quoteData.numSecondInnerBoxes === 0 ? (
         <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((detail.quoteData.boxWidth/detail.quoteData.numFrames)* screen)+'px',top:(detail.quoteData.boxHeight-detail.quoteData.newHeights)*screen+'px', }} >
       
       </div>
       ) : (
         <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((detail.quoteData.boxWidth/detail.quoteData.numFrames)* screen)+'px',bottom:'-10px',top:'50px', }} >
          
         </div>
       )}
  </>
  ) : (
  <>
  <div style={{ display: 'flex' }}>
   {[...Array(detail.quoteData.numSecondInnerBoxes)].map((_, index) => (
     <>
     
     <div key={index} style={{ flex: '1', height: `calc(${(detail.quoteData.boxHeight) * screen}px - 24px)`, width: `calc(${detail.quoteData.newWidths[index] * screen}px - 5px - 19px)`, backgroundColor: detail.quoteData.boxColors[index] || 'lightblue', marginRight: index === detail.quoteData.numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #36454F',marginTop:'5px' }}>
          
  
               <div className="expanding-arrow-line" style={{ 
                 position: 'absolute',
                 float:'left', 
                 width: `calc(${detail.quoteData.newWidths[index] * screen}px - 15px)`,
                 bottom:'-10px', 
                 top: `calc(${detail.quoteData.boxHeight * screen}px - 35px)`,
                }}>
               
               </div>
  
  
     <div style={{
         height:'5px',
         width: '1px',
         float: 'right',
         right: '0px',
         marginTop: '-6px',
         marginRight: '-1px',
         background: index === (detail.quoteData.numSecondInnerBoxes-1) ? 'transparent' : 'black',
      }}></div>
  
     <div style={{
         height:'5px',
         width: '1px',
         float: 'right',
         right: '0px',
         marginTop: `calc(${(detail.quoteData.boxHeight) * screen}px - 24px)`,
         marginRight: '-1px',
         background: index === (detail.quoteData.numSecondInnerBoxes-1) ? 'transparent' : 'black',
      }}></div>
          
          
            { detail.quoteData.boxNames[index] !== 'Shutter' ? (<></>) : (
               <img
                 src={Handle}
                 height = {deviceWidth > 700 ? '10px' : '15px'}
                 style={{
                   float: detail.quoteData.boxHandle[index] === 'left' ? 'left' : 'right',
                   marginTop: (detail.quoteData.boxHeight) * ((screen/2)-(screen/13))+'px',
                   marginLeft: detail.quoteData.boxHandle[index] === 'left' ? '2px' : '0px',
                   marginRight: detail.quoteData.boxHandle[index] === 'left' ? '0px' : '4px',
                   width: 'auto'
                 }}
                 
                 alt="Handle"
               />
             )}
               
             {mesh === 'yes' && detail.quoteData.boxNames[index] === 'Shutter' && index % 2 === 0 && (
                 <img src={meshimg} style={{width:'50px', float:'left', marginLeft: detail.quoteData.boxHandle[index] === 'left' ? '-6px' : '0px', marginTop: `calc(${((detail.quoteData.boxHeight-detail.quoteData.newHeights)*screen)}px - 75px)`}} />
                 )
             }
            
     </div>
     </>
   ))}
  </div> 
       { detail.quoteData.numSecondInnerBoxes === 0 ? (
         <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((detail.quoteData.boxWidth/detail.quoteData.numFrames)* screen)+'px', top:detail.quoteData.boxHeight*screen+'px',  }} >
      
       </div>
       ) : (
         <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((detail.quoteData.boxWidth/detail.quoteData.numFrames)* screen)+'px', bottom:'-10px',top:'40px', }} >
          
         </div>
       )}
  </>
   )}
   </div>
    ))}
  </div>
  
  { detail.quoteData.boxPosition === 'Top' ? (
   detail.quoteData.newHeights === 0 ? (
  
     <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
       <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}px)`, top: '0', position: 'relative' }}>
       
       </div>
     </div>
  ) : (  
       <>
         <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
  
          
  
         </div>
  
         <div style={{ width: '30px', float: 'left', paddingLeft: '0px', textAlign: 'left' }}>
        
       </div>
       </>
  )
  ) : (
  detail.quoteData.newHeights === 0 ? (
  
     <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
    
     </div>
  ) : (  
       <>
         <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
         
         </div>
         <div style={{ width: '30px', float: 'left', paddingLeft: '0px', textAlign: 'left' }}>
      
       </div>
       </>
  )
  )}
     
  </div>
                 
              
          )}
    
    {detail.quoteData.item === 'Ventilator' && (
            <div >
            {detail.quoteData.boxHeight > 1 && detail.quoteData.boxWidth > 1 && (  
             <>
  
             <div style={{float:'left', width: detail.quoteData.boxWidth*screen+'px', height:detail.quoteData.boxHeight*screen+'px', border:'0.5px solid #666'}}>
            
                 {detail.quoteData.fanTop === 'Top' && detail.quoteData.fanLeft === 'Left' && divider === 'Vertical' && switchs === 'on' && (
                   <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}px - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}px - 10px)`, margin: '4px', border:'0.5px solid #666'}}>
                     <img src={Fan} style={{float:'left', width: .85*screen+'px', marginLeft: '4px', marginTop: '4px'}} />
                     <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight) * screen}px - 20px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}px - 26px)`, margin: '5px', border:'0.5px solid #666', backgroundImage: `repeating-linear-gradient(lightblue, white ${(6/detail.quoteData.boxHeight)*screen}%, lightblue ${(6/detail.quoteData.boxHeight)*screen}%)`}}></div>
                     <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}px - 25px)`, width: `calc(${(.85) * screen}px - 0px)`, margin: '4px', marginTop: `calc(-${(detail.quoteData.boxHeight-.85) * screen}px + 20px)`, border:'0.5px solid #666', background: 'lightblue'}}></div>
                   </div>
                 )}
                 
                 {detail.quoteData.fanTop === 'Bottom' && detail.quoteData.fanLeft === 'Left'  && divider === 'Vertical' && switchs === 'on' && (
                     <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}px - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}px - 10px)`, margin: '4px', border:'0.5px solid #666'}}>
                       <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}px - 25px)`, width: `calc(${(.85) * screen}px - 0px)`, margin: '4px', marginTop: '5px', border:'0.5px solid #666', background: 'lightblue'}}></div>
                       <div style={{float:'right', height: `calc(${(detail.quoteData.boxHeight) * screen}px - 20px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}px - 26px)`, margin: '5px', marginTop: `calc(-${(detail.quoteData.boxHeight-.85) * screen}px + 20px)`, border:'0.5px solid #666', backgroundImage: `repeating-linear-gradient(lightblue, white ${(6/detail.quoteData.boxHeight)*screen}%, lightblue ${(6/detail.quoteData.boxHeight)*screen}%)`}}></div>
                       <img src={Fan} style={{float:'left', width: .85*screen+'px', marginLeft: '4px', marginBottom: '4px'}} />
                     </div>
                 )}
  
                 {detail.quoteData.fanTop === 'Top' && detail.quoteData.fanLeft === 'Right' &&  divider === 'Vertical' && switchs === 'on' && (
                   <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}px - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}px - 10px)`, margin: '4px', border:'0.5px solid #666'}}>
                     <img src={Fan} style={{float:'right', width: .85*screen+'px', marginRight: '4px', marginTop: '4px'}} />
                     <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight) * screen}px - 20px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}px - 26px)`, margin: '5px', border:'0.5px solid #666', backgroundImage: `repeating-linear-gradient(lightblue, white ${(6/detail.quoteData.boxHeight)*screen}%, lightblue ${(6/detail.quoteData.boxHeight)*screen}%)`}}></div>
                     <div style={{float:'right', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}px - 25px)`, width: `calc(${(.85) * screen}px - 0px)`, margin: '4px', marginTop: `calc(-${(detail.quoteData.boxHeight-.85) * screen}px + 20px)`, border:'0.5px solid #666', background: 'lightblue'}}></div>
                   </div>
                 )}
  
                 {detail.quoteData.fanTop === 'Bottom' && detail.quoteData.fanLeft === 'Right' &&  divider === 'Vertical' && switchs === 'on' && (
                   <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}px - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}px - 10px)`, margin: '4px', border:'0.5px solid #666'}}>
                     <div style={{float:'right', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}px - 25px)`, width: `calc(${(.85) * screen}px - 0px)`, margin: '4px', marginTop: '5px', border:'0.5px solid #666', background: 'lightblue'}}></div>
                     <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight) * screen}px - 20px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}px - 26px)`, margin: '5px', marginTop: `calc(-${(detail.quoteData.boxHeight-.85) * screen}px + 20px)`, border:'0.5px solid #666', backgroundImage: `repeating-linear-gradient(lightblue, white ${(6/detail.quoteData.boxHeight)*screen}%, lightblue ${(6/detail.quoteData.boxHeight)*screen}%)`}}></div>
                     <img src={Fan} style={{float:'right', width: .85*screen+'px', marginRight: '4px', marginBottom: '4px'}} />
                   </div>
                 )}
  
                 {detail.quoteData.fanTop === 'Top' && detail.quoteData.fanLeft === 'Left' &&  divider === 'Vertical' && switchs === 'off' && (
                   <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}px - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}px - 10px)`, margin: '4px', border:'0.5px solid #666'}}>
                     <img src={Fan} style={{float:'left', width: .85*screen+'px', marginLeft: '4px', marginTop: '4px'}} />
                     <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight) * screen}px - 20px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}px - 26px)`, margin: '5px', border:'0.5px solid #666', background: 'lightblue'}}></div>
                     <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}px - 25px)`, width: `calc(${(.85) * screen}px - 0px)`, margin: '4px', marginTop: `calc(-${(detail.quoteData.boxHeight-.85) * screen}px + 20px)`, border:'0.5px solid #666', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/detail.quoteData.boxHeight)*screen}%, lightblue ${(10/detail.quoteData.boxHeight)*screen}%)`}}></div>
                   </div>
                 )}
                 
                 {detail.quoteData.fanTop === 'Bottom' && detail.quoteData.fanLeft === 'Left' && divider === 'Vertical' && switchs === 'off' && (
                     <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}px - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}px - 10px)`, margin: '4px', border:'0.5px solid #666'}}>
                       <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}px - 25px)`, width: `calc(${(.85) * screen}px - 0px)`, margin: '4px', marginTop: '5px', border:'0.5px solid #666', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/detail.quoteData.boxHeight)*screen}%, lightblue ${(10/detail.quoteData.boxHeight)*screen}%)`}}></div>
                       <div style={{float:'right', height: `calc(${(detail.quoteData.boxHeight) * screen}px - 20px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}px - 26px)`, margin: '5px', marginTop: `calc(-${(detail.quoteData.boxHeight-.85) * screen}px + 20px)`, border:'0.5px solid #666', background: 'lightblue'}}></div>
                       <img src={Fan} style={{float:'left', width: .85*screen+'px', marginLeft: '4px', marginBottom: '4px'}} />
                     </div>
                 )}
  
                 {detail.quoteData.fanTop === 'Top' && detail.quoteData.fanLeft === 'Right' &&  divider === 'Vertical' && switchs === 'off' && (
                   <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}px - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}px - 10px)`, margin: '4px', border:'0.5px solid #666'}}>
                     <img src={Fan} style={{float:'right', width: .85*screen+'px', marginRight: '4px', marginTop: '4px'}} />
                     <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight) * screen}px - 20px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}px - 26px)`, margin: '5px', border:'0.5px solid #666', background: 'lightblue'}}></div>
                     <div style={{float:'right', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}px - 25px)`, width: `calc(${(.85) * screen}px - 0px)`, margin: '4px', marginTop: `calc(-${(detail.quoteData.boxHeight-.85) * screen}px + 20px)`, border:'0.5px solid #666', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/detail.quoteData.boxHeight)*screen}%, lightblue ${(10/detail.quoteData.boxHeight)*screen}%)`}}></div>
                   </div>
                 )}
  
                 {detail.quoteData.fanTop === 'Bottom' && detail.quoteData.fanLeft === 'Right'  && divider === 'Vertical' && switchs === 'off' && (
                   <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}px - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}px - 10px)`, margin: '4px', border:'0.5px solid #666'}}>
                     <div style={{float:'right', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}px - 25px)`, width: `calc(${(.85) * screen}px - 0px)`, margin: '4px', marginTop: '5px', border:'0.5px solid #666', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/detail.quoteData.boxHeight)*screen}%, lightblue ${(10/detail.quoteData.boxHeight)*screen}%)`}}></div>
                     <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight) * screen}px - 20px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}px - 26px)`, margin: '5px', marginTop: `calc(-${(detail.quoteData.boxHeight-.85) * screen}px + 20px)`, border:'0.5px solid #666', background: 'lightblue'}}></div>
                     <img src={Fan} style={{float:'right', width: .85*screen+'px', marginRight: '4px', marginBottom: '4px'}} />
                   </div>
                 )}
                 
                 {detail.quoteData.fanTop === 'Top' && detail.quoteData.fanLeft === 'Left' && divider === 'Horizontal' && switchs === 'on' && (
                   <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}px - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}px - 10px)`, margin: '4px', border:'0.5px solid #666'}}>
                     <img src={Fan} style={{float:'left', width: .85*screen+'px', marginLeft: '4px', marginTop: '4px'}} />
                     <div style={{float:'right', height: `calc(${(.85) * screen}px - 0px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}px - 26px)`, margin: '5px',  border:'0.5px solid #666', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/detail.quoteData.boxHeight)*screen}%, lightblue ${(10/detail.quoteData.boxHeight)*screen}%)`}}></div>
                     <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}px - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}px - 20px)`, margin: '4px', marginTop: '0px', border:'0.5px solid #666', background: 'lightblue'}}></div>
                   </div>
                 )}
                 
                 {detail.quoteData.fanTop === 'Bottom' && detail.quoteData.fanLeft === 'Left'  && divider === 'Horizontal' && switchs === 'on' && (
                   <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}px - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}px - 10px)`, margin: '4px', border:'0.5px solid #666'}}>
                     <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}px - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}px - 20px)`, margin: '4px', marginTop: '5px', border:'0.5px solid #666', background: 'lightblue'}}></div>
                     <div style={{float:'right', height: `calc(${(.85) * screen}px - 0px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}px - 26px)`, marginRight: '5px', marginBottom: '4px',  border:'0.5px solid #666', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/detail.quoteData.boxHeight)*screen}%, lightblue ${(10/detail.quoteData.boxHeight)*screen}%)`}}></div>
                     <img src={Fan} style={{float:'left', width: .85*screen+'px', marginLeft: '4px', marginBottom: '4px'}} />
                   </div>
                 )}
                 
                 {detail.quoteData.fanTop === 'Top' && detail.quoteData.fanLeft === 'Right'  && divider === 'Horizontal' && switchs === 'on' && (
                   <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}px - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}px - 10px)`, margin: '4px', border:'0.5px solid #666'}}>
                     <img src={Fan} style={{float:'right', width: .85*screen+'px', marginRight: '4px', marginTop: '4px'}} />
                     <div style={{float:'left', height: `calc(${(.85) * screen}px - 0px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}px - 26px)`, margin: '5px',  border:'0.5px solid #666', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/detail.quoteData.boxHeight)*screen}%, lightblue ${(10/detail.quoteData.boxHeight)*screen}%)`}}></div>
                     <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}px - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}px - 20px)`, margin: '4px', marginTop: '0px', border:'0.5px solid #666', background: 'lightblue'}}></div>
                   </div>
                 )}
                 
                 {detail.quoteData.fanTop === 'Bottom' && detail.quoteData.fanLeft === 'Right'  && divider === 'Horizontal' && switchs === 'on' && (
                   <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}px - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}px - 10px)`, margin: '4px', border:'0.5px solid #666'}}>
                     <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}px - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}px - 20px)`, margin: '4px', marginTop: '5px', border:'0.5px solid #666', background: 'lightblue'}}></div>
                     <div style={{float:'left', height: `calc(${(.85) * screen}px - 0px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}px - 26px)`, marginLeft: '5px', marginBottom: '4px',  border:'0.5px solid #666', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/detail.quoteData.boxHeight)*screen}%, lightblue ${(10/detail.quoteData.boxHeight)*screen}%)`}}></div>
                     <img src={Fan} style={{float:'right', width: .85*screen+'px', marginRight: '4px', marginBottom: '4px'}} />
                   </div>
                 )}
  
                 {detail.quoteData.fanTop === 'Top' && detail.quoteData.fanLeft === 'Left'  && divider === 'Horizontal' && switchs === 'off' && (
                   <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}px - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}px - 10px)`, margin: '4px', border:'0.5px solid #666'}}>
                     <img src={Fan} style={{float:'left', width: .85*screen+'px', marginLeft: '4px', marginTop: '4px'}} />
                     <div style={{float:'right', height: `calc(${(.85) * screen}px - 0px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}px - 26px)`, margin: '5px',  border:'0.5px solid #666', background: 'lightblue' }}></div>
                     <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}px - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}px - 20px)`, margin: '4px', marginTop: '0px', border:'0.5px solid #666', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/detail.quoteData.boxHeight)*screen}%, lightblue ${(10/detail.quoteData.boxHeight)*screen}%)`}}></div>
                   </div>
                 )}
                 
                 {detail.quoteData.fanTop === 'Top' && detail.quoteData.fanLeft === 'Right'  && divider === 'Horizontal' && switchs === 'off' && (
                   <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}px - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}px - 10px)`, margin: '4px', border:'0.5px solid #666'}}>
                     <img src={Fan} style={{float:'right', width: .85*screen+'px', marginRight: '4px', marginTop: '4px'}} />
                     <div style={{float:'left', height: `calc(${(.85) * screen}px - 0px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}px - 26px)`, margin: '5px',  border:'0.5px solid #666', background: 'lightblue' }}></div>
                     <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}px - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}px - 20px)`, margin: '4px', marginTop: '0px', border:'0.5px solid #666', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/detail.quoteData.boxHeight)*screen}%, lightblue ${(10/detail.quoteData.boxHeight)*screen}%)`}}></div>
                   </div>
                 )}
                 
                 {detail.quoteData.fanTop === 'Bottom' && detail.quoteData.fanLeft === 'Right'  && divider === 'Horizontal' && switchs === 'off' && (
                   <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}px - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}px - 10px)`, margin: '4px', border:'0.5px solid #666'}}>
                     <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}px - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}px - 20px)`, margin: '4px', marginTop: '5px', border:'0.5px solid #666', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/detail.quoteData.boxHeight)*screen}%, lightblue ${(10/detail.quoteData.boxHeight)*screen}%)`}}></div>
                     <img src={Fan} style={{float:'right', width: .85*screen+'px', marginRight: '4px', marginTop: '0px'}} />
                     <div style={{float:'left', height: `calc(${(.85) * screen}px - 0px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}px - 26px)`, marginLeft: '4px',  border:'0.5px solid #666', background: 'lightblue' }}></div>
                   </div>
                 )}
  
                 {detail.quoteData.fanTop === 'Bottom' && detail.quoteData.fanLeft === 'Left'  && divider === 'Horizontal' && switchs === 'off' && (
                   <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}px - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}px - 10px)`, margin: '4px', border:'0.5px solid #666'}}>
                   <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}px - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}px - 20px)`, margin: '4px', marginTop: '5px', border:'0.5px solid #666', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/detail.quoteData.boxHeight)*screen}%, lightblue ${(10/detail.quoteData.boxHeight)*screen}%)`}}></div>
                     <img src={Fan} style={{float:'left', width: .85*screen+'px', marginLeft: '4px', marginRight: '4px'}} />
                     <div style={{float:'right', height: `calc(${(.85) * screen}px - 0px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}px - 26px)`, marginRight: '5px',  border:'0.5px solid #666', background: 'lightblue' }}></div>
                   </div>
                 )}
  
             </div>
                 
             </>
             
           )} 
  
           {detail.quoteData.boxHeight == 1 && detail.quoteData.boxWidth > 1 && (
             <>
            {detail.quoteData.switchFixed === 'on' && (  
             
               <div style={{float:'left', width: detail.quoteData.boxWidth*screen+'px', height:detail.quoteData.boxHeight*screen+'px', border:'0.5px solid #666'}}>
                 <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}px - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}px - 10px)`, margin: '4px', border:'0.5px solid #666'}}>
                   <img src={Fan} style={{float:'left', width: .75*screen+'px', margin: '4px', }} />
                   <div style={{float: 'right', height: `calc(${(detail.quoteData.boxHeight)*screen}px - 22px)`, width: `calc(${(detail.quoteData.boxWidth- .94)*screen}px - 10px)`, marginRight: '5px', marginTop: '5px', background:'lightblue', border:'0.5px solid #666'}}></div>
                 </div>
               </div>
             )}
  
             {detail.quoteData.switchFixed === 'off' && (  
             
             <div style={{float:'left', width: detail.quoteData.boxWidth*screen+'px', height:detail.quoteData.boxHeight*screen+'px', border:'0.5px solid #666'}}>
               <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}px - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}px - 10px)`, margin: '4px', border:'0.5px solid #666'}}>
                 <img src={Fan} style={{float:'left', width: .75*screen+'px', margin: '4px', }} />
                 <div style={{float: 'right', height: `calc(${(detail.quoteData.boxHeight)*screen}px - 22px)`, width: `calc(${(detail.quoteData.boxWidth- .94)*screen}px - 10px)`, marginRight: '5px', marginTop: '5px',  backgroundImage: `repeating-linear-gradient(lightblue, white ${(6/detail.quoteData.boxHeight)*screen}%, lightblue ${(6/detail.quoteData.boxHeight)*screen}%)`, border:'0.5px solid #666'}}></div>
               </div>
             </div>
           )}
             </>
           )}
  
           {detail.quoteData.boxHeight > 1 && detail.quoteData.boxWidth == 1 && (
             <>
              {detail.quoteData.switchFixed === 'on' && (  
               <div style={{float:'left', width: detail.quoteData.boxWidth*screen+'px', height:detail.quoteData.boxHeight*screen+'px', border:'0.5px solid #666'}}>
                   <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}px - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}px - 10px)`, margin: '4px', border:'0.5px solid #666'}}>
                   <img src={Fan} style={{float:'left', width: .75*screen+'px', margin: '4px', }} />
                   <div style={{float: 'right', height: `calc(${(detail.quoteData.boxHeight-.75)*screen}px - 25px)`, width: `calc(${(detail.quoteData.boxWidth)*screen}px - 20px)`, marginRight: '5px', background:'lightblue', border:'0.5px solid #666'}}></div>
                 </div>
               </div>
              )}
              
              {detail.quoteData.switchFixed === 'off' && (
               <div style={{float:'left', width: detail.quoteData.boxWidth*screen+'px', height:detail.quoteData.boxHeight*screen+'px', border:'0.5px solid #666'}}>
                 <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}px - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}px - 10px)`, margin: '4px', border:'0.5px solid #666'}}>
                 <img src={Fan} style={{float:'left', width: .75*screen+'px', margin: '4px', }} />
                 <div style={{float: 'right', height: `calc(${(detail.quoteData.boxHeight-.75)*screen}px - 25px)`, width: `calc(${(detail.quoteData.boxWidth)*screen}px - 20px)`, marginRight: '5px',  backgroundImage: `repeating-linear-gradient(lightblue, white ${(6/detail.quoteData.boxHeight)*screen}%, lightblue ${(6/detail.quoteData.boxHeight)*screen}%)`, border:'0.5px solid #666'}}></div>
                 </div>
               </div>
              )}
             </>
           )}
           
           {(detail.quoteData.boxWidth > 1 || detail.quoteData.boxHeight > 1) && (
             <>
               <div className="expanding-arrow-line" style={{ 
                   position: 'absolute',
                   float:'left', 
                   width: `calc(${detail.quoteData.boxWidth * screen}px )`,
                   bottom:'-10px', 
                   top: `calc(${detail.quoteData.boxHeight* screen}px - 25px)`,
                   }}> 
                
                 </div>
  
             <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
               <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}px)`, top: '0', position: 'relative' }}>
               
               </div>
             </div>  
             </>
           )}
  
  
              </div>
            
          )}
                    </td>
                    
                    {detail.quoteData.item === 'CasementDoor' && (
                       <td style={{border:'0.5px solid #666', padding:'10px', fontSize:'15px', textAlign:'left',fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>
                       <h6>{detail.quoteData.item}</h6>
                       <p style={{color:"#36454F", fontSize:'15px',}}>Steel Frame, Steel Mullion, Steel Shutter, Door Lock with Espag, 3D Hinges,Screws/Misc</p>
                     </td>
                    )}
                     {detail.quoteData.item === 'CasementWindow' && (
                       <td style={{border:'0.5px solid #666', padding:'10px', fontSize:'15px', textAlign:'left',fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>
                       <h6>{detail.quoteData.item}</h6>
                       <p style={{color:"#36454F", fontSize:'15px'}}>{detail.quoteDatamesh === 'yes' && (<> Mesh 3.4 SS, Steel Mesh,</>)} Steel Frame, Steel Mullion, Steel Shutter,
                         Multi Point Lock ( MPL ), Handle, Hings Set / Friction Stay, Screws/Misc</p>
                     </td>
                     )}
                      {detail.quoteData.item === 'SlidingDoor' && (
                         <td style={{border:'0.5px solid #666', padding:'10px', fontSize:'15px',textAlign:'left',fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>
                         <h6>{detail.quoteData.item}</h6>
                         <p style={{color:"#36454F", fontSize:'15px'}}>Steel Frame, Steel Shutter, Aluminium Track, Bump Arrester, Jump Stopper, Dust Arrester, Grove Roller,
                          Touch Lock, Screws/Misc</p>
                         
                       </td>
                      )}
                      {detail.quoteData.item === 'SlidingWindow' && (
                         <td style={{border:'0.5px solid #666', padding:'10px', textAlign:'left', fontSize:'15px', fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>
                         <h6>{detail.quoteData.item}</h6>
                         <p style={{color:"#36454F", fontSize:'15px'}}>Steel Frame, Steel Shutter,
  {detail.quoteData.newHeights > 0 &&(
    <>
  Steel Mullion
  </>
  )}
  {detail.quoteData.mesh === 'yes' && (
    <>
  Mesh 30A Grade,
  Mesh Roller,
  Steel Mesh,

  </>
  )}
  Weather Strip,
  Touch Lock with SS Receiver,
  Grove Roller,
  Dust Arrester,
  Jump Stopper,
  Bump Arrester,
  Aluminium Track,
  InterLock</p>
                        
                       </td>

                      )}
                      {detail.quoteData.item === 'Ventilator' && (
                         <td style={{border:'0.5px solid #666', padding:'10px', fontSize:'15px', textAlign:'left',fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>
                         <h6>{detail.quoteData.item}</h6>
                         <p style={{color:"#36454F", fontSize:'15px'}}>Screws, Glass,  {(detail.quoteData.boxHeight > 1 && detail.quoteData.boxWidth > 1) || (detail.quoteData.switchFixed === 'off') ? (<>Louver Blades</>):(<></>)} </p>
                         
                       </td>
                      )}
                
                   
                    
                    <td style={{border:'0.5px solid #666', padding:'2px', textAlign:'center',fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>{detail.quoteData.series}</td>
                    <td style={{border:'0.5px solid #666', padding:'2px', textAlign:'center',fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}> {detail.quoteData.boxWidth} Sqft</td>
                    <td style={{border:'0.5px solid #666', padding:'2px', textAlign:'center',fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>{detail.quoteData.boxHeight} Sqft </td>
                   

<td style={{border:'0.5px solid #666', padding:'2px', textAlign:'center',fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif',color:"#36454F"}}>{detail.quoteData.frameArea}</td>
                    <td style={{border:'0.5px solid #666', padding:'2px', textAlign:'center',fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>{detail.quoteData.qty}</td>
                    <td style={{border:'0.5px solid #666', padding:'2px', textAlign:'center',fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>{detail.quoteData.frameArea*detail.quoteData.qty}Sqft</td>
                    <td style={{border:'0.5px solid #666', padding:'2px', textAlign:'center',fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>₹ {numeral(detail.quoteData.grandTotal*detail.quoteData.qty).format('0,0.00')}</td>
                </tr>
                
              ))}
             

               
              
                {/* Add more rows as needed */}
            </tbody>
          ))}
          
          <tfoot>
			<tr >
        <td  colspan="6" rowspan="4" style={{ textAlign:"left", padding:'10px', paddingLeft:'20px', fontSize:'15px', fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>THIS QUOTE IS VALID FOR 10 DAYS ONLY. <br />THE ABOVE RATE IS INCLUSIVE OF PROFILE, FABRICATION, GRILL, GLASS, HARDWARES & ERECTION. <br /> DELIVERY TERMS : 21 - 28 DAYS FROM THE DATE OF ORDER CONFIRMATION</td>
				<th style={{border:'0.5px solid #666', textAlign:"left", padding:'10px', paddingLeft:'50px', fontSize:'15px', fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>Total</th>
        <td style={{border:'0.5px solid #666', padding:'2px', textAlign:'center',fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-seri, color:"#36454F"f'}}>{qty}</td>
        <td style={{border:'0.5px solid #666', padding:'2px', textAlign:'center',fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>{totalframearea}</td>
				<td  style={{border:'0.5px solid #666', textAlign:"right", padding:'10px', paddingLeft:'20px', fontSize:'15px', fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>₹{numeral(total).format('0,0.00')}</td>
			</tr>
      <tr>
      <th  colspan="3" style={{border:'0.5px solid #666', textAlign:"left", padding:'10px', paddingLeft:'50px', fontSize:'15px', fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>Average Rate/sqft</th>
      <td  style={{border:'0.5px solid #666', textAlign:"right", padding:'10px', paddingLeft:'20px', fontSize:'15px', fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>{numeral(average).format('0,0.00')}</td>
      </tr>

      
      
      <tr >
      
				<th  colspan="3" style={{border:'0.5px solid #666', textAlign:"left", padding:'10px', paddingLeft:'50px', fontSize:'15px', fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>Gst @ 18%</th>
				<td  style={{border:'0.5px solid #666', textAlign:"right", padding:'10px', paddingLeft:'20px', fontSize:'15px', fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>₹{numeral(gst).format('0,0.00')}</td>
			</tr>
      <tr>
      
				<th colspan="3" style={{border:'0.5px solid #666', textAlign:"left", padding:'10px', paddingLeft:'50px', fontSize:'15px', fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>Grand Total</th>
				<td  style={{border:'0.5px solid #666', textAlign:"right", padding:'10px', paddingLeft:'20px', fontSize:'15px', fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>₹{numeral(total+gst).format('0,0.00')}</td>
			</tr>
		</tfoot>



           
        </table>
      </div>
     
    </div>
   
  )
}
export default Downlaod;
