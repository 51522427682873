import React, { useEffect, useState } from 'react';
import { ReactSession } from 'react-client-session';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Navbar from "./navbar";
import Header from "./header";
import left from '../images/handleLeft.png';
import right from '../images/handleRight.png';
import meshimg from '../images/mesh.png';
import numeral from 'numeral';
import './box.css';
ReactSession.setStoreType("localStorage");


const App = () => {

  const userId = ReactSession.get("userId");
  const userType = ReactSession.get("userType");
  const quoteId = ReactSession.get("quoteId");
  const navigate = useNavigate();
  const indexPath = '/';
  const selectPath = '/select';
  const item = 'CasementDoor';

  useEffect(() => {
    if (userId === 0 ) {
      navigate(indexPath);
    }
  }, [userId, navigate]);

  const [measure, setMeasure] = useState('ft');
  const [mCalc, setMcalc] = useState(1);
  const [boxWidth, setBoxWidth] = useState(4); 
  const [boxHeight, setBoxHeight] = useState(6); 
  const [numFirstInnerBoxes, setNumFirstInnerBoxes] = useState(0);
  const [numFrames, setNumFrames] = useState(0);
  const [numSecondInnerBoxes, setNumSecondInnerBoxes] = useState(0);
  const [newHeights, setNewInnerBoxHeight] = useState(0); 
  const [panelHeights, setpanelHeight] = useState(0); 
  const [newWidths, setNewInnerBoxWidth] = useState([]); 
  const [boxColors, setBoxColors] = useState([]);
  const [boxNames, setBoxNames] = useState([]); 
  const [boxHandle, setBoxHandle] = useState([]); 
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
  const [screen, setScreen] = useState(deviceWidth > 700 ? 3 : 8);
  const [boxPosition, setBoxPosition] = useState('Bottom');
  const shutterCount = boxNames.filter(item => item === "Shutter").length;
  const fixedCount = boxNames.filter(item => item === "Fixed").length;
  const [bottomFrame, setBottomFrame] = useState('yes');
  const [mesh, setMesh] = useState('no');
  const [panelType, setPanelType] = useState(1);
  const [profileDetails, setProfileData] = useState(true);
  const user = { "userId": userId };
  const [qty, setQty] = useState(1);
  const [costData, setCostData] = useState(true);
  const [customProfile, setCustomProfile] = useState(0);
  const [calcBtn, setCalcBtn] = useState(false);
  const [isProfile, setIsProfile] = useState(false);
  const [series, setSeries] = useState(null);
  

  useEffect(() => {
    const updateDeviceWidth = () => {
      setDeviceWidth(window.innerWidth);
      setScreen(window.innerWidth > 700 ? 3 : 8);
    };

    window.addEventListener('resize', updateDeviceWidth);

    return () => {
      window.removeEventListener('resize', updateDeviceWidth);
    };
  }, []);

  useEffect(() => {
    const fetchcostData = async () => {
      try {
        const response = await axios.post('https://upvcapi.sninfoserv.com/costs/', user);
        setCostData(response.data.data);
        //console.log(response.data);
      } catch (error) {
      } 
    };

    fetchcostData();
  }, []);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post('https://upvcapi.sninfoserv.com/profiles/', user);
        setProfileData(response.data);
        //console.log("profile:",response.data);
      } catch (error) {
      } 
    };

    fetchData();
  }, []);


  const handleQty = (event) => {
    if(event === 'plus'){
      setQty(qty + 1);
      
    }else{
      const minValue = 2;
      if (qty < minValue) {
        setQty(qty);
      } else {
        setQty(qty - 1);
      }
    }
  };

  const handleQtyChange = (event) => {
    const newValue = event.target.value;
    const minValue = 1;
    if (newValue < minValue) {
      setQty(minValue);
    } else {
      setQty(newValue);
    }
  };


  const handleWidthChange = (event) => {
    const newValue = event.target.value;
    const minValue = 1;
    if (newValue < minValue) {
      setBoxWidth(minValue); 
    } else {
      setBoxWidth(newValue);
    }
    setNewInnerBoxWidth((prevWidths) => {
      const newWidth = ((newValue)/ (numSecondInnerBoxes))/numFrames;
      const newWidths = newWidth.toFixed(2)
      const updatedWidths = prevWidths.map(() => newWidths);
      return [...updatedWidths, newWidths];
    });
  };

  const handleHeightChange = (event) => {
    const newValue = event.target.value;
    const minValue = 1;
    if (newValue < minValue) {
      setBoxHeight(minValue);
    } else {
      setBoxHeight(newValue);
    }
  };
  
  console.log(boxHeight);

  const addWindow = (name) => {
    setNumSecondInnerBoxes((prevNum) => prevNum + 1);
    setBoxColors((prevColors) => [...prevColors, 'lightgray']);
    setNewInnerBoxWidth((prevWidths) => {
      const newWidth = boxWidth / (numSecondInnerBoxes + 1) / numFrames;
      const newWidths = newWidth.toFixed(2)
      const updatedWidths = prevWidths.map(() => newWidths);
      return [...updatedWidths, newWidths];
    });
    setBoxNames((prevNames) => [...prevNames, name]);
    const newBoxHandles = [...boxHandle, 'right'];
    setBoxHandle(newBoxHandles);
  };


  function changeBottom(name) {
    if (name === 'no'){
      setBottomFrame('yes');
    }else{
      setBottomFrame('no');
    }
  }

  function changeMesh(name) {
    if (name === 'no'){
      setMesh('yes');
    }else{
      setMesh('no');
    }
  }

  const deleteInnerBox = (index) => {

    setNumSecondInnerBoxes((prevNum) => prevNum - 1);
    const newBalanceWidth = boxWidth / (numSecondInnerBoxes-1) / numFrames;
    const updatedWidths = [...newWidths];
    updatedWidths.splice(index, 1);
    const updateWidths = updatedWidths.map(() => newBalanceWidth);
    setNewInnerBoxWidth(updateWidths);

    setBoxColors((prevColors) => {
      const updatedColors = [...prevColors];
      updatedColors.splice(index, 1);
      return updatedColors;
    });
    setBoxNames((prevNames) => {
      const updatedNames = [...prevNames];
      updatedNames.splice(index, 1);
      return updatedNames;
    });

  };

 
  
  const handleNewWidthChange = (index, event) => {
    const value = parseFloat(event.target.value);
    if (isNaN(value)) return;
    
   
      const updatedWidths = [...newWidths];
      updatedWidths[index] = value;

      const remainingValue = boxWidth - value;
      const otherCount = newWidths.length - 1;
      const newWidth = remainingValue / otherCount;

      const newWidthsFormatted = updatedWidths.map((width, i) => {
        if (i === index) {
          return value;
        } else {
          return newWidth;
        }
      });

      setNewInnerBoxWidth(newWidthsFormatted.map((num) => parseFloat(num.toFixed(2))));
   
  };
  

  const changeHandle = (index) => {
    if (boxHandle[index] === 'left') {
      const updatedBoxHandle = [...boxHandle];
      updatedBoxHandle[index] = 'right';
      updatedBoxHandle[index+1] = 'right';
      setBoxHandle(updatedBoxHandle);
    }
    if (boxHandle[index] === 'right') {
      const updatedBoxHandle = [...boxHandle];
      updatedBoxHandle[index] = 'left';
      updatedBoxHandle[index+1] = 'left';
      setBoxHandle(updatedBoxHandle);
    }
  };


  const handleWidth = (event) => {
    if(event === 'plus'){
      setBoxWidth(Number(boxWidth) + 0.50);
      
    }else{
      const minValue = 2;
      if (boxWidth < minValue) {
        setBoxWidth(boxWidth);
      } else {
        setBoxWidth(Number(boxWidth) - 0.50);
      }
    }
    setNumSecondInnerBoxes(0);
    setNewInnerBoxWidth([]);
    setBoxNames([]);
  };

  const handleHeight = (event) => {
    if(event === 'plus'){
      setBoxHeight(Number(boxHeight) + 0.50);
    }else{
      const minValue = 2;
      if (boxHeight < minValue) {
        setBoxHeight(boxHeight);
      } else {
        setBoxHeight(Number(boxHeight)-0.50);
      }
      
    }
    setNumSecondInnerBoxes(0);
    setNewInnerBoxWidth([]);
    setBoxNames([]);
  };

  const handleTop = (operation) => {
    if (operation === 'minus') {
      setNewInnerBoxHeight((prevHeight) => Math.max(0, prevHeight - 0.50)); 
    } else if (operation === 'plus') {
      setNewInnerBoxHeight((prevHeight) => Math.min(boxHeight-2, prevHeight + 0.50)); 
    }
  };

  if (numFirstInnerBoxes > 0 && newHeights === 0) {
    setNumFirstInnerBoxes(0);
    console.log(newHeights);
 }

  const handleTopChange = () => {
    setNumFirstInnerBoxes(1);
    setNewInnerBoxHeight((prevHeight) => Math.min(boxHeight-1, prevHeight + 1)); 
  };

  const handleTopHeight = (event) => {
    let newValue = parseFloat(event.target.value);
    newValue = Math.round(newValue * 100) / 100; 
    newValue = Math.min(boxHeight-2, Math.max(0.50, newValue));
    setNewInnerBoxHeight(newValue);
    if (newValue === 0) {
       setNumFirstInnerBoxes(0);
       console.log(newValue);
    }
  };

  const addFrame = (operation) => {
    if (operation === 'minus') {
      setNumFrames((prevFrames) => Math.max(0, prevFrames - 1)); 
      

    } else if (operation === 'plus') {
      setNumFrames((prevFrames) => Math.min(4, prevFrames + 1)); 
      
    }
    setNumSecondInnerBoxes(0);
    setNewInnerBoxWidth([]);
    setBoxNames([]);
  };

  const toggleBoxPosition = () => {
    const newPosition = boxPosition === 'Top' ? 'Bottom' : 'Top';
    setBoxPosition(newPosition);
  };

  const handleButtonClick = (buttonName) => {
    setPanelType(buttonName);
  };

  function changeMeasure(name) {
    if (name === 'ft'){
      setMeasure('mm');
      setMcalc(304.8);
      setBoxHeight((Number(boxHeight)*304.8).toFixed(2));
      setBoxWidth((Number(boxWidth)*304.8).toFixed(2));
      if(newHeights > 0){
        setNewInnerBoxHeight((Number(newHeights)*304.8).toFixed(2));
      }
    }else{
      setMeasure('ft');
      setMcalc(1);
      setBoxHeight((Number(boxHeight)/304.8).toFixed(2));
      setBoxWidth((Number(boxWidth)/304.8).toFixed(2));
      if(newHeights > 0){
        setNewInnerBoxHeight((Number(newHeights)/304.8).toFixed(2));
      }
    }
    setNumSecondInnerBoxes(0);
    setNewInnerBoxWidth([]);
    setBoxNames([]);
  }

  const handleProfileChange = (event) => {
    setCustomProfile(event.target.value);
    setSelectedFrameSeries(null);
    setSelectedFrameId(null);
    setSelectedShutterSeries(null);
    setSelectedShutterId(null);
    setSelectedMullionSeries(null);
    setSelectedMullionId(null);
    setSelectedBeedingSeries(null);
    setSelectedBeedingId(null);
    setSelectedPanelSeries(null);
    setSelectedPanelId(null); 
    
  };
  const shutterChange = (index) => {
    setBoxNames((prevBoxNames) => {
      const newBoxNames = [...prevBoxNames];
      newBoxNames[index] = newBoxNames[index] === 'Shutter' ? 'Fixed' : 'Shutter';
      return newBoxNames;
    });
    setBoxColors((prevboxColors) => {
      const newboxColors = [...prevboxColors];
      newboxColors[index] = newboxColors[index] === 'lightgreen' ? 'lightblue' : 'lightgreen';
      return newboxColors;
    });
  };

   //----------prifile Section-----------//

   const profileData = profileDetails && profileDetails.data && profileDetails.data[customProfile].profileData;
  const selectedProfile = profileDetails && profileDetails.data && profileDetails.data[customProfile].profileName;

   const [selectedFrameSeries, setSelectedFrameSeries] = useState(null);
   const [selectedFrameId, setSelectedFrameId] = useState(null);
   
 
   const handleFrameSeriesChange = (event) => {
     const seriesId = event.target.value;
      setSelectedFrameSeries(seriesId);
      setSelectedFrameId(null); 
   };
 
 
   const handleFrameIdChange = (event) => {
     const id = event.target.value;
     setSelectedFrameId(id);
   };



   const seriesDataFrame = profileData && profileData.find(serieses => serieses.series === selectedFrameSeries);
  const selectedFramePrice = selectedFrameId ? seriesDataFrame.items.find(item => item.id === selectedFrameId).price : 0;
  const selectedFrameLength = selectedFrameId ? seriesDataFrame.items.find(item => item.id === selectedFrameId).length : 0;
  const selectedFrameWeight = selectedFrameId ? seriesDataFrame.items.find(item => item.id === selectedFrameId).weight : 0;
  const selectedFrameName = selectedFrameId ? seriesDataFrame.items.find(item => item.id === selectedFrameId).name : 'Loading';



  const [selectedShutterSeries, setSelectedShutterSeries] = useState(null);
  const [selectedShutterId, setSelectedShutterId] = useState(null);
  
  const handleShutterSeriesChange = (event) => {
    const seriesId = event.target.value;
    setSelectedShutterSeries(seriesId);
    setSelectedShutterId(null); 
  };

  const handleShutterIdChange = (event) => {
    const id = event.target.value;
    setSelectedShutterId(id);
  };

  const seriesDataShutter = profileData && profileData.find(serieses => serieses.series === selectedShutterSeries);
  const selectedShutterPrice = selectedShutterId ? seriesDataShutter.items.find(item => item.id === selectedShutterId).price : 0;
  const selectedShutterLength = selectedShutterId ? seriesDataShutter.items.find(item => item.id === selectedShutterId).length : 0;
  const selectedShutterWeight = selectedShutterId ? seriesDataShutter.items.find(item => item.id === selectedShutterId).weight : 0;
  const selectedShutterName = selectedShutterId ? seriesDataShutter.items.find(item => item.id === selectedShutterId).name : 'Loading';



  const [selectedMullionSeries, setSelectedMullionSeries] = useState(null);
  const [selectedMullionId, setSelectedMullionId] = useState(null);
  
  const handleMullionSeriesChange = (event) => {
    const seriesId = event.target.value;
    setSelectedMullionSeries(seriesId);
    setSelectedMullionId(null); 
  };

  const handleMullionIdChange = (event) => {
    const id = event.target.value;
    setSelectedMullionId(id);
  };

  const seriesDataMullion = profileData && profileData.find(serieses => serieses.series === selectedMullionSeries);
  const selectedMullionPrice = selectedMullionId ? seriesDataMullion.items.find(item => item.id === selectedMullionId).price : 0;
  const selectedMullionLength = selectedMullionId ? seriesDataMullion.items.find(item => item.id === selectedMullionId).length : 0;
  const selectedMullionWeight = selectedMullionId ? seriesDataMullion.items.find(item => item.id === selectedMullionId).weight : 0;
  const selectedMullionName = selectedMullionId ? seriesDataMullion.items.find(item => item.id === selectedMullionId).name : 'Loading';
  


  const [selectedBeedingSeries, setSelectedBeedingSeries] = useState(null);
  const [selectedBeedingId, setSelectedBeedingId] = useState(null);
  
  const handleBeedingSeriesChange = (event) => {
    const seriesId = event.target.value;
    setSelectedBeedingSeries(seriesId);
    setSelectedBeedingId(null); 
  };

  const handleBeedingIdChange = (event) => {
    const id = event.target.value;
    setSelectedBeedingId(id);
  };

  const seriesDataBeeding = profileData && profileData.find(serieses => serieses.series === selectedBeedingSeries);
  const selectedBeedingPrice = selectedBeedingId ? seriesDataBeeding.items.find(item => item.id === selectedBeedingId).price : 0;
  const selectedBeedingLength = selectedBeedingId ? seriesDataBeeding.items.find(item => item.id === selectedBeedingId).length : 0;
  const selectedBeedingWeight = selectedBeedingId ? seriesDataBeeding.items.find(item => item.id === selectedBeedingId).weight : 0;
  const selectedBeedingName = selectedBeedingId ? seriesDataBeeding.items.find(item => item.id === selectedBeedingId).name : 'Loading';

  
  const [selectedPanelSeries, setSelectedPanelSeries] = useState(null);
  const [selectedPanelId, setSelectedPanelId] = useState(null);
  
  const handlePanelChange = (event) => {
    const seriesId = event.target.value;
    setSelectedPanelSeries(seriesId);
    setSelectedPanelId(null); 
  };

  const handlePanelIdChange = (event) => {
    const id = event.target.value;
    setSelectedPanelId(id);
  };

  const seriesDataPanel = profileData && profileData.find(serieses => serieses.series === selectedPanelSeries);
  const selectedPanelPrice = selectedPanelId ? seriesDataPanel.items.find(item => item.id === selectedPanelId).price : 0;
  const selectedPanelLength = selectedPanelId ? seriesDataPanel.items.find(item => item.id === selectedPanelId).length : 0;
  const selectedPanelWeight = selectedPanelId ? seriesDataPanel.items.find(item => item.id === selectedPanelId).weight : 0;
  const selectedPanelName = selectedPanelId ? seriesDataPanel.items.find(item => item.id === selectedPanelId).name : 'Loading';

  //-------------------cost--------------------------//

  const [handleCost, sethandleCost] = useState(0);
  const handles = costData && costData.costData && costData.costData.handle;
  const handleChange = (event) => {
    const cost = event.target.value;
    sethandleCost(cost);
  };

  const [lockCost, setlockCost] = useState(0);
  const lockes = costData && costData.costData && costData.costData.mpl;
  const lockChange = (event) => {
    const cost = event.target.value;
    setlockCost(cost);
  };

  const [hingesCost, sethingesCost] = useState(0);
  const hinges = costData && costData.costData && costData.costData.hinges;
  const hingesChange = (event) => {
    const cost = event.target.value;
    sethingesCost(cost);
  };

  
  const [glassCost, setGlassCost] = useState(0);
  const glasses = costData && costData.costData && costData.costData.glass;
  const glassChange = (event) => {
    const cost = event.target.value;
    setGlassCost(cost);
  };

  const [marginCost, setMarginCost] = useState(0);
  const margin = costData && costData.costData && costData.costData.margin;
  const marginChange = (event) => {
    const cost = event.target.value;
    setMarginCost(cost);
  };

  const [marketingCost, setMarketingCost] = useState(0);
  const marketing= costData && costData.costData && costData.costData.marketing;
  const marketingChange = (event) => {
    const cost = event.target.value;
    setMarketingCost(cost);
  };

  const [labourFCost, setlabourFCost] = useState(0);
  const labourF = costData && costData.costData && costData.costData.labourFactory;
  const labourFChange = (event) => {
    const cost = event.target.value;
    setlabourFCost(cost);
  };

  const [labourSCost, setlabourSCost] = useState(0);
  const labourS = costData && costData.costData && costData.costData.labourSite;
  const labourSChange = (event) => {
    const cost = event.target.value;
    setlabourSCost(cost);
  };

  const [transportCost, setTransportCost] = useState(0);
  const transport = costData && costData.costData && costData.costData.transport;
  const transportChange = (event) => {
    const cost = event.target.value;
    setTransportCost(cost);
  };

  const [boardCost, setBoardCost] = useState(0);
  const board = costData && costData.costData && costData.costData.board;
  const boardChange = (event) => {
    const cost = event.target.value;
    setBoardCost(cost);
  };


  const handleDef = costData && costData.costData && costData.costData.handle['Handle'];
  const mplDef = costData && costData.costData && costData.costData.mpl['MPL-1'];
  const hingesDef = costData && costData.costData && costData.costData.hinges['Hinges-1'];
  const glassDef = costData && costData.costData && costData.costData.glass['5mm'];
  const marginDef = costData && costData.costData && costData.costData.margin['Margin-5'];
  const marketingDef = costData && costData.costData && costData.costData.marketing['Marketing-5'];
  const labourFactoryDef = costData && costData.costData && costData.costData.labourFactory['LabourFactory-5'];
  const labourSiteDef = costData && costData.costData && costData.costData.labourSite['LabourSite-5'];
  const transportDef = costData && costData.costData && costData.costData.transport['Transport-5'];
  const boarddDef = costData && costData.costData && costData.costData.board['Board-1'];
  
  const addDef = () => {
      sethandleCost(handleDef);
      setlockCost(mplDef);
      sethingesCost(hingesDef);
      setGlassCost(glassDef);
      setMarginCost(marginDef);
      setMarketingCost(marketingDef);
      setlabourFCost(labourFactoryDef);
      setlabourSCost(labourSiteDef);
      setTransportCost(transportDef);
      setBoardCost(boarddDef);
  };

  useEffect(() => {
      addDef();
  }, []);
  
  
  const data = {
    frame:{
      length: selectedFrameLength*3.28084,
      weight: selectedFrameWeight,
      amount: selectedFramePrice,
      name : selectedFrameName
    },
    shutter:{
      length: selectedShutterLength*3.28084,
      weight: selectedShutterWeight,
      amount: selectedShutterPrice,
      name : selectedShutterName
    },
    mullion:{
      length: selectedMullionLength*3.28084,
      weight: selectedMullionWeight,
      amount: selectedMullionPrice,
      name : selectedMullionName
    },
    beeding:{
      length: selectedBeedingLength*3.28084,
      weight: selectedBeedingWeight,
      amount: selectedBeedingPrice,
      name : selectedBeedingName
    },
    panelCost:{
      length: selectedPanelLength*3.28084,
      weight: selectedPanelWeight,
      amount: selectedPanelPrice,
      name : selectedPanelName
    },
    gasket:{
      length:'1',
      weight:'1',
      amount: costData && costData.costData && costData.costData.gasket && costData.costData.gasket.ProfileGasket
    },
    
      steel: costData && costData.costData && costData.costData.Steel,
      wastage: costData && costData.costData && costData.costData.wastage,
      mplCost:lockCost,
      hingsCost: hingesCost,
      screwsCost: costData && costData.costData && costData.costData.screws,
      marketingCost: marketingCost,
      transportCost: transportCost,
      labourFcost: labourFCost,
      labourScost: labourSCost,
      glassCost: glassCost,
      marginCost: marginCost, 
      handleCost: handleCost,
      boardCost:boardCost
  }
   

  const Frame = () => {
    
    if(measure === 'ft' ){
    if(bottomFrame === 'yes'){
      return (2 * (boxWidth + boxHeight)).toFixed(2);
    }else{
      return (2 *(boxHeight +boxWidth)).toFixed(2) ;
    }
  }else{
    if(bottomFrame === 'yes'){
      return (2 * ((boxWidth/304.8) + (boxHeight/304.8))).toFixed(2);
    }else{
      return (2 *((boxHeight/304.8) +(boxWidth/304.8))).toFixed(2);
    }
  }
  };
  
  const Shutter = () => {
    if(measure === 'ft' ){
      return (((((boxHeight-newHeights)*shutterCount)*2)*numFrames)+((boxWidth/numSecondInnerBoxes)*shutterCount)*2).toFixed(2);
    }else{
      return ((((((boxHeight/304.8)-(newHeights/304.8))*shutterCount)*2)*numFrames)+(((boxWidth/304.8)/numSecondInnerBoxes)*shutterCount)*2).toFixed(2);
    }
  };

  const Panel = () => {
    if(measure === 'ft' ){
    if(panelType === 1){
       return ((boxHeight / (150*0.00328084)) *boxWidth).toFixed(2);
    }else{
      return 0; 
    }
  }else{
    if(panelType === 1){
      return (((boxHeight/304.8) / (150*0.00328084)) *(boxWidth/304.8)).toFixed(2);
   }else{
     return 0; 
   }
  }
     
  };

const Mullion = () => {
  if(measure === 'ft' ){
    if (newHeights === 0){
      return ((boxHeight * (numSecondInnerBoxes - 1))*numFrames).toFixed(2);
    }else{
      return (((boxHeight-newHeights) * (numSecondInnerBoxes - 1)+(boxWidth/numFrames))*numFrames).toFixed(2);
    }
  }else{
    if (newHeights === 0){
      return (((boxHeight/304.8) * (numSecondInnerBoxes - 1))*numFrames).toFixed(2);
    }else{
      return ((((boxHeight/304.8)-(newHeights/304.8)) * (numSecondInnerBoxes - 1)+((boxWidth/304.8)/numFrames))*numFrames).toFixed(2);
    }
  }
};

const Beeding = () => {
  if(measure === 'ft' ){
  if (newHeights === 0){
    return (((boxHeight * numSecondInnerBoxes * 2)+((boxWidth*2/numFrames)))*numFrames).toFixed(2);
  }else{
    return (((newHeights*2) + ((boxHeight-newHeights) * (numSecondInnerBoxes * 2))+((boxWidth*4/numFrames)))*numFrames).toFixed(2);
  }
}else{
  if (newHeights === 0){
    return ((((boxHeight/304.8) * numSecondInnerBoxes * 2)+(((boxWidth/304.8)*2/numFrames)))*numFrames).toFixed(2);
  }else{
    return ((((newHeights/304.8)*2) + (((boxHeight/304.8)-(newHeights/304.8)) * (numSecondInnerBoxes * 2))+(((boxWidth/304.8)*4/numFrames)))*numFrames).toFixed(2);
  }
}
};

const beedingGasket = () => {
  if(measure === 'ft' ){
  if (newHeights === 0){
    return ((((boxHeight * numSecondInnerBoxes * 2)+((boxWidth*2/numFrames)))*numFrames)*1).toFixed(2);
  }else{
    return ((((newHeights*2) + ((boxHeight-newHeights) * (numSecondInnerBoxes * 2))+((boxWidth*4/numFrames)))*numFrames)*1).toFixed(2);
  }
}else{
  if (newHeights === 0){
    return (((((boxHeight/304.8) * numSecondInnerBoxes * 2)+(((boxWidth/304.8)*2/numFrames)))*numFrames)*1).toFixed(2);
  }else{
    return (((((newHeights/304.8)*2) + (((boxHeight/304.8)-(newHeights/304.8)) * (numSecondInnerBoxes * 2))+(((boxWidth/304.8)*4/numFrames)))*numFrames)*1).toFixed(2);
  }
}
};

const shutterGasket = () => {
  if(measure === 'ft' ){
    return ((((((boxHeight-newHeights)*shutterCount)*2)+((boxWidth/numSecondInnerBoxes)*shutterCount)*2)*numFrames)*2).toFixed(2);
  }else{
    return (((((((boxHeight/304.8)-(newHeights/304.8))*shutterCount)*2)+(((boxWidth/304.8)/numSecondInnerBoxes)*shutterCount)*2)*numFrames)*2).toFixed(2);
  }
};

const frameGasket = () => {
  if(measure === 'ft' ){
  if(bottomFrame === 'yes'){
    return (2 * (boxWidth + boxHeight)).toFixed(2);
  }else{
    return (2 *(boxHeight +boxWidth)).toFixed(2);
  }
}else{
  if(bottomFrame === 'yes'){
    return (2 * ((boxWidth/304.8) + (boxHeight/304.8))).toFixed(2);
  }else{
    return (2 *((boxHeight/304.8) +(boxWidth/304.8))).toFixed(2);
  }
}
};

const steelFrame = () => {
  if(measure === 'ft' ){
  if(bottomFrame === 'yes'){
    return (2 * (boxWidth + boxHeight)).toFixed(2);
  }else{
    return (2 *(boxHeight +boxWidth)).toFixed(2);
  }
}else{
  if(bottomFrame === 'yes'){
    return (2 * ((boxWidth/304.8) + (boxHeight/304.8))).toFixed(2);
  }else{
    return (2 *((boxHeight/304.8) +(boxWidth/304.8))).toFixed(2);
  }
}
};

const steelMullion = () => {
  if(measure === 'ft' ){
  if (newHeights === 0){
    return ((boxHeight * (numSecondInnerBoxes - 1))*numFrames).toFixed(2);
    }else{
      return (((boxHeight-newHeights) * (numSecondInnerBoxes - 1)+(boxWidth/numFrames))*numFrames).toFixed(2);
    }
  }else{
    if (newHeights === 0){
        return (((boxHeight/304.8) * (numSecondInnerBoxes - 1))*numFrames).toFixed(2);
      }else{
        return ((((boxHeight/304.8)-(newHeights/304.8)) * (numSecondInnerBoxes - 1)+((boxWidth/304.8)/numFrames))*numFrames).toFixed(2);
    }
  }
};

const steelShutter = () => {
  if(measure === 'ft' ){
    return (((((boxHeight-newHeights)*shutterCount)*2)+((boxWidth/numSecondInnerBoxes)*shutterCount)*2)*numFrames).toFixed(2);
  }else{
    return ((((((boxHeight/304.8)-(newHeights/304.8))*shutterCount)*2)+(((boxWidth/304.8)/numSecondInnerBoxes)*shutterCount)*2)*numFrames).toFixed(2);
  }
};

const cuplerJoint = () => {
  if(measure === 'ft' ){
    return ((boxHeight)*(numFrames-1)).toFixed(2);
  }else{
    return (((boxHeight/304.8))*(numFrames-1)).toFixed(2);
  }
};

  let frameArea = null;
  if(measure === 'ft' ){
    frameArea = boxHeight * boxWidth;
  }else{
    frameArea = (boxHeight/304.8) * (boxWidth/304.8);
  }
const fixedShutters = (fixedCount + numFirstInnerBoxes)*numFrames;

const values = {
  frame:{
    wpf: data.frame.weight/data.frame.length,
    rw: (data.frame.weight/data.frame.length)*Frame(),
    rpf: data.frame.amount,
    amount: ((data.frame.weight/data.frame.length)*Frame())*data.frame.amount,
  },
  shutter:{
    wpf: data.shutter.weight/data.shutter.length,
    rw: (data.shutter.weight/data.shutter.length)*Shutter(),
    rpf: data.shutter.amount,
    amount: ((data.shutter.weight/data.shutter.length)*Shutter())*data.shutter.amount,
  },
  mullion:{
    wpf: data.mullion.weight/data.mullion.length,
    rw: (data.mullion.weight/data.mullion.length)*Mullion(),
    rpf: data.mullion.amount,
    amount: ((data.mullion.weight/data.mullion.length)*Mullion())*data.mullion.amount,
  },
  beeding:{
    wpf: data.beeding.weight/data.beeding.length,
    rw: (data.beeding.weight/data.beeding.length)*Beeding(),
    rpf: data.beeding.amount,
    amount: ((data.beeding.weight/data.beeding.length)*Beeding())*data.beeding.amount,
  },
  shutterGasket:{
    wpf: data.gasket.weight/data.gasket.length,
    rw: (data.gasket.weight/data.gasket.length)*shutterGasket(),
    rpf: data.gasket.amount,
    amount: ((data.gasket.weight/data.gasket.length)*shutterGasket())*data.gasket.amount,
  },
  frameGasket:{
    wpf: data.gasket.weight/data.gasket.length,
    rw: (data.gasket.weight/data.gasket.length)*frameGasket(),
    rpf: data.gasket.amount,
    amount: ((data.gasket.weight/data.gasket.length)*frameGasket())*data.gasket.amount,
  },
  beedingGasket:{
    wpf: data.gasket.weight/data.gasket.length,
    rw: (data.gasket.weight/data.gasket.length)*beedingGasket(),
    rpf: data.gasket.amount,
    amount: ((data.gasket.weight/data.gasket.length)*beedingGasket())*data.gasket.amount,
  },
  panel:{
    wpf: data.panelCost.weight/data.panelCost.length,
    rw: (data.panelCost.weight/data.panelCost.length)*Panel(),
    rpf: data.panelCost.amount,
    amount: ((data.panelCost.weight/data.panelCost.length)*Panel())*data.panelCost.amount,
  },
  steelFrame:{
    qty: steelFrame(),
    price: data.steel,
    amount: steelFrame()*data.steel,
  },
  steelShutter:{
    qty: steelShutter(),
    price: data.steel,
    amount: steelShutter()*data.steel,
  },
  steelMullion:{
    qty: steelMullion(),
    price: data.steel,
    amount: steelMullion()*data.steel,
  },
  mpl:{
    qty:shutterCount*numFrames,
    price:data.mplCost,
    amount:shutterCount*numFrames*data.mplCost
  },
  hings:{
    qty:shutterCount*numFrames*4,
    price:data.hingsCost,
    amount:shutterCount*numFrames*4*data.hingsCost
  },
  screws:{
    qty:Math.ceil((frameArea+ (measure === 'ft' ? boxHeight : (boxHeight/304.8) + ( measure === 'ft' ? boxWidth : (boxWidth/304.8) )*numSecondInnerBoxes*numFrames))*1.6),
    price:data.screwsCost,
    amount:Math.ceil((frameArea+ (measure === 'ft' ? boxHeight : (boxHeight/304.8) + ( measure === 'ft' ? boxWidth : (boxWidth/304.8) )*numSecondInnerBoxes*numFrames ))*1.6)*data.screwsCost
  },
  marketing:{
    qty: frameArea,
    price: data.marketingCost,
    amount:frameArea*data.marketingCost
  },
  transport:{
    qty: frameArea,
    price: data.transportCost,
    amount:frameArea*data.transportCost
  },
  labourF:{
    qty: frameArea,
    price: data.labourFcost,
    amount:frameArea*data.labourFcost
  },
  labourS:{
    qty: frameArea,
    price: data.labourScost,
    amount:frameArea*data.labourScost
  },
  glass:{
    qty: panelType === 3 ? frameArea: 0,
    price: data.glassCost,
    amount: panelType === 3 ? frameArea*data.glassCost : 0
  },
  board:{
    qty: panelType === 2 ? frameArea: 0,
    price: data.boardCost,
    amount: panelType === 2 ? frameArea*data.boardCost : 0
  },
  margin:{
    qty: frameArea,
    price: data.marginCost,
    amount: frameArea*data.marginCost
  },
  
};

    const frameTotal = Number(values.panel.amount + values.frame.amount + values.shutter.amount + values.mullion.amount + values.beeding.amount + values.shutterGasket.amount + values.frameGasket.amount + values.beedingGasket.amount);
    const wastage = frameTotal *data.wastage/100;
    const frps = (frameTotal+wastage) / frameArea;
  
    const hardTotal = Number(values.mpl.amount + values.hings.amount + values.screws.amount);
    const hrps = hardTotal/frameArea;
  
    const prodTotal = Number(values.marketing.amount + values.transport.amount + values.labourF.amount + values.labourS.amount + values.glass.amount + values.board.amount);
    const prps = prodTotal/frameArea;
  
    const marginTotal = Number(values.margin.amount);
    const mrps = marginTotal/frameArea;
  
    const grandTotal = frameTotal + hardTotal + prodTotal + wastage + marginTotal;
    const grandAps = grandTotal/frameArea;

    const calculatedBoxWidth = measure === 'ft' ? boxWidth : (boxWidth / 304.8);
    const calculatedBoxHeight = measure === 'ft' ? boxHeight : (boxHeight / 304.8);
    const calculatednewWidths = newWidths.map(width => 
      measure === 'ft' ? parseFloat(width) : parseFloat(width) / 304.8
    );
    const calculatednewHeights = measure === 'ft' ? newHeights : (newHeights / 304.8);
    
    const coreData = {userId, quoteId, data: {item, measure,  series, bottomFrame, panelHeights, selectedProfile, qty, data, mesh, boxWidth: calculatedBoxWidth, boxHeight: calculatedBoxHeight, panelType, numFirstInnerBoxes, numFrames, numSecondInnerBoxes, newHeights: calculatednewHeights, newWidths: calculatednewWidths, boxColors, boxNames, boxHandle, deviceWidth, screen, boxPosition, shutterCount, fixedCount, frameArea, values, frameTotal, wastage, hardTotal, prodTotal, marginTotal, grandTotal}
    };

    const sendDataToUrl = async () => {
      try {
        const response = await axios.post('https://upvcapi.sninfoserv.com/quotes/', {
          coreData, userId
        });
       // console.log('Response:', response.data);
        if(response.data.status === 'success'){
          navigate(selectPath);
        }
        // Handle response as needed
      } catch (error) {
        console.error('Error sending data:', error);
        // Handle error as needed
      }
    };

    const profileDatas = {width: calculatedBoxWidth, series, height: calculatedBoxHeight, profileId: selectedProfile, mesh, shutterCount, type: item}
 

    const handleSeriesChange = (event) => {
      const id = event.target.value;
      setSeries(id);
      sendprofileDataToUrl();
    };   



    const sendprofileDataToUrl = async () => {
      setCalcBtn(true);
      try {
        const response = await axios.post('https://upvcapi.sninfoserv.com/find-default/', profileDatas);
        console.log('Response:', response.data);
        if(response.data.status === 'success'){
           if(response.data.data[0].beeding !== ''){
            setSelectedBeedingSeries(response.data.data[0].beedingSeries);
            setSelectedBeedingId(response.data.data[0].beeding);
           }
           if(response.data.data[0].frame !== ''){
            setSelectedFrameSeries(response.data.data[0].frameSeries);
            setSelectedFrameId(response.data.data[0].frame);
           }
           if(response.data.data[0].interlock !== ''){
            //alert('interlock');
           }
           
           if(response.data.data[0].mullion !== null){
            setSelectedMullionSeries(response.data.data[0].mullionSeries);
            setSelectedMullionId(response.data.data[0].mullion); 
           }
           if(response.data.data[0].panel !== ''){
            //alert('panel');
           }
           if(response.data.data[0].shutter !== ''){
            setSelectedShutterSeries(response.data.data[0].shutterSeries);
            setSelectedShutterId(response.data.data[0].shutter);
           }
           

        }
      } catch (error) {
        console.error('Error sending data:', error);
      }
      setCalcBtn(false);
      setIsProfile(true);
      addDef();
    };


    return (
    <div>
    <Navbar />
    <Header />
    
    <div class="pc-container">
      <div class="pc-content">
        
        <div class="row">
          <div class="col-md-12 col-xxl-4 col-xl-4">

           <div class="card">
              <div class="card-body">
               
                  <div class=" row align-items-center">
                  <div class="col-12 mb-2">
                    <p>Messurements</p>
                    <button className="btn btn-primary w-100" type="button" onClick={() => changeMeasure(measure)}>{measure === 'ft' ? 'Feet': 'MM'}</button>
                  </div>
                    <div class="col-6">
                      <p>Width </p>
                      <div class="input-group mb-2">
                        <button class="btn btn-danger" type="button" onClick={() =>  handleWidth('minus')}>-</button>
                        <input type="text" class="form-control" value={boxWidth} onChange={handleWidthChange} />
                        <button class="btn btn-success" type="button" onClick={() =>  handleWidth('plus')}>+</button>
                      </div>
                    </div>

                    <div class="col-6">
                      <p>Height</p>
                      <div class="input-group mb-2">
                        <button class="btn btn-danger" type="button" onClick={() =>  handleHeight('minus')}>-</button>
                        <input type="text" class="form-control" value={boxHeight} onChange={handleHeightChange} />
                        <button class="btn btn-success" type="button" onClick={() =>  handleHeight('plus')}>+</button>
                      </div>
                    </div>

                  </div>
               
                  <div class="mt-3 row align-items-center">
                    <div class="col-6">
                      <p>Outer Frame</p>
                      <div class="input-group mb-2">
                        <button class="btn btn-danger" type="button" onClick={() =>  addFrame('minus')}>-</button>
                        <input type="text" class="form-control"  value={numFrames} />
                        <button class="btn btn-success" type="button" onClick={() =>  addFrame('plus')}>+</button>
                      </div>
                    </div>

                    <div class="col-6">
                      <p>No of Windows</p>
                        <div class="input-group mb-2">
                          <button class="btn btn-danger" type="button" onClick={() =>  handleQty('minus')}>-</button>
                          <input type="text" class="form-control"  value={qty} onChange={handleQtyChange} />
                          <button class="btn btn-success" type="button" onClick={() =>  handleQty('plus')}>+</button>
                        </div>
                    </div>

                </div>

            {numFrames === 0 ? (
              <></>
            ) : (
              
               
                  <div class="mt-3 row align-items-center">
                    
                      {newHeights === 0 ? (
                        <>
                        <div class="col-6">
                         <p>Horizontal Fixed</p>
                          <button className="btn btn-primary w-100" type="button" onClick={() => handleTopChange()}>Add H.Fixed</button>
                        </div>
                        <div class="col-6">
                        <p>Outer Frame Bottom</p>
                        <button className={bottomFrame === 'no' ? "btn btn-success mb-2 w-100" : "btn btn-info mb-2 w-100"}style={{ marginRight: '10px' }} onClick={() => changeBottom(bottomFrame)}>Bottom - {bottomFrame}</button>
                        </div>
                        </>
                      ) : (
                      <>
                      <div class="col-12">
                      <p>Horizontal Fixed Height</p>
                        <div className="input-group mb-2">
                          <button className="btn btn-danger" type="button" onClick={() => handleTop('minus')}>-</button>
                          <input type="text" className="form-control" value={newHeights} onChange={handleTopHeight} />
                          <button className="btn btn-success" type="button" onClick={() => handleTop('plus')}>+</button>
                          <button className="btn btn-outline-secondary" type="button" onClick={toggleBoxPosition}> {boxPosition === 'Top' ? 'Bottom' : 'Top'}</button>
                        </div>
                      </div>
                      <div class="col-12">
                      <p>Outer Frame Bottom</p>
                        <button className={bottomFrame === 'no' ? "btn btn-success mb-2 w-100" : "btn btn-info mb-2 w-100"}style={{ marginRight: '10px' }} onClick={() => changeBottom(bottomFrame)}>Bottom - {bottomFrame}</button>
                      </div>
            
                      </>
                      )}
                   
                    <div class="col-12 mt-3">
                    <p>Panel Type</p>
              <div class="input-group mb-2">
                    <button className={panelType === 1 ? 'btn  w-33 btn-success' : ' btn w-33 btn-secondary'} type="button" onClick={() => handleButtonClick(1)}>Panel</button>
                    <button className={panelType === 2 ? 'btn  w-33 btn-success' : ' btn w-33 btn-secondary'} type="button" onClick={() => handleButtonClick(2)}>Board</button>
                    <button className={panelType === 3 ? 'btn  w-33 btn-success' : ' btn w-33 btn-secondary'} type="button" onClick={() => handleButtonClick(3)}>Glass</button>
              </div>
                    </div>
                    <div class="col-12 mt-3">
                     <p>Shutter</p>            
                       <button className="btn btn-primary w-100" onClick={() => addWindow('Shutter')}>Add Shutter</button>
                    </div>
                  </div>
              )}
                  <div class="col-12 mb-2 mt-3">
                    <p>Select Profile</p>
                    <select type="text" className="form-control" value={customProfile} onChange={handleProfileChange}>
                    {profileDetails && profileDetails.data && profileDetails.data.map((profiles, index) => (
                      <option className="form-control" value={index}>{profiles.profileName}</option>
                    ))}
                       <option className="form-control text-sm text-center" disabled>Add more Profile contact Administration</option>
                    </select>
                  </div>

                  <div class="mt-3 row align-items-center">
                  <div class="col-6 mb-2 mt-2">
                 
                      <select className="form-control" value={series} onChange={handleSeriesChange}>
                        <option value="" hidden>Select Series</option>
                        <option className="form-control" value="50 Series">50 Series</option>
                        <option className="form-control" value="60 Series">60 Series</option>
                      </select>
                  </div>

                  <div class="col-6 mb-2 mt-2 ">
                      <button class={calcBtn === false ? "btn btn-info w-100" : "btn btn-secondary w-100"} onClick={sendprofileDataToUrl}>{calcBtn === false ? 'Load Profile' : 'Profile Loading...'}</button>
                  </div>
                  </div>

          </div>
        </div>

        
          
      </div>
           
        

      <div class="col-md-12 col-xxl-8 col-xl-8" >
            <div class="card" style={{background:'#fff'}}>
              <div class="card-body mb-5">
              
              <div>

<div style={{float:'left', width: boxWidth*screen/mCalc+'vw',  height:boxHeight*screen/mCalc+'vw', border:'1px solid #333', borderBottom: bottomFrame === 'no' ? '1px solid #33333350' : '1px solid #333'  }}>
{[...Array(numFrames)].map((_, index) => (
  <div style={{float:'left', margin:'5px', border:'1px solid #333',width:`calc(${boxWidth*screen/mCalc/numFrames+'vw'} - 12px)`, height: bottomFrame === 'no' ? `calc(${boxHeight*screen/mCalc+'vw'} - 6px)` : `calc(${boxHeight*screen/mCalc+'vw'} - 12px)`, } }> 
  {newHeights !== 0 ? (
<>
  
  { boxPosition === 'Top' ? (
      <div></div>
    ) : (
      [...Array(numFirstInnerBoxes)].map((_, index) => (
  <div style={{width: `calc(${boxWidth*screen/mCalc/numFrames+'vw'} - 23px)`, height:`calc(${(newHeights *screen/mCalc)}vw )`, background: 'lightblue', margin:'5px', border:'1px solid #333'}}></div>
  ))
  )}
 
  <div style={{ display: 'flex' }}>
    {[...Array(numSecondInnerBoxes)].map((_, index) => (
      <div key={index} style={{ flex: '1', height: bottomFrame === 'no' ? `calc(${(boxHeight - newHeights) *screen/mCalc}vw - 23px)` : `calc(${(boxHeight - newHeights) *screen/mCalc}vw - 30px)`, width: `calc(${newWidths[index] *screen/mCalc}vw - 5px - 19px)`, backgroundColor: panelType === 1 ? 'lightgray' : panelType === 2 ? 'lightyellow' : 'lightblue', marginTop: boxPosition === 'Top' ? '5px' : '0px', marginRight: index === numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #333' }}>
       
              <div className="expanding-arrow-line" style={{ 
                position: 'absolute',
                float:'left', 
                width: `calc(${newWidths[index] *screen/mCalc}vw - 15px)`,
                bottom:'-10px', 
                top: `calc(${boxHeight*screen/mCalc}vw - 25px)`,
                
               }}>
                <div className="arrow-left"></div>
                  <div className="line" align="center">
                    <div style={{marginTop:'-7px',background:'white',maxWidth:'60px',textAlign:'center'}}><p>{typeof newWidths[index] === 'number' ? newWidths[index].toFixed(2) : newWidths[index]}</p></div>
                  </div>
                <div className="arrow-right"></div>
              </div>
       
       
        { index === 0 && ( boxHandle[index] === 'left' ? (
             <img
             src={left}
             width='15'
             style={{
               float: 'left',
               marginTop: (boxHeight) * ((screen/mCalc/2)-(screen/mCalc/15))+'vw',
               marginLeft: '2px',
               width: screen === 4 ? '13px' : '8px'
             }}
             onClick={() => changeHandle(index)}
             alt="Left Handle"
           />
         ) : (
           <img
             src={right}
             width='15'
             style={{
               float: 'right',
               marginTop: (boxHeight) * ((screen/mCalc/2)-(screen/mCalc/15))+'vw',
               marginRight: '2px',
               width: screen === 4 ? '13px' : '8px'
             }}
             onClick={() => changeHandle(index)}
             alt="Right Handle"
           />
         ) )}


              { mesh === 'yes' ? (
                <img src={meshimg} style={{width:'50px', float:'left', marginLeft: boxHandle[index] === 'left' ? '-15px' : '0px', marginTop: `calc(${((boxHeight-newHeights)*screen/mCalc)}vw - ${bottomFrame === 'no' ? '76px' : '80px' })`}} />
                ):(<></>)
              }
        
              

      </div>

    ))}
    
  </div>
  { boxPosition === 'Bottom' ? (
      <div></div>
    ) : (
      [...Array(numFirstInnerBoxes)].map((_, index) => (
  <div style={{width: `calc(${boxWidth*screen/mCalc/numFrames+'vw'} - 23px)`, height:`calc(${(newHeights *screen/mCalc)}vw )`, background: 'lightgreen', margin:'5px', border:'1px solid #333'}}></div>
  ))
  )}

  { numSecondInnerBoxes === 0 ? (
        <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((boxWidth/numFrames)*screen/mCalc)+'vw',top:(boxHeight-newHeights)*screen/mCalc+'vw', }} >
        <div className="arrow-left"></div>
        <div className="line" align="center">
          <div style={{marginTop:'-8px',background:'white',maxWidth:'60px',textAlign:'center'}}><p>{typeof (boxWidth/numFrames) === 'number' ? (boxWidth/numFrames).toFixed(2) : (boxWidth/numFrames)}</p></div>
        </div>
        <div className="arrow-right"></div>
      </div>
      ) : (
        <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((boxWidth/numFrames)*screen/mCalc)+'vw',bottom:'-10px',top:'50px', }} >
          <div className="arrow-left"></div>
          <div className="line" align="center">
            <div style={{marginTop:'-8px',background:'white',maxWidth:'60px',textAlign:'center'}}><p>{typeof (boxWidth/numFrames) === 'number' ? (boxWidth/numFrames).toFixed(2) : (boxWidth/numFrames)}</p></div>
          </div>
          <div className="arrow-right"></div>
        </div>
      )}
</>
 ) : (
<>
<div style={{ display: 'flex' }}>
  {[...Array(numSecondInnerBoxes)].map((_, index) => (
    panelHeights === 0 ? (
    <div key={index} style={{ flex: '1', height: bottomFrame === 'no' ? `calc(${(boxHeight) *screen/mCalc}vw - 18px )` : `calc(${(boxHeight) *screen/mCalc}vw - 23px )`, width: `calc(${newWidths[index] *screen/mCalc}vw - 5px - 19px)`, backgroundColor: panelType === 1 ? 'lightgray' : panelType === 2 ? 'lightyellow' : 'lightblue', marginRight: index === numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #333',marginTop:'5px' }}>
     
     <div className="expanding-arrow-line" style={{ 
                float:'left', 
                width: `calc(${newWidths[index] *screen/mCalc}vw - 20px)`,
                bottom:'-10px', 
                top: boxHeight *screen/mCalc + 'vw',
               
               }}>
                <div className="arrow-left"></div>
                  <div className="line" align="center">
                    <div style={{marginTop:'-7px',background:'white',maxWidth:'60px',textAlign:'center'}}><p>{typeof newWidths[index] === 'number' ? newWidths[index].toFixed(2) : newWidths[index]}</p></div>
                  </div>
                <div className="arrow-right"></div>
              </div>
     
      { index === 0 &&  boxHandle[index] === 'left' && (
      
              <img
                src={right}
                width='15'
                style={{
                  float: 'right',
                  marginTop: (boxHeight) * ((screen/mCalc/2)-(screen/mCalc/15))+'vw',
                  marginRight: '2px',
                  width: screen === 4 ? '13px' : '8px'
                }}
                onClick={() => changeHandle(index)}
                alt="Left Handle"
              />
              

            )}  
            { index === 1 &&  boxHandle[index] === 'right' &&(
           
              <img
                src={left}
                width='15'
                style={{
                  float: 'left',
                  marginTop: (boxHeight) * ((screen/mCalc/2)-(screen/mCalc/15))+'vw',
                  marginLeft: '2px',
                  width: screen === 4 ? '13px' : '8px'
                }}
                onClick={() => changeHandle(index)}
                alt="Right Handle"
              />
              
            )}


             { mesh === 'yes' ? (
                <img src={meshimg} style={{width:'50px', float:'left', marginLeft: boxHandle[index] === 'left' ? '-15px' : '0px', marginTop: `calc(${((boxHeight-newHeights)*screen/mCalc)}vw - ${bottomFrame === 'no' ? '71px' : '75px' })`}} />
                ):(<></>)
              }
             
              
              
           
    </div>
    ) : (
      <div key={index} style={{  height: panelHeights*screen/mCalc+'vw', minWidth:`calc(${newWidths[index] *screen/mCalc}vw - 5px - 19px)`, backgroundColor: 'lightblue', border: '1px solid #333',margin:'5px 5px 5px'}}>
    
      <div key={index} style={{ height: bottomFrame === 'no' ? `calc(${(boxHeight-panelHeights) *screen/mCalc}vw - 18px )` : `calc(${(boxHeight-panelHeights) *screen/mCalc}vw - 30px )`, width: `calc(${newWidths[index] *screen/mCalc}vw - 5px - 19px)`, backgroundColor: boxColors[index] || 'lightblue',  border: '1px solid #333',marginTop: `calc(${panelHeights *screen/mCalc}vw + 5px )` }}>
        {  index === 0 && ( boxHandle[index] === 'left' ? (
          <>
                <img
                  src={left}
                  width='15'
                  style={{
                    float: 'left',
                    marginTop: (boxHeight) * ((screen/mCalc/2)-(screen/mCalc/15))+'vw',
                    marginLeft: '2px',
                    width: screen === 4 ? '13px' : '8px'
                  }}
                  onClick={() => changeHandle(index)}
                  alt="Left Handle"
                />
                
                </>
              ) : (
                <>
                <img
                  src={right}
                  width='15'
                  style={{
                    float: 'right',
                    marginTop: (boxHeight) * ((screen/mCalc/2)-(screen/mCalc/15))+'vw',
                    marginRight: '2px',
                    width: screen === 4 ? '13px' : '8px'
                  }}
                  onClick={() => changeHandle(index)}
                  alt="Right Handle"
                />
                
                </>
              ) )}
               { mesh === 'yes' ? (
                <img src={meshimg} style={{width:'50px', float:'left',  bottom:'0px', marginTop:`calc(${(boxHeight) *screen/mCalc}vw - 75px )` }} />
               ):(<></>)}
               
                
                <div className="expanding-arrow-line" style={{ 
                  float:'left', 
                  width: `calc(${newWidths[index] *screen/mCalc}vw - 25px)`,
                   
                  ...(index === 0 && mesh === 'yes' && { top: boxHeight * ((screen/mCalc/2)-(screen/mCalc*0.43)) + 'vw' }),
                  ...(index === 0 && mesh === 'no' && { top: boxHeight * (1.3) + 'vw' }),
                  ...(index !== 0 && mesh === 'yes' &&  { top: boxHeight * (0.27) + 'vw' }),
                  ...(index !== 0 && mesh === 'no' &&  { top: boxHeight * ((screen/mCalc/2)+(screen/mCalc/2.02)) + 'vw' }),
                 }}>
                  <div className="arrow-left"></div>
                    <div className="line" align="center">
                      <div style={{marginTop:'-7px',background:'white',maxWidth:'60px',textAlign:'center'}}><p>{typeof newWidths[index] === 'number' ? newWidths[index].toFixed(2) : newWidths[index]}</p></div>
                    </div>
                  <div className="arrow-right"></div>
                </div>
             
      </div>
      </div>
    )
  ))}
</div> 
      { numSecondInnerBoxes === 0 ? (
        <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((boxWidth/numFrames)*screen/mCalc)+'vw', top:boxHeight*screen/mCalc+'vw',  }} >
        <div className="arrow-left"></div>
        <div className="line" align="center">
          <div style={{marginTop:'-8px',background:'white',maxWidth:'60px',textAlign:'center'}}><p>{typeof (boxWidth/numFrames) === 'number' ? (boxWidth/numFrames).toFixed(2) : (boxWidth/numFrames)}</p></div>
        </div>
        <div className="arrow-right"></div>
      </div>
      ) : (
        <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((boxWidth/numFrames)*screen/mCalc)+'vw', bottom:'-10px',top:'40px', }} >
          <div className="arrow-left"></div>
          <div className="line" align="center">
            <div style={{marginTop:'-8px',background:'white',maxWidth:'60px',textAlign:'center'}}><p>{typeof (boxWidth/numFrames) === 'number' ? (boxWidth/numFrames).toFixed(2) : (boxWidth/numFrames)}</p></div>
          </div>
          <div className="arrow-right"></div>
        </div>
      )}
</>
  )}
  </div>
   ))}
</div>

{ boxPosition === 'Top' ? (
  newHeights === 0 ? (

    <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
      <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${boxHeight *screen/mCalc}vw)`, top: '0', position: 'relative' }}>
       <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
       <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
         <div style={{ marginLeft: '-10px', marginTop: boxHeight * (screen/mCalc/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'50px'}}>{typeof boxHeight === 'number' ? boxHeight.toFixed(2) : boxHeight}</p></div>
       </div>
       <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
      </div>
    </div>
) : (  
      <>
        <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>

          <div className="expanding-arrow-line" style={{order: 1, flex:1, float: 'left', height: `calc(${(boxHeight-newHeights) *screen/mCalc}vw - 20px)`, top: '0', position: 'relative' }}>
            <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
            <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
              <div style={{ marginLeft: '-10px', marginTop: (boxHeight-newHeights) * (screen/mCalc/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'50px'}}>{typeof (boxHeight-newHeights) === 'number' ? (boxHeight-newHeights).toFixed(2) : (boxHeight-newHeights)}</p></div>
            </div>
            <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
          </div>
           <div className="expanding-arrow-line" style={{order: 2, flex:2, float: 'left', height: `calc(${newHeights *screen/mCalc}vw `, top: `calc(${(boxHeight-newHeights) *screen/mCalc}vw - 10px)`, position: 'relative' }}>
            <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
            <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
              <div style={{ marginLeft: '-10px', marginTop: newHeights * (((screen/mCalc/2)-(screen/mCalc/5)))+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'50px'}}>{typeof newHeights === 'number' ? newHeights.toFixed(2) : newHeights}</p></div>
            </div>
            <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
          </div>

        </div>

        <div style={{ width: '30px', float: 'left', paddingLeft: '0px', textAlign: 'left' }}>
        <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${boxHeight *screen/mCalc}vw)`, top: '0', position: 'relative' }}>
         <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
         <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
           <div style={{ marginLeft: '-10px', marginTop: boxHeight * (screen/mCalc/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'50px'}}>{typeof boxHeight === 'number' ? boxHeight.toFixed(2) : boxHeight}</p></div>
         </div>
         <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
        </div>
      </div>
      </>
)
) : (
newHeights === 0 ? (

    <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
      <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${boxHeight *screen/mCalc}vw)`, top: '0', position: 'relative' }}>
       <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
       <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
         <div style={{ marginLeft: '-10px', marginTop: boxHeight * (screen/mCalc/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'50px'}}>{typeof boxHeight === 'number' ? boxHeight.toFixed(2) : boxHeight}</p></div>
       </div>
       <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
      </div>
    </div>
) : (  
      <>
        <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
          <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${newHeights *screen/mCalc}vw + 10px)`, top: '0', position: 'relative' }}>
            <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
            <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
              <div style={{ marginLeft: '-10px', marginTop: newHeights * (screen/mCalc/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'50px'}}>{typeof newHeights === 'number' ? newHeights.toFixed(2) : newHeights}</p></div>
            </div>
            <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
          </div>
          <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${(boxHeight-newHeights) *screen/mCalc}vw - 20px)`, top: `calc(${newHeights *screen/mCalc}vw + 20px)`, position: 'relative' }}>
            <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
            <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
              <div style={{ marginLeft: '-10px', marginTop: (boxHeight-newHeights) * ((screen/mCalc/2)-(screen/mCalc/8))+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'50px'}}>{typeof (boxHeight-newHeights) === 'number' ? (boxHeight-newHeights).toFixed(2) : (boxHeight-newHeights)}</p></div>
            </div>
            <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
          </div>
        </div>
        <div style={{ width: '30px', float: 'left', paddingLeft: '0px', textAlign: 'left' }}>
        <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${boxHeight *screen/mCalc}vw)`, top: '0', position: 'relative' }}>
         <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
         <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
           <div style={{ marginLeft: '-10px', marginTop: boxHeight * (screen/mCalc/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'50px'}}>{typeof boxHeight === 'number' ? boxHeight.toFixed(2) : boxHeight}</p></div>
         </div>
         <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
        </div>
      </div>
      </>
)
)}
    
</div>
                
              </div>
            </div>

            {shutterCount === 0 ? (
              <></>
          ) : (
            <div class="card">
            <div class="card-body">
            <div class="row">
            
          <>
                  {[...Array(numSecondInnerBoxes)].map((_, index) => (
                  <div class="col-md-6 col-xxl-4 col-xl-4" key={index}>
                    <p>{boxNames[index]}</p>
                   <div className="input-group mb-2">
                    <input type="text" className="form-control" value={newWidths[index]}  onChange={(event) => handleNewWidthChange(index, event)} />
                    <button className="btn btn-danger" type="button" onClick={() => deleteInnerBox(index)}><i class="fas fa-trash"></i></button>
                    {boxNames[index] === 'Shutter' && (
                       boxHandle[index] === 'left' ? (
                        <button className="btn btn-outline-secondary" type="button" onClick={() => changeHandle(index)}><i class="fas fa-angle-left me-2"></i> </button>
                       ) : (
                        <button className="btn btn-outline-secondary" type="button" onClick={() => changeHandle(index)}><i class="fas fa-angle-right me-2"></i></button>
                      )
                    )}
                   </div>
                  </div>
                  ))}
              
             </>
         
           </div>
           </div>
            </div>
             )}
          </div>

{ isProfile === false ? (<div></div>):( 
          <>
         


          <div class="col-md-12 col-xxl-12 col-xl-12">
            <div class="card">
              <div class="card-header">
                <h5>Profile Cost</h5>
              </div>
              <div class="card-body">
              <table id="left-right-fix" class="table stripe row-border order-column">
                  <thead>
                    <tr>
                      <th>Product Description</th>
                      <th>Required Profile</th>
                      <th width="10%">Req. Qty (Feet)</th>
                      <th width="10%">Kg/Feet</th>
                      <th width="10%">Req. Qty (Kg)</th>
                      <th width="10%">Rate/Kg</th>
                      <th width="10%">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Casement Frame</td>
                      <td class="container-line">
                      <select class="mb-3 form-select-select item-line w-25" value={selectedFrameSeries || ''} onChange={handleFrameSeriesChange}>
                            <option value="">Select Series</option>
                            {profileData && profileData.map(seriesId => (
                              <option key={seriesId.series} value={seriesId.series}>{seriesId.series}</option>
                            ))}
                          </select>
                          
       
                            <select class="mb-3 form-select-select item-line w-75" value={selectedFrameId || ''} onChange={handleFrameIdChange}>
                            {selectedFrameSeries ? (<option value="">Select ID</option>):(<option value="">Select Series First</option>)}
                              {selectedFrameSeries && seriesDataFrame.items.map((item) => (
                                <option key={item.id} value={item.id}>{item.id+' - '+ item.name}</option>
                              ))}
                            </select>
                          
                      </td>
                      <td>{numeral(Frame()).format('0,0.00')}</td>
                      <td>{numeral(values.frame.wpf).format('0,0.00')}</td>
                      <td>{numeral(values.frame.rw).format('0,0.00')}</td>
                      <td>{numeral(values.frame.rpf).format('0,0.00')}</td>
                      <td>{numeral(values.frame.amount).format('0,0.00')}</td>
                    </tr>
                    <tr>
              <td>Casement Shutter</td>

              <td class="container-line">
                <select class="form-select-select item-line w-25" value={selectedShutterSeries || ''} onChange={handleShutterSeriesChange}>
                  <option value="">Select Series</option>
                  {profileData && profileData.map(seriesId => (
                    <option key={seriesId.series} value={seriesId.series}>{seriesId.series}</option>
                  ))}
                </select>
                <select class="form-select-select item-line w-75" value={selectedShutterId || ''} onChange={handleShutterIdChange}>
                  {selectedShutterSeries ? (<option value="">Select ID</option>):(<option value="">Select Series First</option>)}
                  {seriesDataShutter && seriesDataShutter.items.map((item) => (
                    <option key={item.id} value={item.id}>{item.id+' - '+ item.name}</option>
                  ))}
                </select>
              </td>
              <td>{numeral(Shutter()).format('0,0.00')}</td>
              <td>{numeral(values.shutter.wpf).format('0,0.00')}</td>
              <td>{numeral(values.shutter.rw).format('0,0.00')}</td>
              <td>{numeral(values.shutter.rpf).format('0,0.00')}</td>
              <td>{numeral(values.shutter.amount).format('0,0.00')}</td>
            </tr>
            <tr>
              <td>Mullion</td>
              <td class="container-line">
                <select class="mb-3 form-select-select item-line w-25" value={selectedMullionSeries || ''} onChange={handleMullionSeriesChange}>
                  <option value="">Select Series</option>
                  {profileData && profileData.map(seriesId => (
                    <option key={seriesId.series} value={seriesId.series}>{seriesId.series}</option>
                  ))}
                </select>
                <select class="mb-3 form-select-select item-line w-75" value={selectedMullionId || ''} onChange={handleMullionIdChange}>
                {selectedMullionSeries ? (<option value="">Select ID</option>):(<option value="">Select Series </option>)}
                    {seriesDataMullion && seriesDataMullion.items.map((item) => (
                      <option key={item.id} value={item.id}>{item.id+' - '+ item.name}</option>
                    ))}
                  </select>
              </td>
              <td>{numeral(Mullion()).format('0,0.00')}</td>
              <td>{numeral(values.mullion.wpf).format('0,0.00')}</td>
              <td>{numeral(values.mullion.rw).format('0,0.00')}</td>
              <td>{numeral(values.mullion.rpf).format('0,0.00')}</td>
              <td>{numeral(values.mullion.amount).format('0,0.00')}</td>
            </tr>
            <tr>
              <td>Beeding</td>
              <td class="container-line">
                  <select class="mb-3 form-select-select item-line w-25" value={selectedBeedingSeries || ''} onChange={handleBeedingSeriesChange}>
                    <option value="">Select Series</option>
                      {profileData && profileData.map(seriesId => (
                        <option key={seriesId.series} value={seriesId.series}>{seriesId.series}</option>
                      ))}
                  </select>
                    <select class="mb-3 form-select-select item-line w-75" value={selectedBeedingId || ''} onChange={handleBeedingIdChange}>
                      {selectedBeedingSeries ? (<option value="">Select ID</option>):(<option value="">Select Series </option>)}
                      {seriesDataBeeding && seriesDataBeeding.items.map((item) => (
                        <option key={item.id} value={item.id}>{item.id+' - '+ item.name}</option>
                      ))}
                    </select>
                </td>
              
              <td>{numeral(Beeding()).format('0,0.00')}</td>
              <td>{numeral(values.beeding.wpf).format('0,0.00')}</td>
              <td>{numeral(values.beeding.rw).format('0,0.00')}</td>
              <td>{numeral(values.beeding.rpf).format('0,0.00')}</td>
              <td>{numeral(values.beeding.amount).format('0,0.00')}</td>
            </tr>
            {panelType === 1 &&(
              <tr>
              <td>Panel</td>
              <td class="container-line">
                  <select class="mb-3 form-select-select item-line w-25" value={selectedPanelSeries || ''} onChange={handlePanelChange}>
                    <option value="">Select Series</option>
                      {profileData && profileData.map(seriesId => (
                        <option key={seriesId.series} value={seriesId.series}>{seriesId.series}</option>
                      ))}
                  </select>
                    <select class="mb-3 form-select-select item-line w-75" value={selectedPanelId || ''} onChange={handlePanelIdChange}>
                      {setSelectedPanelSeries ? (<option value="">Select ID</option>):(<option value="">Select Series </option>)}
                      {seriesDataPanel && seriesDataPanel.items.map((item) => (
                        <option key={item.id} value={item.id}>{item.id+' - '+ item.name}</option>
                      ))}
                    </select>
                </td>
              <td>{numeral(Panel()).format('0,0.00')}</td>
              <td>{numeral(values.panel.wpf).format('0,0.00')}</td>
              <td>{numeral(values.panel.rw).format('0,0.00')}</td>
              <td>{numeral(values.panel.rpf).format('0,0.00')}</td>
              <td>{numeral(values.panel.amount).format('0,0.00')}</td>
            </tr>
            )}
           
            <tr>
              <td colspan='2'>Frame Gasket</td>
              <td>{numeral(frameGasket()).format('0,0.00')}</td>
              <td>{numeral(values.frameGasket.wpf).format('0,0.00')}</td>
              <td>{numeral(values.frameGasket.rw).format('0,0.00')}</td>
              <td>{numeral(values.frameGasket.rpf).format('0,0.00')}</td>
              <td>{numeral(values.frameGasket.amount).format('0,0.00')}</td>
            </tr>
            <tr>
              <td colspan='2'>Shutter Gasket</td>
              <td>{numeral(shutterGasket()).format('0,0.00')}</td>
              <td>{numeral(values.shutterGasket.wpf).format('0,0.00')}</td>
              <td>{numeral(values.shutterGasket.rw).format('0,0.00')}</td>
              <td>{numeral(values.shutterGasket.rpf).format('0,0.00')}</td>
              <td>{numeral(values.shutterGasket.amount).format('0,0.00')}</td>
            </tr>
            <tr>
              <td colspan='2'>Beeding Gasket</td>
              <td>{numeral(beedingGasket()).format('0,0.00')}</td>
              <td>{numeral(values.beedingGasket.wpf).format('0,0.00')}</td>
              <td>{numeral(values.beedingGasket.rw).format('0,0.00')}</td>
              <td>{numeral(values.beedingGasket.rpf).format('0,0.00')}</td>
              <td>{numeral(values.beedingGasket.amount).format('0,0.00')}</td>
            </tr>

           
           
            <tr>
              <td colspan="6">Total</td>
              <td>{numeral(frameTotal).format('0,0.00')}</td>
            </tr>
            <tr>
              <th colspan="6">Wastage ( {data.wastage}% )</th>
              <th>{numeral(frameTotal+wastage).format('0,0.00')}</th>
            </tr>
            <tr>
              <th colspan="6">Profile Cost/Sqft</th>
              <th>{numeral(frps).format('0,0.00')}</th>
            </tr>
                  </tbody>
                </table>
            </div>   
            <hr />
                <div class="card-header">
                <h5>Hardware Cost</h5>
              </div>
              <div class="card-body">
              <table id="left-right-fix" class="table stripe row-border order-column">
                  <thead>
                    <tr>
                      <th colspan='2'>Product Description</th>
                      <th width="10%">Req. Qty </th>
                      <th width="10%">Price</th>
                      <th width="10%">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                
                  <tr>
              <td colspan='2'>Steel Frame</td>
              <td>{numeral(values.steelFrame.qty).format('0,0.00')}</td>
              <td>{numeral(values.steelFrame.price).format('0,0.00')}</td>
              <td>{numeral(values.steelFrame.amount).format('0,0.00')}</td>
            </tr>
            <tr>
              <td colspan='2'>Steel Mullion</td>
              <td>{numeral(values.steelMullion.qty).format('0,0.00')}</td>
              <td>{numeral(values.steelMullion.price).format('0,0.00')}</td>
              <td>{numeral(values.steelMullion.amount).format('0,0.00')}</td>
            </tr>
            <tr>
              <td colspan='2'>Steel Shutter</td>
              <td>{numeral(values.steelShutter.qty).format('0,0.00')}</td>
              <td>{numeral(values.steelShutter.price).format('0,0.00')}</td>
              <td>{numeral(values.steelShutter.amount).format('0,0.00')}</td>
            </tr>
            <tr>
              <td>Door Lock with Espag</td>
              <td>
              <select class="form-select-select item-line w-75" value={lockCost} onChange={lockChange}>
                  <option value="">Select Model</option>
                  {Object.entries(lockes).map(([key, value]) => (
                    <option key={key} value={value}>{key+' - ₹ '+value}</option>
                  ))}
                </select>
              </td>
              <td>{numeral(values.mpl.qty).format('0,0.00')}</td>
              <td>{numeral(values.mpl.price).format('0,0.00')}</td>
              <td>{numeral(values.mpl.amount).format('0,0.00')}</td>
            </tr>
            <tr>
              <td>3D Hinges</td>
              <td>
              <select class="form-select-select item-line w-75" value={hingesCost} onChange={hingesChange}>
                  <option value="">Select Model</option>
                  {Object.entries(hinges).map(([key, value]) => (
                    <option key={key} value={value}>{key+' - ₹ '+value}</option>
                  ))}
                </select>
              </td>
              <td>{numeral(values.hings.qty).format('0,0.00')}</td>
              <td>{numeral(values.hings.price).format('0,0.00')}</td>
              <td>{numeral(values.hings.amount).format('0,0.00')}</td>
            </tr>
            <tr>
              <td colspan='2'>Screws/Misc</td>
              <td>{numeral(values.screws.qty).format('0,0.00')}</td>
              <td>{numeral(values.screws.price).format('0,0.00')}</td>
              <td>{numeral(values.screws.amount).format('0,0.00')}</td>
            </tr>
            
            <tr>
              <th colspan="4">Total</th>
              <th>{numeral(hardTotal).format('0,0.00')}</th>
            </tr>
            <tr>
              <th colspan="4">Hardware Cost/Sqft</th>
              <th>{numeral(hrps).format('0,0.00')}</th>
            </tr>
                    </tbody>
                </table>
              </div>
              <hr />
            
              <div class="card-header ">
                <h5>Margin Cost</h5>
              </div>
              <div class="card-body">
              <table id="left-right-fix" class="table stripe row-border order-column">
              <thead>
                    <tr>
                      <th colspan='2'>Product Description</th>
                      <th width="10%">Req. Qty </th>
                      <th width="10%">Price</th>
                      <th width="10%">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr>
              <td>Margin</td>
              <td>
                <select class="form-select-select item-line w-75" value={marginCost} onChange={marginChange}>
                  <option value="" hidden>Select Margin Cost</option>
                  {margin && Object.entries(margin).map(([key, value]) => (
                    <option key={key} value={value}>{key+' - ₹ '+ Number(value).toFixed(2)+'/sqft'}</option>
                  ))}
                </select>
              </td>
              <td>{numeral(values.margin.qty).format('0,0.00')}</td>
              <td>{numeral(values.margin.price).format('0,0.00')}</td>
              <td>{numeral(values.margin.amount).format('0,0.00')}</td>
            </tr>
            <tr>
              <th colspan="4">Total</th>
              <th>{numeral(marginTotal).format('0,0.00')}</th>
            </tr>
            <tr>
              <th colspan="4">Margin Cost/Sqft</th>
              <th>{numeral(mrps).format('0,0.00')}</th>
            </tr>
            </tbody>
                </table>
              </div>
              <hr />

              <div class="card-header">
                <h5>Production Cost</h5>
              </div>
              <div class="card-body">
              <table id="left-right-fix" class="table stripe row-border order-column">
                  <thead>
                    <tr>
                      <th colspan='2'>Product Description</th>
                      <th width="10%">Req. Qty </th>
                      <th width="10%">Price</th>
                      <th width="10%">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr>
              <td>Markting OH</td>
              <td>
              <select class="form-select-select item-line w-75" value={marketingCost} onChange={marketingChange}>
                  <option value="" hidden>Select Marketing Coast</option>
                  {marketing && Object.entries(marketing).map(([key, value]) => (
                    <option key={key} value={value}>{key+' - ₹ '+ Number(value).toFixed(2)+'/sqft'}</option>
                  ))}
                </select>
              </td>
              <td>{numeral(values.marketing.qty).format('0,0.00')}</td>
              <td>{numeral(values.marketing.price).format('0,0.00')}</td>
              <td>{numeral(values.marketing.amount).format('0,0.00')}</td>
            </tr>
            <tr>
              <td>Transport</td>
              <td>
                <select class="form-select-select item-line w-75" value={transportCost} onChange={transportChange}>
                  <option value="" hidden>Select Margin Cost</option>
                  {transport && Object.entries(transport).map(([key, value]) => (
                    <option key={key} value={value}>{key+' - ₹ '+ Number(value).toFixed(2)+'/sqft'}</option>
                  ))}
                </select>
              </td>
              <td>{numeral(values.transport.qty).format('0,0.00')}</td>
              <td>{numeral(values.transport.price).format('0,0.00')}</td>
              <td>{numeral(values.transport.amount).format('0,0.00')}</td>
            </tr>
            <tr>
              <td>Labour (Factory)</td>
              <td>
              <select class="form-select-select item-line w-75" value={labourFCost} onChange={labourFChange}>
                  <option value="" hidden>Select Labour Coast</option>
                  {labourF && Object.entries(labourF).map(([key, value]) => (
                    <option key={key} value={value}>{key+' - ₹ '+ Number(value).toFixed(2)+'/sqft'}</option>
                  ))}
                </select>
              </td>
              <td>{numeral(values.labourF.qty).format('0,0.00')}</td>
              <td>{numeral(values.labourF.price).format('0,0.00')}</td>
              <td>{numeral(values.labourF.amount).format('0,0.00')}</td>
            </tr>
            <tr>
              <td>Labour (Site)</td>
              <td>
              <select class="form-select-select item-line w-75" value={labourSCost} onChange={labourSChange}>
                  <option value="" hidden>Select Labour Coast</option>
                  {labourS && Object.entries(labourS).map(([key, value]) => (
                    <option key={key} value={value}>{key+' - ₹ '+ Number(value).toFixed(2)+'/sqft'}</option>
                  ))}
                </select>
              </td>
              <td>{numeral(values.labourS.qty).format('0,0.00')}</td>
              <td>{numeral(values.labourS.price).format('0,0.00')}</td>
              <td>{numeral(values.labourS.amount).format('0,0.00')}</td>
            </tr>
            {panelType === 2 &&(
              <tr>
                <td>Board</td>
                <td>
                <select class="form-select-select item-line w-75" value={boardCost} onChange={boardChange}>
                  <option value="" hidden>Select Board Cost</option>
                  {glasses && Object.entries(board).map(([key, value]) => (
                    <option key={key} value={value}>{key+' - ₹ '+ Number(value).toFixed(2)+'/sqft'}</option>
                  ))}
                </select>
                </td>
                <td>{numeral(values.board.qty).format('0,0.00')}</td>
                <td>{numeral(values.board.price).format('0,0.00')}</td>
                <td>{numeral(values.board.amount).format('0,0.00')}</td>
              </tr>
            )}
            {panelType === 3 &&(
              <tr>
                <td>Glass</td>
                <td>
                <select class="form-select-select item-line w-75" value={glassCost} onChange={glassChange}>
                  <option value="" hidden>Select Glass Cost</option>
                  {glasses && Object.entries(glasses).map(([key, value]) => (
                    <option key={key} value={value}>{key+' - ₹ '+ Number(value).toFixed(2)+'/sqft'}</option>
                  ))}
                </select>
              </td>
                <td>{numeral(values.glass.qty).format('0,0.00')}</td>
                <td>{numeral(values.glass.price).format('0,0.00')}</td>
                <td>{numeral(values.glass.amount).format('0,0.00')}</td>
              </tr>
            )}
            <tr>
              <th colspan="4">Total</th>
              <th>{numeral(prodTotal).format('0,0.00')}</th>
            </tr>
            <tr>
              <th colspan="4">Production Cost/Sqft</th>
              <th>{numeral(prps).format('0,0.00')}</th>
            </tr>

            </tbody>
                </table>
              </div>


            </div>
          </div>
        
      
          <div class="col-md-12 col-xxl-3 col-xl-3">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1 ms-3">
                    <h6 class="mb-0">Area</h6>
                  </div>
                </div>
                <div class="bg-body p-3 mt-3 rounded">
                  <div class="mt-3 row align-items-center">
                    <div class="col-12">
                      <h6 class="mb-1">{frameArea} sqft</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 col-xxl-3 col-xl-3">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1 ms-3">
                    <h6 class="mb-0">Total</h6>
                  </div>
                </div>
                <div class="bg-body p-3 mt-3 rounded">
                  <div class="mt-3 row align-items-center">
                    <div class="col-12">
                      <h6 class="mb-1">₹ {numeral(grandTotal).format('0,0.00')}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 col-xxl-3 col-xl-3">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1 ms-3">
                    <h6 class="mb-0">Rate/Sqft</h6>
                  </div>
                </div>
                <div class="bg-body p-3 mt-3 rounded">
                  <div class="mt-3 row align-items-center">
                    <div class="col-12">
                      <h6 class="mb-1">₹ {numeral(grandAps).format('0,0.00')}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 col-xxl-3 col-xl-3">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1 ms-3">
                    <h6 class="mb-0">Save Quote</h6>
                  </div>
                </div>
                <div class="bg-body p-3 mt-2 rounded">
                  <div class="row align-items-center">
                    <div class="col-12">
                    <button class="btn btn-success w-100" onClick={sendDataToUrl}>Save</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          
          </>
)}

        
        </div>
      </div>
    </div>



    </div>
    );
};
    
    export default App;