import React, { useEffect, useState } from 'react';
import { ReactSession } from 'react-client-session';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Navbar from "./navbar";
import Header from "./header";
import left from '../images/handleLeft.png';
import right from '../images/handleRight.png';
import meshimg from '../images/mesh.png';
import numeral from 'numeral';
import Handle from '../images/slideHandle.png';
import Fan from '../images/fan.png';
import './box.css';
ReactSession.setStoreType("localStorage");

 function Quotees() {
  const urlParams = new URLSearchParams(window.location.search);
  const quoteIds = urlParams.get('id');
  const arrayvalue = urlParams.get('arrayValues');
  console.log("url",arrayvalue);
  const [quoteData, setquoteData] = useState(null);
  const [state, setState]=useState(null);

  const [updatedData, setUpdatedData] = useState(null);
    
  const userId = ReactSession.get("userId");
  const userType = ReactSession.get("userType");
  const quoteId = ReactSession.get("quoteId");
  const navigate = useNavigate();
  const indexPath = '/';
  const selectPath = '/select';

  useEffect(() => {
    if (userId === 0 ) {
      navigate(indexPath);
    }
  }, [userId, navigate]);

  const [boxWidth, setBoxWidth] = useState(3); 
  const [boxHeight, setBoxHeight] = useState(6); 
  const [numFirstInnerBoxes, setNumFirstInnerBoxes] = useState(0);
  const [numFrames, setNumFrames] = useState(0);
  const [numSecondInnerBoxes, setNumSecondInnerBoxes] = useState(0);
  const [newHeights, setNewInnerBoxHeight] = useState(0); 
  const [panelHeights, setpanelHeight] = useState(0); 
  const [panelType, setpanelType] = useState(0); 
  const [newWidths, setNewInnerBoxWidth] = useState([]); 
  const [boxColors, setBoxColors] = useState([]);
  const [boxNames, setBoxNames] = useState([]); 
  const [boxHandle, setBoxHandle] = useState([]); 
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
  const [screen, setScreen] = useState(deviceWidth > 700 ? 4 : 8);
  const [boxPosition, setBoxPosition] = useState('Bottom');
  const [shutterCount, setshutterCount]= useState(0);
  const [fixedCount, setfixedCount]= useState(0);
  const [bottomFrame, setBottomFrame] = useState('yes');
  const [mesh, setMesh] = useState('no');
  const [data, setData] = useState([]);
  const [values, setValues] = useState([]);
  const [grandTotal, setGrandTotal]= useState(0);
  const [frameTotal, setframeTotal]= useState(0);
  const [frameArea, setframeArea]= useState(0);
  const [hardTotal, sethardTotal]= useState(0);
  const [marginTotal, setmarginTotal]= useState(0);
  const [prodTotal, setprodTotal]= useState(0);
  const[wastage, setWastage]=useState([]);
  const [fanTop, setfanTop] = useState('Top');
  const [fanLeft, setfanLeft] = useState('Left');
  const [divider, setDevider] = useState('Vertical');
  const [switchs, setSwitch] = useState('on');
  const [switchFixed, setFixed] = useState('on');
  const [fanType, setfanType] = useState(1);
  const [item, setItem]=useState('');
  console.log("item", item);
  console.log("value", values);




  const fetchData = async () => {
    try {
        const response = await axios.post('https://upvcapi.sninfoserv.com/all-quotes/', { user: userId });
        setquoteData(response.data);
        console.log("String",response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  

  
  
useEffect(() => {
fetchData();
}, []);

const filteredQuotes = quoteData && quoteData.data.filter(quote => quote.quoteId === quoteIds);


useEffect(()=>{
  if(filteredQuotes){
    setState(filteredQuotes[0].quoteDetails[arrayvalue].quoteData)
    

  }
  

}
,[filteredQuotes])
console.log(state)






useEffect(()=>{
  if(state){
    setBoxHeight(state.boxHeight);
    setBoxWidth(state.boxWidth);
    setNumFirstInnerBoxes(state.numFirstInnerBoxes);
    setNumFrames(state.numFrames);
    setNumSecondInnerBoxes (state.numSecondInnerBoxes);
    setNewInnerBoxHeight(state.newHeights);
    setNewInnerBoxWidth(state.newWidths);
    setBoxColors(state.boxColors);
    setBoxNames(state.boxNames);
    setBoxHandle(state.boxHandle);
    setDeviceWidth(state.deviceWidth);
    setScreen(state.screen);
    setBoxPosition(state.boxPosition);
    setMesh(state.mesh);
    setData(state.data);
    setValues(state.values);
    setGrandTotal(state.grandTotal);
    setframeTotal(state.frameTotal);
    sethardTotal(state.hardTotal);
    setmarginTotal(state.marginTotal);
    setprodTotal(state.prodTotal);
    setWastage(state.wastage);
    setfanTop(state.fanTop);
    setfanLeft(state.fanLeft);
    setDevider(state.divider);
    setSwitch(state.switchs);
    setFixed(state.switchFixed);
    setfanType(state.fanType);
    setshutterCount(state.shutterCount);
    setfixedCount(state.fixedCount);
    setItem(state.item);
    setpanelHeight(state.panelHeight);
    setpanelType(state.panelType);
    setframeArea(state.frameArea);
    setframeTotal(state.frameTotal);

    
    
    




  }
 
},[state])
  useEffect(() => {
    const updateDeviceWidth = () => {
      setDeviceWidth(window.innerWidth);
      setScreen(window.innerWidth > 700 ? 4 : 8);
    };

    window.addEventListener('resize', updateDeviceWidth);

    return () => {
      window.removeEventListener('resize', updateDeviceWidth);
    };
  }, []);
  


  

  const Frame = () => {
    if(bottomFrame === 'yes'){
      return 2 * (boxWidth + boxHeight);
    }else{
      return 2 *(boxHeight) +boxWidth ;
    }
    
  };
  
  const Shutter = () => {
    return ((((boxHeight-newHeights)*shutterCount)*2)+((boxWidth/numSecondInnerBoxes)*shutterCount)*2)*numFrames;
  };

  const Panel = () => {
    return ((boxHeight-newHeights)*(boxWidth));  
  };

const Mullion = () => {
  if (newHeights === 0){
  return (boxHeight * (numSecondInnerBoxes - 1))*numFrames;
  }else{
    return ((boxHeight-newHeights) * (numSecondInnerBoxes - 1)+(boxWidth/numFrames))*numFrames;
  }
};

const Beeding = () => {
  if (newHeights === 0){
    return ((boxHeight * numSecondInnerBoxes * 2)+((boxWidth*2/numFrames)))*numFrames;
  }else{
    return ((newHeights*2) + ((boxHeight-newHeights) * (numSecondInnerBoxes * 2))+((boxWidth*4/numFrames)))*numFrames;
  }
};

const beedingGasket = () => {
  if (newHeights === 0){
    return (((boxHeight * numSecondInnerBoxes * 2)+((boxWidth*2/numFrames)))*numFrames)*1;
  }else{
    return (((newHeights*2) + ((boxHeight-newHeights) * (numSecondInnerBoxes * 2))+((boxWidth*4/numFrames)))*numFrames)*1;
  }
};

const shutterGasket = () => {
    return (((((boxHeight-newHeights)*shutterCount)*2)+((boxWidth/numSecondInnerBoxes)*shutterCount)*2)*numFrames)*2;
};

const frameGasket = () => {
  if(bottomFrame === 'yes'){
    return 2 * (boxWidth + boxHeight);
  }else{
    return 2 *(boxHeight) +boxWidth ;
  }
};

const steelFrame = () => {
  if(bottomFrame === 'yes'){
    return 2 * (boxWidth + boxHeight);
  }else{
    return 2 *(boxHeight) +boxWidth ;
  }
};

const steelMullion = () => {
  if (newHeights === 0){
    return (boxHeight * (numSecondInnerBoxes - 1))*numFrames;
    }else{
      return ((boxHeight-newHeights) * (numSecondInnerBoxes - 1)+(boxWidth/numFrames))*numFrames;
    }
};

const steelShutter = () => {
  return ((((boxHeight-newHeights)*shutterCount)*2)+((boxWidth/numSecondInnerBoxes)*shutterCount)*2)*numFrames;
};

const cuplerJoint = () => {
  return (boxHeight)*(numFrames-1);
};

const meshFrame = () => {
  if(mesh === 'yes'){
    return ((((boxHeight-newHeights)*shutterCount)*2)+((boxWidth/numSecondInnerBoxes)*shutterCount)*2)*numFrames;
  }else{
    return 0;
  }
};

const meshGasket = () => {
  if(mesh === 'yes'){
    return ((((boxHeight-newHeights)*shutterCount)*2)+((boxWidth/numSecondInnerBoxes)*shutterCount)*2)*numFrames;
  }else{
    return 0;
  }
};
const interLock = () => {
  return ((boxHeight)*(numSecondInnerBoxes-1))*2;
};





const fixedShutters = (fixedCount + numFirstInnerBoxes)*numFrames;


   

    console.log(newWidths);
    console.log(numSecondInnerBoxes);
  return (
    <div>
    <Navbar />
    <Header />
    
   

    {item === 'CasementDoor' && (
      <div class="pc-container">
      <div class="pc-content">
        
        <div class="row">
        
           
         


          <div class="col-md-12 col-xxl-12 col-xl-12">
            <div class="card" style={{background:'#fff'}}>
              <div class="card-body mb-5">
              
              <div>

<div style={{float:'left', width: boxWidth*screen+'vw',  height:boxHeight*screen+'vw', border:'1px solid #333', borderBottom: bottomFrame === 'no' ? '1px solid #33333350' : '1px solid #333'  }}>
{[...Array(numFrames)].map((_, index) => (
  <div style={{float:'left', margin:'5px', border:'1px solid #333',width:`calc(${boxWidth*screen/numFrames+'vw'} - 12px)`, height: bottomFrame === 'no' ? `calc(${boxHeight*screen+'vw'} - 6px)` : `calc(${boxHeight*screen+'vw'} - 12px)`, } }> 
  {newHeights !== 0 ? (
<>
  
  { boxPosition === 'Top' ? (
      <div></div>
    ) : (
      [...Array(numFirstInnerBoxes)].map((_, index) => (
  <div style={{width: `calc(${boxWidth*screen/numFrames+'vw'} - 23px)`, height:`calc(${(newHeights * screen)}vw )`, background: 'lightblue', margin:'5px', border:'1px solid #333'}}></div>
  ))
  )}
 
  <div style={{ display: 'flex' }}>
    {[...Array(numSecondInnerBoxes)].map((_, index) => (
      <div key={index} style={{ flex: '1', height: bottomFrame === 'no' ? `calc(${(boxHeight - newHeights) * screen}vw - 23px)` : `calc(${(boxHeight - newHeights) * screen}vw - 30px)`, width: `calc(${newWidths[index] * screen}vw - 5px - 19px)`, backgroundColor: panelType === 1 ? 'lightgray' : panelType === 2 ? 'lightyellow' : 'lightblue', marginTop: boxPosition === 'Top' ? '5px' : '0px', marginRight: index === numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #333' }}>
       
              <div className="expanding-arrow-line" style={{ 
                position: 'absolute',
                float:'left', 
                width: `calc(${newWidths[index] * screen}vw - 15px)`,
                bottom:'-10px', 
                top: `calc(${boxHeight* screen}vw - 25px)`,
                
               }}>
                <div className="arrow-left"></div>
                  <div className="line" align="center">
                    <div style={{marginTop:'-7px',background:'white',maxWidth:'20px',textAlign:'center'}}><p>{typeof newWidths[index] === 'number' ? newWidths[index].toFixed(2) : newWidths[index]}</p></div>
                  </div>
                <div className="arrow-right"></div>
              </div>
       
       
        { index === 0 && ( boxHandle[index] === 'left' ? (
             <img
             src={left}
             width='15'
             style={{
               float: 'left',
               marginTop: (boxHeight) * ((screen/2)-(screen/15))+'vw',
               marginLeft: '2px',
               width: screen === 4 ? '13px' : '8px'
             }}
            
             alt="Left Handle"
           />
         ) : (
           <img
             src={right}
             width='15'
             style={{
               float: 'right',
               marginTop: (boxHeight) * ((screen/2)-(screen/15))+'vw',
               marginRight: '2px',
               width: screen === 4 ? '13px' : '8px'
             }}
            
             alt="Right Handle"
           />
         ) )}


              { mesh === 'yes' ? (
                <img src={meshimg} style={{width:'50px', float:'left', marginLeft: boxHandle[index] === 'left' ? '-15px' : '0px', marginTop: `calc(${((boxHeight-newHeights)*screen)}vw - ${bottomFrame === 'no' ? '76px' : '80px' })`}} />
                ):(<></>)
              }
        
              

      </div>

    ))}
    
  </div>
  { boxPosition === 'Bottom' ? (
      <div></div>
    ) : (
      [...Array(numFirstInnerBoxes)].map((_, index) => (
  <div style={{width: `calc(${boxWidth*screen/numFrames+'vw'} - 23px)`, height:`calc(${(newHeights * screen)}vw )`, background: 'lightgreen', margin:'5px', border:'1px solid #333'}}></div>
  ))
  )}

  { numSecondInnerBoxes === 0 ? (
        <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((boxWidth/numFrames)* screen)+'vw',top:(boxHeight-newHeights)*screen+'vw', }} >
        <div className="arrow-left"></div>
        <div className="line" align="center">
          <div style={{marginTop:'-8px',background:'white',maxWidth:'40px',textAlign:'center'}}><p>{typeof (boxWidth/numFrames) === 'number' ? (boxWidth/numFrames).toFixed(2) : (boxWidth/numFrames)}</p></div>
        </div>
        <div className="arrow-right"></div>
      </div>
      ) : (
        <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((boxWidth/numFrames)* screen)+'vw',bottom:'-10px',top:'50px', }} >
          <div className="arrow-left"></div>
          <div className="line" align="center">
            <div style={{marginTop:'-8px',background:'white',maxWidth:'40px',textAlign:'center'}}><p>{typeof (boxWidth/numFrames) === 'number' ? (boxWidth/numFrames).toFixed(2) : (boxWidth/numFrames)}</p></div>
          </div>
          <div className="arrow-right"></div>
        </div>
      )}
  </>
 ) : (
  <>
  <div style={{ display: 'flex' }}>
  {[...Array(numSecondInnerBoxes)].map((_, index) => (
    panelHeights === 0 ? (
    <div key={index} style={{ flex: '1', height: bottomFrame === 'no' ? `calc(${(boxHeight) * screen}vw - 18px )` : `calc(${(boxHeight) * screen}vw - 23px )`, width: `calc(${newWidths[index] * screen}vw - 5px - 19px)`, backgroundColor: panelType === 1 ? 'lightgray' : panelType === 2 ? 'lightyellow' : 'lightblue', marginRight: index === numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #333',marginTop:'5px' }}>
     
     <div className="expanding-arrow-line" style={{ 
                float:'left', 
                width: `calc(${newWidths[index] * screen}vw - 20px)`,
                bottom:'-10px', 
                top: boxHeight * screen + 'vw',
               
               }}>
                <div className="arrow-left"></div>
                  <div className="line" align="center">
                    <div style={{marginTop:'-7px',background:'white',maxWidth:'20px',textAlign:'center'}}><p>{typeof newWidths[index] === 'number' ? newWidths[index].toFixed(2) : newWidths[index]}</p></div>
                  </div>
                <div className="arrow-right"></div>
              </div>
     
      { index === 0 &&  boxHandle[index] === 'left' && (
      
              <img
                src={right}
                width='15'
                style={{
                  float: 'right',
                  marginTop: (boxHeight) * ((screen/2)-(screen/15))+'vw',
                  marginRight: '2px',
                  width: screen === 4 ? '13px' : '8px'
                }}
               
                alt="Left Handle"
              />
              

            )}  
            { index === 1 &&  boxHandle[index] === 'right' &&(
           
              <img
                src={left}
                width='15'
                style={{
                  float: 'left',
                  marginTop: (boxHeight) * ((screen/2)-(screen/15))+'vw',
                  marginLeft: '2px',
                  width: screen === 4 ? '13px' : '8px'
                }}
               
                alt="Right Handle"
              />
              
            )}


             { mesh === 'yes' ? (
                <img src={meshimg} style={{width:'50px', float:'left', marginLeft: boxHandle[index] === 'left' ? '-15px' : '0px', marginTop: `calc(${((boxHeight-newHeights)*screen)}vw - ${bottomFrame === 'no' ? '71px' : '75px' })`}} />
                ):(<></>)
              }
             
              
              
           
    </div>
    ) : (
      <div key={index} style={{  height: panelHeights*screen+'vw', minWidth:`calc(${newWidths[index] * screen}vw - 5px - 19px)`, backgroundColor: 'lightblue', border: '1px solid #333',margin:'5px 5px 5px'}}>
    
      <div key={index} style={{ height: bottomFrame === 'no' ? `calc(${(boxHeight) * screen}vw - 18px )` : `calc(${(boxHeight) * screen}vw - 30px )`, width: `calc(${newWidths[index] * screen}vw - 5px - 19px)`, backgroundColor: boxColors[index] || 'lightblue',  border: '1px solid #333',marginTop: `calc(${panelHeights * screen}vw + 5px )` }}>
        {  index === 0 && ( boxHandle[index] === 'left' ? (
          <>
                <img
                  src={left}
                  width='15'
                  style={{
                    float: 'left',
                    marginTop: (boxHeight) * ((screen/2)-(screen/15))+'vw',
                    marginLeft: '2px',
                    width: screen === 4 ? '13px' : '8px'
                  }}
                 
                  alt="Left Handle"
                />
                
                </>
              ) : (
                <>
                <img
                  src={right}
                  width='15'
                  style={{
                    float: 'right',
                    marginTop: (boxHeight) * ((screen/2)-(screen/15))+'vw',
                    marginRight: '2px',
                    width: screen === 4 ? '13px' : '8px'
                  }}
                 
                  alt="Right Handle"
                />
                
                </>
              ) )}
               { mesh === 'yes' ? (
                <img src={meshimg} style={{width:'50px', float:'left',  bottom:'0px', marginTop:`calc(${(boxHeight) * screen}vw - 75px )` }} />
               ):(<></>)}
               
                
                <div className="expanding-arrow-line" style={{ 
                  float:'left', 
                  width: `calc(${newWidths[index] * screen}vw - 25px)`,
                   
                  ...(index === 0 && mesh === 'yes' && { top: boxHeight * ((screen/2)-(screen*0.43)) + 'vw' }),
                  ...(index === 0 && mesh === 'no' && { top: boxHeight * (1.3) + 'vw' }),
                  ...(index !== 0 && mesh === 'yes' &&  { top: boxHeight * (0.27) + 'vw' }),
                  ...(index !== 0 && mesh === 'no' &&  { top: boxHeight * ((screen/2)+(screen/2.02)) + 'vw' }),
                 }}>
                  <div className="arrow-left"></div>
                    <div className="line" align="center">
                      <div style={{marginTop:'-7px',background:'white',maxWidth:'20px',textAlign:'center'}}><p>{typeof newWidths[index] === 'number' ? newWidths[index].toFixed(2) : newWidths[index]}</p></div>
                    </div>
                  <div className="arrow-right"></div>
                </div>
             
      </div>
      </div>
    )
  ))}
</div> 
      { numSecondInnerBoxes === 0 ? (
        <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((boxWidth/numFrames)* screen)+'vw', top:boxHeight*screen+'vw',  }} >
        <div className="arrow-left"></div>
        <div className="line" align="center">
          <div style={{marginTop:'-8px',background:'white',maxWidth:'40px',textAlign:'center'}}><p>{typeof (boxWidth/numFrames) === 'number' ? (boxWidth/numFrames).toFixed(2) : (boxWidth/numFrames)}</p></div>
        </div>
        <div className="arrow-right"></div>
      </div>
      ) : (
        <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((boxWidth/numFrames)* screen)+'vw', bottom:'-10px',top:'40px', }} >
          <div className="arrow-left"></div>
          <div className="line" align="center">
            <div style={{marginTop:'-8px',background:'white',maxWidth:'40px',textAlign:'center'}}><p>{typeof (boxWidth/numFrames) === 'number' ? (boxWidth/numFrames).toFixed(2) : (boxWidth/numFrames)}</p></div>
          </div>
          <div className="arrow-right"></div>
        </div>
      )}
</>
  )}
  </div>
   ))}
</div>


{ boxPosition === 'Top' ? (
  newHeights === 0 ? (

    <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
      <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${boxHeight * screen}vw)`, top: '0', position: 'relative' }}>
       <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
       <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
         <div style={{ marginLeft: '-10px', marginTop: boxHeight * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof boxHeight === 'number' ? boxHeight.toFixed(2) : boxHeight}</p></div>
       </div>
       <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
      </div>
    </div>
) : (  
      <>
        <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>

          <div className="expanding-arrow-line" style={{order: 1, flex:1, float: 'left', height: `calc(${(boxHeight-newHeights) * screen}vw - 20px)`, top: '0', position: 'relative' }}>
            <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
            <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
              <div style={{ marginLeft: '-10px', marginTop: (boxHeight-newHeights) * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof (boxHeight-newHeights) === 'number' ? (boxHeight-newHeights).toFixed(2) : (boxHeight-newHeights)}</p></div>
            </div>
            <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
          </div>
           <div className="expanding-arrow-line" style={{order: 2, flex:2, float: 'left', height: `calc(${newHeights * screen}vw `, top: `calc(${(boxHeight-newHeights) * screen}vw - 10px)`, position: 'relative' }}>
            <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
            <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
              <div style={{ marginLeft: '-10px', marginTop: newHeights * (((screen/2)-(screen/5)))+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof newHeights === 'number' ? newHeights.toFixed(2) : newHeights}</p></div>
            </div>
            <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
          </div>

        </div>

        <div style={{ width: '30px', float: 'left', paddingLeft: '0px', textAlign: 'left' }}>
        <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${boxHeight * screen}vw)`, top: '0', position: 'relative' }}>
         <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
         <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
           <div style={{ marginLeft: '-10px', marginTop: boxHeight * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof boxHeight === 'number' ? boxHeight.toFixed(2) : boxHeight}</p></div>
         </div>
         <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
        </div>
      </div>
      </>
)
) : (
newHeights === 0 ? (

    <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
      <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${boxHeight * screen}vw)`, top: '0', position: 'relative' }}>
       <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
       <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
         <div style={{ marginLeft: '-10px', marginTop: boxHeight * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof boxHeight === 'number' ? boxHeight.toFixed(2) : boxHeight}</p></div>
       </div>
       <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
      </div>
    </div>
) : (  
      <>
        <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
          <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${newHeights * screen}vw + 10px)`, top: '0', position: 'relative' }}>
            <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
            <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
              <div style={{ marginLeft: '-10px', marginTop: newHeights * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof newHeights === 'number' ? newHeights.toFixed(2) : newHeights}</p></div>
            </div>
            <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
          </div>
          <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${(boxHeight-newHeights) * screen}vw - 20px)`, top: `calc(${newHeights * screen}vw + 20px)`, position: 'relative' }}>
            <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
            <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
              <div style={{ marginLeft: '-10px', marginTop: (boxHeight-newHeights) * ((screen/2)-(screen/8))+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof (boxHeight-newHeights) === 'number' ? (boxHeight-newHeights).toFixed(2) : (boxHeight-newHeights)}</p></div>
            </div>
            <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
          </div>
        </div>
        <div style={{ width: '30px', float: 'left', paddingLeft: '0px', textAlign: 'left' }}>
        <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${boxHeight * screen}vw)`, top: '0', position: 'relative' }}>
         <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
         <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
           <div style={{ marginLeft: '-10px', marginTop: boxHeight * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof boxHeight === 'number' ? boxHeight.toFixed(2) : boxHeight}</p></div>
         </div>
         <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
        </div>
      </div>
      </>
)
)}
    
</div>
                
              </div>
            </div>
          </div>

{ numSecondInnerBoxes === 0 ? (<div></div>):( 
          <>
          <div class="col-md-12 col-xxl-2 col-xl-2">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1 ms-3">
                    <h6 class="mb-0">Area</h6>
                  </div>
                </div>
                <div class="bg-body p-3 mt-3 rounded">
                  <div class="mt-3 row align-items-center">
                    <div class="col-12">
                      <h6 class="mb-1">{frameArea} sqft</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 col-xxl-2 col-xl-2">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1 ms-3">
                    <h6 class="mb-0">Shutters</h6>
                  </div>
                </div>
                <div class="bg-body p-3 mt-3 rounded">
                  <div class="mt-3 row align-items-center">
                    <div class="col-12">
                      <h6 class="mb-1">{shutterCount*numFrames}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 col-xxl-2 col-xl-2">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1 ms-3">
                    <h6 class="mb-0">Fixed</h6>
                  </div>
                </div>
                <div class="bg-body p-3 mt-3 rounded">
                  <div class="mt-3 row align-items-center">
                    <div class="col-12">
                      <h6 class="mb-1">{fixedShutters}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 col-xxl-2 col-xl-2">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1 ms-3">
                    <h6 class="mb-0">Mullions</h6>
                  </div>
                </div>
                <div class="bg-body p-3 mt-3 rounded">
                  <div class="mt-3 row align-items-center">
                    <div class="col-12">
                      <h6 class="mb-1">{newHeights === 0 ? ((numSecondInnerBoxes-1)*numFrames):((numSecondInnerBoxes)*numFrames)}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 col-xxl-2 col-xl-2">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1 ms-3">
                    <h6 class="mb-0">Total</h6>
                  </div>
                </div>
                <div class="bg-body p-3 mt-3 rounded">
                  <div class="mt-3 row align-items-center">
                    <div class="col-12">
                      <h6 class="mb-1">₹ {numeral(grandTotal).format('0,0.00')}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 col-xxl-2 col-xl-2">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1 ms-3">
                    <h6 class="mb-0">Rate/Sqft</h6>
                  </div>
                </div>
                <div class="bg-body p-3 mt-3 rounded">
                  <div class="mt-3 row align-items-center">
                    <div class="col-12">
                      <h6 class="mb-1">₹ {numeral(grandTotal/frameArea).format('0,0.00')}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>




          <div class="col-md-12 col-xxl-12 col-xl-12">
            <div class="card">
              <div class="card-header">
                <h5>Profile Cost</h5>
              </div>
              <div class="card-body">
              <table id="left-right-fix" class="table stripe row-border order-column">
                  <thead>
                    <tr>
                      <th>Product Description</th>
                      <th width="10%">Req. Qty (Feet)</th>
                      <th width="10%">Kg/Feet</th>
                      <th width="10%">Req. Qty (Kg)</th>
                      <th width="10%">Rate/Kg</th>
                      <th width="10%">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Casement Frame</td>
                      <td>{numeral(Frame()).format('0,0.00')}</td>
                      <td>{numeral(values.frame.wpf).format('0,0.00')}</td>
                      <td>{numeral(values.frame.rw).format('0,0.00')}</td>
                      <td>{numeral(values.frame.rpf).format('0,0.00')}</td>
                      <td>{numeral(values.frame.amount).format('0,0.00')}</td>
                    </tr>
                    <tr>
              <td>Casement Shutter</td>
              <td>{numeral(Shutter()).format('0,0.00')}</td>
              <td>{numeral(values.shutter.wpf).format('0,0.00')}</td>
              <td>{numeral(values.shutter.rw).format('0,0.00')}</td>
              <td>{numeral(values.shutter.rpf).format('0,0.00')}</td>
              <td>{numeral(values.shutter.amount).format('0,0.00')}</td>
            </tr>
            <tr>
              <td>Mullion</td>
              <td>{numeral(Mullion()).format('0,0.00')}</td>
              <td>{numeral(values.mullion.wpf).format('0,0.00')}</td>
              <td>{numeral(values.mullion.rw).format('0,0.00')}</td>
              <td>{numeral(values.mullion.rpf).format('0,0.00')}</td>
              <td>{numeral(values.mullion.amount).format('0,0.00')}</td>
            </tr>
            <tr>
              <td>Beeding</td>
              <td>{numeral(Beeding()).format('0,0.00')}</td>
              <td>{numeral(values.beeding.wpf).format('0,0.00')}</td>
              <td>{numeral(values.beeding.rw).format('0,0.00')}</td>
              <td>{numeral(values.beeding.rpf).format('0,0.00')}</td>
              <td>{numeral(values.beeding.amount).format('0,0.00')}</td>
            </tr>
            <tr>
              <td>Frame Gasket</td>
              <td>{numeral(frameGasket()).format('0,0.00')}</td>
              <td>{numeral(values.frameGasket.wpf).format('0,0.00')}</td>
              <td>{numeral(values.frameGasket.rw).format('0,0.00')}</td>
              <td>{numeral(values.frameGasket.rpf).format('0,0.00')}</td>
              <td>{numeral(values.frameGasket.amount).format('0,0.00')}</td>
            </tr>
            <tr>
              <td>Shutter Gasket</td>
              <td>{numeral(shutterGasket()).format('0,0.00')}</td>
              <td>{numeral(values.shutterGasket.wpf).format('0,0.00')}</td>
              <td>{numeral(values.shutterGasket.rw).format('0,0.00')}</td>
              <td>{numeral(values.shutterGasket.rpf).format('0,0.00')}</td>
              <td>{numeral(values.shutterGasket.amount).format('0,0.00')}</td>
            </tr>
            <tr>
              <td>Beeding Gasket</td>
              <td>{numeral(beedingGasket()).format('0,0.00')}</td>
              <td>{numeral(values.beedingGasket.wpf).format('0,0.00')}</td>
              <td>{numeral(values.beedingGasket.rw).format('0,0.00')}</td>
              <td>{numeral(values.beedingGasket.rpf).format('0,0.00')}</td>
              <td>{numeral(values.beedingGasket.amount).format('0,0.00')}</td>
            </tr>

            {panelType === 1 &&(
              <tr>
              <td>Panel</td>
              <td>{numeral(Panel()).format('0,0.00')}</td>
              <td>{numeral(values.panel.wpf).format('0,0.00')}</td>
              <td>{numeral(values.panel.rw).format('0,0.00')}</td>
              <td>{numeral(values.panel.rpf).format('0,0.00')}</td>
              <td>{numeral(values.panel.amount).format('0,0.00')}</td>
            </tr>
            )}
           
           
            <tr>
              <td colspan="5">Total</td>
              <td>{numeral(frameTotal).format('0,0.00')}</td>
            </tr>
            <tr>
              <th colspan="5">Wastage ( {data.wastage}% )</th>
              <th>{numeral(frameTotal+wastage).format('0,0.00')}</th>
            </tr>
            <tr>
              <th colspan="5">Profile Cost/Sqft</th>
              <th>{numeral((frameTotal+wastage) / frameArea).format('0,0.00')}</th>
            </tr>
                  </tbody>
                </table>
            </div>   
            <hr />
                <div class="card-header">
                <h5>Hardware Cost</h5>
              </div>
              <div class="card-body">
              <table id="left-right-fix" class="table stripe row-border order-column">
                  <thead>
                    <tr>
                      <th>Product Description</th>
                      <th width="10%">Req. Qty </th>
                      <th width="10%">Price</th>
                      <th width="10%">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                
                  <tr>
              <td>Steel Frame</td>
              <td>{numeral(values.steelFrame.qty).format('0,0.00')}</td>
              <td>{numeral(values.steelFrame.price).format('0,0.00')}</td>
              <td>{numeral(values.steelFrame.amount).format('0,0.00')}</td>
            </tr>
            <tr>
              <td>Steel Mullion</td>
              <td>{numeral(values.steelMullion.qty).format('0,0.00')}</td>
              <td>{numeral(values.steelMullion.price).format('0,0.00')}</td>
              <td>{numeral(values.steelMullion.amount).format('0,0.00')}</td>
            </tr>
            <tr>
              <td>Steel Shutter</td>
              <td>{numeral(values.steelShutter.qty).format('0,0.00')}</td>
              <td>{numeral(values.steelShutter.price).format('0,0.00')}</td>
              <td>{numeral(values.steelShutter.amount).format('0,0.00')}</td>
            </tr>
            <tr>
              <td>Door Lock with Espag</td>
              <td>{numeral(values.mpl.qty).format('0,0.00')}</td>
              <td>{numeral(values.mpl.price).format('0,0.00')}</td>
              <td>{numeral(values.mpl.amount).format('0,0.00')}</td>
            </tr>
            <tr>
              <td>3D Hinges</td>
              <td>{numeral(values.hings.qty).format('0,0.00')}</td>
              <td>{numeral(values.hings.price).format('0,0.00')}</td>
              <td>{numeral(values.hings.amount).format('0,0.00')}</td>
            </tr>
            <tr>
              <td>Screws/Misc</td>
              <td>{numeral(values.screws.qty).format('0,0.00')}</td>
              <td>{numeral(values.screws.price).format('0,0.00')}</td>
              <td>{numeral(values.screws.amount).format('0,0.00')}</td>
            </tr>
            
            <tr>
              <th colspan="3">Total</th>
              <th>{numeral(hardTotal).format('0,0.00')}</th>
            </tr>
            <tr>
              <th colspan="3">Hardware Cost/Sqft</th>
              <th>{numeral(hardTotal/frameArea).format('0,0.00')}</th>
            </tr>
                    </tbody>
                </table>
              </div>
              <hr />
            
              <div class="card-header ">
                <h5>Margin Cost</h5>
              </div>
              <div class="card-body">
              <table id="left-right-fix" class="table stripe row-border order-column">
                  <thead>
                    <tr>
                      <th>Product Description</th>
                      <th width="10%">Req. Qty </th>
                      <th width="10%">Price</th>
                      <th width="10%">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr>
              <td>Margin</td>
              <td>{numeral(values.margin.qty).format('0,0.00')}</td>
              <td>{numeral(values.margin.price).format('0,0.00')}</td>
              <td>{numeral(values.margin.amount).format('0,0.00')}</td>
            </tr>
            <tr>
              <th colspan="3">Total</th>
              <th>{numeral(marginTotal).format('0,0.00')}</th>
            </tr>
            <tr>
              <th colspan="3">Margin Cost/Sqft</th>
              <th>{numeral(marginTotal/frameArea).format('0,0.00')}</th>
            </tr>
            </tbody>
                </table>
              </div>
              <hr />

              <div class="card-header">
                <h5>Production Cost</h5>
              </div>
              <div class="card-body">
              <table id="left-right-fix" class="table stripe row-border order-column">
                  <thead>
                    <tr>
                      <th>Product Description</th>
                      <th width="10%">Req. Qty </th>
                      <th width="10%">Price</th>
                      <th width="10%">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr>
              <td>Markting OH</td>
              <td>{numeral(values.marketing.qty).format('0,0.00')}</td>
              <td>{numeral(values.marketing.price).format('0,0.00')}</td>
              <td>{numeral(values.marketing.amount).format('0,0.00')}</td>
            </tr>
            <tr>
              <td>Transport</td>
              <td>{numeral(values.transport.qty).format('0,0.00')}</td>
              <td>{numeral(values.transport.price).format('0,0.00')}</td>
              <td>{numeral(values.transport.amount).format('0,0.00')}</td>
            </tr>
            <tr>
              <td>Labour (Factory)</td>
              <td>{numeral(values.labourF.qty).format('0,0.00')}</td>
              <td>{numeral(values.labourF.price).format('0,0.00')}</td>
              <td>{numeral(values.labourF.amount).format('0,0.00')}</td>
            </tr>
            <tr>
              <td>Labour (Site)</td>
              <td>{numeral(values.labourS.qty).format('0,0.00')}</td>
              <td>{numeral(values.labourS.price).format('0,0.00')}</td>
              <td>{numeral(values.labourS.amount).format('0,0.00')}</td>
            </tr>
            {panelType === 2 &&(
              <tr>
                <td>Board</td>
                <td>{numeral(values.board.qty).format('0,0.00')}</td>
                <td>{numeral(values.board.price).format('0,0.00')}</td>
                <td>{numeral(values.board.amount).format('0,0.00')}</td>
              </tr>
            )}
            {panelType === 3 &&(
              <tr>
                <td>Glass</td>
                <td>{numeral(values.glass.qty).format('0,0.00')}</td>
                <td>{numeral(values.glass.price).format('0,0.00')}</td>
                <td>{numeral(values.glass.amount).format('0,0.00')}</td>
              </tr>
            )}
            <tr>
              <th colspan="3">Total</th>
              <th>{numeral(prodTotal).format('0,0.00')}</th>
            </tr>
            <tr>
              <th colspan="3">Production Cost/Sqft</th>
              <th>{numeral( prodTotal/frameArea).format('0,0.00')}</th>
            </tr>

            </tbody>
                </table>
              </div>


            </div>
          </div>
        
      
         

          
          </>
)}

        
        </div>
      </div>
    </div>



    )}

{item === 'CasementWindow' && (
   <div class="pc-container">
   <div class="pc-content">
     
     <div class="row">
       
        
      


       <div class="col-md-12 col-xxl-12 col-xl-12">
         <div class="card" style={{background:'#fff'}}>
           <div class="card-body mb-5">
           
           <div>

<div style={{float:'left', width: boxWidth*screen+'vw', height:boxHeight*screen+'vw', border:'1px solid #333'}}>
{[...Array(numFrames)].map((_, index) => (
<div style={{float:'left', margin:'5px', border:'1px solid #333',width:`calc(${boxWidth*screen/numFrames+'vw'} - 12px)`, height:`calc(${boxHeight*screen+'vw'} - 12px)`}}> 
{newHeights !== 0 ? (
<>

{ boxPosition === 'Top' ? (
   <div></div>
 ) : (
   [...Array(numFirstInnerBoxes)].map((_, index) => (
<div style={{width: `calc(${boxWidth*screen/numFrames+'vw'} - 23px)`, height:`calc(${(newHeights * screen)}vw )`, background: 'lightgreen', margin:'5px', border:'1px solid #333'}}></div>
))
)}

<div style={{ display: 'flex' }}>
 {[...Array(numSecondInnerBoxes)].map((_, index) => (
   <div key={index} style={{ flex: '1', height: `calc(${(boxHeight - newHeights) * screen}vw - 30px)`, width: `calc(${newWidths[index] * screen}vw - 5px - 19px)`, backgroundColor: boxColors[index] || 'lightblue', marginTop: boxPosition === 'Top' ? '5px' : '0px', marginRight: index === numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #333' }}>
    
           <div className="expanding-arrow-line" style={{ 
             position: 'absolute',
             float:'left', 
             width: `calc(${newWidths[index] * screen}vw - 15px)`,
             bottom:'-10px', 
             top: `calc(${boxHeight * screen}vw - 25px)` ,
            
            }}>
             <div className="arrow-left"></div>
               <div className="line" align="center">
                 <div style={{marginTop:'-7px',background:'white',maxWidth:'20px',textAlign:'center'}}><p>{typeof newWidths[index] === 'number' ? newWidths[index].toFixed(2) : newWidths[index]}</p></div>
               </div>
             <div className="arrow-right"></div>
           </div>

    
    
     { boxNames[index] === 'Shutter' && ( boxHandle[index] === 'left' ? (
           <img
             src={left}
             style={{
               float: 'left',
               marginTop: `calc(${(((boxHeight-newHeights)*screen)/2)}vw - 15px`,
               marginLeft: '2px',
               width: screen === 4 ? '13px' : '8px'
             }}
            
             alt="Left Handle"
           />
         ) : (
           <img
             src={right}
             style={{
               float: 'right',
               marginTop: `calc(${(((boxHeight-newHeights)*screen)/2)}vw - 15px`,
               marginRight: '2px',
               width: screen === 4 ? '13px' : '8px'
             }}
            
             alt="Right Handle"
           />
           
         ) )}

           {mesh === 'yes' && boxNames[index] === 'Shutter' && (
             <img src={meshimg} style={{width:'50px', float:'left', marginLeft: boxHandle[index] === 'left' ? '-15px' : '0px', marginTop: `calc(${((boxHeight-newHeights)*screen)}vw - 82px)`}} />
             )
           }
         
           
   </div>

 ))}
 
</div>
{ boxPosition === 'Bottom' ? (
   <div></div>
 ) : (
   [...Array(numFirstInnerBoxes)].map((_, index) => (
<div style={{width: `calc(${boxWidth*screen/numFrames+'vw'} - 23px)`, height:`calc(${(newHeights * screen)}vw )`, background: 'lightgreen', margin:'5px', border:'1px solid #333'}}></div>
))
)}

{ numSecondInnerBoxes === 0 ? (
     <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((boxWidth/numFrames)* screen)+'vw',top:(boxHeight-newHeights)*screen+'vw', }} >
     <div className="arrow-left"></div>
     <div className="line" align="center">
       <div style={{marginTop:'-8px',background:'white',maxWidth:'40px',textAlign:'center'}}><p>{typeof (boxWidth/numFrames) === 'number' ? (boxWidth/numFrames).toFixed(2) : (boxWidth/numFrames)}</p></div>
     </div>
     <div className="arrow-right"></div>
   </div>
   ) : (
     <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((boxWidth/numFrames)* screen)+'vw',bottom:'-10px',top:'50px', }} >
       <div className="arrow-left"></div>
       <div className="line" align="center">
         <div style={{marginTop:'-8px',background:'white',maxWidth:'40px',textAlign:'center'}}><p>{typeof (boxWidth/numFrames) === 'number' ? (boxWidth/numFrames).toFixed(2) : (boxWidth/numFrames)}</p></div>
       </div>
       <div className="arrow-right"></div>
     </div>
   )}
</>
) : (
<>
<div style={{ display: 'flex' }}>
{[...Array(numSecondInnerBoxes)].map((_, index) => (
 <div key={index} style={{ flex: '1', height: `calc(${(boxHeight) * screen}vw - 23px)`, width: `calc(${newWidths[index] * screen}vw - 5px - 19px)`, backgroundColor: boxColors[index] || 'lightblue', marginRight: index === numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #333',marginTop:'5px' }}>
   
           <div className="expanding-arrow-line" style={{ 
             float:'left', 
             width: `calc(${newWidths[index] * screen}vw - 15px)`,
             bottom:'-10px', 
             top: boxHeight *screen + 'vw',
            
            }}>
             <div className="arrow-left"></div>
               <div className="line" align="center">
                 <div style={{marginTop:'-7px',background:'white',maxWidth:'20px',textAlign:'center'}}><p>{typeof newWidths[index] === 'number' ? newWidths[index].toFixed(2) : newWidths[index]}</p></div>
               </div>
             <div className="arrow-right"></div>
           </div>
   
           
   
   { boxNames[index] === 'Shutter' && ( boxHandle[index] === 'left' ? (
           <img
             src={left}
             width='15px'
             style={{
               float: 'left',
               marginTop: `calc(${((boxHeight*screen)/2)}vw - 15px`,
               marginLeft: '2px',
               width: screen === 4 ? '13px' : '8px'
             }}
            
             alt="Left Handle"
           />
         ) : (
           <img
             src={right}
             width='15px'
             style={{
               float: 'right',
               marginTop: `calc(${((boxHeight*screen)/2)}vw - 15px`,
               marginRight: '2px',
               width: screen === 4 ? '13px' : '8px',
               verticalAlign: 'bottom'
             }}
            
             alt="Right Handle"
           />
         ) )}

        
           {mesh === 'yes' && boxNames[index] === 'Shutter' && (
             <img src={meshimg} style={{width:'50px', float:'left', marginLeft: boxHandle[index] === 'left' ? '-15px' : '0px', marginTop: `calc(${(boxHeight*screen)}vw - 75px)`}} />
           )}
          

           
        
 </div>
))}
</div> 
   { numSecondInnerBoxes === 0 ? (
     <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((boxWidth/numFrames)* screen)+'vw', top:boxHeight*screen+'vw',  }} >
     <div className="arrow-left"></div>
     <div className="line" align="center">
       <div style={{marginTop:'-8px',background:'white',maxWidth:'40px',textAlign:'center'}}><p>{typeof (boxWidth/numFrames) === 'number' ? (boxWidth/numFrames).toFixed(2) : (boxWidth/numFrames)}</p></div>
     </div>
     <div className="arrow-right"></div>
   </div>
   ) : (
     <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((boxWidth/numFrames)* screen)+'vw', bottom:'-10px',top:'40px', }} >
       <div className="arrow-left"></div>
       <div className="line" align="center">
         <div style={{marginTop:'-8px',background:'white',maxWidth:'40px',textAlign:'center'}}><p>{typeof (boxWidth/numFrames) === 'number' ? (boxWidth/numFrames).toFixed(2) : (boxWidth/numFrames)}</p></div>
       </div>
       <div className="arrow-right"></div>
     </div>
   )}
</>
)}
</div>
))}
</div>

{ boxPosition === 'Top' ? (
newHeights === 0 ? (

 <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
   <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${boxHeight * screen}vw)`, top: '0', position: 'relative' }}>
    <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
    <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
      <div style={{ marginLeft: '-10px', marginTop: boxHeight * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof boxHeight === 'number' ? boxHeight.toFixed(2) : boxHeight}</p></div>
    </div>
    <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
   </div>
 </div>
) : (  
   <>
     <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>

       <div className="expanding-arrow-line" style={{order: 1, flex:1, float: 'left', height: `calc(${(boxHeight-newHeights) * screen}vw - 20px)`, top: '0', position: 'relative' }}>
         <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
         <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
           <div style={{ marginLeft: '-10px', marginTop: (boxHeight-newHeights) * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof (boxHeight-newHeights) === 'number' ? (boxHeight-newHeights).toFixed(2) : (boxHeight-newHeights)}</p></div>
         </div>
         <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
       </div>
        <div className="expanding-arrow-line" style={{order: 2, flex:2, float: 'left', height: `calc(${newHeights * screen}vw `, top: `calc(${(boxHeight-newHeights) * screen}vw - 10px)`, position: 'relative' }}>
         <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
         <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
           <div style={{ marginLeft: '-10px', marginTop: newHeights * (((screen/2)-(screen/5)))+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof newHeights === 'number' ? newHeights.toFixed(2) : newHeights}</p></div>
         </div>
         <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
       </div>

     </div>

     <div style={{ width: '30px', float: 'left', paddingLeft: '0px', textAlign: 'left' }}>
     <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${boxHeight * screen}vw)`, top: '0', position: 'relative' }}>
      <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
      <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
        <div style={{ marginLeft: '-10px', marginTop: boxHeight * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof boxHeight === 'number' ? boxHeight.toFixed(2) : boxHeight}</p></div>
      </div>
      <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
     </div>
   </div>
   </>
)
) : (
newHeights === 0 ? (

 <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
   <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${boxHeight * screen}vw)`, top: '0', position: 'relative' }}>
    <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
    <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
      <div style={{ marginLeft: '-10px', marginTop: boxHeight * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof boxHeight === 'number' ? boxHeight.toFixed(2) : boxHeight}</p></div>
    </div>
    <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
   </div>
 </div>
) : (  
   <>
     <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
       <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${newHeights * screen}vw + 10px)`, top: '0', position: 'relative' }}>
         <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
         <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
           <div style={{ marginLeft: '-10px', marginTop: newHeights * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof newHeights === 'number' ? newHeights.toFixed(2) : newHeights}</p></div>
         </div>
         <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
       </div>
       <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${(boxHeight-newHeights) * screen}vw - 20px)`, top: `calc(${newHeights * screen}vw + 20px)`, position: 'relative' }}>
         <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
         <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
           <div style={{ marginLeft: '-10px', marginTop: (boxHeight-newHeights) * ((screen/2)-(screen/8))+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof (boxHeight-newHeights) === 'number' ? (boxHeight-newHeights).toFixed(2) : (boxHeight-newHeights)}</p></div>
         </div>
         <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
       </div>
     </div>
     <div style={{ width: '30px', float: 'left', paddingLeft: '0px', textAlign: 'left' }}>
     <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${boxHeight * screen}vw)`, top: '0', position: 'relative' }}>
      <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
      <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
        <div style={{ marginLeft: '-10px', marginTop: boxHeight * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof boxHeight === 'number' ? boxHeight.toFixed(2) : boxHeight}</p></div>
      </div>
      <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
     </div>
   </div>
   </>
)
)}
 
</div>
             
           </div>
         </div>
       </div>

{ numSecondInnerBoxes === 0 ? (<div></div>):( 
       <>
       <div class="col-md-12 col-xxl-2 col-xl-2">
         <div class="card">
           <div class="card-body">
             <div class="d-flex align-items-center">
               <div class="flex-grow-1 ms-3">
                 <h6 class="mb-0">Area</h6>
               </div>
             </div>
             <div class="bg-body p-3 mt-3 rounded">
               <div class="mt-3 row align-items-center">
                 <div class="col-12">
                   <h6 class="mb-1">{frameArea} sqft</h6>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>

       <div class="col-md-12 col-xxl-2 col-xl-2">
         <div class="card">
           <div class="card-body">
             <div class="d-flex align-items-center">
               <div class="flex-grow-1 ms-3">
                 <h6 class="mb-0">Shutters</h6>
               </div>
             </div>
             <div class="bg-body p-3 mt-3 rounded">
               <div class="mt-3 row align-items-center">
                 <div class="col-12">
                   <h6 class="mb-1">{shutterCount*numFrames}</h6>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>

       <div class="col-md-12 col-xxl-2 col-xl-2">
         <div class="card">
           <div class="card-body">
             <div class="d-flex align-items-center">
               <div class="flex-grow-1 ms-3">
                 <h6 class="mb-0">Fixed</h6>
               </div>
             </div>
             <div class="bg-body p-3 mt-3 rounded">
               <div class="mt-3 row align-items-center">
                 <div class="col-12">
                   <h6 class="mb-1">{fixedShutters}</h6>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>

       <div class="col-md-12 col-xxl-2 col-xl-2">
         <div class="card">
           <div class="card-body">
             <div class="d-flex align-items-center">
               <div class="flex-grow-1 ms-3">
                 <h6 class="mb-0">Mullions</h6>
               </div>
             </div>
             <div class="bg-body p-3 mt-3 rounded">
               <div class="mt-3 row align-items-center">
                 <div class="col-12">
                   <h6 class="mb-1">{newHeights === 0 ? ((numSecondInnerBoxes-1)*numFrames):((numSecondInnerBoxes)*numFrames)}</h6>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>

       <div class="col-md-12 col-xxl-2 col-xl-2">
         <div class="card">
           <div class="card-body">
             <div class="d-flex align-items-center">
               <div class="flex-grow-1 ms-3">
                 <h6 class="mb-0">Total</h6>
               </div>
             </div>
             <div class="bg-body p-3 mt-3 rounded">
               <div class="mt-3 row align-items-center">
                 <div class="col-12">
                   <h6 class="mb-1">₹ {numeral(grandTotal).format('0,0.00')}</h6>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>

       <div class="col-md-12 col-xxl-2 col-xl-2">
         <div class="card">
           <div class="card-body">
             <div class="d-flex align-items-center">
               <div class="flex-grow-1 ms-3">
                 <h6 class="mb-0">Rate/Sqft</h6>
               </div>
             </div>
             <div class="bg-body p-3 mt-3 rounded">
               <div class="mt-3 row align-items-center">
                 <div class="col-12">
                   <h6 class="mb-1">₹ {numeral(grandTotal/frameArea).format('0,0.00')}</h6>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>




       <div class="col-md-12 col-xxl-12 col-xl-12">
         <div class="card">
           <div class="card-header">
             <h5>Profile Cost</h5>
           </div>
           <div class="card-body">
           <table id="left-right-fix" class="table stripe row-border order-column">
               <thead>
                 <tr>
                   <th>Product Description</th>
                   <th width="10%">Req. Qty (Feet)</th>
                   <th width="10%">Kg/Feet</th>
                   <th width="10%">Req. Qty (Kg)</th>
                   <th width="10%">Rate/Kg</th>
                   <th width="10%">Amount</th>
                 </tr>
               </thead>
               <tbody>
                 <tr>
                   <td>Casement Outer Frame</td>
                   <td>{numeral(Frame()).format('0,0.00')}</td>
                   <td>{numeral(values.frame.wpf).format('0,0.00')}</td>
                   <td>{numeral(values.frame.rw).format('0,0.00')}</td>
                   <td>{numeral(values.frame.rpf).format('0,0.00')}</td>
                   <td>{numeral(values.frame.amount).format('0,0.00')}</td>
                 </tr>
                 <tr>
           <td>Casement Shutter</td>
           <td>{numeral(Shutter()).format('0,0.00')}</td>
           <td>{numeral(values.shutter.wpf).format('0,0.00')}</td>
           <td>{numeral(values.shutter.rw).format('0,0.00')}</td>
           <td>{numeral(values.shutter.rpf).format('0,0.00')}</td>
           <td>{numeral(values.shutter.amount).format('0,0.00')}</td>
         </tr>

         {mesh === 'yes' && (
           
           <tr>
             <td>Mesh Frame</td>
             <td>{numeral(meshFrame()).format('0,0.00')}</td>
             <td>{numeral(values.meshFrame.wpf).format('0,0.00')}</td>
             <td>{numeral(values.meshFrame.rw).format('0,0.00')}</td>
             <td>{numeral(values.meshFrame.rpf).format('0,0.00')}</td>
             <td>{numeral(values.meshFrame.amount).format('0,0.00')}</td>
           </tr>

         )}

         <tr>
           <td>Beeding</td>
           <td>{numeral(Beeding()).format('0,0.00')}</td>
           <td>{numeral(values.beeding.wpf).format('0,0.00')}</td>
           <td>{numeral(values.beeding.rw).format('0,0.00')}</td>
           <td>{numeral(values.beeding.rpf).format('0,0.00')}</td>
           <td>{numeral(values.beeding.amount).format('0,0.00')}</td>
         </tr>
         <tr>
           <td>Mullion</td>
           <td>{numeral(Mullion()).format('0,0.00')}</td>
           <td>{numeral(values.mullion.wpf).format('0,0.00')}</td>
           <td>{numeral(values.mullion.rw).format('0,0.00')}</td>
           <td>{numeral(values.mullion.rpf).format('0,0.00')}</td>
           <td>{numeral(values.mullion.amount).format('0,0.00')}</td>
         </tr>
         <tr>
           <td>Frame Gasket</td>
           <td>{numeral(frameGasket()).format('0,0.00')}</td>
           <td>{numeral(values.frameGasket.wpf).format('0,0.00')}</td>
           <td>{numeral(values.frameGasket.rw).format('0,0.00')}</td>
           <td>{numeral(values.frameGasket.rpf).format('0,0.00')}</td>
           <td>{numeral(values.frameGasket.amount).format('0,0.00')}</td>
         </tr>
         <tr>
           <td>Shutter Gasket</td>
           <td>{numeral(shutterGasket()).format('0,0.00')}</td>
           <td>{numeral(values.shutterGasket.wpf).format('0,0.00')}</td>
           <td>{numeral(values.shutterGasket.rw).format('0,0.00')}</td>
           <td>{numeral(values.shutterGasket.rpf).format('0,0.00')}</td>
           <td>{numeral(values.shutterGasket.amount).format('0,0.00')}</td>
         </tr>
         <tr>
           <td>Mullion Gasket</td>
           <td>{numeral(Mullion()).format('0,0.00')}</td>
           <td>{numeral(values.mullionGasket.wpf).format('0,0.00')}</td>
           <td>{numeral(values.mullionGasket.rw).format('0,0.00')}</td>
           <td>{numeral(values.mullionGasket.rpf).format('0,0.00')}</td>
           <td>{numeral(values.mullionGasket.amount).format('0,0.00')}</td>
         </tr>
         <tr>
           <td>Beeding Gasket</td>
           <td>{numeral(beedingGasket()).format('0,0.00')}</td>
           <td>{numeral(values.beedingGasket.wpf).format('0,0.00')}</td>
           <td>{numeral(values.beedingGasket.rw).format('0,0.00')}</td>
           <td>{numeral(values.beedingGasket.rpf).format('0,0.00')}</td>
           <td>{numeral(values.beedingGasket.amount).format('0,0.00')}</td>
         </tr>
         {mesh === 'yes' && (

           <tr>
             <td>Mesh Gasket</td>
             <td>{numeral(meshGasket()).format('0,0.00')}</td>
             <td>{numeral(values.meshGasket.wpf).format('0,0.00')}</td>
             <td>{numeral(values.meshGasket.rw).format('0,0.00')}</td>
             <td>{numeral(values.meshGasket.rpf).format('0,0.00')}</td>
             <td>{numeral(values.meshGasket.amount).format('0,0.00')}</td>
           </tr>
          
         )}
         { numFrames === 1 ? (<></>):(
         <tr>
           <td>Cupler Joint/Square Joint </td>
           <td>{numeral(cuplerJoint()).format('0,0.00')}</td>
           <td>{numeral(values.cupler.wpf).format('0,0.00')}</td>
           <td>{numeral(values.cupler.rw).format('0,0.00')}</td>
           <td>{numeral(values.cupler.rpf).format('0,0.00')}</td>
           <td>{numeral(values.cupler.amount).format('0,0.00')}</td>
         </tr>
         )}
        
         <tr>
           <td colspan="5">Total</td>
           <td>{numeral(frameTotal).format('0,0.00')}</td>
         </tr>
         <tr>
           <th colspan="5">Wastage ( {data.wastage}% )</th>
           <th>{numeral(frameTotal+wastage).format('0,0.00')}</th>
         </tr>
         <tr>
           <th colspan="5">Profile Cost/Sqft</th>
           <th>{numeral((frameTotal+wastage) / frameArea).format('0,0.00')}</th>
         </tr>
               </tbody>
             </table>
         </div>   
         <hr />
             <div class="card-header">
             <h5>Hardware Cost</h5>
           </div>
           <div class="card-body">
           <table id="left-right-fix" class="table stripe row-border order-column">
               <thead>
                 <tr>
                   <th>Product Description</th>
                   <th width="10%">Req. Qty </th>
                   <th width="10%">Price</th>
                   <th width="10%">Amount</th>
                 </tr>
               </thead>
               <tbody>
           {mesh === 'yes' && (
             <>
             <tr>
               <td>Mesh 3.4 SS</td>
               <td>{numeral(values.mesh.qty).format('0,0.00')}</td>
               <td>{numeral(values.mesh.price).format('0,0.00')}</td>
               <td>{numeral(values.mesh.amount).format('0,0.00')}</td>
             </tr>
             <tr>
             <td>Steel Mesh</td>
             <td>{numeral(values.steelMesh.qty).format('0,0.00')}</td>
             <td>{numeral(values.steelMesh.price).format('0,0.00')}</td>
             <td>{numeral(values.steelMesh.amount).format('0,0.00')}</td>
           </tr>
           </>
           )}
               <tr>
           <td>Steel Frame</td>
           <td>{numeral(values.steelFrame.qty).format('0,0.00')}</td>
           <td>{numeral(values.steelFrame.price).format('0,0.00')}</td>
           <td>{numeral(values.steelFrame.amount).format('0,0.00')}</td>
         </tr>
         <tr>
           <td>Steel Mullion</td>
           <td>{numeral(values.steelMullion.qty).format('0,0.00')}</td>
           <td>{numeral(values.steelMullion.price).format('0,0.00')}</td>
           <td>{numeral(values.steelMullion.amount).format('0,0.00')}</td>
         </tr>
         <tr>
           <td>Steel Shutter</td>
           <td>{numeral(values.steelShutter.qty).format('0,0.00')}</td>
           <td>{numeral(values.steelShutter.price).format('0,0.00')}</td>
           <td>{numeral(values.steelShutter.amount).format('0,0.00')}</td>
         </tr>
         <tr>
           <td>Multi Point Lock ( MPL )</td>
           <td>{numeral(values.mpl.qty).format('0,0.00')}</td>
           <td>{numeral(values.mpl.price).format('0,0.00')}</td>
           <td>{numeral(values.mpl.amount).format('0,0.00')}</td>
         </tr>
         <tr>
           <td>Handle</td>
           <td>{numeral(values.handle.qty).format('0,0.00')}</td>
           <td>{numeral(values.handle.price).format('0,0.00')}</td>
           <td>{numeral(values.handle.amount).format('0,0.00')}</td>
         </tr>
         <tr>
           <td>Hings Set / Friction Stay</td>
           <td>{numeral(values.hings.qty).format('0,0.00')}</td>
           <td>{numeral(values.hings.price).format('0,0.00')}</td>
           <td>{numeral(values.hings.amount).format('0,0.00')}</td>
         </tr>
         <tr>
           <td>Screws/Misc</td>
           <td>{numeral(values.screws.qty).format('0,0.00')}</td>
           <td>{numeral(values.screws.price).format('0,0.00')}</td>
           <td>{numeral(values.screws.amount).format('0,0.00')}</td>
         </tr>
         <tr>
           <th colspan="3">Total</th>
           <th>{numeral(hardTotal).format('0,0.00')}</th>
         </tr>
         <tr>
           <th colspan="3">Hardware Cost/Sqft</th>
           <th>{numeral(hardTotal/frameArea).format('0,0.00')}</th>
         </tr>
                 </tbody>
             </table>
           </div>
           <hr />
         
           <div class="card-header ">
             <h5>Margin Cost</h5>
           </div>
           <div class="card-body">
           <table id="left-right-fix" class="table stripe row-border order-column">
               <thead>
                 <tr>
                   <th>Product Description</th>
                   <th width="10%">Req. Qty </th>
                   <th width="10%">Price</th>
                   <th width="10%">Amount</th>
                 </tr>
               </thead>
               <tbody>
               <tr>
           <td>Margin</td>
           <td>{numeral(values.margin.qty).format('0,0.00')}</td>
           <td>{numeral(values.margin.price).format('0,0.00')}</td>
           <td>{numeral(values.margin.amount).format('0,0.00')}</td>
         </tr>
         <tr>
           <th colspan="3">Total</th>
           <th>{numeral(marginTotal).format('0,0.00')}</th>
         </tr>
         <tr>
           <th colspan="3">Margin Cost/Sqft</th>
           <th>{numeral(marginTotal/frameArea).format('0,0.00')}</th>
         </tr>
         </tbody>
             </table>
           </div>
           <hr />

           <div class="card-header">
             <h5>Production Cost</h5>
           </div>
           <div class="card-body">
           <table id="left-right-fix" class="table stripe row-border order-column">
               <thead>
                 <tr>
                   <th>Product Description</th>
                   <th width="10%">Req. Qty </th>
                   <th width="10%">Price</th>
                   <th width="10%">Amount</th>
                 </tr>
               </thead>
               <tbody>
               <tr>
           <td>Markting OH</td>
           <td>{numeral(values.marketing.qty).format('0,0.00')}</td>
           <td>{numeral(values.marketing.price).format('0,0.00')}</td>
           <td>{numeral(values.marketing.amount).format('0,0.00')}</td>
         </tr>
         <tr>
           <td>Transport</td>
           <td>{numeral(values.transport.qty).format('0,0.00')}</td>
           <td>{numeral(values.transport.price).format('0,0.00')}</td>
           <td>{numeral(values.transport.amount).format('0,0.00')}</td>
         </tr>
         <tr>
           <td>Labour (Factory)</td>
           <td>{numeral(values.labourF.qty).format('0,0.00')}</td>
           <td>{numeral(values.labourF.price).format('0,0.00')}</td>
           <td>{numeral(values.labourF.amount).format('0,0.00')}</td>
         </tr>
         <tr>
           <td>Labour (Site)</td>
           <td>{numeral(values.labourS.qty).format('0,0.00')}</td>
           <td>{numeral(values.labourS.price).format('0,0.00')}</td>
           <td>{numeral(values.labourS.amount).format('0,0.00')}</td>
         </tr>
         <tr>
           <td>Glass</td>
           <td>{numeral(values.glass.qty).format('0,0.00')}</td>
           <td>{numeral(values.glass.price).format('0,0.00')}</td>
           <td>{numeral(values.glass.amount).format('0,0.00')}</td>
         </tr>
         <tr>
           <th colspan="3">Total</th>
           <th>{numeral(prodTotal).format('0,0.00')}</th>
         </tr>
         <tr>
           <th colspan="3">Production Cost/Sqft</th>
           <th>{numeral( prodTotal/frameArea).format('0,0.00')}</th>
         </tr>

         </tbody>
             </table>
           </div>


         </div>
       </div>
     
   
      

       
       </>
)}

     
     </div>
   </div>
 </div>



    )}

{item === 'SlidingWindow' && (
       <div class="pc-container">
       <div class="pc-content">
         
         <div class="row">
          
            
          
 
 
           <div class="col-md-12 col-xxl-12 col-xl-12">
             <div class="card" style={{background:'#fff'}}>
               <div class="card-body mb-5">
               
               <div>
 
 <div style={{float:'left', width: boxWidth*screen+'vw', height:boxHeight*screen+'vw', border:'1px solid #333'}}>
 {[...Array(numFrames)].map((_, index) => (
   <div style={{float:'left', margin:'5px', border:'1px solid #333',width:`calc(${boxWidth*screen/numFrames+'vw'} - 12px)`, height:`calc(${boxHeight*screen+'vw'} - 12px)`}}> 
   {newHeights !== 0 ? (
 <>
   
   { boxPosition === 'Top' ? (
       <div></div>
     ) : (
       [...Array(numFirstInnerBoxes)].map((_, index) => (
   <div style={{width: `calc(${boxWidth*screen/numFrames+'vw'} - 23px)`, height:`calc(${(newHeights * screen)}vw )`, background: 'lightgreen', margin:'5px', border:'1px solid #333'}}></div>
   ))
   )}
  
   <div style={{ display: 'flex' }}>
     {[...Array(numSecondInnerBoxes)].map((_, index) => (
       <div key={index} style={{ flex: '1', height: `calc(${(boxHeight - newHeights) * screen}vw - 30px)`, width: `calc(${newWidths[index] * screen}vw - 5px - 19px)`, backgroundColor: boxColors[index] || 'lightblue', marginTop: boxPosition === 'Top' ? '5px' : '0px', marginRight: index === numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #333' }}>
       
               <div className="expanding-arrow-line" style={{ 
                 position: 'absolute',
                 float:'left', 
                 width: `calc(${newWidths[index] * screen}vw - 15px)`,
                 bottom:'-10px', 
                 top: `calc(${boxHeight * screen}vw - 25px)`,
                }}>
                 <div className="arrow-left"></div>
                   <div className="line" align="center">
                     <div style={{marginTop:'-7px',background:'white',maxWidth:'20px',textAlign:'center'}}><p>{typeof newWidths[index] === 'number' ? newWidths[index].toFixed(2) : newWidths[index]}</p></div>
                   </div>
                 <div className="arrow-right"></div>
               </div>
       
        <div style={{
         height:'5px',
         width: '1px',
         float: 'right',
         right: '0px',
         marginTop: '-6px',
         marginRight: '-1px',
         background: index === (numSecondInnerBoxes-1) ? 'transparent' : 'black',
      }}></div>
 
     <div style={{
         height:'7px',
         width: '1px',
         float: 'right',
         right: '0px',
         marginTop: `calc(${(boxHeight-newHeights) * screen}vw - 31px)`,
         marginRight: '-1px',
         background: index === (numSecondInnerBoxes-1) ? 'transparent' : 'black',
      }}></div>
        
        
             { boxNames[index] !== 'Shutter' ? (<></>) : (
               <img
                 src={Handle}
                 height = {screen === 8 ? '15px' : '26px'}
                 style={{
                   float: boxHandle[index] === 'left' ? 'left' : 'right',
                   marginTop: ((boxHeight-newHeights) * ((screen/2)-(screen/8)))+'vw',
                   marginLeft: boxHandle[index] === 'left' ? '2px' : '0px',
                   marginRight: boxHandle[index] === 'left' ? '0px' : '4px',
                   width: 'auto'
                 }}
                
                 alt="Handle"
               />
             )}
             
             {mesh === 'yes' && boxNames[index] === 'Shutter' && index % 2 === 0 && (
                 <img src={meshimg} style={{width:'50px', float:'left', marginLeft: boxHandle[index] === 'left' ? '-6px' : '0px', marginTop: `calc(${((boxHeight-newHeights)*screen)}vw - 82px)`}} />
                 )
             }
 
       </div>
 
     ))}
     
   </div>
   { boxPosition === 'Bottom' ? (
       <div></div>
     ) : (
       [...Array(numFirstInnerBoxes)].map((_, index) => (
   <div style={{width: `calc(${boxWidth*screen/numFrames+'vw'} - 23px)`, height:`calc(${(newHeights * screen)}vw )`, background: 'lightgreen', margin:'5px', border:'1px solid #333'}}></div>
   ))
   )}
 
   { numSecondInnerBoxes === 0 ? (
         <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((boxWidth/numFrames)* screen)+'vw',top:(boxHeight-newHeights)*screen+'vw', }} >
         <div className="arrow-left"></div>
         <div className="line" align="center">
           <div style={{marginTop:'-8px',background:'white',maxWidth:'40px',textAlign:'center'}}><p>{typeof (boxWidth/numFrames) === 'number' ? (boxWidth/numFrames).toFixed(2) : (boxWidth/numFrames)}</p></div>
         </div>
         <div className="arrow-right"></div>
       </div>
       ) : (
         <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((boxWidth/numFrames)* screen)+'vw',bottom:'-10px',top:'50px', }} >
           <div className="arrow-left"></div>
           <div className="line" align="center">
             <div style={{marginTop:'-8px',background:'white',maxWidth:'40px',textAlign:'center'}}><p>{typeof (boxWidth/numFrames) === 'number' ? (boxWidth/numFrames).toFixed(2) : (boxWidth/numFrames)}</p></div>
           </div>
           <div className="arrow-right"></div>
         </div>
       )}
 </>
  ) : (
 <>
 <div style={{ display: 'flex' }}>
   {[...Array(numSecondInnerBoxes)].map((_, index) => (
     <>
     
     <div key={index} style={{ flex: '1', height: `calc(${(boxHeight) * screen}vw - 24px)`, width: `calc(${newWidths[index] * screen}vw - 5px - 19px)`, backgroundColor: boxColors[index] || 'lightblue', marginRight: index === numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #333',marginTop:'5px' }}>
          
 
               <div className="expanding-arrow-line" style={{ 
                 position: 'absolute',
                 float:'left', 
                 width: `calc(${newWidths[index] * screen}vw - 15px)`,
                 bottom:'-10px', 
                 top: `calc(${boxHeight * screen}vw - 35px)`,
                }}>
                 <div className="arrow-left"></div>
                   <div className="line" align="center">
                     <div style={{marginTop:'-7px',background:'white',maxWidth:'20px',textAlign:'center'}}><p>{typeof newWidths[index] === 'number' ? newWidths[index].toFixed(2) : newWidths[index]}</p></div>
                   </div>
                 <div className="arrow-right"></div>
               </div>
 
 
     <div style={{
         height:'5px',
         width: '1px',
         float: 'right',
         right: '0px',
         marginTop: '-6px',
         marginRight: '-1px',
         background: index === (numSecondInnerBoxes-1) ? 'transparent' : 'black',
      }}></div>
 
     <div style={{
         height:'5px',
         width: '1px',
         float: 'right',
         right: '0px',
         marginTop: `calc(${(boxHeight) * screen}vw - 24px)`,
         marginRight: '-1px',
         background: index === (numSecondInnerBoxes-1) ? 'transparent' : 'black',
      }}></div>
          
          
            { boxNames[index] !== 'Shutter' ? (<></>) : (
               <img
                 src={Handle}
                 height = {screen === 8 ? '15px' : '26px'}
                 style={{
                   float: boxHandle[index] === 'left' ? 'left' : 'right',
                   marginTop: (boxHeight) * ((screen/2)-(screen/13))+'vw',
                   marginLeft: boxHandle[index] === 'left' ? '2px' : '0px',
                   marginRight: boxHandle[index] === 'left' ? '0px' : '4px',
                   width: 'auto'
                 }}
                
                 alt="Handle"
               />
             )}
               
             {mesh === 'yes' && boxNames[index] === 'Shutter' && index % 2 === 0 && (
                 <img src={meshimg} style={{width:'50px', float:'left', marginLeft: boxHandle[index] === 'left' ? '-6px' : '0px', marginTop: `calc(${((boxHeight-newHeights)*screen)}vw - 75px)`}} />
                 )
             }
            
     </div>
     </>
   ))}
 </div> 
       { numSecondInnerBoxes === 0 ? (
         <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((boxWidth/numFrames)* screen)+'vw', top:boxHeight*screen+'vw',  }} >
         <div className="arrow-left"></div>
         <div className="line" align="center">
           <div style={{marginTop:'-8px',background:'white',maxWidth:'40px',textAlign:'center'}}><p>{typeof (boxWidth/numFrames) === 'number' ? (boxWidth/numFrames).toFixed(2) : (boxWidth/numFrames)}</p></div>
         </div>
         <div className="arrow-right"></div>
       </div>
       ) : (
         <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((boxWidth/numFrames)* screen)+'vw', bottom:'-10px',top:'40px', }} >
           <div className="arrow-left"></div>
           <div className="line" align="center">
             <div style={{marginTop:'-8px',background:'white',maxWidth:'40px',textAlign:'center'}}><p>{typeof (boxWidth/numFrames) === 'number' ? (boxWidth/numFrames).toFixed(2) : (boxWidth/numFrames)}</p></div>
           </div>
           <div className="arrow-right"></div>
         </div>
       )}
 </>
   )}
   </div>
    ))}
 </div>
 
 { boxPosition === 'Top' ? (
   newHeights === 0 ? (
 
     <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
       <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${boxHeight * screen}vw)`, top: '0', position: 'relative' }}>
        <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
        <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
          <div style={{ marginLeft: '-10px', marginTop: boxHeight * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof boxHeight === 'number' ? boxHeight.toFixed(2) : boxHeight}</p></div>
        </div>
        <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
       </div>
     </div>
 ) : (  
       <>
         <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
 
           <div className="expanding-arrow-line" style={{order: 1, flex:1, float: 'left', height: `calc(${(boxHeight-newHeights) * screen}vw - 20px)`, top: '0', position: 'relative' }}>
             <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
             <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
               <div style={{ marginLeft: '-10px', marginTop: (boxHeight-newHeights) * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof (boxHeight-newHeights) === 'number' ? (boxHeight-newHeights).toFixed(2) : (boxHeight-newHeights)}</p></div>
             </div>
             <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
           </div>
            <div className="expanding-arrow-line" style={{order: 2, flex:2, float: 'left', height: `calc(${newHeights * screen}vw `, top: `calc(${(boxHeight-newHeights) * screen}vw - 10px)`, position: 'relative' }}>
             <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
             <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
               <div style={{ marginLeft: '-10px', marginTop: newHeights * (((screen/2)-(screen/5)))+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof newHeights === 'number' ? newHeights.toFixed(2) : newHeights}</p></div>
             </div>
             <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
           </div>
 
         </div>
 
         <div style={{ width: '30px', float: 'left', paddingLeft: '0px', textAlign: 'left' }}>
         <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${boxHeight * screen}vw)`, top: '0', position: 'relative' }}>
          <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
          <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
            <div style={{ marginLeft: '-10px', marginTop: boxHeight * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof boxHeight === 'number' ? boxHeight.toFixed(2) : boxHeight}</p></div>
          </div>
          <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
         </div>
       </div>
       </>
 )
 ) : (
 newHeights === 0 ? (
 
     <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
       <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${boxHeight * screen}vw)`, top: '0', position: 'relative' }}>
        <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
        <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
          <div style={{ marginLeft: '-10px', marginTop: boxHeight * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof boxHeight === 'number' ? boxHeight.toFixed(2) : boxHeight}</p></div>
        </div>
        <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
       </div>
     </div>
 ) : (  
       <>
         <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
           <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${newHeights * screen}vw + 10px)`, top: '0', position: 'relative' }}>
             <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
             <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
               <div style={{ marginLeft: '-10px', marginTop: newHeights * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof newHeights === 'number' ? newHeights.toFixed(2) : newHeights}</p></div>
             </div>
             <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
           </div>
           <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${(boxHeight-newHeights) * screen}vw - 20px)`, top: `calc(${newHeights * screen}vw + 20px)`, position: 'relative' }}>
             <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
             <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
               <div style={{ marginLeft: '-10px', marginTop: (boxHeight-newHeights) * ((screen/2)-(screen/8))+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof (boxHeight-newHeights) === 'number' ? (boxHeight-newHeights).toFixed(2) : (boxHeight-newHeights)}</p></div>
             </div>
             <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
           </div>
         </div>
         <div style={{ width: '30px', float: 'left', paddingLeft: '0px', textAlign: 'left' }}>
         <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${boxHeight * screen}vw)`, top: '0', position: 'relative' }}>
          <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
          <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
            <div style={{ marginLeft: '-10px', marginTop: boxHeight * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof boxHeight === 'number' ? boxHeight.toFixed(2) : boxHeight}</p></div>
          </div>
          <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
         </div>
       </div>
       </>
 )
 )}
     
 </div>
                 
               </div>
             </div>
           </div>
 
 { numSecondInnerBoxes === 0 ? (<div></div>):( 
           <>
           <div class="col-md-12 col-xxl-2 col-xl-2">
             <div class="card">
               <div class="card-body">
                 <div class="d-flex align-items-center">
                   <div class="flex-grow-1 ms-3">
                     <h6 class="mb-0">Area</h6>
                   </div>
                 </div>
                 <div class="bg-body p-3 mt-3 rounded">
                   <div class="mt-3 row align-items-center">
                     <div class="col-12">
                       <h6 class="mb-1">{frameArea} sqft</h6>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>
 
           <div class="col-md-12 col-xxl-2 col-xl-2">
             <div class="card">
               <div class="card-body">
                 <div class="d-flex align-items-center">
                   <div class="flex-grow-1 ms-3">
                     <h6 class="mb-0">Shutters</h6>
                   </div>
                 </div>
                 <div class="bg-body p-3 mt-3 rounded">
                   <div class="mt-3 row align-items-center">
                     <div class="col-12">
                       <h6 class="mb-1">{shutterCount*numFrames}</h6>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>
 
           <div class="col-md-12 col-xxl-2 col-xl-2">
             <div class="card">
               <div class="card-body">
                 <div class="d-flex align-items-center">
                   <div class="flex-grow-1 ms-3">
                     <h6 class="mb-0">Fixed</h6>
                   </div>
                 </div>
                 <div class="bg-body p-3 mt-3 rounded">
                   <div class="mt-3 row align-items-center">
                     <div class="col-12">
                       <h6 class="mb-1">{fixedShutters}</h6>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>
 
           <div class="col-md-12 col-xxl-2 col-xl-2">
             <div class="card">
               <div class="card-body">
                 <div class="d-flex align-items-center">
                   <div class="flex-grow-1 ms-3">
                     <h6 class="mb-0">Mullions</h6>
                   </div>
                 </div>
                 <div class="bg-body p-3 mt-3 rounded">
                   <div class="mt-3 row align-items-center">
                     <div class="col-12">
                       <h6 class="mb-1">{newHeights === 0 ? 0 : 1 }</h6>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>
 
           <div class="col-md-12 col-xxl-2 col-xl-2">
             <div class="card">
               <div class="card-body">
                 <div class="d-flex align-items-center">
                   <div class="flex-grow-1 ms-3">
                     <h6 class="mb-0">Total</h6>
                   </div>
                 </div>
                 <div class="bg-body p-3 mt-3 rounded">
                   <div class="mt-3 row align-items-center">
                     <div class="col-12">
                       <h6 class="mb-1">₹ {numeral(grandTotal).format('0,0.00')}</h6>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>
 
           <div class="col-md-12 col-xxl-2 col-xl-2">
             <div class="card">
               <div class="card-body">
                 <div class="d-flex align-items-center">
                   <div class="flex-grow-1 ms-3">
                     <h6 class="mb-0">Rate/Sqft</h6>
                   </div>
                 </div>
                 <div class="bg-body p-3 mt-3 rounded">
                   <div class="mt-3 row align-items-center">
                     <div class="col-12">
                       <h6 class="mb-1">₹ {numeral(grandTotal/frameArea).format('0,0.00')}</h6>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>
 
 
 
 
           <div class="col-md-12 col-xxl-12 col-xl-12">
             <div class="card">
               <div class="card-header">
                 <h5>Profile Cost</h5>
               </div>
               <div class="card-body">
               <table id="left-right-fix" class="table stripe row-border order-column">
                   <thead>
                     <tr>
                       <th>Product Description</th>
                       <th width="10%">Req. Qty (Feet)</th>
                       <th width="10%">Kg/Feet</th>
                       <th width="10%">Req. Qty (Kg)</th>
                       <th width="10%">Rate/Kg</th>
                       <th width="10%">Amount</th>
                     </tr>
                   </thead>
                   <tbody>
                     <tr>
                       <td>{shutterCount}{mesh === 'yes' ? '.5' : ''} Track Outer Frame</td> 
                       <td>{numeral(Frame()).format('0,0.00')}</td>
                       <td>{numeral(values.frame.wpf).format('0,0.00')}</td>
                       <td>{numeral(values.frame.rw).format('0,0.00')}</td>
                       <td>{numeral(values.frame.rpf).format('0,0.00')}</td>
                       <td>{numeral(values.frame.amount).format('0,0.00')}</td>
                     </tr>
                     <tr>
               <td>Shutter Frame</td>
               <td>{numeral(Shutter()).format('0,0.00')}</td>
               <td>{numeral(values.shutter.wpf).format('0,0.00')}</td>
               <td>{numeral(values.shutter.rw).format('0,0.00')}</td>
               <td>{numeral(values.shutter.rpf).format('0,0.00')}</td>
               <td>{numeral(values.shutter.amount).format('0,0.00')}</td>
             </tr>
             {mesh === 'yes' && (
               
               <tr>
                 <td>Mesh Frame</td>
                 <td>{numeral(meshFrame()).format('0,0.00')}</td>
                 <td>{numeral(values.meshFrame.wpf).format('0,0.00')}</td>
                 <td>{numeral(values.meshFrame.rw).format('0,0.00')}</td>
                 <td>{numeral(values.meshFrame.rpf).format('0,0.00')}</td>
                 <td>{numeral(values.meshFrame.amount).format('0,0.00')}</td>
               </tr>
              
             )}
 
            {newHeights > 0 &&(
             <tr>
               <td>Mullion</td>
               <td>{numeral(Mullion()).format('0,0.00')}</td>
               <td>{numeral(values.mullion.wpf).format('0,0.00')}</td>
               <td>{numeral(values.mullion.rw).format('0,0.00')}</td>
               <td>{numeral(values.mullion.rpf).format('0,0.00')}</td>
               <td>{numeral(values.mullion.amount).format('0,0.00')}</td>
             </tr>
           )}
 
             <tr>
               <td>Beeding</td>
               <td>{numeral(Beeding()).format('0,0.00')}</td>
               <td>{numeral(values.beeding.wpf).format('0,0.00')}</td>
               <td>{numeral(values.beeding.rw).format('0,0.00')}</td>
               <td>{numeral(values.beeding.rpf).format('0,0.00')}</td>
               <td>{numeral(values.beeding.amount).format('0,0.00')}</td>
             </tr>
             <tr>
               <td>Frame Gasket</td>
               <td>{numeral(frameGasket()).format('0,0.00')}</td>
               <td>{numeral(values.frameGasket.wpf).format('0,0.00')}</td>
               <td>{numeral(values.frameGasket.rw).format('0,0.00')}</td>
               <td>{numeral(values.frameGasket.rpf).format('0,0.00')}</td>
               <td>{numeral(values.frameGasket.amount).format('0,0.00')}</td>
             </tr>
             <tr>
               <td>Shutter Gasket</td>
               <td>{numeral(shutterGasket()).format('0,0.00')}</td>
               <td>{numeral(values.shutterGasket.wpf).format('0,0.00')}</td>
               <td>{numeral(values.shutterGasket.rw).format('0,0.00')}</td>
               <td>{numeral(values.shutterGasket.rpf).format('0,0.00')}</td>
               <td>{numeral(values.shutterGasket.amount).format('0,0.00')}</td>
             </tr>
 
             {newHeights > 0 &&(
               <tr>
                 <td>Mullion Gasket</td>
                 <td>{numeral(Mullion()).format('0,0.00')}</td>
                 <td>{numeral(values.mullionGasket.wpf).format('0,0.00')}</td>
                 <td>{numeral(values.mullionGasket.rw).format('0,0.00')}</td>
                 <td>{numeral(values.mullionGasket.rpf).format('0,0.00')}</td>
                 <td>{numeral(values.mullionGasket.amount).format('0,0.00')}</td>
               </tr>
             )}
 
             <tr>
               <td>Beeding Gasket</td>
               <td>{numeral(beedingGasket()).format('0,0.00')}</td>
               <td>{numeral(values.beedingGasket.wpf).format('0,0.00')}</td>
               <td>{numeral(values.beedingGasket.rw).format('0,0.00')}</td>
               <td>{numeral(values.beedingGasket.rpf).format('0,0.00')}</td>
               <td>{numeral(values.beedingGasket.amount).format('0,0.00')}</td>
             </tr>
             {mesh === 'yes' && (
              
               <tr>
                 <td>Mesh Gasket</td>
                 <td>{numeral(meshGasket()).format('0,0.00')}</td>
                 <td>{numeral(values.meshGasket.wpf).format('0,0.00')}</td>
                 <td>{numeral(values.meshGasket.rw).format('0,0.00')}</td>
                 <td>{numeral(values.meshGasket.rpf).format('0,0.00')}</td>
                 <td>{numeral(values.meshGasket.amount).format('0,0.00')}</td>
               </tr>
              
             )}
             <tr>
               <td>InterLock</td>
               <td>{numeral(interLock()).format('0,0.00')}</td>
               <td>{numeral(values.interLock.wpf).format('0,0.00')}</td>
               <td>{numeral(values.interLock.rw).format('0,0.00')}</td>
               <td>{numeral(values.interLock.rpf).format('0,0.00')}</td>
               <td>{numeral(values.interLock.amount).format('0,0.00')}</td>
             </tr>
             
             { numFrames === 1 ? (<></>):(
             <tr>
               <td>Cupler Joint/Square Joint </td>
               <td>{numeral(cuplerJoint()).format('0,0.00')}</td>
               <td>{numeral(values.cupler.wpf).format('0,0.00')}</td>
               <td>{numeral(values.cupler.rw).format('0,0.00')}</td>
               <td>{numeral(values.cupler.rpf).format('0,0.00')}</td>
               <td>{numeral(values.cupler.amount).format('0,0.00')}</td>
             </tr>
             )}
            
             <tr>
               <td colspan="5">Total</td>
               <td>{numeral(frameTotal).format('0,0.00')}</td>
             </tr>
             <tr>
               <th colspan="5">Wastage ( {data.wastage}% )</th>
               <th>{numeral(frameTotal+wastage).format('0,0.00')}</th>
             </tr>
             <tr>
               <th colspan="5">Profile Cost/Sqft</th>
               <th>{numeral((frameTotal+wastage) / frameArea).format('0,0.00')}</th>
             </tr>
                   </tbody>
                 </table>
             </div>   
             <hr />
                 <div class="card-header">
                 <h5>Hardware Cost</h5>
                
               </div>
               <div class="card-body">
               <table id="left-right-fix" class="table stripe row-border order-column">
                   <thead>
                     <tr>
                       <th>Product Description</th>
                       <th width="10%">Req. Qty </th>
                       <th width="10%">Price</th>
                       <th width="10%">Amount</th>
                     </tr>
                   </thead>
                   <tbody>
                  
             <tr>
               <td>Steel Frame</td>
               <td>{numeral(values.steelFrame.qty).format('0,0.00')}</td>
               <td>{numeral(values.steelFrame.price).format('0,0.00')}</td>
               <td>{numeral(values.steelFrame.amount).format('0,0.00')}</td>
             </tr>
             <tr>
               <td>Steel Shutter</td>
               <td>{numeral(values.steelShutter.qty).format('0,0.00')}</td>
               <td>{numeral(values.steelShutter.price).format('0,0.00')}</td>
               <td>{numeral(values.steelShutter.amount).format('0,0.00')}</td>
             </tr>
             {newHeights > 0 &&(
               <tr>
                 <td>Steel Mullion</td>
                 <td>{numeral(values.steelMullion.qty).format('0,0.00')}</td>
                 <td>{numeral(values.steelMullion.price).format('0,0.00')}</td>
                 <td>{numeral(values.steelMullion.amount).format('0,0.00')}</td>
               </tr>
             )}
             {mesh === 'yes' && (
             <tr>
               <td>Steel Mesh</td>
               <td>{numeral(values.steelMesh.qty).format('0,0.00')}</td>
               <td>{numeral(values.steelMesh.price).format('0,0.00')}</td>
               <td>{numeral(values.steelMesh.amount).format('0,0.00')}</td>
             </tr>
             )}
             <tr>
               <td>Aluminium Track</td>
               <td>{numeral(values.alTrack.qty).format('0,0.00')}</td>
               <td>{numeral(values.alTrack.price).format('0,0.00')}</td>
               <td>{numeral(values.alTrack.amount).format('0,0.00')}</td>
             </tr>
             <tr>
               <td>Bump Arrester</td>
               <td>{numeral(values.bArrester.qty).format('0,0.00')}</td>
               <td>{numeral(values.bArrester.price).format('0,0.00')}</td>
               <td>{numeral(values.bArrester.amount).format('0,0.00')}</td>
             </tr>
             <tr>
               <td>Jump Stopper</td>
               <td>{numeral(values.jStopper.qty).format('0,0.00')}</td>
               <td>{numeral(values.jStopper.price).format('0,0.00')}</td>
               <td>{numeral(values.jStopper.amount).format('0,0.00')}</td>
             </tr>
             <tr>
               <td>Dust Arrester</td>
               <td>{numeral(values.dArrester.qty).format('0,0.00')}</td>
               <td>{numeral(values.dArrester.price).format('0,0.00')}</td>
               <td>{numeral(values.dArrester.amount).format('0,0.00')}</td>
             </tr>
             <tr>
               <td>Grove Roller</td>
               <td>{numeral(values.gRoller.qty).format('0,0.00')}</td>
               <td>{numeral(values.gRoller.price).format('0,0.00')}</td>
               <td>{numeral(values.gRoller.amount).format('0,0.00')}</td>
             </tr>
             <tr>
               <td>Touch Lock with SS Receiver</td>
               <td>{numeral(values.touchLock.qty).format('0,0.00')}</td>
               <td>{numeral(values.touchLock.price).format('0,0.00')}</td>
               <td>{numeral(values.touchLock.amount).format('0,0.00')}</td>
             </tr>
             <tr>
               <td>Weather Strip</td>
               <td>{numeral(values.wStrip.qty).format('0,0.00')}</td>
               <td>{numeral(values.wStrip.price).format('0,0.00')}</td>
               <td>{numeral(values.wStrip.amount).format('0,0.00')}</td>
             </tr>
             <tr>
               <td>Screws/Misc</td>
               <td>{numeral(values.screws.qty).format('0,0.00')}</td>
               <td>{numeral(values.screws.price).format('0,0.00')}</td>
               <td>{numeral(values.screws.amount).format('0,0.00')}</td>
             </tr>
             {mesh === 'yes' && (
               <>
                 <tr>
                   <td>Mesh 30A Grade</td>
                   <td>{numeral(values.mesh.qty).format('0,0.00')}</td>
                   <td>{numeral(values.mesh.price).format('0,0.00')}</td>
                   <td>{numeral(values.mesh.amount).format('0,0.00')}</td>
                 </tr>
                 <tr>
                  <td>Mesh Roller</td>
                  <td>{numeral(values.mRoller.qty).format('0,0.00')}</td>
                  <td>{numeral(values.mRoller.price).format('0,0.00')}</td>
                  <td>{numeral(values.mRoller.amount).format('0,0.00')}</td>
                 </tr>
                 </>
               )}
             <tr>
               <th colspan="3">Total</th>
               <th>{numeral(hardTotal).format('0,0.00')}</th>
             </tr>
             <tr>
               <th colspan="3">Hardware Cost/Sqft</th>
               <th>{numeral(hardTotal/frameArea).format('0,0.00')}</th>
             </tr>
                     </tbody>
                 </table>
               </div>
               <hr />
             
               <div class="card-header ">
                 <h5>Margin Cost</h5>
               </div>
               <div class="card-body">
               <table id="left-right-fix" class="table stripe row-border order-column">
                   <thead>
                     <tr>
                       <th>Product Description</th>
                       <th width="10%">Req. Qty </th>
                       <th width="10%">Price</th>
                       <th width="10%">Amount</th>
                     </tr>
                   </thead>
                   <tbody>
                   <tr>
               <td>Margin</td>
               <td>{numeral(values.margin.qty).format('0,0.00')}</td>
               <td>{numeral(values.margin.price).format('0,0.00')}</td>
               <td>{numeral(values.margin.amount).format('0,0.00')}</td>
             </tr>
             <tr>
               <th colspan="3">Total</th>
               <th>{numeral(marginTotal).format('0,0.00')}</th>
             </tr>
             <tr>
               <th colspan="3">Margin Cost/Sqft</th>
               <th>{numeral(marginTotal/frameArea).format('0,0.00')}</th>
             </tr>
             </tbody>
                 </table>
               </div>
               <hr />
 
               <div class="card-header">
                 <h5>Production Cost</h5>
               </div>
               <div class="card-body">
               <table id="left-right-fix" class="table stripe row-border order-column">
                   <thead>
                     <tr>
                       <th>Product Description</th>
                       <th width="10%">Req. Qty </th>
                       <th width="10%">Price</th>
                       <th width="10%">Amount</th>
                     </tr>
                   </thead>
                   <tbody>
                   <tr>
               <td>Markting OH</td>
               <td>{numeral(values.marketing.qty).format('0,0.00')}</td>
               <td>{numeral(values.marketing.price).format('0,0.00')}</td>
               <td>{numeral(values.marketing.amount).format('0,0.00')}</td>
             </tr>
             <tr>
               <td>Transport</td>
               <td>{numeral(values.transport.qty).format('0,0.00')}</td>
               <td>{numeral(values.transport.price).format('0,0.00')}</td>
               <td>{numeral(values.transport.amount).format('0,0.00')}</td>
             </tr>
             <tr>
               <td>Labour (Factory)</td>
               <td>{numeral(values.labourF.qty).format('0,0.00')}</td>
               <td>{numeral(values.labourF.price).format('0,0.00')}</td>
               <td>{numeral(values.labourF.amount).format('0,0.00')}</td>
             </tr>
             <tr>
               <td>Labour (Site)</td>
               <td>{numeral(values.labourS.qty).format('0,0.00')}</td>
               <td>{numeral(values.labourS.price).format('0,0.00')}</td>
               <td>{numeral(values.labourS.amount).format('0,0.00')}</td>
             </tr>
             <tr>
               <td>Glass</td>
               <td>{numeral(values.glass.qty).format('0,0.00')}</td>
               <td>{numeral(values.glass.price).format('0,0.00')}</td>
               <td>{numeral(values.glass.amount).format('0,0.00')}</td>
             </tr>
             <tr>
               <th colspan="3">Total</th>
               <th>{numeral(prodTotal).format('0,0.00')}</th>
             </tr>
             <tr>
               <th colspan="3">Production Cost/Sqft</th>
               <th>{numeral( prodTotal/frameArea).format('0,0.00')}</th>
             </tr>
 
             </tbody>
                 </table>
               </div>
 
 
             </div>
           </div>
         
       
           </>
 )}
 
         
         </div>
       </div>
     </div>
 
 
    )}

{item === 'SlidingDoor' && (
       
    <div class="pc-container">
    <div class="pc-content">
      
      <div class="row">
        <div class="col-md-12 col-xxl-12 col-xl-12">
          <div class="card" style={{background:'#fff'}}>
            <div class="card-body mb-5">
            
            <div>

<div style={{float:'left', width: boxWidth*screen+'vw', height:boxHeight*screen+'vw', border:'1px solid #333'}}>
{[...Array(numFrames)].map((_, index) => (
<div style={{float:'left', margin:'5px', border:'1px solid #333',width:`calc(${boxWidth*screen/numFrames+'vw'} - 12px)`, height:`calc(${boxHeight*screen+'vw'} - 12px)`}}> 
{newHeights !== 0 ? (
<>

{ boxPosition === 'Top' ? (
    <div></div>
  ) : (
    [...Array(numFirstInnerBoxes)].map((_, index) => (
<div style={{width: `calc(${boxWidth*screen/numFrames+'vw'} - 23px)`, height:`calc(${(newHeights * screen)}vw )`, background: 'lightgreen', margin:'5px', border:'1px solid #333'}}></div>
))
)}

<div style={{ display: 'flex' }}>
  {[...Array(numSecondInnerBoxes)].map((_, index) => (
    
    <div key={index} style={{ flex: '1', height: `calc(${(boxHeight - newHeights) * screen}vw - 30px)`, width: `calc(${newWidths[index] * screen}vw - 5px - 19px)`, backgroundColor: panelType === 1 ? 'lightgray' : panelType === 2 ? 'lightyellow' : 'lightblue', marginTop: boxPosition === 'Top' ? '5px' : '0px', marginRight: index === numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #333' }}>
    
            <div className="expanding-arrow-line" style={{ 
              position: 'absolute',
              float:'left', 
              width: `calc(${newWidths[index] * screen}vw - 15px)`,
              bottom:'-10px', 
              top: `calc(${boxHeight * screen}vw - 25px)`,
             }}>
              <div className="arrow-left"></div>
                <div className="line" align="center">
                  <div style={{marginTop:'-7px',background:'white',maxWidth:'20px',textAlign:'center'}}><p>{typeof newWidths[index] === 'number' ? newWidths[index].toFixed(2) : newWidths[index]}</p></div>
                </div>
              <div className="arrow-right"></div>
            </div> 


     <div style={{
      height:'5px',
      width: '1px',
      float: 'right',
      right: '0px',
      marginTop: '-6px',
      marginRight: '-1px',
      background: index === (numSecondInnerBoxes-1) ? 'transparent' : 'black',
   }}></div>

  <div style={{
      height:'7px',
      width: '1px',
      float: 'right',
      right: '0px',
      marginTop: `calc(${(boxHeight-newHeights) * screen}vw - 31px)`,
      marginRight: '-1px',
      background: index === (numSecondInnerBoxes-1) ? 'transparent' : 'black',
   }}></div>
     
     
          { boxNames[index] !== 'Shutter' ? (<></>) : (
            <img
              src={Handle}
              height = {screen === 8 ? '15px' : '26px'}
              style={{
                float: boxHandle[index] === 'left' ? 'left' : 'right',
                marginTop: ((boxHeight-newHeights) * ((screen/2)-(screen/8)))+'vw',
                marginLeft: boxHandle[index] === 'left' ? '2px' : '0px',
                marginRight: boxHandle[index] === 'left' ? '0px' : '4px',
                width: 'auto'
              }}
             
              alt="Handle"
            />
          )}

          {mesh === 'yes' && boxNames[index] === 'Shutter' && index % 2 === 0 && (
              <img src={meshimg} style={{width:'50px', float:'left', marginLeft: boxHandle[index] === 'left' ? '-6px' : '0px', marginTop: `calc(${((boxHeight-newHeights)*screen)}vw - 80px)`}} />
              )
          }
          
            

    </div>

  ))}
  
</div>
{ boxPosition === 'Bottom' ? (
    <div></div>
  ) : (
    [...Array(numFirstInnerBoxes)].map((_, index) => (
<div style={{width: `calc(${boxWidth*screen/numFrames+'vw'} - 23px)`, height:`calc(${(newHeights * screen)}vw )`, background: 'lightgreen', margin:'5px', border:'1px solid #333'}}></div>
))
)}

{ numSecondInnerBoxes === 0 ? (
      <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((boxWidth/numFrames)* screen)+'vw',top:(boxHeight-newHeights)*screen+'vw', }} >
      <div className="arrow-left"></div>
      <div className="line" align="center">
        <div style={{marginTop:'-8px',background:'white',maxWidth:'40px',textAlign:'center'}}><p>{typeof (boxWidth/numFrames) === 'number' ? (boxWidth/numFrames).toFixed(2) : (boxWidth/numFrames)}</p></div>
      </div>
      <div className="arrow-right"></div>
    </div>
    ) : (
      <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((boxWidth/numFrames)* screen)+'vw',bottom:'-10px',top:'50px', }} >
        <div className="arrow-left"></div>
        <div className="line" align="center">
          <div style={{marginTop:'-8px',background:'white',maxWidth:'40px',textAlign:'center'}}><p>{typeof (boxWidth/numFrames) === 'number' ? (boxWidth/numFrames).toFixed(2) : (boxWidth/numFrames)}</p></div>
        </div>
        <div className="arrow-right"></div>
      </div>
    )}
</>
) : (
<>
<div style={{ display: 'flex' }}>
{[...Array(numSecondInnerBoxes)].map((_, index) => (
  <>
  
  <div key={index} style={{ flex: '1', height: `calc(${(boxHeight) * screen}vw - 23px)`, width: `calc(${newWidths[index] * screen}vw - 5px - 19px)`, backgroundColor: panelType === 1 ? 'lightgray' : panelType === 2 ? 'lightyellow' : 'lightblue', marginRight: index === numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #333',marginTop:'5px' }}>
          
           <div className="expanding-arrow-line" style={{ 
              position: 'absolute',
              float:'left', 
              width: `calc(${newWidths[index] * screen}vw - 15px)`,
              bottom:'-10px', 
              top: `calc(${boxHeight * screen}vw - 35px)`,
             }}>
              <div className="arrow-left"></div>
                <div className="line" align="center">
                  <div style={{marginTop:'-7px',background:'white',maxWidth:'20px',textAlign:'center'}}><p>{typeof newWidths[index] === 'number' ? newWidths[index].toFixed(2) : newWidths[index]}</p></div>
                </div>
              <div className="arrow-right"></div>
            </div> 


  <div style={{
      height:'5px',
      width: '1px',
      float: 'right',
      right: '0px',
      marginTop: '-6px',
      marginRight: '-1px',
      background: index === (numSecondInnerBoxes-1) ? 'transparent' : 'black',
   }}></div>

  <div style={{
      height:'5px',
      width: '1px',
      float: 'right',
      right: '0px',
      marginTop: `calc(${(boxHeight) * screen}vw - 24px)`,
      marginRight: '-1px',
      background: index === (numSecondInnerBoxes-1) ? 'transparent' : 'black',
   }}></div>
       
       
         { boxNames[index] !== 'Shutter' ? (<></>) : (
            <img
              src={Handle}
              height = {screen === 8 ? '15px' : '26px'}
              style={{
                float: boxHandle[index] === 'left' ? 'left' : 'right',
                marginTop: (boxHeight) * ((screen/2)-(screen/13))+'vw',
                marginLeft: boxHandle[index] === 'left' ? '2px' : '0px',
                marginRight: boxHandle[index] === 'left' ? '0px' : '4px',
                width: 'auto'
              }}
             
              alt="Handle"
            />
          )}
           
          {mesh === 'yes' && boxNames[index] === 'Shutter' && index % 2 === 0 && (
              <img src={meshimg} style={{width:'50px', float:'left', marginLeft: boxHandle[index] === 'left' ? '-6px' : '0px', marginTop: `calc(${((boxHeight-newHeights)*screen)}vw - 75px)`}} />
              )
          }
         
  </div>
  </>
))}
</div> 
    { numSecondInnerBoxes === 0 ? (
      <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((boxWidth/numFrames)* screen)+'vw', top:boxHeight*screen+'vw',  }} >
      <div className="arrow-left"></div>
      <div className="line" align="center">
        <div style={{marginTop:'-8px',background:'white',maxWidth:'40px',textAlign:'center'}}><p>{typeof (boxWidth/numFrames) === 'number' ? (boxWidth/numFrames).toFixed(2) : (boxWidth/numFrames)}</p></div>
      </div>
      <div className="arrow-right"></div>
    </div>
    ) : (
      <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((boxWidth/numFrames)* screen)+'vw', bottom:'-10px',top:'40px', }} >
        <div className="arrow-left"></div>
        <div className="line" align="center">
          <div style={{marginTop:'-8px',background:'white',maxWidth:'40px',textAlign:'center'}}><p>{typeof (boxWidth/numFrames) === 'number' ? (boxWidth/numFrames).toFixed(2) : (boxWidth/numFrames)}</p></div>
        </div>
        <div className="arrow-right"></div>
      </div>
    )}
</>
)}
</div>
 ))}
</div>

{ boxPosition === 'Top' ? (
newHeights === 0 ? (

  <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
    <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${boxHeight * screen}vw)`, top: '0', position: 'relative' }}>
     <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
     <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
       <div style={{ marginLeft: '-10px', marginTop: boxHeight * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof boxHeight === 'number' ? boxHeight.toFixed(2) : boxHeight}</p></div>
     </div>
     <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
    </div>
  </div>
) : (  
    <>
      <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>

        <div className="expanding-arrow-line" style={{order: 1, flex:1, float: 'left', height: `calc(${(boxHeight-newHeights) * screen}vw - 20px)`, top: '0', position: 'relative' }}>
          <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
          <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
            <div style={{ marginLeft: '-10px', marginTop: (boxHeight-newHeights) * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof (boxHeight-newHeights) === 'number' ? (boxHeight-newHeights).toFixed(2) : (boxHeight-newHeights)}</p></div>
          </div>
          <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
        </div>
         <div className="expanding-arrow-line" style={{order: 2, flex:2, float: 'left', height: `calc(${newHeights * screen}vw `, top: `calc(${(boxHeight-newHeights) * screen}vw - 10px)`, position: 'relative' }}>
          <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
          <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
            <div style={{ marginLeft: '-10px', marginTop: newHeights * (((screen/2)-(screen/5)))+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof newHeights === 'number' ? newHeights.toFixed(2) : newHeights}</p></div>
          </div>
          <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
        </div>

      </div>

      <div style={{ width: '30px', float: 'left', paddingLeft: '0px', textAlign: 'left' }}>
      <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${boxHeight * screen}vw)`, top: '0', position: 'relative' }}>
       <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
       <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
         <div style={{ marginLeft: '-10px', marginTop: boxHeight * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof boxHeight === 'number' ? boxHeight.toFixed(2) : boxHeight}</p></div>
       </div>
       <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
      </div>
    </div>
    </>
)
) : (
newHeights === 0 ? (

  <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
    <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${boxHeight * screen}vw)`, top: '0', position: 'relative' }}>
     <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
     <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
       <div style={{ marginLeft: '-10px', marginTop: boxHeight * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof boxHeight === 'number' ? boxHeight.toFixed(2) : boxHeight}</p></div>
     </div>
     <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
    </div>
  </div>
) : (  
    <>
      <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
        <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${newHeights * screen}vw + 10px)`, top: '0', position: 'relative' }}>
          <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
          <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
            <div style={{ marginLeft: '-10px', marginTop: newHeights * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof newHeights === 'number' ? newHeights.toFixed(2) : newHeights}</p></div>
          </div>
          <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
        </div>
        <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${(boxHeight-newHeights) * screen}vw - 20px)`, top: `calc(${newHeights * screen}vw + 20px)`, position: 'relative' }}>
          <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
          <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
            <div style={{ marginLeft: '-10px', marginTop: (boxHeight-newHeights) * ((screen/2)-(screen/8))+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof (boxHeight-newHeights) === 'number' ? (boxHeight-newHeights).toFixed(2) : (boxHeight-newHeights)}</p></div>
          </div>
          <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
        </div>
      </div>
      <div style={{ width: '30px', float: 'left', paddingLeft: '0px', textAlign: 'left' }}>
      <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${boxHeight * screen}vw)`, top: '0', position: 'relative' }}>
       <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
       <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
         <div style={{ marginLeft: '-10px', marginTop: boxHeight * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof boxHeight === 'number' ? boxHeight.toFixed(2) : boxHeight}</p></div>
       </div>
       <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
      </div>
    </div>
    </>
)
)}
  
</div>
              
            </div>
          </div>
        </div>

{ numSecondInnerBoxes === 0 ? (<div></div>):( 
        <>
        <div class="col-md-12 col-xxl-2 col-xl-2">
          <div class="card">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1 ms-3">
                  <h6 class="mb-0">Area</h6>
                </div>
              </div>
              <div class="bg-body p-3 mt-3 rounded">
                <div class="mt-3 row align-items-center">
                  <div class="col-12">
                    <h6 class="mb-1">{frameArea} sqft</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 col-xxl-2 col-xl-2">
          <div class="card">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1 ms-3">
                  <h6 class="mb-0">Shutters</h6>
                </div>
              </div>
              <div class="bg-body p-3 mt-3 rounded">
                <div class="mt-3 row align-items-center">
                  <div class="col-12">
                    <h6 class="mb-1">{shutterCount*numFrames}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 col-xxl-2 col-xl-2">
          <div class="card">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1 ms-3">
                  <h6 class="mb-0">Fixed</h6>
                </div>
              </div>
              <div class="bg-body p-3 mt-3 rounded">
                <div class="mt-3 row align-items-center">
                  <div class="col-12">
                    <h6 class="mb-1">{fixedShutters}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 col-xxl-2 col-xl-2">
          <div class="card">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1 ms-3">
                  <h6 class="mb-0">Mullions</h6>
                </div>
              </div>
              <div class="bg-body p-3 mt-3 rounded">
                <div class="mt-3 row align-items-center">
                  <div class="col-12">
                    <h6 class="mb-1">{newHeights === 0 ? 0 : 1 }</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 col-xxl-2 col-xl-2">
          <div class="card">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1 ms-3">
                  <h6 class="mb-0">Total</h6>
                </div>
              </div>
              <div class="bg-body p-3 mt-3 rounded">
                <div class="mt-3 row align-items-center">
                  <div class="col-12">
                    <h6 class="mb-1">₹ {numeral(grandTotal).format('0,0.00')}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 col-xxl-2 col-xl-2">
          <div class="card">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1 ms-3">
                  <h6 class="mb-0">Rate/Sqft</h6>
                </div>
              </div>
              <div class="bg-body p-3 mt-3 rounded">
                <div class="mt-3 row align-items-center">
                  <div class="col-12">
                    <h6 class="mb-1">₹ {numeral(grandTotal/frameArea).format('0,0.00')}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>




        <div class="col-md-12 col-xxl-12 col-xl-12">
          <div class="card">
            <div class="card-header">
              <h5>Profile Cost</h5>
            </div>
            <div class="card-body">
            <table id="left-right-fix" class="table stripe row-border order-column">
                <thead>
                  <tr>
                    <th>Product Description</th>
                    <th width="10%">Req. Qty (Feet)</th>
                    <th width="10%">Kg/Feet</th>
                    <th width="10%">Req. Qty (Kg)</th>
                    <th width="10%">Rate/Kg</th>
                    <th width="10%">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Track Outer Frame</td>
                    <td>{numeral(Frame()).format('0,0.00')}</td>
                    <td>{numeral(values.frame.wpf).format('0,0.00')}</td>
                    <td>{numeral(values.frame.rw).format('0,0.00')}</td>
                    <td>{numeral(values.frame.rpf).format('0,0.00')}</td>
                    <td>{numeral(values.frame.amount).format('0,0.00')}</td>
                  </tr>
                  <tr>
            <td>Shutter Frame</td>
            <td>{numeral(Shutter()).format('0,0.00')}</td>
            <td>{numeral(values.shutter.wpf).format('0,0.00')}</td>
            <td>{numeral(values.shutter.rw).format('0,0.00')}</td>
            <td>{numeral(values.shutter.rpf).format('0,0.00')}</td>
            <td>{numeral(values.shutter.amount).format('0,0.00')}</td>
          </tr>
          <tr>
            <td>InterLock</td>
            <td>{numeral(interLock()).format('0,0.00')}</td>
            <td>{numeral(values.interLock.wpf).format('0,0.00')}</td>
            <td>{numeral(values.interLock.rw).format('0,0.00')}</td>
            <td>{numeral(values.interLock.rpf).format('0,0.00')}</td>
            <td>{numeral(values.interLock.amount).format('0,0.00')}</td>
          </tr>
          <tr>
            <td>Beeding</td>
            <td>{numeral(Beeding()).format('0,0.00')}</td>
            <td>{numeral(values.beeding.wpf).format('0,0.00')}</td>
            <td>{numeral(values.beeding.rw).format('0,0.00')}</td>
            <td>{numeral(values.beeding.rpf).format('0,0.00')}</td>
            <td>{numeral(values.beeding.amount).format('0,0.00')}</td>
          </tr>
          <tr>
            <td>Frame Gasket</td>
            <td>{numeral(frameGasket()).format('0,0.00')}</td>
            <td>{numeral(values.frameGasket.wpf).format('0,0.00')}</td>
            <td>{numeral(values.frameGasket.rw).format('0,0.00')}</td>
            <td>{numeral(values.frameGasket.rpf).format('0,0.00')}</td>
            <td>{numeral(values.frameGasket.amount).format('0,0.00')}</td>
          </tr>
          <tr>
            <td>Shutter Gasket</td>
            <td>{numeral(shutterGasket()).format('0,0.00')}</td>
            <td>{numeral(values.shutterGasket.wpf).format('0,0.00')}</td>
            <td>{numeral(values.shutterGasket.rw).format('0,0.00')}</td>
            <td>{numeral(values.shutterGasket.rpf).format('0,0.00')}</td>
            <td>{numeral(values.shutterGasket.amount).format('0,0.00')}</td>
          </tr>
          <tr>
            <td>Beeding Gasket</td>
            <td>{numeral(beedingGasket()).format('0,0.00')}</td>
            <td>{numeral(values.beedingGasket.wpf).format('0,0.00')}</td>
            <td>{numeral(values.beedingGasket.rw).format('0,0.00')}</td>
            <td>{numeral(values.beedingGasket.rpf).format('0,0.00')}</td>
            <td>{numeral(values.beedingGasket.amount).format('0,0.00')}</td>
          </tr>

          {panelType === 1 &&(
            <tr>
            <td>Panel</td>
            <td>{numeral(Panel()).format('0,0.00')}</td>
            <td>{numeral(values.panel.wpf).format('0,0.00')}</td>
            <td>{numeral(values.panel.rw).format('0,0.00')}</td>
            <td>{numeral(values.panel.rpf).format('0,0.00')}</td>
            <td>{numeral(values.panel.amount).format('0,0.00')}</td>
          </tr>
          )}

          { numFrames === 1 ? (<></>):(
          <tr>
            <td>Cupler Joint/Square Joint </td>
            <td>{numeral(cuplerJoint()).format('0,0.00')}</td>
            <td>{numeral(values.cupler.wpf).format('0,0.00')}</td>
            <td>{numeral(values.cupler.rw).format('0,0.00')}</td>
            <td>{numeral(values.cupler.rpf).format('0,0.00')}</td>
            <td>{numeral(values.cupler.amount).format('0,0.00')}</td>
          </tr>
          )}
         
          <tr>
            <td colspan="5">Total</td>
            <td>{numeral(frameTotal).format('0,0.00')}</td>
          </tr>
          <tr>
            <th colspan="5">Wastage ( {data.wastage}% )</th>
            <th>{numeral(frameTotal+wastage).format('0,0.00')}</th>
          </tr>
          <tr>
            <th colspan="5">Profile Cost/Sqft</th>
            <th>{numeral((frameTotal+wastage) / frameArea).format('0,0.00')}</th>
          </tr>
                </tbody>
              </table>
          </div>   
          <hr />
              <div class="card-header">
              <h5>Hardware Cost</h5>
            </div>
            <div class="card-body">
            <table id="left-right-fix" class="table stripe row-border order-column">
                <thead>
                  <tr>
                    <th>Product Description</th>
                    <th width="10%">Req. Qty </th>
                    <th width="10%">Price</th>
                    <th width="10%">Amount</th>
                  </tr>
                </thead>
                <tbody>
          <tr>
            <td>Steel Frame</td>
            <td>{numeral(values.steelFrame.qty).format('0,0.00')}</td>
            <td>{numeral(values.steelFrame.price).format('0,0.00')}</td>
            <td>{numeral(values.steelFrame.amount).format('0,0.00')}</td>
          </tr>
          <tr>
            <td>Steel Shutter</td>
            <td>{numeral(values.steelShutter.qty).format('0,0.00')}</td>
            <td>{numeral(values.steelShutter.price).format('0,0.00')}</td>
            <td>{numeral(values.steelShutter.amount).format('0,0.00')}</td>
          </tr>
          <tr>
            <td>Aluminium Track</td>
            <td>{numeral(values.alTrack.qty).format('0,0.00')}</td>
            <td>{numeral(values.alTrack.price).format('0,0.00')}</td>
            <td>{numeral(values.alTrack.amount).format('0,0.00')}</td>
          </tr>
          <tr>
            <td>Bump Arrester</td>
            <td>{numeral(values.bArrester.qty).format('0,0.00')}</td>
            <td>{numeral(values.bArrester.price).format('0,0.00')}</td>
            <td>{numeral(values.bArrester.amount).format('0,0.00')}</td>
          </tr>
          <tr>
            <td>Jump Stopper</td>
            <td>{numeral(values.jStopper.qty).format('0,0.00')}</td>
            <td>{numeral(values.jStopper.price).format('0,0.00')}</td>
            <td>{numeral(values.jStopper.amount).format('0,0.00')}</td>
          </tr>
          <tr>
            <td>Dust Arrester</td>
            <td>{numeral(values.dArrester.qty).format('0,0.00')}</td>
            <td>{numeral(values.dArrester.price).format('0,0.00')}</td>
            <td>{numeral(values.dArrester.amount).format('0,0.00')}</td>
          </tr>
          <tr>
            <td>Grove Roller</td>
            <td>{numeral(values.gRoller.qty).format('0,0.00')}</td>
            <td>{numeral(values.gRoller.price).format('0,0.00')}</td>
            <td>{numeral(values.gRoller.amount).format('0,0.00')}</td>
          </tr>
          <tr>
            <td>Touch Lock</td>
            <td>{numeral(values.touchLock.qty).format('0,0.00')}</td>
            <td>{numeral(values.touchLock.price).format('0,0.00')}</td>
            <td>{numeral(values.touchLock.amount).format('0,0.00')}</td>
          </tr>
          <tr>
            <td>Screws/Misc</td>
            <td>{numeral(values.screws.qty).format('0,0.00')}</td>
            <td>{numeral(values.screws.price).format('0,0.00')}</td>
            <td>{numeral(values.screws.amount).format('0,0.00')}</td>
          </tr>
          <tr>
            <th colspan="3">Total</th>
            <th>{numeral(hardTotal).format('0,0.00')}</th>
          </tr>
          <tr>
            <th colspan="3">Hardware Cost/Sqft</th>
            <th>{numeral(hardTotal/frameArea).format('0,0.00')}</th>
          </tr>
                  </tbody>
              </table>
            </div>
            <hr />
          
            <div class="card-header ">
              <h5>Margin Cost</h5>
            </div>
            <div class="card-body">
            <table id="left-right-fix" class="table stripe row-border order-column">
                <thead>
                  <tr>
                    <th>Product Description</th>
                    <th width="10%">Req. Qty </th>
                    <th width="10%">Price</th>
                    <th width="10%">Amount</th>
                  </tr>
                </thead>
                <tbody>
                <tr>
            <td>Margin</td>
            <td>{numeral(values.margin.qty).format('0,0.00')}</td>
            <td>{numeral(values.margin.price).format('0,0.00')}</td>
            <td>{numeral(values.margin.amount).format('0,0.00')}</td>
          </tr>
          <tr>
            <th colspan="3">Total</th>
            <th>{numeral(marginTotal).format('0,0.00')}</th>
          </tr>
          <tr>
            <th colspan="3">Margin Cost/Sqft</th>
            <th>{numeral(marginTotal/frameArea).format('0,0.00')}</th>
          </tr>
          </tbody>
              </table>
            </div>
            <hr />

            <div class="card-header">
              <h5>Production Cost</h5>
            </div>
            <div class="card-body">
            <table id="left-right-fix" class="table stripe row-border order-column">
                <thead>
                  <tr>
                    <th>Product Description</th>
                    <th width="10%">Req. Qty </th>
                    <th width="10%">Price</th>
                    <th width="10%">Amount</th>
                  </tr>
                </thead>
                <tbody>
                <tr>
            <td>Markting OH</td>
            <td>{numeral(values.marketing.qty).format('0,0.00')}</td>
            <td>{numeral(values.marketing.price).format('0,0.00')}</td>
            <td>{numeral(values.marketing.amount).format('0,0.00')}</td>
          </tr>
          <tr>
            <td>Transport</td>
            <td>{numeral(values.transport.qty).format('0,0.00')}</td>
            <td>{numeral(values.transport.price).format('0,0.00')}</td>
            <td>{numeral(values.transport.amount).format('0,0.00')}</td>
          </tr>
          <tr>
            <td>Labour (Factory)</td>
            <td>{numeral(values.labourF.qty).format('0,0.00')}</td>
            <td>{numeral(values.labourF.price).format('0,0.00')}</td>
            <td>{numeral(values.labourF.amount).format('0,0.00')}</td>
          </tr>
          <tr>
            <td>Labour (Site)</td>
            <td>{numeral(values.labourS.qty).format('0,0.00')}</td>
            <td>{numeral(values.labourS.price).format('0,0.00')}</td>
            <td>{numeral(values.labourS.amount).format('0,0.00')}</td>
          </tr>
          {panelType === 2 &&(
            <tr>
              <td>Board</td>
              <td>{numeral(values.board.qty).format('0,0.00')}</td>
              <td>{numeral(values.board.price).format('0,0.00')}</td>
              <td>{numeral(values.board.amount).format('0,0.00')}</td>
            </tr>
          )}
          {panelType === 3 &&(
            <tr>
              <td>Glass</td>
              <td>{numeral(values.glass.qty).format('0,0.00')}</td>
              <td>{numeral(values.glass.price).format('0,0.00')}</td>
              <td>{numeral(values.glass.amount).format('0,0.00')}</td>
            </tr>
          )}
          <tr>
            <th colspan="3">Total</th>
            <th>{numeral(prodTotal).format('0,0.00')}</th>
          </tr>
          <tr>
            <th colspan="3">Production Cost/Sqft</th>
            <th>{numeral( prodTotal/frameArea).format('0,0.00')}</th>
          </tr>

          </tbody>
              </table>
            </div>


          </div>
        </div>
      
    

        
        </>
)}

      
      </div>
    </div>
  </div>


    )}


{item === 'Ventilator' && (
      <div class="pc-container">
      <div class="pc-content">
       
        <div class="row">
        
          
           
         


          <div class="col-md-12 col-xxl-12 col-xl-12">
            <div class="card" style={{background:'#fff'}}>
              <div class="card-body mb-5">
           {boxHeight > 1 && boxWidth > 1 && (  
            <>

            <div style={{float:'left', width: boxWidth*screen+'vw', height:boxHeight*screen+'vw', border:'1px solid #333'}}>
           
                {fanTop === 'Top' && fanLeft === 'Left' && divider === 'Vertical' && switchs === 'on' && (
                  <div style={{float:'left', height: `calc(${boxHeight * screen}vw - 10px)`, width: `calc(${boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                    <img src={Fan} style={{float:'left', width: .85*screen+'vw', marginLeft: '4px', marginTop: '4px'}} />
                    <div style={{float:'left', height: `calc(${(boxHeight) * screen}vw - 20px)`, width: `calc(${(boxWidth-.85) * screen}vw - 26px)`, margin: '5px', border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(6/boxHeight)*screen}%, lightblue ${(6/boxHeight)*screen}%)`}}></div>
                    <div style={{float:'left', height: `calc(${(boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(.85) * screen}vw - 0px)`, margin: '4px', marginTop: `calc(-${(boxHeight-.85) * screen}vw + 20px)`, border:'1px solid #333', background: 'lightblue'}}></div>
                  </div>
                )}
                
                {fanTop === 'Bottom' && fanLeft === 'Left'  && divider === 'Vertical' && switchs === 'on' && (
                    <div style={{float:'left', height: `calc(${boxHeight * screen}vw - 10px)`, width: `calc(${boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                      <div style={{float:'left', height: `calc(${(boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(.85) * screen}vw - 0px)`, margin: '4px', marginTop: '5px', border:'1px solid #333', background: 'lightblue'}}></div>
                      <div style={{float:'right', height: `calc(${(boxHeight) * screen}vw - 20px)`, width: `calc(${(boxWidth-.85) * screen}vw - 26px)`, margin: '5px', marginTop: `calc(-${(boxHeight-.85) * screen}vw + 20px)`, border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(6/boxHeight)*screen}%, lightblue ${(6/boxHeight)*screen}%)`}}></div>
                      <img src={Fan} style={{float:'left', width: .85*screen+'vw', marginLeft: '4px', marginBottom: '4px'}} />
                    </div>
                )}

                {fanTop === 'Top' && fanLeft === 'Right' &&  divider === 'Vertical' && switchs === 'on' && (
                  <div style={{float:'left', height: `calc(${boxHeight * screen}vw - 10px)`, width: `calc(${boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                    <img src={Fan} style={{float:'right', width: .85*screen+'vw', marginRight: '4px', marginTop: '4px'}} />
                    <div style={{float:'left', height: `calc(${(boxHeight) * screen}vw - 20px)`, width: `calc(${(boxWidth-.85) * screen}vw - 26px)`, margin: '5px', border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(6/boxHeight)*screen}%, lightblue ${(6/boxHeight)*screen}%)`}}></div>
                    <div style={{float:'right', height: `calc(${(boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(.85) * screen}vw - 0px)`, margin: '4px', marginTop: `calc(-${(boxHeight-.85) * screen}vw + 20px)`, border:'1px solid #333', background: 'lightblue'}}></div>
                  </div>
                )}

                {fanTop === 'Bottom' && fanLeft === 'Right' &&  divider === 'Vertical' && switchs === 'on' && (
                  <div style={{float:'left', height: `calc(${boxHeight * screen}vw - 10px)`, width: `calc(${boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                    <div style={{float:'right', height: `calc(${(boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(.85) * screen}vw - 0px)`, margin: '4px', marginTop: '5px', border:'1px solid #333', background: 'lightblue'}}></div>
                    <div style={{float:'left', height: `calc(${(boxHeight) * screen}vw - 20px)`, width: `calc(${(boxWidth-.85) * screen}vw - 26px)`, margin: '5px', marginTop: `calc(-${(boxHeight-.85) * screen}vw + 20px)`, border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(6/boxHeight)*screen}%, lightblue ${(6/boxHeight)*screen}%)`}}></div>
                    <img src={Fan} style={{float:'right', width: .85*screen+'vw', marginRight: '4px', marginBottom: '4px'}} />
                  </div>
                )}

                {fanTop === 'Top' && fanLeft === 'Left' &&  divider === 'Vertical' && switchs === 'off' && (
                  <div style={{float:'left', height: `calc(${boxHeight * screen}vw - 10px)`, width: `calc(${boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                    <img src={Fan} style={{float:'left', width: .85*screen+'vw', marginLeft: '4px', marginTop: '4px'}} />
                    <div style={{float:'left', height: `calc(${(boxHeight) * screen}vw - 20px)`, width: `calc(${(boxWidth-.85) * screen}vw - 26px)`, margin: '5px', border:'1px solid #333', background: 'lightblue'}}></div>
                    <div style={{float:'left', height: `calc(${(boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(.85) * screen}vw - 0px)`, margin: '4px', marginTop: `calc(-${(boxHeight-.85) * screen}vw + 20px)`, border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/boxHeight)*screen}%, lightblue ${(10/boxHeight)*screen}%)`}}></div>
                  </div>
                )}
                
                {fanTop === 'Bottom' && fanLeft === 'Left' && divider === 'Vertical' && switchs === 'off' && (
                    <div style={{float:'left', height: `calc(${boxHeight * screen}vw - 10px)`, width: `calc(${boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                      <div style={{float:'left', height: `calc(${(boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(.85) * screen}vw - 0px)`, margin: '4px', marginTop: '5px', border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/boxHeight)*screen}%, lightblue ${(10/boxHeight)*screen}%)`}}></div>
                      <div style={{float:'right', height: `calc(${(boxHeight) * screen}vw - 20px)`, width: `calc(${(boxWidth-.85) * screen}vw - 26px)`, margin: '5px', marginTop: `calc(-${(boxHeight-.85) * screen}vw + 20px)`, border:'1px solid #333', background: 'lightblue'}}></div>
                      <img src={Fan} style={{float:'left', width: .85*screen+'vw', marginLeft: '4px', marginBottom: '4px'}} />
                    </div>
                )}

                {fanTop === 'Top' && fanLeft === 'Right' &&  divider === 'Vertical' && switchs === 'off' && (
                  <div style={{float:'left', height: `calc(${boxHeight * screen}vw - 10px)`, width: `calc(${boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                    <img src={Fan} style={{float:'right', width: .85*screen+'vw', marginRight: '4px', marginTop: '4px'}} />
                    <div style={{float:'left', height: `calc(${(boxHeight) * screen}vw - 20px)`, width: `calc(${(boxWidth-.85) * screen}vw - 26px)`, margin: '5px', border:'1px solid #333', background: 'lightblue'}}></div>
                    <div style={{float:'right', height: `calc(${(boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(.85) * screen}vw - 0px)`, margin: '4px', marginTop: `calc(-${(boxHeight-.85) * screen}vw + 20px)`, border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/boxHeight)*screen}%, lightblue ${(10/boxHeight)*screen}%)`}}></div>
                  </div>
                )}

                {fanTop === 'Bottom' && fanLeft === 'Right'  && divider === 'Vertical' && switchs === 'off' && (
                  <div style={{float:'left', height: `calc(${boxHeight * screen}vw - 10px)`, width: `calc(${boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                    <div style={{float:'right', height: `calc(${(boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(.85) * screen}vw - 0px)`, margin: '4px', marginTop: '5px', border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/boxHeight)*screen}%, lightblue ${(10/boxHeight)*screen}%)`}}></div>
                    <div style={{float:'left', height: `calc(${(boxHeight) * screen}vw - 20px)`, width: `calc(${(boxWidth-.85) * screen}vw - 26px)`, margin: '5px', marginTop: `calc(-${(boxHeight-.85) * screen}vw + 20px)`, border:'1px solid #333', background: 'lightblue'}}></div>
                    <img src={Fan} style={{float:'right', width: .85*screen+'vw', marginRight: '4px', marginBottom: '4px'}} />
                  </div>
                )}
                
                {fanTop === 'Top' && fanLeft === 'Left' && divider === 'Horizontal' && switchs === 'on' && (
                  <div style={{float:'left', height: `calc(${boxHeight * screen}vw - 10px)`, width: `calc(${boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                    <img src={Fan} style={{float:'left', width: .85*screen+'vw', marginLeft: '4px', marginTop: '4px'}} />
                    <div style={{float:'right', height: `calc(${(.85) * screen}vw - 0px)`, width: `calc(${(boxWidth-.85) * screen}vw - 26px)`, margin: '5px',  border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/boxHeight)*screen}%, lightblue ${(10/boxHeight)*screen}%)`}}></div>
                    <div style={{float:'left', height: `calc(${(boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(boxWidth) * screen}vw - 20px)`, margin: '4px', marginTop: '0px', border:'1px solid #333', background: 'lightblue'}}></div>
                  </div>
                )}
                
                {fanTop === 'Bottom' && fanLeft === 'Left'  && divider === 'Horizontal' && switchs === 'on' && (
                  <div style={{float:'left', height: `calc(${boxHeight * screen}vw - 10px)`, width: `calc(${boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                    <div style={{float:'left', height: `calc(${(boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(boxWidth) * screen}vw - 20px)`, margin: '4px', marginTop: '5px', border:'1px solid #333', background: 'lightblue'}}></div>
                    <div style={{float:'right', height: `calc(${(.85) * screen}vw - 0px)`, width: `calc(${(boxWidth-.85) * screen}vw - 26px)`, marginRight: '5px', marginBottom: '4px',  border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/boxHeight)*screen}%, lightblue ${(10/boxHeight)*screen}%)`}}></div>
                    <img src={Fan} style={{float:'left', width: .85*screen+'vw', marginLeft: '4px', marginBottom: '4px'}} />
                  </div>
                )}
                
                {fanTop === 'Top' && fanLeft === 'Right'  && divider === 'Horizontal' && switchs === 'on' && (
                  <div style={{float:'left', height: `calc(${boxHeight * screen}vw - 10px)`, width: `calc(${boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                    <img src={Fan} style={{float:'right', width: .85*screen+'vw', marginRight: '4px', marginTop: '4px'}} />
                    <div style={{float:'left', height: `calc(${(.85) * screen}vw - 0px)`, width: `calc(${(boxWidth-.85) * screen}vw - 26px)`, margin: '5px',  border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/boxHeight)*screen}%, lightblue ${(10/boxHeight)*screen}%)`}}></div>
                    <div style={{float:'left', height: `calc(${(boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(boxWidth) * screen}vw - 20px)`, margin: '4px', marginTop: '0px', border:'1px solid #333', background: 'lightblue'}}></div>
                  </div>
                )}
                
                {fanTop === 'Bottom' && fanLeft === 'Right'  && divider === 'Horizontal' && switchs === 'on' && (
                  <div style={{float:'left', height: `calc(${boxHeight * screen}vw - 10px)`, width: `calc(${boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                    <div style={{float:'left', height: `calc(${(boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(boxWidth) * screen}vw - 20px)`, margin: '4px', marginTop: '5px', border:'1px solid #333', background: 'lightblue'}}></div>
                    <div style={{float:'left', height: `calc(${(.85) * screen}vw - 0px)`, width: `calc(${(boxWidth-.85) * screen}vw - 26px)`, marginLeft: '5px', marginBottom: '4px',  border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/boxHeight)*screen}%, lightblue ${(10/boxHeight)*screen}%)`}}></div>
                    <img src={Fan} style={{float:'right', width: .85*screen+'vw', marginRight: '4px', marginBottom: '4px'}} />
                  </div>
                )}

                {fanTop === 'Top' && fanLeft === 'Left'  && divider === 'Horizontal' && switchs === 'off' && (
                  <div style={{float:'left', height: `calc(${boxHeight * screen}vw - 10px)`, width: `calc(${boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                    <img src={Fan} style={{float:'left', width: .85*screen+'vw', marginLeft: '4px', marginTop: '4px'}} />
                    <div style={{float:'right', height: `calc(${(.85) * screen}vw - 0px)`, width: `calc(${(boxWidth-.85) * screen}vw - 26px)`, margin: '5px',  border:'1px solid #333', background: 'lightblue' }}></div>
                    <div style={{float:'left', height: `calc(${(boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(boxWidth) * screen}vw - 20px)`, margin: '4px', marginTop: '0px', border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/boxHeight)*screen}%, lightblue ${(10/boxHeight)*screen}%)`}}></div>
                  </div>
                )}
                
                {fanTop === 'Top' && fanLeft === 'Right'  && divider === 'Horizontal' && switchs === 'off' && (
                  <div style={{float:'left', height: `calc(${boxHeight * screen}vw - 10px)`, width: `calc(${boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                    <img src={Fan} style={{float:'right', width: .85*screen+'vw', marginRight: '4px', marginTop: '4px'}} />
                    <div style={{float:'left', height: `calc(${(.85) * screen}vw - 0px)`, width: `calc(${(boxWidth-.85) * screen}vw - 26px)`, margin: '5px',  border:'1px solid #333', background: 'lightblue' }}></div>
                    <div style={{float:'left', height: `calc(${(boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(boxWidth) * screen}vw - 20px)`, margin: '4px', marginTop: '0px', border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/boxHeight)*screen}%, lightblue ${(10/boxHeight)*screen}%)`}}></div>
                  </div>
                )}
                
                {fanTop === 'Bottom' && fanLeft === 'Right'  && divider === 'Horizontal' && switchs === 'off' && (
                  <div style={{float:'left', height: `calc(${boxHeight * screen}vw - 10px)`, width: `calc(${boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                    <div style={{float:'left', height: `calc(${(boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(boxWidth) * screen}vw - 20px)`, margin: '4px', marginTop: '5px', border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/boxHeight)*screen}%, lightblue ${(10/boxHeight)*screen}%)`}}></div>
                    <img src={Fan} style={{float:'right', width: .85*screen+'vw', marginRight: '4px', marginTop: '0px'}} />
                    <div style={{float:'left', height: `calc(${(.85) * screen}vw - 0px)`, width: `calc(${(boxWidth-.85) * screen}vw - 26px)`, marginLeft: '4px',  border:'1px solid #333', background: 'lightblue' }}></div>
                  </div>
                )}

                {fanTop === 'Bottom' && fanLeft === 'Left'  && divider === 'Horizontal' && switchs === 'off' && (
                  <div style={{float:'left', height: `calc(${boxHeight * screen}vw - 10px)`, width: `calc(${boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                  <div style={{float:'left', height: `calc(${(boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(boxWidth) * screen}vw - 20px)`, margin: '4px', marginTop: '5px', border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/boxHeight)*screen}%, lightblue ${(10/boxHeight)*screen}%)`}}></div>
                    <img src={Fan} style={{float:'left', width: .85*screen+'vw', marginLeft: '4px', marginRight: '4px'}} />
                    <div style={{float:'right', height: `calc(${(.85) * screen}vw - 0px)`, width: `calc(${(boxWidth-.85) * screen}vw - 26px)`, marginRight: '5px',  border:'1px solid #333', background: 'lightblue' }}></div>
                  </div>
                )}

            </div>
                
            </>
            
          )} 

          {boxHeight == 1 && boxWidth > 1 && (
            <>
           {switchFixed === 'on' && (  
            
              <div style={{float:'left', width: boxWidth*screen+'vw', height:boxHeight*screen+'vw', border:'1px solid #333'}}>
                <div style={{float:'left', height: `calc(${boxHeight * screen}vw - 10px)`, width: `calc(${boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                  <img src={Fan} style={{float:'left', width: .75*screen+'vw', margin: '4px', }} />
                  <div style={{float: 'right', height: `calc(${(boxHeight)*screen}vw - 22px)`, width: `calc(${(boxWidth- .94)*screen}vw - 10px)`, marginRight: '5px', marginTop: '5px', background:'lightblue', border:'1px solid #333'}}></div>
                </div>
              </div>
            )}

            {switchFixed === 'off' && (  
            
            <div style={{float:'left', width: boxWidth*screen+'vw', height:boxHeight*screen+'vw', border:'1px solid #333'}}>
              <div style={{float:'left', height: `calc(${boxHeight * screen}vw - 10px)`, width: `calc(${boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                <img src={Fan} style={{float:'left', width: .75*screen+'vw', margin: '4px', }} />
                <div style={{float: 'right', height: `calc(${(boxHeight)*screen}vw - 22px)`, width: `calc(${(boxWidth- .94)*screen}vw - 10px)`, marginRight: '5px', marginTop: '5px',  backgroundImage: `repeating-linear-gradient(lightblue, white ${(6/boxHeight)*screen}%, lightblue ${(6/boxHeight)*screen}%)`, border:'1px solid #333'}}></div>
              </div>
            </div>
          )}
            </>
          )}

          {boxHeight > 1 && boxWidth == 1 && (
            <>
             {switchFixed === 'on' && (  
              <div style={{float:'left', width: boxWidth*screen+'vw', height:boxHeight*screen+'vw', border:'1px solid #333'}}>
                  <div style={{float:'left', height: `calc(${boxHeight * screen}vw - 10px)`, width: `calc(${boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                  <img src={Fan} style={{float:'left', width: .75*screen+'vw', margin: '4px', }} />
                  <div style={{float: 'right', height: `calc(${(boxHeight-.75)*screen}vw - 25px)`, width: `calc(${(boxWidth)*screen}vw - 20px)`, marginRight: '5px', background:'lightblue', border:'1px solid #333'}}></div>
                </div>
              </div>
             )}
             
             {switchFixed === 'off' && (
              <div style={{float:'left', width: boxWidth*screen+'vw', height:boxHeight*screen+'vw', border:'1px solid #333'}}>
                <div style={{float:'left', height: `calc(${boxHeight * screen}vw - 10px)`, width: `calc(${boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                <img src={Fan} style={{float:'left', width: .75*screen+'vw', margin: '4px', }} />
                <div style={{float: 'right', height: `calc(${(boxHeight-.75)*screen}vw - 25px)`, width: `calc(${(boxWidth)*screen}vw - 20px)`, marginRight: '5px',  backgroundImage: `repeating-linear-gradient(lightblue, white ${(6/boxHeight)*screen}%, lightblue ${(6/boxHeight)*screen}%)`, border:'1px solid #333'}}></div>
                </div>
              </div>
             )}
            </>
          )}
          
          {(boxWidth > 1 || boxHeight > 1) && (
            <>
              <div className="expanding-arrow-line" style={{ 
                  position: 'absolute',
                  float:'left', 
                  width: `calc(${boxWidth * screen}vw )`,
                  bottom:'-10px', 
                  top: `calc(${boxHeight* screen}vw - 25px)`,
                  }}> 
                <div className="arrow-left"></div>
                  <div className="line" align="center">
                    <div style={{marginTop:'-7px',background:'white',maxWidth:'20px',textAlign:'center'}}><p>{typeof boxWidth === 'number' ? boxWidth.toFixed(2) : boxWidth}</p></div>
                  </div>
                  <div className="arrow-right"></div>
                </div>

            <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
              <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${boxHeight * screen}vw)`, top: '0', position: 'relative' }}>
                <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                  <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                    <div style={{ marginLeft: '-10px', marginTop: boxHeight * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof boxHeight === 'number' ? boxHeight.toFixed(2) : boxHeight}</p></div>
                  </div>
                <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
              </div>
            </div>  
            </>
          )}


             </div>
            </div>
          </div>

        
          <div class="col-md-12 col-xxl-12">
            <div class="card">
              <div class="card-header">
                <h5>Profile Cost</h5>
              </div>
              <div class="card-body">
              <table id="left-right-fix" class="table stripe row-border order-column">
                  <thead>
                    <tr>
                      <th>Product Description</th>
                      <th width="10%">Req. Qty (Feet)</th>
                      <th width="10%">Kg/Feet</th>
                      <th width="10%">Req. Qty (Kg)</th>
                      <th width="10%">Rate/Kg</th>
                      <th width="10%">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Outer Frame</td>
                      <td>{numeral(Frame()).format('0,0.00')}</td>
                      <td>{numeral(values.frameCost.wpf).format('0,0.00')}</td>
                      <td>{numeral(values.frameCost.rw).format('0,0.00')}</td>
                      <td>{numeral(values.frameCost.rpf).format('0,0.00')}</td>
                      <td>{numeral(values.frameCost.amount).format('0,0.00')}</td>
                    </tr>
                    <tr>
                      <td>Mullion</td>
                      <td>{numeral(Mullion()).format('0,0.00')}</td>
                      <td>{numeral(values.mullionCost.wpf).format('0,0.00')}</td>
                      <td>{numeral(values.mullionCost.rw).format('0,0.00')}</td>
                      <td>{numeral(values.mullionCost.rpf).format('0,0.00')}</td>
                      <td>{numeral(values.mullionCost.amount).format('0,0.00')}</td>
                    </tr>
                    <tr>
                      <td colspan="5">Total</td>
                      <td>{numeral(frameTotal).format('0,0.00')}</td>
                    </tr>
                    <tr>
                      <th colspan="5">Wastage ( {data.wastage}% )</th>
                      <th>{numeral(frameTotal+wastage).format('0,0.00')}</th>
                    </tr>
                    <tr>
                      <th colspan="5">Profile Cost/Sqft</th>
                      <th>{numeral(frameTotal+wastage).format('0,0.00')}</th>
                    </tr>


                  </tbody>
                </table>
            </div>   
            <hr />

              <div class="card-header">
                <h5>Hardware Cost</h5>
              </div>
              <div class="card-body">
                <table id="left-right-fix" class="table stripe row-border order-column">
                  <thead>
                    <tr>
                      <th>Product Description</th>
                      <th width="10%">Req. Qty </th>
                      <th width="10%">Price</th>
                      <th width="10%">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                 
                  <tr>
                     <td>Screws</td>
                     <td>{numeral(values.screws.qty).format('0,0.00')}</td>
                     <td>{numeral(values.screws.price).format('0,0.00')}</td>
                     <td>{numeral(values.screws.amount).format('0,0.00')}</td>
                  </tr>
                  <tr>
                     <td>Glass</td>
                     <td>{numeral(values.glass.qty).format('0,0.00')}</td>
                     <td>{numeral(values.glass.price).format('0,0.00')}</td>
                     <td>{numeral(values.glass.amount).format('0,0.00')}</td>
                  </tr>
                  {(boxHeight > 1 && boxWidth > 1) || (switchFixed === 'off') ? (
                  <tr>
                     <td>Louver Blades</td>
                     <td>{numeral(values.LouverBlades.qty).format('0,0.00')}</td>
                     <td>{numeral(values.LouverBlades.price).format('0,0.00')}</td>
                     <td>{numeral(values.LouverBlades.amount).format('0,0.00')}</td>
                  </tr>
                  ):(<></>)}
                  <tr>
                    <th colspan="3">Total</th>
                    <th>{numeral(hardTotal).format('0,0.00')}</th>
                  </tr>
                  <tr>
                    <th colspan="3">Hardware Cost/Sqft</th>
                    <th>{numeral( hardTotal/frameArea).format('0,0.00')}</th>
                  </tr>
                  </tbody>
                </table>
              </div>
              <hr />

              <div class="card-header ">
                <h5>Margin Cost</h5>
              </div>
              <div class="card-body">
              <table id="left-right-fix" class="table stripe row-border order-column">
                  <thead>
                    <tr>
                      <th>Product Description</th>
                      <th width="10%">Req. Qty </th>
                      <th width="10%">Price</th>
                      <th width="10%">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr>
              <td>Margin</td>
              <td>{numeral(values.margin.qty).format('0,0.00')}</td>
              <td>{numeral(values.margin.price).format('0,0.00')}</td>
              <td>{numeral(values.margin.amount).format('0,0.00')}</td>
            </tr>
            <tr>
              <th colspan="3">Total</th>
              <th>{numeral(marginTotal).format('0,0.00')}</th>
            </tr>
            <tr>
              <th colspan="3">Margin Cost/Sqft</th>
              <th>{numeral(marginTotal/frameArea).format('0,0.00')}</th>
            </tr>
            </tbody>
                </table>
              </div>
              <hr />

              <div class="card-header">
                <h5>Production Cost</h5>
              </div>
              <div class="card-body">
              <table id="left-right-fix" class="table stripe row-border order-column">
                  <thead>
                    <tr>
                      <th>Product Description</th>
                      <th width="10%">Req. Qty </th>
                      <th width="10%">Price</th>
                      <th width="10%">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr>
              <td>Markting OH</td>
              <td>{numeral(values.marketing.qty).format('0,0.00')}</td>
              <td>{numeral(values.marketing.price).format('0,0.00')}</td>
              <td>{numeral(values.marketing.amount).format('0,0.00')}</td>
            </tr>
            <tr>
              <td>Transport</td>
              <td>{numeral(values.transport.qty).format('0,0.00')}</td>
              <td>{numeral(values.transport.price).format('0,0.00')}</td>
              <td>{numeral(values.transport.amount).format('0,0.00')}</td>
            </tr>
            <tr>
              <td>Labour (Factory)</td>
              <td>{numeral(values.labourF.qty).format('0,0.00')}</td>
              <td>{numeral(values.labourF.price).format('0,0.00')}</td>
              <td>{numeral(values.labourF.amount).format('0,0.00')}</td>
            </tr>
            <tr>
              <td>Labour (Site)</td>
              <td>{numeral(values.labourS.qty).format('0,0.00')}</td>
              <td>{numeral(values.labourS.price).format('0,0.00')}</td>
              <td>{numeral(values.labourS.amount).format('0,0.00')}</td>
            </tr>
            
            <tr>
              <th colspan="3">Total</th>
              <th>{numeral(prodTotal).format('0,0.00')}</th>
            </tr>
            <tr>
              <th colspan="3">Production Cost/Sqft</th>
              <th>{numeral(prodTotal/frameArea).format('0,0.00')}</th>
            </tr>

            </tbody>
                </table>
              </div>



          </div>
        </div>





        
        </div>
      </div>
    </div>

    
    )}

    </div>
    
  )
}
export default Quotees;
