import React, { useEffect, useState } from 'react';
import { ReactSession } from 'react-client-session';
ReactSession.setStoreType("localStorage");

const Navbar = () => {
  
  const userId = ReactSession.get("userId");
  const userType = ReactSession.get("userType");
  const userName = ReactSession.get("userName");
  const userImage = ReactSession.get("Image");

  const [isMobileSidebarActive, setIsMobileSidebarActive] = useState(false);
  const [SidebarActive, setSidebarActive] = useState(false);
  

  const sibeBar = () => {
    setSidebarActive(!SidebarActive);
  };

  const handleSidebarClick = () => {
    setIsMobileSidebarActive(!isMobileSidebarActive);
  };

  const savedTheme = ReactSession.get("theme") || 'light';
  const [theme, setTheme] = useState(savedTheme);

  useEffect(() => {
    document.body.setAttribute('data-pc-theme', theme);
    ReactSession.set("theme", theme); // Save the theme to the session
  }, [theme]);

  const toggleTheme = () => {
    setTheme(prevTheme => {
      const newTheme = prevTheme === 'dark' ? 'light' : 'dark';
      ReactSession.set("theme", newTheme); // Save the new theme to the session
      return newTheme;
    });
  };
    return (
<>
 <nav className={`${SidebarActive ? 'pc-sidebar-hide' : 'pc-sidebar'} ${isMobileSidebarActive ? 'mob-sidebar-active pc-sidebar' : 'pc-sidebar'}`}>
  <div className={`${isMobileSidebarActive ? 'pc-menu-overlay' : ''}`} >
  {isMobileSidebarActive && (
    <button onClick={handleSidebarClick} style={{float:'right', top: '10px', right: '10px', background: 'transparent', border: 'none', color: theme === 'dark' ? '#fff': '#000', fontSize: '24px', margin: '20px 20px 0px 0px', fontWeight: '600', zIndex: '999'}}>x</button>
  )}
    </div>
        <div class="navbar-wrapper">
           
            <div class="navbar-content">
                <div class="card pc-user-card">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div class="flex-shrink-0"><img src={`https://upvcapi.sninfoserv.com/user/images/${userImage}`} alt={userName} class="user-avtar wid-45 rounded-circle" /></div>
                            <div class="flex-grow-1 ms-3 me-2">
                                <h6 class="mb-0">{userName}</h6><small>{userType === '1' ? 'Administrator' : 'User'}</small></div>
                            <a class="btn btn-icon btn-link-secondary avtar" data-bs-toggle="collapse" href="#pc_sidebar_userlink">
                                <svg class="pc-icon">
                                    <use href="#custom-sort-outline"></use>
                                </svg>
                            </a>
                        </div>
                        <div class="collapse pc-user-links" id="pc_sidebar_userlink">
                            <div class="pt-3">
							  <a href="/account"><i class="ti ti-user"></i> <span>My Account</span> </a>
							  <a href="/settings"><i class="ti ti-settings"></i> <span>Settings</span> </a>
							  <a href="/logout"><i class="ti ti-power"></i> <span>Logout</span></a>
                            </div>
                        </div>
                    </div>
                </div>
                <ul class="pc-navbar">
        <li class="pc-item pc-caption">
          <label>Navigation</label>
        </li>
        <li class="pc-item">
          <a href="/dashboard" class="pc-link">
            <span class="pc-micon">
              <svg class="pc-icon">
                <use href="#custom-status-up"></use>
              </svg>
            </span>
            <span class="pc-mtext">Dashboard</span>
          </a>
        
        </li>

        <li class="pc-item">
          <a href="/create-quotation" class="pc-link">
            <span class="pc-micon">
              <svg class="pc-icon">
                <use href="#custom-document"></use>
              </svg>
            </span>
            <span class="pc-mtext">New Quotation</span>
          </a>
        </li>

        {userType === '1' && (
          <>
          <li class="pc-item">
            <a href="/default" class="pc-link">
              <span class="pc-micon">
              <svg class="pc-icon">
                <use href="#custom-document-filter"></use>
              </svg>
              </span>
              <span class="pc-mtext">Add Default Profiles</span>
            </a>
          </li> 

          <li class="pc-item">
            <a href="/add-user" class="pc-link">
              <span class="pc-micon">
              <svg class="pc-icon">
                <use href="#custom-user"></use>
              </svg>
              </span>
              <span class="pc-mtext">Add User</span>
            </a>
          </li>
          </>
        )}

      </ul>
            </div>
        </div>
    </nav>
   
    <header class="pc-header">
        <div class="header-wrapper">
            <div class="me-auto pc-mob-drp">
                <ul class="list-unstyled">
                    <li class="pc-h-item pc-sidebar-collapse"><a onClick={sibeBar} class="pc-head-link ms-0" id="sidebar-hide"><i class="ti ti-menu-2"></i></a></li>
                    <li class="pc-h-item pc-sidebar-popup"><a onClick={handleSidebarClick} class="pc-head-link ms-0" id="mobile-collapse"><i class="ti ti-menu-2"></i></a></li>
                   
                </ul>
            </div>
         
            <div class="ms-auto">
                <ul class="list-unstyled">
                    <li class="dropdown pc-h-item">
                        <a class="pc-head-link dropdown-toggle arrow-none me-0" onClick={toggleTheme}>
                            <svg class="pc-icon">
                            { theme === 'dark' ?( <use href="#custom-sun-1"></use>):( <use href="#custom-moon"></use>)}
                            </svg>
                        </a>
                    </li>
                   
                    <li class="pc-h-item">
                        <a href="#" class="pc-head-link me-0" data-bs-toggle="offcanvas" data-bs-target="#announcement" aria-controls="announcement">
                            <svg class="pc-icon">
                                <use href="#custom-flash"></use>
                            </svg>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </header>

<div class="offcanvas pc-announcement-offcanvas offcanvas-end" tabindex="-1" id="announcement" aria-labelledby="announcementLabel">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="announcementLabel">What's new announcement?</h5>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <p class="text-span">Today</p>
    <div class="card mb-3">
      <div class="card-body">
        <div class="align-items-center d-flex flex-wrap gap-2 mb-3">
          <div class="badge bg-light-success f-12">Meeting</div>
          <p class="mb-0 text-muted">2 min ago</p>
          <span class="badge dot bg-warning"></span>
        </div>
        <h5 class="mb-3">Meeting</h5>
        <p class="text-muted">UPVC Meeting arranged for discuss about marketing.</p>
        <img src="assets/images/layout/img-announcement-1.png" alt="img" class="img-fluid mb-3" />
        <div class="row">
          <div class="col-12">
            <div class="d-grid"
              ><a
                class="btn btn-outline-secondary"
                href="#"
                target="_blank"
                >Check Now</a ></div>
          </div>
        </div>
      </div>
    </div>
   

    <p class="text-span mt-4">Yesterday</p>
    <div class="card mb-3">
      <div class="card-body">
        <div class="align-items-center d-flex flex-wrap gap-2 mb-3">
          <div class="badge bg-light-primary f-12">Alert</div>
          <p class="mb-0 text-muted">12 hour ago</p>
          <span class="badge dot bg-warning"></span>
        </div>
        <h5 class="mb-3">New Member</h5>
        <p class="text-muted">New member Mr.NAME joined to  our family.</p>
        <img src="assets/images/layout/img-announcement-3.png" alt="img" class="img-fluid" />
      </div>
    </div>
    
  </div>
</div>

</>

);
};

export default Navbar;