import React, { useEffect, useState } from 'react';
import { ReactSession } from 'react-client-session';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-modal';
import axios from 'axios';
import Navbar from "./navbar";
import Header from "./header";
import './box.css';
ReactSession.setStoreType("localStorage");


const App = () => {

  const userId = ReactSession.get("userId");
  const userType = ReactSession.get("userType");
  const navigate = useNavigate();
  const indexPath = '/';
  const selectpath ='/select';
  const [values, setValues] = useState({ name: '', phone:'', location: "", engineer: '', require: '',}); 
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newEngineerData, setNewEngineerData] = useState({ name: '', phone: '', email: '', location: '',});
  const [engDetails, setengData] = useState(true);
  const user = { "userId": userId };

  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
    console.log(values); 
  };

  useEffect(() => {
    if (userId == 0 ) {
      navigate(indexPath);
    }
  }, [userId, navigate]);

  function generateUniqueId() {
    const now = new Date();
    const year = now.getFullYear().toString().padStart(4, '0');
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    const hour = now.getHours().toString().padStart(2, '0');
    const minute = now.getMinutes().toString().padStart(2, '0');
    const second = now.getSeconds().toString().padStart(2, '0');
    const id = `${userId}-${year}${month}${day}-${hour}${minute}${second}`;
    return id;
  }

  const handleSubmit = async (e) => {
    const uniqueId = generateUniqueId();
    ReactSession.set("quoteId",uniqueId);
    e.preventDefault();
    try {
      const response = await axios.post('https://upvcapi.sninfoserv.com/new-quotes/', { 
        uniqueId,userId,values
      });
     // console.log('Response:', response.data);
      if(response.data.status === 'success'){
        navigate(selectpath);
      }
    } catch (error) {
      console.error('Error sending data:', error);
      // Handle error as needed, such as showing an error message to the user
    }
  };
  

  const handleEngChange = (e) => {
    const { name, value } = e.target;
    setNewEngineerData((prevData) => ({ ...prevData, [name]: value }));
  };
  

  const handleAddEngineer = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://upvcapi.sninfoserv.com/new-engineer/', { 
        userId,newEngineerData
      });
     // console.log('Response:', response.data);
      if(response.data.status === 'success'){
        setModalIsOpen(false);
        fetchData();
      }
    } catch (error) {
      console.error('Error sending data:', error);
      // Handle error as needed, such as showing an error message to the user
    }
   // console.log('Adding new engineer:', newEngineerData);
    setModalIsOpen(false); // Close the modal after adding engineer
  };
  
  
  const fetchData = async () => {
    try {
      const response = await axios.post('https://upvcapi.sninfoserv.com/engineers/', user);
      setengData(response.data);
      //console.log(response.data);
    } catch (error) {
    } 
};

useEffect(() => {
  fetchData();
}, []);
  


    return (
    <div>
    <Navbar />

    
    <div class="pc-container">
      <div class="pc-content">
        
        <div class="row">
          <div class="col-md-12 col-xxl-12 col-xl-12">
          <div class="card">
            <div class="card-header">
              <h5>Create New Quatation</h5>
            </div>
            <div class="card-body">
              <form onSubmit={handleSubmit}>
              <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                      <label class="form-label" for="exampleInputEmail1">Client Name</label>
                      <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                        placeholder="Enter name..." name="name" value={values.name} onChange={handleChange} required />
                    </div>
                    <div class="form-group">
                      <label class="form-label" for="exampleInputPassword1">Location</label>
                      <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Enter location..."
                      name="location" value={values.location} onChange={handleChange} required/>
                    </div>
                </div>

                

                <div class="col-md-4">
                  
                    <div class="form-group">
                      <label class="form-label">Phone</label>
                      <input type="text" class="form-control" placeholder="Enter phone..." name="phone" value={values.phone} onChange={handleChange} required />
                    </div>
                    <div class="form-group">
                      <label class="form-label" for="exampleFormControlSelect1">Select Engineers</label>
                      <select class="form-select" id="exampleFormControlSelect1" name="engineer" value={values.engineer} onChange={handleChange} required>
                      <option value="" disabled>Select an Engineer</option>
                         {engDetails && engDetails.data ? 
                           ( engDetails.data.map(engineer => (
                             <option key={engineer.engineerId} value={engineer.engineerId}>
                               {engineer.engineerName}
                             </option>
                           ))
                         ) : (
                           <option value="" disabled>Loading Engineers...</option>
                         )}
                      </select>
                    </div>
                    <div class="form-group">
                      <button type="button" className="btn btn-primary ms-2" onClick={() => setModalIsOpen(true)}>
                        Add Engineer
                      </button>
                    </div>
                </div>
                
                <div class="col-md-4">
                    <div class="form-group">
                      <label class="form-label" for="exampleFormControlTextarea1">Enter client requirements</label>
                      <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" name='require' value={values.require} onChange={handleChange}></textarea>
                    </div>
                    <button type="submit" class="btn btn-primary mb-4 w-100" >Create Quote</button>
                </div>
                
               
                
                
              </div>
              </form>
              <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)} className="modal1">
        <h2>Add New Engineer</h2>
      
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input type="text" id="name" name="name" value={newEngineerData.name} onChange={handleEngChange} />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone</label>
            <input type="text" id="phone" name="phone" value={newEngineerData.phone} onChange={handleEngChange} />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" value={newEngineerData.email} onChange={handleEngChange} />
          </div>
          <div className="form-group">
            <label htmlFor="location">Location</label>
            <input type="text" id="location" name="location" value={newEngineerData.location} onChange={handleEngChange} />
          </div>
          <button type="button" className="btn btn-primary" onClick={handleAddEngineer}>
            Add Engineer
          </button>
          <button type="button" className="btn btn-secondary ms-2" onClick={() => setModalIsOpen(false)}>
            Cancel
          </button>
       
      </Modal>
            </div>
          </div>
          </div>
          
        </div>
      </div>
    </div>



    </div>
    );
};
    
    export default App;