import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/index';
import DashBoard from './pages/dashboard';
import NewQuote from './pages/newQuote';
import Cw from './pages/casementWindow';
import Sw from './pages/slidingWindow';
import Cd from './pages/casementDoor';
import Sd from './pages/slidingDoor';
import Vent from './pages/ventilators';
import Test from './pages/test';
import Upvc from './pages/upvc';
import Quote from './pages/quote';
import Quotedet from './pages/quotesdet';
import Quotee from './pages/quotees';
import Download from './pages/download';
import Schedule from './pages/cuttingSchedule';
import Tests from './pages/testss';
import Default from './pages/defaultProfile';
import Custom from './pages/customCost';
import AddUser from './pages/addUser';
import Account from './pages/account';
import Editcasementwindows from './pages/editcasementWindow';
import Editslidingwindows from './pages/editslidingWindow';
import Editcasementdoor from './pages/editcasementDoor';
import Editslidingdoor from './pages/editslidingDoor';
import Editventilator from './pages/editventilator';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/:id" element={<HomePage />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/dashboard" element={<DashBoard/>} />
        <Route path="/create-quotation" element={<NewQuote/>} />
        <Route path="/casement-window" element={<Cw/>} />
        <Route path="/sliding-window" element={<Sw/>} />
        <Route path="/casement-door" element={<Cd/>} />
        <Route path="/sliding-door" element={<Sd/>} />
        <Route path="/ventilators" element={<Vent/>} />
        <Route path="/test" element={<Test/>} />
        <Route path="/select" element={<Upvc/>} />
        <Route path="/quote" element={<Quote/>} />
        <Route path="/quotesdet" element={<Quotedet/>} />
        <Route path="/quotees" element={<Quotee/>} />
        <Route path="/download" element={<Download/>} />
        <Route path="/cutting-schedule" element={<Schedule/>} />
        <Route path="/testss" element={<Tests/>} />
        <Route path="/default" element={<Default/>} />
        <Route path="/settings" element={<Custom/>} />
        <Route path="/add-user" element={<AddUser/>} />
        <Route path="/account" element={<Account/>} />
        <Route path="/editcasementWindow" element={<Editcasementwindows/>} />
        <Route path="/editslidingWindow" element={<Editslidingwindows/>} />
        <Route path="/editcasementDoor" element={<Editcasementdoor/>} />
        <Route path="/editslidingDoor" element={<Editslidingdoor/>} />
        <Route path="/editslidingDoor" element={<Editslidingdoor/>} />
        <Route path="/editventilator" element={<Editventilator/>} />
      </Routes>
      
    </Router>
  );
}

export default App;