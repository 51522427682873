import React, { useEffect, useState } from 'react';
import { ReactSession } from 'react-client-session';
import axios from 'axios';
import Navbar from './navbar';
import Header from './header';
import numeral from 'numeral';
import left from '../images/handleLeft.png';
import right from '../images/handleRight.png';
import meshimg from '../images/mesh.png';
import Handle from '../images/slideHandle.png';
import Fan from '../images/fan.png';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import HomeComponent from './download';
import ScheduleComponent from './cuttingSchedule';
import './box.css';





function Quotesdet({ quotes })  {
  const urlParams = new URLSearchParams(window.location.search);
  const quoteIds = urlParams.get('id');
  const arrayValues = urlParams.get('arrayValues');
  console.log(arrayValues);
  const userId = ReactSession.get('userId');
  const [quoteData, setQuoteData] = useState(null);
  const [filteredArray, setFilteredArray] = useState([]);

  const [bottomFrame, setBottomFrame] = useState('yes');
  const [mesh, setMesh] = useState('no');
  const [panelHeights, setpanelHeight] = useState(0); 
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
  const [screen, setScreen] = useState(deviceWidth > 700 ? 2 : 4);
  const [divider, setDevider] = useState('Vertical');
  const [switchs, setSwitch] = useState('on');

  const [total, setTotal] = useState(0);
    const [gst, setGst] = useState(0);
  
 
  
  
  useEffect(() => {
    const updateDeviceWidth = () => {
      setDeviceWidth(window.innerWidth);
      setScreen(window.innerWidth > 700 ? 2 : 4);
    };

    window.addEventListener('resize', updateDeviceWidth);

    return () => {
      window.removeEventListener('resize', updateDeviceWidth);
    };
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.post(`https://upvcapi.sninfoserv.com/all-quotes/`, { user: userId });
      setQuoteData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Ensure quoteData is valid before using filter method
  const filteredQuotes = quoteData && quoteData.data.filter(quote => quote.quoteId === quoteIds);
  console.log(filteredQuotes);

  useEffect(() => {
    if (arrayValues) {
      // Split arrayValues string into an array of values
      const valuesArray = arrayValues.split(',');

      // Filter quotes based on valuesArray
      const filtered = quotes.filter(quote =>
        valuesArray.every(value => quote.details.includes(value))
      );

      setFilteredArray(filtered);
      console.log(filtered);
    }
  }, [arrayValues, quotes]);



  useEffect(() => {
    if (filteredQuotes && filteredQuotes.length > 0) {
      let sum = 0;
      filteredQuotes.forEach(quote => {
        quote &&  quote.quoteDetails && quote.quoteDetails.forEach(detail => {
          sum += (detail.quoteData.grandTotal * detail.quoteData.qty);
          console.log(detail.quoteData.qty);
        });
        
      });
      setTotal(sum);
      setGst((18/100)*sum);
    }
  }, [filteredQuotes]);
  


  const openModal = () => {
    const modal = document.getElementById('onloadModal');
    if (modal) {
        modal.classList.add('show');
        modal.style.display = 'block';
        document.body.classList.add('modal-open');
    }
  };

  const closeModal = () => {
    const modal = document.getElementById('onloadModal');
    if (modal) {
        modal.classList.remove('show');
        modal.style.display = 'none';
        document.body.classList.remove('modal-open');
    }
  };

  const openModals = () => {
    const modal = document.getElementById('onloadModals');
    if (modal) {
        modal.classList.add('show');
        modal.style.display = 'block';
        document.body.classList.add('modal-open');
    }
  };

  const closeModals = () => {
    const modal = document.getElementById('onloadModals');
    if (modal) {
        modal.classList.remove('show');
        modal.style.display = 'none';
        document.body.classList.remove('modal-open');
    }
  };
  
  const handleDownloadPDF = async () => {
    openModal();
    const input = document.getElementById('pdf-content');
    input.classList.remove('hide');
  
    const pdf = new jsPDF({
      orientation: 'portrait', // Use 'portrait' or 'landscape' as per your requirement
      unit: 'pt', // Set unit to millimeters
      format: 'a4', // Set format to A4
    });
    const inputHeight = input.clientHeight;
    const pageHeight = pdf.internal.pageSize.getHeight()+600;
    let currentPosition = 0;
    const scaleFactor = 4; 
    
    // Increase this value for higher resolution and better quality
  
    while (currentPosition < inputHeight) {
      const canvas = await html2canvas(input, {
        height: Math.min(pageHeight),
        
        y: currentPosition,
        scrollY: -currentPosition,
        scale: scaleFactor
      });

      

      
      const imgData = canvas.toDataURL('image/png', 4);
      pdf.addImage(imgData, 'PNG', 5, 5, 580, pdf.internal.pageSize.getHeight(), '', 'FAST');
      //currentPosition +=canvas.height;
      currentPosition += pageHeight;
      if (currentPosition < inputHeight) {
        pdf.addPage();
      }
     
    }
    pdf.save(filteredQuotes && filteredQuotes[0].client && filteredQuotes[0].client.name+'-'+quoteIds+'.pdf');
    
    input.classList.add('hide');
    closeModal();
  };


  
  const handleSchedulePDF = async () => {
    openModals();
    const inputs = document.getElementById('pdf-schedule');
    inputs.classList.remove('hide');
  
    const pdfs = new jsPDF({
      orientation: 'portrait', // Use 'portrait' or 'landscape' as per your requirement
      unit: 'pt', // Set unit to millimeters
      format: 'a4', // Set format to A4
    });
    const inputHeight = inputs.clientHeight;
    const pageHeight = pdfs.internal.pageSize.getHeight()+600;
    let currentPosition = 0;
    const scaleFactor = 4; 
    
    // Increase this value for higher resolution and better quality
  
    while (currentPosition < inputHeight) {
      const canvas = await html2canvas(inputs, {
        height: Math.min(pageHeight),
        
        y: currentPosition,
        scrollY: -currentPosition,
        scale: scaleFactor
      });

      

      
      const imgData = canvas.toDataURL('image/png', 4);
      pdfs.addImage(imgData, 'PNG', 5, 5, 580, pdfs.internal.pageSize.getHeight(), '', 'FAST');
      //currentPosition +=canvas.height;
      currentPosition += pageHeight;
      if (currentPosition < inputHeight) {
        pdfs.addPage();
      }
     
    }
    pdfs.save(filteredQuotes && filteredQuotes[0].client && filteredQuotes[0].client.name+'-'+quoteIds+'.pdf');
    
    inputs.classList.add('hide');
    closeModals();
  };
  
  
  
  
  

  return (
    <div>
      <Navbar />
      <Header />
      <div className="pc-container">
        <div className="pc-content">
          <div className="row">
            <div className="col-md-12 col-xxl-12 col-xl-12">
              <div className="card">
                <div className="card-header">
                  <h5>Quotes</h5>
                      
        <div class="row mt-5">
        <div class="col-md-6 col-xxl-3 col-xl-3">
            <div class="card">
             
              <div class="card-body">
                <div class="d-flex align-items-center">
                  
                  <div class="flex-grow-1 ms-3">
                    <h6 class="mb-0">Quote</h6>
                  </div>
              
                </div>
                <div class="bg-body p-3 mt-3 rounded">
                  <div class="mt-3 row align-items-center">
                    
                    {filteredQuotes?.map((quote, index) => (
                    <div class="col-10">
                      
    <div key={index}>
      <h5 class="mb-3">{quote.date}</h5>
      <h5 class="mb-3">{quote.quoteId}</h5>
      <h5 class="mb-3">{quote.items} Items</h5>
    
    </div>
  
                      </div>
                      ))}
                  </div>
                </div>
              </div>
            
            </div>
          </div>
          <div class="col-md-6 col-xxl-3 col-xl-3">
            <div class="card">
            
              <div class="card-body">
                <div class="d-flex align-items-center">
                
                  <div class="flex-grow-1 ms-3">
                    <h6 class="mb-0">Client</h6>
                  </div>
             
                </div>
                <div class="bg-body p-3 mt-3 rounded">
                  <div class="mt-3 row align-items-center">
                 
                    
                    {filteredQuotes?.map((quote, index) => (
                    <div class="col-10">
                      
    <div key={index}>
      <h5 class="mb-3">{quote.client.name}</h5>
      <h5 class="mb-3">{quote.client.location}</h5>
      <h5 class="mb-3">{quote.client.number}</h5>
      
    </div>
  
                      </div>
                      ))}
                  </div>
                 
                </div>
              </div>
              
            </div>
          </div>
          <div class="col-md-6 col-xxl-3 col-xl-3">
            <div class="card">
          
              <div class="card-body">
                <div class="d-flex align-items-center">
                 
                  <div class="flex-grow-1 ms-3">
                    <h6 class="mb-0">Amount</h6>
                  </div>
                  
                </div>
                <div class="bg-body p-3 mt-3 rounded">
                <div class="mt-3 row align-items-center">
                 
                    
                
                 <div class="col-10">
                   
 <div>
   <h5 class="mb-3"><span style={{ fontWeight: 'bold'}}>Total :</span> ₹ {numeral(total).format('0,0.00')}</h5>
   <h5 class="mb-3"><span style={{ fontWeight: 'bold'}}>GST 18% :</span> ₹ {numeral(gst).format('0,0.00')}</h5>
   <h5 class="mb-3"><span style={{ fontWeight: 'bold'}}>Grand Total :</span> ₹ {numeral(total+gst).format('0,0.00')}</h5>
   
 </div>

                   </div>
                  
               </div>
                </div>
              </div>
             
            </div>
          </div>
          <div class="col-md-6 col-xxl-3 col-xl-3">
            <div class="card">
         
              <div class="card-body">
                <div class="d-flex align-items-center">
                
                  <div class="flex-grow-1 ms-3">
                    <h6 class="mb-0">Download</h6>
                  </div>
                
                </div>
                <div class="bg-body p-3 mt-1 rounded">
                  <div class="mt-1 row align-items-center">
                   
                    <div class="col-12">
                      
                      <div class="col-md-12 col-xxl-12 col-xl-12"  >
                 
                      <HomeComponent id="pdf-content"/>

                      <button class="btn btn-primary p-3 mb-1 w-100" onClick={handleDownloadPDF}>Download Quote</button>
                      
                      <ScheduleComponent id="pdf-schedule"/>
                 
                      <button class="btn btn-warning p-3 mb-1 w-100" onClick={handleSchedulePDF}>Download Cutting Schedule</button>
 
                     </div>
                    </div>
                  </div>
                </div>
              </div>
           
            </div>
          </div>

          
          
        
        </div>


                    

                  
         
                </div>
                <div className="card-body">
                <div class="table-responsive"> 
                  <table id="left-right-fix" className="table stripe row-border order-column">
                    <thead>
                      <tr align="left">
                        <th>Item</th>
                        <th width="3%">QTY</th>
                        <th width="3%">Details</th>
                        <th width="3%">Action</th>
                        <th width="3%">View More</th>
                        
                      </tr>
                    </thead>
                    
                    {filteredQuotes?.map((quote, index) => (
  <tbody key={index}>
    {quote.quoteDetails.map((detail, detailIndex) => (
      <tr key={detailIndex}>
       
        <td>
        <h5 style={{ marginLeft: '10px' }}>{detail.quoteData.item}</h5>
        {detail.quoteData.item === 'CasementDoor' && (
          <div class="col-md-12 col-xxl-12 col-xl-12">
          <div class="card" style={{background:'#fff'}}>
            <div class="card-body mb-5">
            
            <div>

<div style={{float:'left', width: detail.quoteData.boxWidth*screen+'vw',  height:detail.quoteData.boxHeight*screen+'vw', border:'1px solid #333', borderBottom: bottomFrame === 'no' ? '1px solid #33333350' : '1px solid #333'  }}>
{[...Array(detail.quoteData.numFrames)].map((_, index) => (
<div style={{float:'left', margin:'5px', border:'1px solid #333',width:`calc(${detail.quoteData.boxWidth*screen/detail.quoteData.numFrames+'vw'} - 12px)`, height: bottomFrame === 'no' ? `calc(${detail.quoteData.boxHeight*screen+'vw'} - 6px)` : `calc(${detail.quoteData.boxHeight*screen+'vw'} - 12px)`, } }> 
{detail.quoteData.newHeights !== 0 ? (
<>

{ detail.quoteData.boxPosition === 'Top' ? (
    <div></div>
  ) : (
    [...Array(detail.quoteData.numFirstInnerBoxes)].map((_, index) => (
<div style={{width: `calc(${detail.quoteData.boxWidth*screen/detail.quoteData.numFrames+'vw'} - 23px)`, height:`calc(${(detail.quoteData.newHeights * screen)}vw )`, background: 'lightblue', margin:'5px', border:'1px solid #333'}}></div>
))
)}

<div style={{ display: 'flex' }}>
  {[...Array(detail.quoteData.numSecondInnerBoxes)].map((_, index) => (
    <div key={index} style={{ flex: '1', height: bottomFrame === 'no' ? `calc(${(detail.quoteData.boxHeight - detail.quoteData.newHeights) * screen}vw - 23px)` : `calc(${(detail.quoteData.boxHeight - detail.quoteData.newHeights) * screen}vw - 30px)`, width: `calc(${detail.quoteData.newWidths[index] * screen}vw - 5px - 19px)`, backgroundColor: detail.quoteData.panelType === 1 ? 'lightgray' : detail.quoteData.panelType === 2 ? 'lightyellow' : 'lightblue', marginTop: detail.quoteData.boxPosition === 'Top' ? '5px' : '0px', marginRight: index === detail.quoteData.numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #333' }}>
     
            <div className="expanding-arrow-line" style={{ 
              position: 'absolute',
              float:'left', 
              width: `calc(${detail.quoteData.newWidths[index] * screen}vw - 15px)`,
              bottom:'-10px', 
              top: `calc(${detail.quoteData.boxHeight* screen}vw - 25px)`,
              
             }}>
              <div className="arrow-left"></div>
                <div className="line" align="center">
                  <div style={{marginTop:'-7px',background:'white',maxWidth:'20px',textAlign:'center'}}><p>{typeof detail.quoteData.newWidths[index] === 'number' ? detail.quoteData.newWidths[index].toFixed(2) : detail.quoteData.newWidths[index]}</p></div>
                </div>
              <div className="arrow-right"></div>
            </div>
     
     
      { index === 0 && ( detail.quoteData.boxHandle[index] === 'left' ? (
           <img
           src={left}
           width='15'
           style={{
             float: 'left',
             marginTop: (detail.quoteData.boxHeight) * ((screen/2)-(screen/15))+'vw',
             marginLeft: '2px',
             width: screen === 4 ? '13px' : '8px'
           }}
           
           alt="Left Handle"
         />
       ) : (
         <img
           src={right}
           width='15'
           style={{
             float: 'right',
             marginTop: (detail.quoteData.boxHeight) * ((screen/2)-(screen/15))+'vw',
             marginRight: '2px',
             width: screen === 4 ? '13px' : '8px'
           }}
           
           alt="Right Handle"
         />
       ) )}


            { mesh === 'yes' ? (
              <img src={meshimg} style={{width:'50px', float:'left', marginLeft: detail.quoteData.boxHandle[index] === 'left' ? '-15px' : '0px', marginTop: `calc(${((detail.quoteData.boxHeight-detail.quoteData.newHeights)*screen)}vw - ${bottomFrame === 'no' ? '76px' : '80px' })`}} />
              ):(<></>)
            }
      
            

    </div>

  ))}
  
</div>
{ detail.quoteData.boxPosition === 'Bottom' ? (
    <div></div>
  ) : (
    [...Array(detail.quoteData.numFirstInnerBoxes)].map((_, index) => (
<div style={{width: `calc(${detail.quoteData.boxWidth*screen/detail.quoteData.numFrames+'vw'} - 23px)`, height:`calc(${(detail.quoteData.newHeights * screen)}vw )`, background: 'lightgreen', margin:'5px', border:'1px solid #333'}}></div>
))
)}

{ detail.quoteData.numSecondInnerBoxes === 0 ? (
      <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((detail.quoteData.boxWidth/detail.quoteData.numFrames)* screen)+'vw',top:(detail.quoteData.boxHeight-detail.quoteData.newHeights)*screen+'vw', }} >
      <div className="arrow-left"></div>
      <div className="line" align="center">
        <div style={{marginTop:'-8px',background:'white',maxWidth:'40px',textAlign:'center'}}><p>{typeof (detail.quoteData.boxWidth/detail.quoteData.numFrames) === 'number' ? (detail.quoteData.boxWidth/detail.quoteData.numFrames).toFixed(2) : (detail.quoteData.boxWidth/detail.quoteData.numFrames)}</p></div>
      </div>
      <div className="arrow-right"></div>
    </div>
    ) : (
      <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((detail.quoteData.boxWidth/detail.quoteData.numFrames)* screen)+'vw',bottom:'-10px',top:'50px', }} >
        <div className="arrow-left"></div>
        <div className="line" align="center">
          <div style={{marginTop:'-8px',background:'white',maxWidth:'40px',textAlign:'center'}}><p>{typeof (detail.quoteData.boxWidth/detail.quoteData.numFrames) === 'number' ? (detail.quoteData.boxWidth/detail.quoteData.numFrames).toFixed(2) : (detail.quoteData.boxWidth/detail.quoteData.numFrames)}</p></div>
        </div>
        <div className="arrow-right"></div>
      </div>
    )}
</>
) : (
<>
<div style={{ display: 'flex' }}>
{[...Array(detail.quoteData.numSecondInnerBoxes)].map((_, index) => (
  panelHeights === 0 ? (
  <div key={index} style={{ flex: '1', height: bottomFrame === 'no' ? `calc(${(detail.quoteData.boxHeight) * screen}vw - 18px )` : `calc(${(detail.quoteData.boxHeight) * screen}vw - 23px )`, width: `calc(${detail.quoteData.newWidths[index] * screen}vw - 5px - 19px)`, backgroundColor: detail.quoteData.panelType === 1 ? 'lightgray' : detail.quoteData.panelType === 2 ? 'lightyellow' : 'lightblue', marginRight: index === detail.quoteData.numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #333',marginTop:'5px' }}>
   
   <div className="expanding-arrow-line" style={{ 
              float:'left', 
              width: `calc(${detail.quoteData.newWidths[index] * screen}vw - 20px)`,
              bottom:'-10px', 
              top: detail.quoteData.boxHeight * screen + 'vw',
             
             }}>
              <div className="arrow-left"></div>
                <div className="line" align="center">
                  <div style={{marginTop:'-7px',background:'white',maxWidth:'20px',textAlign:'center'}}><p>{typeof detail.quoteData.newWidths[index] === 'number' ? detail.quoteData.newWidths[index].toFixed(2) : detail.quoteData.newWidths[index]}</p></div>
                </div>
              <div className="arrow-right"></div>
            </div>
   
    { index === 0 &&  detail.quoteData.boxHandle[index] === 'left' && (
    
            <img
              src={right}
              width='15'
              style={{
                float: 'right',
                marginTop: (detail.quoteData.boxHeight) * ((screen/2)-(screen/15))+'vw',
                marginRight: '2px',
                width: screen === 4 ? '13px' : '8px'
              }}
              
              alt="Left Handle"
            />
            

          )}  
          { index === 1 &&  detail.quoteData.boxHandle[index] === 'right' &&(
         
            <img
              src={left}
              width='15'
              style={{
                float: 'left',
                marginTop: (detail.quoteData.boxHeight) * ((screen/2)-(screen/15))+'vw',
                marginLeft: '2px',
                width: screen === 4 ? '13px' : '8px'
              }}
              
              alt="Right Handle"
            />
            
          )}


           { mesh === 'yes' ? (
              <img src={meshimg} style={{width:'50px', float:'left', marginLeft: detail.quoteData.boxHandle[index] === 'left' ? '-15px' : '0px', marginTop: `calc(${((detail.quoteData.boxHeight-detail.quoteData.newHeights)*screen)}vw - ${bottomFrame === 'no' ? '71px' : '75px' })`}} />
              ):(<></>)
            }
           
            
            
         
  </div>
  ) : (
    <div key={index} style={{  height: panelHeights*screen+'vw', minWidth:`calc(${detail.quoteData.newWidths[index] * screen}vw - 5px - 19px)`, backgroundColor: 'lightblue', border: '1px solid #333',margin:'5px 5px 5px'}}>
  
    <div key={index} style={{ height: bottomFrame === 'no' ? `calc(${(detail.quoteData.boxHeight-panelHeights) * screen}vw - 18px )` : `calc(${(detail.quoteData.boxHeight-panelHeights) * screen}vw - 30px )`, width: `calc(${detail.quoteData.newWidths[index] * screen}vw - 5px - 19px)`, backgroundColor: detail.quoteData.boxColors[index] || 'lightblue',  border: '1px solid #333',marginTop: `calc(${panelHeights * screen}vw + 5px )` }}>
      {  index === 0 && ( detail.quoteData.boxHandle[index] === 'left' ? (
        <>
              <img
                src={left}
                width='15'
                style={{
                  float: 'left',
                  marginTop: (detail.quoteData.boxHeight) * ((screen/2)-(screen/15))+'vw',
                  marginLeft: '2px',
                  width: screen === 4 ? '13px' : '8px'
                }}
                
                alt="Left Handle"
              />
              
              </>
            ) : (
              <>
              <img
                src={right}
                width='15'
                style={{
                  float: 'right',
                  marginTop: (detail.quoteData.boxHeight) * ((screen/2)-(screen/15))+'vw',
                  marginRight: '2px',
                  width: screen === 4 ? '13px' : '8px'
                }}
                
                alt="Right Handle"
              />
              
              </>
            ) )}
             { mesh === 'yes' ? (
              <img src={meshimg} style={{width:'50px', float:'left',  bottom:'0px', marginTop:`calc(${(detail.quoteData.boxHeight) * screen}vw - 75px )` }} />
             ):(<></>)}
             
              
              <div className="expanding-arrow-line" style={{ 
                float:'left', 
                width: `calc(${detail.quoteData.newWidths[index] * screen}vw - 25px)`,
                 
                ...(index === 0 && mesh === 'yes' && { top: detail.quoteData.boxHeight * ((screen/2)-(screen*0.43)) + 'vw' }),
                ...(index === 0 && mesh === 'no' && { top: detail.quoteData.boxHeight * (1.3) + 'vw' }),
                ...(index !== 0 && mesh === 'yes' &&  { top: detail.quoteData.boxHeight * (0.27) + 'vw' }),
                ...(index !== 0 && mesh === 'no' &&  { top: detail.quoteData.boxHeight * ((screen/2)+(screen/2.02)) + 'vw' }),
               }}>
                <div className="arrow-left"></div>
                  <div className="line" align="center">
                    <div style={{marginTop:'-7px',background:'white',maxWidth:'20px',textAlign:'center'}}><p>{typeof detail.quoteData.newWidths[index] === 'number' ? detail.quoteData.newWidths[index].toFixed(2) : detail.quoteData.newWidths[index]}</p></div>
                  </div>
                <div className="arrow-right"></div>
              </div>
           
    </div>
    </div>
  )
))}
</div> 
    { detail.quoteData.numSecondInnerBoxes === 0 ? (
      <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((detail.quoteData.boxWidth/detail.quoteData.numFrames)* screen)+'vw', top:detail.quoteData.boxHeight*screen+'vw',  }} >
      <div className="arrow-left"></div>
      <div className="line" align="center">
        <div style={{marginTop:'-8px',background:'white',maxWidth:'40px',textAlign:'center'}}><p>{typeof (detail.quoteData.boxWidth/detail.quoteData.numFrames) === 'number' ? (detail.quoteData.boxWidth/detail.quoteData.numFrames).toFixed(2) : (detail.quoteData.boxWidth/detail.quoteData.numFrames)}</p></div>
      </div>
      <div className="arrow-right"></div>
    </div>
    ) : (
      <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((detail.quoteData.boxWidth/detail.quoteData.numFrames)* screen)+'vw', bottom:'-10px',top:'40px', }} >
        <div className="arrow-left"></div>
        <div className="line" align="center">
          <div style={{marginTop:'-8px',background:'white',maxWidth:'40px',textAlign:'center'}}><p>{typeof (detail.quoteData.boxWidth/detail.quoteData.numFrames) === 'number' ? (detail.quoteData.boxWidth/detail.quoteData.numFrames).toFixed(2) : (detail.quoteData.boxWidth/detail.quoteData.numFrames)}</p></div>
        </div>
        <div className="arrow-right"></div>
      </div>
    )}
</>
)}
</div>
 ))}
</div>

{ detail.quoteData.boxPosition === 'Top' ? (
detail.quoteData.newHeights === 0 ? (

  <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
    <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}vw)`, top: '0', position: 'relative' }}>
     <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
     <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
       <div style={{ marginLeft: '-10px', marginTop: detail.quoteData.boxHeight * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof detail.quoteData.boxHeight === 'number' ? detail.quoteData.boxHeight.toFixed(2) : detail.quoteData.boxHeight}</p></div>
     </div>
     <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
    </div>
  </div>
) : (  
    <>
      <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>

        <div className="expanding-arrow-line" style={{order: 1, flex:1, float: 'left', height: `calc(${(detail.quoteData.boxHeight-detail.quoteData.newHeights) * screen}vw - 20px)`, top: '0', position: 'relative' }}>
          <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
          <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
            <div style={{ marginLeft: '-10px', marginTop: (detail.quoteData.boxHeight-detail.quoteData.newHeights) * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof (detail.quoteData.boxHeight-detail.quoteData.newHeights) === 'number' ? (detail.quoteData.boxHeight-detail.quoteData.newHeights).toFixed(2) : (detail.quoteData.boxHeight-detail.quoteData.newHeights)}</p></div>
          </div>
          <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
        </div>
         <div className="expanding-arrow-line" style={{order: 2, flex:2, float: 'left', height: `calc(${detail.quoteData.newHeights * screen}vw `, top: `calc(${(detail.quoteData.boxHeight-detail.quoteData.newHeights) * screen}vw - 10px)`, position: 'relative' }}>
          <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
          <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
            <div style={{ marginLeft: '-10px', marginTop: detail.quoteData.newHeights * (((screen/2)-(screen/5)))+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof detail.quoteData.newHeights === 'number' ? detail.quoteData.newHeights.toFixed(2) : detail.quoteData.newHeights}</p></div>
          </div>
          <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
        </div>

      </div>

      <div style={{ width: '30px', float: 'left', paddingLeft: '0px', textAlign: 'left' }}>
      <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}vw)`, top: '0', position: 'relative' }}>
       <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
       <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
         <div style={{ marginLeft: '-10px', marginTop: detail.quoteData.boxHeight * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof detail.quoteData.boxHeight === 'number' ? detail.quoteData.boxHeight.toFixed(2) : detail.quoteData.boxHeight}</p></div>
       </div>
       <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
      </div>
    </div>
    </>
)
) : (
detail.quoteData.newHeights === 0 ? (

  <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
    <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}vw)`, top: '0', position: 'relative' }}>
     <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
     <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
       <div style={{ marginLeft: '-10px', marginTop: detail.quoteData.boxHeight * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof detail.quoteData.boxHeight === 'number' ? detail.quoteData.boxHeight.toFixed(2) : detail.quoteData.boxHeight}</p></div>
     </div>
     <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
    </div>
  </div>
) : (  
    <>
      <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
        <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${detail.quoteData.newHeights * screen}vw + 10px)`, top: '0', position: 'relative' }}>
          <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
          <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
            <div style={{ marginLeft: '-10px', marginTop: detail.quoteData.newHeights * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof detail.quoteData.newHeights === 'number' ? detail.quoteData.newHeights.toFixed(2) : detail.quoteData.newHeights}</p></div>
          </div>
          <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
        </div>
        <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight-detail.quoteData.newHeights) * screen}vw - 20px)`, top: `calc(${detail.quoteData.newHeights * screen}vw + 20px)`, position: 'relative' }}>
          <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
          <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
            <div style={{ marginLeft: '-10px', marginTop: (detail.quoteData.boxHeight-detail.quoteData.newHeights) * ((screen/2)-(screen/8))+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof (detail.quoteData.boxHeight-detail.quoteData.newHeights) === 'number' ? (detail.quoteData.boxHeight-detail.quoteData.newHeights).toFixed(2) : (detail.quoteData.boxHeight-detail.quoteData.newHeights)}</p></div>
          </div>
          <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
        </div>
      </div>
      <div style={{ width: '30px', float: 'left', paddingLeft: '0px', textAlign: 'left' }}>
      <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}vw)`, top: '0', position: 'relative' }}>
       <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
       <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
         <div style={{ marginLeft: '-10px', marginTop: detail.quoteData.boxHeight * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof detail.quoteData.boxHeight === 'number' ? detail.quoteData.boxHeight.toFixed(2) : detail.quoteData.boxHeight}</p></div>
       </div>
       <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
      </div>
    </div>
    </>
)
)}
  
</div>
<h5 style={{ marginLeft: '20px' , color:'#36454F'}}>Hardwares</h5>
<div style={{ display: 'flex', flexWrap: 'wrap' }}>
  <p style={{ fontSize: '15px', marginRight: '10px', color:'#36454F'}}>Steel Frame,</p>
  <p style={{ fontSize: '15px',marginRight: '10px', color:'#36454F'}}>Steel Mullion,</p>
  <p style={{ fontSize: '15px',marginRight: '10px', color:'#36454F'}}>Steel Shutter,</p>
  <p style={{ fontSize: '15px',marginRight: '10px', color:'#36454F'}}>Door Lock with Espag,</p>
  <p style={{ fontSize: '15px',marginRight: '10px', color:'#36454F'}}>3D Hinges,</p>
  <p style={{ fontSize: '15px',marginRight: '10px', color:'#36454F'}}>Screws/Misc</p>
</div>
            </div>
          </div>
        </div>
        )}
         {detail.quoteData.item === 'CasementWindow' && (
         <div class="col-md-12 col-xxl-12 col-xl-12">
         <div class="card" style={{background:'#fff'}}>
           <div class="card-body mb-5">
           
           <div>

<div style={{float:'left', width: detail.quoteData.boxWidth*screen+'vw', height:detail.quoteData.boxHeight*screen+'vw', border:'1px solid #333'}}>
{[...Array(detail.quoteData.numFrames)].map((_, index) => (
<div style={{float:'left', margin:'5px', border:'1px solid #333',width:`calc(${detail.quoteData.boxWidth*screen/detail.quoteData.numFrames+'vw'} - 12px)`, height:`calc(${detail.quoteData.boxHeight*screen+'vw'} - 12px)`}}> 
{detail.quoteData.newHeights !== 0 ? (
<>

{ detail.quoteData.boxPosition === 'Top' ? (
   <div></div>
 ) : (
   [...Array(detail.quoteData.numFirstInnerBoxes)].map((_, index) => (
<div style={{width: `calc(${detail.quoteData.boxWidth*screen/detail.quoteData.numFrames+'vw'} - 23px)`, height:`calc(${(detail.quoteData.newHeights * screen)}vw )`, background: 'lightgreen', margin:'5px', border:'1px solid #333'}}></div>
))
)}

<div style={{ display: 'flex' }}>
 {[...Array(detail.quoteData.numSecondInnerBoxes)].map((_, index) => (
   <div key={index} style={{ flex: '1', height: `calc(${(detail.quoteData.boxHeight - detail.quoteData.newHeights) * screen}vw - 30px)`, width: `calc(${detail.quoteData.newWidths[index] * screen}vw - 5px - 19px)`, backgroundColor: detail.quoteData.boxColors[index] || 'lightblue', marginTop: detail.quoteData.boxPosition === 'Top' ? '5px' : '0px', marginRight: index === detail.quoteData.numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #333' }}>
    
           <div className="expanding-arrow-line" style={{ 
             position: 'absolute',
             float:'left', 
             width: `calc(${detail.quoteData.newWidths[index] * screen}vw - 15px)`,
             bottom:'-10px', 
             top: `calc(${detail.quoteData.boxHeight * screen}vw - 25px)` ,
            
            }}>
             <div className="arrow-left"></div>
               <div className="line" align="center">
                 <div style={{marginTop:'-7px',background:'white',maxWidth:'20px',textAlign:'center'}}><p>{typeof detail.quoteData.newWidths[index] === 'number' ? detail.quoteData.newWidths[index].toFixed(2) : detail.quoteData.newWidths[index]}</p></div>
               </div>
             <div className="arrow-right"></div>
           </div>

    
    
     { detail.quoteData.boxNames[index] === 'Shutter' && ( detail.quoteData.boxHandle[index] === 'left' ? (
           <img
             src={left}
             style={{
               float: 'left',
               marginTop: `calc(${(((detail.quoteData.boxHeight-detail.quoteData.newHeights)*screen)/2)}vw - 15px`,
               marginLeft: '2px',
               width: screen === 4 ? '13px' : '8px'
             }}
             
             alt="Left Handle"
           />
         ) : (
           <img
             src={right}
             style={{
               float: 'right',
               marginTop: `calc(${(((detail.quoteData.boxHeight-detail.quoteData.newHeights)*screen)/2)}vw - 15px`,
               marginRight: '2px',
               width: screen === 4 ? '13px' : '8px'
             }}
             
             alt="Right Handle"
           />
           
         ) )}

           {mesh === 'yes' && detail.quoteData.boxNames[index] === 'Shutter' && (
             <img src={meshimg} style={{width:'50px', float:'left', marginLeft: detail.quoteData.boxHandle[index] === 'left' ? '-15px' : '0px', marginTop: `calc(${((detail.quoteData.boxHeight-detail.quoteData.newHeights)*screen)}vw - 82px)`}} />
             )
           }
         
           
   </div>

 ))}
 
</div>
{ detail.quoteData.boxPosition === 'Bottom' ? (
   <div></div>
 ) : (
   [...Array(detail.quoteData.numFirstInnerBoxes)].map((_, index) => (
<div style={{width: `calc(${detail.quoteData.boxWidth*screen/detail.quoteData.numFrames+'vw'} - 23px)`, height:`calc(${(detail.quoteData.newHeights * screen)}vw )`, background: 'lightgreen', margin:'5px', border:'1px solid #333'}}></div>
))
)}

{ detail.quoteData.numSecondInnerBoxes === 0 ? (
     <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((detail.quoteData.boxWidth/detail.quoteData.numFrames)* screen)+'vw',top:(detail.quoteData.boxHeight-detail.quoteData.newHeights)*screen+'vw', }} >
     <div className="arrow-left"></div>
     <div className="line" align="center">
       <div style={{marginTop:'-8px',background:'white',maxWidth:'40px',textAlign:'center'}}><p>{typeof (detail.quoteData.boxWidth/detail.quoteData.numFrames) === 'number' ? (detail.quoteData.boxWidth/detail.quoteData.numFrames).toFixed(2) : (detail.quoteData.boxWidth/detail.quoteData.numFrames)}</p></div>
     </div>
     <div className="arrow-right"></div>
   </div>
   ) : (
     <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((detail.quoteData.boxWidth/detail.quoteData.numFrames)* screen)+'vw',bottom:'-10px',top:'50px', }} >
       <div className="arrow-left"></div>
       <div className="line" align="center">
         <div style={{marginTop:'-8px',background:'white',maxWidth:'40px',textAlign:'center'}}><p>{typeof (detail.quoteData.boxWidth/detail.quoteData.numFrames) === 'number' ? (detail.quoteData.boxWidth/detail.quoteData.numFrames).toFixed(2) : (detail.quoteData.boxWidth/detail.quoteData.numFrames)}</p></div>
       </div>
       <div className="arrow-right"></div>
     </div>
   )}
</>
) : (
<>
<div style={{ display: 'flex' }}>
{[...Array(detail.quoteData.numSecondInnerBoxes)].map((_, index) => (
 <div key={index} style={{ flex: '1', height: `calc(${(detail.quoteData.boxHeight) * screen}vw - 23px)`, width: `calc(${detail.quoteData.newWidths[index] * screen}vw - 5px - 19px)`, backgroundColor: detail.quoteData.boxColors[index] || 'lightblue', marginRight: index === detail.quoteData.numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #333',marginTop:'5px' }}>
   
           <div className="expanding-arrow-line" style={{ 
             float:'left', 
             width: `calc(${detail.quoteData.newWidths[index] * screen}vw - 15px)`,
             bottom:'-10px', 
             top: detail.quoteData.boxHeight *screen + 'vw',
            
            }}>
             <div className="arrow-left"></div>
               <div className="line" align="center">
                 <div style={{marginTop:'-7px',background:'white',maxWidth:'20px',textAlign:'center'}}><p>{typeof detail.quoteData.newWidths[index] === 'number' ? detail.quoteData.newWidths[index].toFixed(2) : detail.quoteData.newWidths[index]}</p></div>
               </div>
             <div className="arrow-right"></div>
           </div>
   
           
   
   { detail.quoteData.boxNames[index] === 'Shutter' && ( detail.quoteData.boxHandle[index] === 'left' ? (
           <img
             src={left}
             width='15px'
             style={{
               float: 'left',
               marginTop: `calc(${((detail.quoteData.boxHeight*screen)/2)}vw - 15px`,
               marginLeft: '2px',
               width: screen === 4 ? '13px' : '8px'
             }}
             
             alt="Left Handle"
           />
         ) : (
           <img
             src={right}
             width='15px'
             style={{
               float: 'right',
               marginTop: `calc(${((detail.quoteData.boxHeight*screen)/2)}vw - 15px`,
               marginRight: '2px',
               width: screen === 4 ? '13px' : '8px',
               verticalAlign: 'bottom'
             }}
             
             alt="Right Handle"
           />
         ) )}

        
           {mesh === 'yes' && detail.quoteData.boxNames[index] === 'Shutter' && (
             <img src={meshimg} style={{width:'50px', float:'left', marginLeft: detail.quoteData.boxHandle[index] === 'left' ? '-15px' : '0px', marginTop: `calc(${(detail.quoteData.boxHeight*screen)}vw - 75px)`}} />
           )}
          

           
        
 </div>
))}
</div> 
   { detail.quoteData.numSecondInnerBoxes === 0 ? (
     <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((detail.quoteData.boxWidth/detail.quoteData.numFrames)* screen)+'vw', top:detail.quoteData.boxHeight*screen+'vw',  }} >
     <div className="arrow-left"></div>
     <div className="line" align="center">
       <div style={{marginTop:'-8px',background:'white',maxWidth:'40px',textAlign:'center'}}><p>{typeof (detail.quoteData.boxWidth/detail.quoteData.numFrames) === 'number' ? (detail.quoteData.boxWidth/detail.quoteData.numFrames).toFixed(2) : (detail.quoteData.boxWidth/detail.quoteData.numFrames)}</p></div>
     </div>
     <div className="arrow-right"></div>
   </div>
   ) : (
     <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((detail.quoteData.boxWidth/detail.quoteData.numFrames)* screen)+'vw', bottom:'-10px',top:'40px', }} >
       <div className="arrow-left"></div>
       <div className="line" align="center">
         <div style={{marginTop:'-8px',background:'white',maxWidth:'40px',textAlign:'center'}}><p>{typeof (detail.quoteData.boxWidth/detail.quoteData.numFrames) === 'number' ? (detail.quoteData.boxWidth/detail.quoteData.numFrames).toFixed(2) : (detail.quoteData.boxWidth/detail.quoteData.numFrames)}</p></div>
       </div>
       <div className="arrow-right"></div>
     </div>
   )}
</>
)}
</div>
))}
</div>

{ detail.quoteData.boxPosition === 'Top' ? (
detail.quoteData.newHeights === 0 ? (

 <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
   <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}vw)`, top: '0', position: 'relative' }}>
    <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
    <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
      <div style={{ marginLeft: '-10px', marginTop: detail.quoteData.boxHeight * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof detail.quoteData.boxHeight === 'number' ? detail.quoteData.boxHeight.toFixed(2) : detail.quoteData.boxHeight}</p></div>
    </div>
    <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
   </div>
 </div>
) : (  
   <>
     <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>

       <div className="expanding-arrow-line" style={{order: 1, flex:1, float: 'left', height: `calc(${(detail.quoteData.boxHeight-detail.quoteData.newHeights) * screen}vw - 20px)`, top: '0', position: 'relative' }}>
         <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
         <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
           <div style={{ marginLeft: '-10px', marginTop: (detail.quoteData.boxHeight-detail.quoteData.newHeights) * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof (detail.quoteData.boxHeight-detail.quoteData.newHeights) === 'number' ? (detail.quoteData.boxHeight-detail.quoteData.newHeights).toFixed(2) : (detail.quoteData.boxHeight-detail.quoteData.newHeights)}</p></div>
         </div>
         <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
       </div>
        <div className="expanding-arrow-line" style={{order: 2, flex:2, float: 'left', height: `calc(${detail.quoteData.newHeights * screen}vw `, top: `calc(${(detail.quoteData.boxHeight-detail.quoteData.newHeights) * screen}vw - 10px)`, position: 'relative' }}>
         <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
         <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
           <div style={{ marginLeft: '-10px', marginTop: detail.quoteData.newHeights * (((screen/2)-(screen/5)))+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof detail.quoteData.newHeights === 'number' ? detail.quoteData.newHeights.toFixed(2) : detail.quoteData.newHeights}</p></div>
         </div>
         <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
       </div>

     </div>

     <div style={{ width: '30px', float: 'left', paddingLeft: '0px', textAlign: 'left' }}>
     <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}vw)`, top: '0', position: 'relative' }}>
      <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
      <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
        <div style={{ marginLeft: '-10px', marginTop: detail.quoteData.boxHeight * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof detail.quoteData.boxHeight === 'number' ? detail.quoteData.boxHeight.toFixed(2) : detail.quoteData.boxHeight}</p></div>
      </div>
      <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
     </div>
   </div>
   </>
)
) : (
detail.quoteData.newHeights === 0 ? (

 <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
   <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}vw)`, top: '0', position: 'relative' }}>
    <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
    <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
      <div style={{ marginLeft: '-10px', marginTop: detail.quoteData.boxHeight * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof detail.quoteData.boxHeight === 'number' ? detail.quoteData.boxHeight.toFixed(2) : detail.quoteData.boxHeight}</p></div>
    </div>
    <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
   </div>
 </div>
) : (  
   <>
     <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
       <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${detail.quoteData.newHeights * screen}vw + 10px)`, top: '0', position: 'relative' }}>
         <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
         <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
           <div style={{ marginLeft: '-10px', marginTop: detail.quoteData.newHeights * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof detail.quoteData.newHeights === 'number' ? detail.quoteData.newHeights.toFixed(2) : detail.quoteData.newHeights}</p></div>
         </div>
         <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
       </div>
       <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight-detail.quoteData.newHeights) * screen}vw - 20px)`, top: `calc(${detail.quoteData.newHeights * screen}vw + 20px)`, position: 'relative' }}>
         <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
         <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
           <div style={{ marginLeft: '-10px', marginTop: (detail.quoteData.boxHeight-detail.quoteData.newHeights) * ((screen/2)-(screen/8))+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof (detail.quoteData.boxHeight-detail.quoteData.newHeights) === 'number' ? (detail.quoteData.boxHeight-detail.quoteData.newHeights).toFixed(2) : (detail.quoteData.boxHeight-detail.quoteData.newHeights)}</p></div>
         </div>
         <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
       </div>
     </div>
     <div style={{ width: '30px', float: 'left', paddingLeft: '0px', textAlign: 'left' }}>
     <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}vw)`, top: '0', position: 'relative' }}>
      <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
      <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
        <div style={{ marginLeft: '-10px', marginTop: detail.quoteData.boxHeight * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof detail.quoteData.boxHeight === 'number' ? detail.quoteData.boxHeight.toFixed(2) : detail.quoteData.boxHeight}</p></div>
      </div>
      <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
     </div>
   </div>
   </>
)
)}
 
</div>
<h5 style={{ marginLeft: '20px' ,color:'#36454F'}}>Hardwares</h5>
<div style={{ display: 'flex', flexWrap: 'wrap' }}>
{detail.quoteDatamesh === 'yes' && (
  <>
  <p style={{ fontSize: '15px',marginRight: '10px', color:'#36454F'}}>Mesh 3.4 SS,</p>
  <p style={{ fontSize: '15px',marginRight: '10px', color:'#36454F'}}>Steel Mesh,</p>
  </>
)}
  <p style={{ fontSize: '15px',marginRight: '10px', color:'#36454F'}}>Steel Frame,</p>
  <p style={{ fontSize: '15px',marginRight: '10px', color:'#36454F'}}>Steel Mullion,</p>
  <p style={{ fontSize: '15px',marginRight: '10px', color:'#36454F'}}>Steel Shutter,</p>
  <p style={{ fontSize: '15px',marginRight: '10px', color:'#36454F'}}>Multi Point Lock ( MPL ),</p>
  <p style={{ fontSize: '15px',marginRight: '10px', color:'#36454F'}}>Handle,</p>
  <p style={{ fontSize: '15px',marginRight: '10px', color:'#36454F'}}>Hings Set / Friction Stay,</p>
  <p style={{ fontSize: '15px',marginRight: '10px', color:'#36454F'}}>Screws/Misc</p>
</div>

             
           </div>
         </div>
       </div>
        )}
         {detail.quoteData.item === 'SlidingDoor' && (
           <div class="col-md-12 col-xxl-12 col-xl-12">
           <div class="card" style={{background:'#fff'}}>
             <div class="card-body mb-5">
             
             <div>

<div style={{float:'left', width: detail.quoteData.boxWidth*screen+'vw', height:detail.quoteData.boxHeight*screen+'vw', border:'1px solid #333'}}>
{[...Array(detail.quoteData.numFrames)].map((_, index) => (
 <div style={{float:'left', margin:'5px', border:'1px solid #333',width:`calc(${detail.quoteData.boxWidth*screen/detail.quoteData.numFrames+'vw'} - 12px)`, height:`calc(${detail.quoteData.boxHeight*screen+'vw'} - 12px)`}}> 
 {detail.quoteData.newHeights !== 0 ? (
<>
 
 { detail.quoteData.boxPosition === 'Top' ? (
     <div></div>
   ) : (
     [...Array(detail.quoteData.numFirstInnerBoxes)].map((_, index) => (
 <div style={{width: `calc(${detail.quoteData.boxWidth*screen/detail.quoteData.numFrames+'vw'} - 23px)`, height:`calc(${(detail.quoteData.newHeights * screen)}vw )`, background: 'lightgreen', margin:'5px', border:'1px solid #333'}}></div>
 ))
 )}

 <div style={{ display: 'flex' }}>
   {[...Array(detail.quoteData.numSecondInnerBoxes)].map((_, index) => (
     
     <div key={index} style={{ flex: '1', height: `calc(${(detail.quoteData.boxHeight - detail.quoteData.newHeights) * screen}vw - 30px)`, width: `calc(${detail.quoteData.newWidths[index] * screen}vw - 5px - 19px)`, backgroundColor: detail.quoteData.panelType === 1 ? 'lightgray' : detail.quoteData.panelType === 2 ? 'lightyellow' : 'lightblue', marginTop: detail.quoteData.boxPosition === 'Top' ? '5px' : '0px', marginRight: index === detail.quoteData.numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #333' }}>
     
             <div className="expanding-arrow-line" style={{ 
               position: 'absolute',
               float:'left', 
               width: `calc(${detail.quoteData.newWidths[index] * screen}vw - 15px)`,
               bottom:'-10px', 
               top: `calc(${detail.quoteData.boxHeight * screen}vw - 25px)`,
              }}>
               <div className="arrow-left"></div>
                 <div className="line" align="center">
                   <div style={{marginTop:'-7px',background:'white',maxWidth:'20px',textAlign:'center'}}><p>{typeof detail.quoteData.newWidths[index] === 'number' ? detail.quoteData.newWidths[index].toFixed(2) : detail.quoteData.newWidths[index]}</p></div>
                 </div>
               <div className="arrow-right"></div>
             </div> 


      <div style={{
       height:'5px',
       width: '1px',
       float: 'right',
       right: '0px',
       marginTop: '-6px',
       marginRight: '-1px',
       background: index === (detail.quoteData.numSecondInnerBoxes-1) ? 'transparent' : 'black',
    }}></div>

   <div style={{
       height:'7px',
       width: '1px',
       float: 'right',
       right: '0px',
       marginTop: `calc(${(detail.quoteData.boxHeight-detail.quoteData.newHeights) * screen}vw - 31px)`,
       marginRight: '-1px',
       background: index === (detail.quoteData.numSecondInnerBoxes-1) ? 'transparent' : 'black',
    }}></div>
      
      
           { detail.quoteData.boxNames[index] !== 'Shutter' ? (<></>) : (
             <img
               src={Handle}
               height = {deviceWidth > 700 ? '10px' : '20px'}
               style={{
                 float: detail.quoteData.boxHandle[index] === 'left' ? 'left' : 'right',
                 marginTop: ((detail.quoteData.boxHeight-detail.quoteData.newHeights) * ((screen/2)-(screen/8)))+'vw',
                 marginLeft: detail.quoteData.boxHandle[index] === 'left' ? '2px' : '0px',
                 marginRight: detail.quoteData.boxHandle[index] === 'left' ? '0px' : '4px',
                 width: 'auto'
               }}
               
               alt="Handle"
             />
           )}

           {mesh === 'yes' && detail.quoteData.boxNames[index] === 'Shutter' && index % 2 === 0 && (
               <img src={meshimg} style={{width:'50px', float:'left', marginLeft: detail.quoteData.boxHandle[index] === 'left' ? '-6px' : '0px', marginTop: `calc(${((detail.quoteData.boxHeight-detail.quoteData.newHeights)*screen)}vw - 80px)`}} />
               )
           }
           
             

     </div>

   ))}
   
 </div>
 { detail.quoteData.boxPosition === 'Bottom' ? (
     <div></div>
   ) : (
     [...Array(detail.quoteData.numFirstInnerBoxes)].map((_, index) => (
 <div style={{width: `calc(${detail.quoteData.boxWidth*screen/detail.quoteData.numFrames+'vw'} - 23px)`, height:`calc(${(detail.quoteData.newHeights * screen)}vw )`, background: 'lightgreen', margin:'5px', border:'1px solid #333'}}></div>
 ))
 )}

 { detail.quoteData.numSecondInnerBoxes === 0 ? (
       <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((detail.quoteData.boxWidth/detail.quoteData.numFrames)* screen)+'vw',top:(detail.quoteData.boxHeight-detail.quoteData.newHeights)*screen+'vw', }} >
       <div className="arrow-left"></div>
       <div className="line" align="center">
         <div style={{marginTop:'-8px',background:'white',maxWidth:'40px',textAlign:'center'}}><p>{typeof (detail.quoteData.boxWidth/detail.quoteData.numFrames) === 'number' ? (detail.quoteData.boxWidth/detail.quoteData.numFrames).toFixed(2) : (detail.quoteData.boxWidth/detail.quoteData.numFrames)}</p></div>
       </div>
       <div className="arrow-right"></div>
     </div>
     ) : (
       <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((detail.quoteData.boxWidth/detail.quoteData.numFrames)* screen)+'vw',bottom:'-10px',top:'50px', }} >
         <div className="arrow-left"></div>
         <div className="line" align="center">
           <div style={{marginTop:'-8px',background:'white',maxWidth:'40px',textAlign:'center'}}><p>{typeof (detail.quoteData.boxWidth/detail.quoteData.numFrames) === 'number' ? (detail.quoteData.boxWidth/detail.quoteData.numFrames).toFixed(2) : (detail.quoteData.boxWidth/detail.quoteData.numFrames)}</p></div>
         </div>
         <div className="arrow-right"></div>
       </div>
     )}
</>
) : (
<>
<div style={{ display: 'flex' }}>
 {[...Array(detail.quoteData.numSecondInnerBoxes)].map((_, index) => (
   <>
   
   <div key={index} style={{ flex: '1', height: `calc(${(detail.quoteData.boxHeight) * screen}vw - 23px)`, width: `calc(${detail.quoteData.newWidths[index] * screen}vw - 5px - 19px)`, backgroundColor: detail.quoteData.panelType === 1 ? 'lightgray' : detail.quoteData.panelType === 2 ? 'lightyellow' : 'lightblue', marginRight: index === detail.quoteData.numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #333',marginTop:'5px' }}>
           
            <div className="expanding-arrow-line" style={{ 
               position: 'absolute',
               float:'left', 
               width: `calc(${detail.quoteData.newWidths[index] * screen}vw - 15px)`,
               bottom:'-10px', 
               top: `calc(${detail.quoteData.boxHeight * screen}vw - 35px)`,
              }}>
               <div className="arrow-left"></div>
                 <div className="line" align="center">
                   <div style={{marginTop:'-7px',background:'white',maxWidth:'20px',textAlign:'center'}}><p>{typeof detail.quoteData.newWidths[index] === 'number' ? detail.quoteData.newWidths[index].toFixed(2) : detail.quoteData.newWidths[index]}</p></div>
                 </div>
               <div className="arrow-right"></div>
             </div> 


   <div style={{
       height:'5px',
       width: '1px',
       float: 'right',
       right: '0px',
       marginTop: '-6px',
       marginRight: '-1px',
       background: index === (detail.quoteData.numSecondInnerBoxes-1) ? 'transparent' : 'black',
    }}></div>

   <div style={{
       height:'5px',
       width: '1px',
       float: 'right',
       right: '0px',
       marginTop: `calc(${(detail.quoteData.boxHeight) * screen}vw - 24px)`,
       marginRight: '-1px',
       background: index === (detail.quoteData.numSecondInnerBoxes-1) ? 'transparent' : 'black',
    }}></div>
        
        
          { detail.quoteData.boxNames[index] !== 'Shutter' ? (<></>) : (
             <img
               src={Handle}
               height = {deviceWidth > 700 ? '10px' : '20px'}
               style={{
                 float: detail.quoteData.boxHandle[index] === 'left' ? 'left' : 'right',
                 marginTop: (detail.quoteData.boxHeight) * ((screen/2)-(screen/13))+'vw',
                 marginLeft: detail.quoteData.boxHandle[index] === 'left' ? '2px' : '0px',
                 marginRight: detail.quoteData.boxHandle[index] === 'left' ? '0px' : '4px',
                 width: 'auto'
               }}
               
               alt="Handle"
             />
           )}
            
           {mesh === 'yes' && detail.quoteData.boxNames[index] === 'Shutter' && index % 2 === 0 && (
               <img src={meshimg} style={{width:'50px', float:'left', marginLeft: detail.quoteData.boxHandle[index] === 'left' ? '-6px' : '0px', marginTop: `calc(${((detail.quoteData.boxHeight-detail.quoteData.newHeights)*screen)}vw - 75px)`}} />
               )
           }
          
   </div>
   </>
 ))}
</div> 
     { detail.quoteData.numSecondInnerBoxes === 0 ? (
       <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((detail.quoteData.boxWidth/detail.quoteData.numFrames)* screen)+'vw', top:detail.quoteData.boxHeight*screen+'vw',  }} >
       <div className="arrow-left"></div>
       <div className="line" align="center">
         <div style={{marginTop:'-8px',background:'white',maxWidth:'40px',textAlign:'center'}}><p>{typeof (detail.quoteData.boxWidth/detail.quoteData.numFrames) === 'number' ? (detail.quoteData.boxWidth/detail.quoteData.numFrames).toFixed(2) : (detail.quoteData.boxWidth/detail.quoteData.numFrames)}</p></div>
       </div>
       <div className="arrow-right"></div>
     </div>
     ) : (
       <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((detail.quoteData.boxWidth/detail.quoteData.numFrames)* screen)+'vw', bottom:'-10px',top:'40px', }} >
         <div className="arrow-left"></div>
         <div className="line" align="center">
           <div style={{marginTop:'-8px',background:'white',maxWidth:'40px',textAlign:'center'}}><p>{typeof (detail.quoteData.boxWidth/detail.quoteData.numFrames) === 'number' ? (detail.quoteData.boxWidth/detail.quoteData.numFrames).toFixed(2) : (detail.quoteData.boxWidth/detail.quoteData.numFrames)}</p></div>
         </div>
         <div className="arrow-right"></div>
       </div>
     )}
</>
 )}
 </div>
  ))}
</div>

{ detail.quoteData.boxPosition === 'Top' ? (
 detail.quoteData.newHeights === 0 ? (

   <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
     <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}vw)`, top: '0', position: 'relative' }}>
      <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
      <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
        <div style={{ marginLeft: '-10px', marginTop: detail.quoteData.boxHeight * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof detail.quoteData.boxHeight === 'number' ? detail.quoteData.boxHeight.toFixed(2) : detail.quoteData.boxHeight}</p></div>
      </div>
      <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
     </div>
   </div>
) : (  
     <>
       <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>

         <div className="expanding-arrow-line" style={{order: 1, flex:1, float: 'left', height: `calc(${(detail.quoteData.boxHeight-detail.quoteData.newHeights) * screen}vw - 20px)`, top: '0', position: 'relative' }}>
           <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
           <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
             <div style={{ marginLeft: '-10px', marginTop: (detail.quoteData.boxHeight-detail.quoteData.newHeights) * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof (detail.quoteData.boxHeight-detail.quoteData.newHeights) === 'number' ? (detail.quoteData.boxHeight-detail.quoteData.newHeights).toFixed(2) : (detail.quoteData.boxHeight-detail.quoteData.newHeights)}</p></div>
           </div>
           <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
         </div>
          <div className="expanding-arrow-line" style={{order: 2, flex:2, float: 'left', height: `calc(${detail.quoteData.newHeights * screen}vw `, top: `calc(${(detail.quoteData.boxHeight-detail.quoteData.newHeights) * screen}vw - 10px)`, position: 'relative' }}>
           <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
           <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
             <div style={{ marginLeft: '-10px', marginTop: detail.quoteData.newHeights * (((screen/2)-(screen/5)))+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof detail.quoteData.newHeights === 'number' ? detail.quoteData.newHeights.toFixed(2) : detail.quoteData.newHeights}</p></div>
           </div>
           <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
         </div>

       </div>

       <div style={{ width: '30px', float: 'left', paddingLeft: '0px', textAlign: 'left' }}>
       <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}vw)`, top: '0', position: 'relative' }}>
        <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
        <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
          <div style={{ marginLeft: '-10px', marginTop: detail.quoteData.boxHeight * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof detail.quoteData.boxHeight === 'number' ? detail.quoteData.boxHeight.toFixed(2) : detail.quoteData.boxHeight}</p></div>
        </div>
        <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
       </div>
     </div>
     </>
)
) : (
detail.quoteData.newHeights === 0 ? (

   <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
     <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}vw)`, top: '0', position: 'relative' }}>
      <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
      <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
        <div style={{ marginLeft: '-10px', marginTop: detail.quoteData.boxHeight * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof detail.quoteData.boxHeight === 'number' ? detail.quoteData.boxHeight.toFixed(2) : detail.quoteData.boxHeight}</p></div>
      </div>
      <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
     </div>
   </div>
) : (  
     <>
       <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
         <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${detail.quoteData.newHeights * screen}vw + 10px)`, top: '0', position: 'relative' }}>
           <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
           <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
             <div style={{ marginLeft: '-10px', marginTop: detail.quoteData.newHeights * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof detail.quoteData.newHeights === 'number' ? detail.quoteData.newHeights.toFixed(2) : detail.quoteData.newHeights}</p></div>
           </div>
           <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
         </div>
         <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight-detail.quoteData.newHeights) * screen}vw - 20px)`, top: `calc(${detail.quoteData.newHeights * screen}vw + 20px)`, position: 'relative' }}>
           <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
           <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
             <div style={{ marginLeft: '-10px', marginTop: (detail.quoteData.boxHeight-detail.quoteData.newHeights) * ((screen/2)-(screen/8))+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof (detail.quoteData.boxHeight-detail.quoteData.newHeights) === 'number' ? (detail.quoteData.boxHeight-detail.quoteData.newHeights).toFixed(2) : (detail.quoteData.boxHeight-detail.quoteData.newHeights)}</p></div>
           </div>
           <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
         </div>
       </div>
       <div style={{ width: '30px', float: 'left', paddingLeft: '0px', textAlign: 'left' }}>
       <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}vw)`, top: '0', position: 'relative' }}>
        <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
        <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
          <div style={{ marginLeft: '-10px', marginTop: detail.quoteData.boxHeight * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof detail.quoteData.boxHeight === 'number' ? detail.quoteData.boxHeight.toFixed(2) : detail.quoteData.boxHeight}</p></div>
        </div>
        <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
       </div>
     </div>
     </>
)
)}
   
</div>
<h5 style={{ marginLeft: '20px', color:'#36454F' }}>Hardwares</h5>
<div style={{ display: 'flex', flexWrap: 'wrap' }}>
  <p style={{ fontSize: '15px',marginRight: '10px', color:'#36454F'}}>Steel Frame,</p>
  <p style={{ fontSize: '15px',marginRight: '10px', color:'#36454F'}}>Steel Shutter,</p>
  <p style={{ fontSize: '15px',marginRight: '10px', color:'#36454F'}}>Aluminium Track,</p>
  <p style={{ fontSize: '15px',marginRight: '10px', color:'#36454F'}}>Bump Arrester,</p>
  <p style={{ fontSize: '15px',marginRight: '10px', color:'#36454F'}}>Jump Stopper,</p>
  <p style={{ fontSize: '15px',marginRight: '10px', color:'#36454F'}}>Dust Arrester,</p>
  <p style={{ fontSize: '15px',marginRight: '10px', color:'#36454F'}}>Grove Roller,</p>
  <p style={{ fontSize: '15px',marginRight: '10px', color:'#36454F'}}>Touch Lock,</p>
  <p style={{ fontSize: '15px',marginRight: '10px', color:'#36454F'}}>Screws/Misc</p>
</div>
               
             </div>
           </div>
         </div>
        )}
        {detail.quoteData.item === 'SlidingWindow' && (
           <div class="col-md-12 col-xxl-12 col-xl-12">
           <div class="card" style={{background:'#fff'}}>
             <div class="card-body mb-5">
             
             <div>

<div style={{float:'left', width: detail.quoteData.boxWidth*screen+'vw', height:detail.quoteData.boxHeight*screen+'vw', border:'1px solid #333'}}>
{[...Array(detail.quoteData.numFrames)].map((_, index) => (
 <div style={{float:'left', margin:'5px', border:'1px solid #333',width:`calc(${detail.quoteData.boxWidth*screen/detail.quoteData.numFrames+'vw'} - 12px)`, height:`calc(${detail.quoteData.boxHeight*screen+'vw'} - 12px)`}}> 
 {detail.quoteData.newHeights !== 0 ? (
<>
 
 { detail.quoteData.boxPosition === 'Top' ? (
     <div></div>
   ) : (
     [...Array(detail.quoteData.numFirstInnerBoxes)].map((_, index) => (
 <div style={{width: `calc(${detail.quoteData.boxWidth*screen/detail.quoteData.numFrames+'vw'} - 23px)`, height:`calc(${(detail.quoteData.newHeights * screen)}vw )`, background: 'lightgreen', margin:'5px', border:'1px solid #333'}}></div>
 ))
 )}

 <div style={{ display: 'flex' }}>
   {[...Array(detail.quoteData.numSecondInnerBoxes)].map((_, index) => (
     <div key={index} style={{ flex: '1', height: `calc(${(detail.quoteData.boxHeight - detail.quoteData.newHeights) * screen}vw - 30px)`, width: `calc(${detail.quoteData.newWidths[index] * screen}vw - 5px - 19px)`, backgroundColor: detail.quoteData.boxColors[index] || 'lightblue', marginTop: detail.quoteData.boxPosition === 'Top' ? '5px' : '0px', marginRight: index === detail.quoteData.numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #333' }}>
     
             <div className="expanding-arrow-line" style={{ 
               position: 'absolute',
               float:'left', 
               width: `calc(${detail.quoteData.newWidths[index] * screen}vw - 15px)`,
               bottom:'-10px', 
               top: `calc(${detail.quoteData.boxHeight * screen}vw - 25px)`,
              }}>
               <div className="arrow-left"></div>
                 <div className="line" align="center">
                   <div style={{marginTop:'-7px',background:'white',maxWidth:'20px',textAlign:'center'}}><p>{typeof detail.quoteData.newWidths[index] === 'number' ? detail.quoteData.newWidths[index].toFixed(2) : detail.quoteData.newWidths[index]}</p></div>
                 </div>
               <div className="arrow-right"></div>
             </div>
     
      <div style={{
       height:'5px',
       width: '1px',
       float: 'right',
       right: '0px',
       marginTop: '-6px',
       marginRight: '-1px',
       background: index === (detail.quoteData.numSecondInnerBoxes-1) ? 'transparent' : 'black',
    }}></div>

   <div style={{
       height:'7px',
       width: '1px',
       float: 'right',
       right: '0px',
       marginTop: `calc(${(detail.quoteData.boxHeight-detail.quoteData.newHeights) * screen}vw - 31px)`,
       marginRight: '-1px',
       background: index === (detail.quoteData.numSecondInnerBoxes-1) ? 'transparent' : 'black',
    }}></div>
      
      
           { detail.quoteData.boxNames[index] !== 'Shutter' ? (<></>) : (
             <img
               src={Handle}
               height = {deviceWidth > 700 ? '10px' : '20px'}
               style={{
                 float: detail.quoteData.boxHandle[index] === 'left' ? 'left' : 'right',
                 marginTop: ((detail.quoteData.boxHeight-detail.quoteData.newHeights) * ((screen/2)-(screen/8)))+'vw',
                 marginLeft: detail.quoteData.boxHandle[index] === 'left' ? '2px' : '0px',
                 marginRight: detail.quoteData.boxHandle[index] === 'left' ? '0px' : '4px',
                 width: 'auto'
               }}
               
               alt="Handle"
             />
           )}
           
           {mesh === 'yes' && detail.quoteData.boxNames[index] === 'Shutter' && index % 2 === 0 && (
               <img src={meshimg} style={{width:'50px', float:'left', marginLeft: detail.quoteData.boxHandle[index] === 'left' ? '-6px' : '0px', marginTop: `calc(${((detail.quoteData.boxHeight-detail.quoteData.newHeights)*screen)}vw - 82px)`}} />
               )
           }

     </div>

   ))}
   
 </div>
 { detail.quoteData.boxPosition === 'Bottom' ? (
     <div></div>
   ) : (
     [...Array(detail.quoteData.numFirstInnerBoxes)].map((_, index) => (
 <div style={{width: `calc(${detail.quoteData.boxWidth*screen/detail.quoteData.numFrames+'vw'} - 23px)`, height:`calc(${(detail.quoteData.newHeights * screen)}vw )`, background: 'lightgreen', margin:'5px', border:'1px solid #333'}}></div>
 ))
 )}

 { detail.quoteData.numSecondInnerBoxes === 0 ? (
       <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((detail.quoteData.boxWidth/detail.quoteData.numFrames)* screen)+'vw',top:(detail.quoteData.boxHeight-detail.quoteData.newHeights)*screen+'vw', }} >
       <div className="arrow-left"></div>
       <div className="line" align="center">
         <div style={{marginTop:'-8px',background:'white',maxWidth:'40px',textAlign:'center'}}><p>{typeof (detail.quoteData.boxWidth/detail.quoteData.numFrames) === 'number' ? (detail.quoteData.boxWidth/detail.quoteData.numFrames).toFixed(2) : (detail.quoteData.boxWidth/detail.quoteData.numFrames)}</p></div>
       </div>
       <div className="arrow-right"></div>
     </div>
     ) : (
       <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((detail.quoteData.boxWidth/detail.quoteData.numFrames)* screen)+'vw',bottom:'-10px',top:'50px', }} >
         <div className="arrow-left"></div>
         <div className="line" align="center">
           <div style={{marginTop:'-8px',background:'white',maxWidth:'40px',textAlign:'center'}}><p>{typeof (detail.quoteData.boxWidth/detail.quoteData.numFrames) === 'number' ? (detail.quoteData.boxWidth/detail.quoteData.numFrames).toFixed(2) : (detail.quoteData.boxWidth/detail.quoteData.numFrames)}</p></div>
         </div>
         <div className="arrow-right"></div>
       </div>
     )}
</>
) : (
<>
<div style={{ display: 'flex' }}>
 {[...Array(detail.quoteData.numSecondInnerBoxes)].map((_, index) => (
   <>
   
   <div key={index} style={{ flex: '1', height: `calc(${(detail.quoteData.boxHeight) * screen}vw - 24px)`, width: `calc(${detail.quoteData.newWidths[index] * screen}vw - 5px - 19px)`, backgroundColor: detail.quoteData.boxColors[index] || 'lightblue', marginRight: index === detail.quoteData.numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #333',marginTop:'5px' }}>
        

             <div className="expanding-arrow-line" style={{ 
               position: 'absolute',
               float:'left', 
               width: `calc(${detail.quoteData.newWidths[index] * screen}vw - 15px)`,
               bottom:'-10px', 
               top: `calc(${detail.quoteData.boxHeight * screen}vw - 35px)`,
              }}>
               <div className="arrow-left"></div>
                 <div className="line" align="center">
                   <div style={{marginTop:'-7px',background:'white',maxWidth:'20px',textAlign:'center'}}><p>{typeof detail.quoteData.newWidths[index] === 'number' ? detail.quoteData.newWidths[index].toFixed(2) : detail.quoteData.newWidths[index]}</p></div>
                 </div>
               <div className="arrow-right"></div>
             </div>


   <div style={{
       height:'5px',
       width: '1px',
       float: 'right',
       right: '0px',
       marginTop: '-6px',
       marginRight: '-1px',
       background: index === (detail.quoteData.numSecondInnerBoxes-1) ? 'transparent' : 'black',
    }}></div>

   <div style={{
       height:'5px',
       width: '1px',
       float: 'right',
       right: '0px',
       marginTop: `calc(${(detail.quoteData.boxHeight) * screen}vw - 24px)`,
       marginRight: '-1px',
       background: index === (detail.quoteData.numSecondInnerBoxes-1) ? 'transparent' : 'black',
    }}></div>
        
        
          { detail.quoteData.boxNames[index] !== 'Shutter' ? (<></>) : (
             <img
               src={Handle}
               height = {deviceWidth > 700 ? '10px' : '20px'}
               style={{
                 float: detail.quoteData.boxHandle[index] === 'left' ? 'left' : 'right',
                 marginTop: (detail.quoteData.boxHeight) * ((screen/2)-(screen/13))+'vw',
                 marginLeft: detail.quoteData.boxHandle[index] === 'left' ? '2px' : '0px',
                 marginRight: detail.quoteData.boxHandle[index] === 'left' ? '0px' : '4px',
                 width: 'auto'
               }}
               
               alt="Handle"
             />
           )}
             
           {mesh === 'yes' && detail.quoteData.boxNames[index] === 'Shutter' && index % 2 === 0 && (
               <img src={meshimg} style={{width:'50px', float:'left', marginLeft: detail.quoteData.boxHandle[index] === 'left' ? '-6px' : '0px', marginTop: `calc(${((detail.quoteData.boxHeight-detail.quoteData.newHeights)*screen)}vw - 75px)`}} />
               )
           }
          
   </div>
   </>
 ))}
</div> 
     { detail.quoteData.numSecondInnerBoxes === 0 ? (
       <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((detail.quoteData.boxWidth/detail.quoteData.numFrames)* screen)+'vw', top:detail.quoteData.boxHeight*screen+'vw',  }} >
       <div className="arrow-left"></div>
       <div className="line" align="center">
         <div style={{marginTop:'-8px',background:'white',maxWidth:'40px',textAlign:'center'}}><p>{typeof (detail.quoteData.boxWidth/detail.quoteData.numFrames) === 'number' ? (detail.quoteData.boxWidth/detail.quoteData.numFrames).toFixed(2) : (detail.quoteData.boxWidth/detail.quoteData.numFrames)}</p></div>
       </div>
       <div className="arrow-right"></div>
     </div>
     ) : (
       <div className="expanding-arrow-line" style={{ float:'left', marginLeft:'-5px', width:((detail.quoteData.boxWidth/detail.quoteData.numFrames)* screen)+'vw', bottom:'-10px',top:'40px', }} >
         <div className="arrow-left"></div>
         <div className="line" align="center">
           <div style={{marginTop:'-8px',background:'white',maxWidth:'40px',textAlign:'center'}}><p>{typeof (detail.quoteData.boxWidth/detail.quoteData.numFrames) === 'number' ? (detail.quoteData.boxWidth/detail.quoteData.numFrames).toFixed(2) : (detail.quoteData.boxWidth/detail.quoteData.numFrames)}</p></div>
         </div>
         <div className="arrow-right"></div>
       </div>
     )}
</>
 )}
 </div>
  ))}
</div>

{ detail.quoteData.boxPosition === 'Top' ? (
 detail.quoteData.newHeights === 0 ? (

   <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
     <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}vw)`, top: '0', position: 'relative' }}>
      <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
      <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
        <div style={{ marginLeft: '-10px', marginTop: detail.quoteData.boxHeight * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof detail.quoteData.boxHeight === 'number' ? detail.quoteData.boxHeight.toFixed(2) : detail.quoteData.boxHeight}</p></div>
      </div>
      <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
     </div>
   </div>
) : (  
     <>
       <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>

         <div className="expanding-arrow-line" style={{order: 1, flex:1, float: 'left', height: `calc(${(detail.quoteData.boxHeight-detail.quoteData.newHeights) * screen}vw - 20px)`, top: '0', position: 'relative' }}>
           <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
           <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
             <div style={{ marginLeft: '-10px', marginTop: (detail.quoteData.boxHeight-detail.quoteData.newHeights) * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof (detail.quoteData.boxHeight-detail.quoteData.newHeights) === 'number' ? (detail.quoteData.boxHeight-detail.quoteData.newHeights).toFixed(2) : (detail.quoteData.boxHeight-detail.quoteData.newHeights)}</p></div>
           </div>
           <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
         </div>
          <div className="expanding-arrow-line" style={{order: 2, flex:2, float: 'left', height: `calc(${detail.quoteData.newHeights * screen}vw `, top: `calc(${(detail.quoteData.boxHeight-detail.quoteData.newHeights) * screen}vw - 10px)`, position: 'relative' }}>
           <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
           <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
             <div style={{ marginLeft: '-10px', marginTop: detail.quoteData.newHeights * (((screen/2)-(screen/5)))+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof detail.quoteData.newHeights === 'number' ? detail.quoteData.newHeights.toFixed(2) : detail.quoteData.newHeights}</p></div>
           </div>
           <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
         </div>

       </div>

       <div style={{ width: '30px', float: 'left', paddingLeft: '0px', textAlign: 'left' }}>
       <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}vw)`, top: '0', position: 'relative' }}>
        <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
        <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
          <div style={{ marginLeft: '-10px', marginTop: detail.quoteData.boxHeight * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof detail.quoteData.boxHeight === 'number' ? detail.quoteData.boxHeight.toFixed(2) : detail.quoteData.boxHeight}</p></div>
        </div>
        <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
       </div>
     </div>
     </>
)
) : (
detail.quoteData.newHeights === 0 ? (

   <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
     <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}vw)`, top: '0', position: 'relative' }}>
      <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
      <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
        <div style={{ marginLeft: '-10px', marginTop: detail.quoteData.boxHeight * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof detail.quoteData.boxHeight === 'number' ? detail.quoteData.boxHeight.toFixed(2) : detail.quoteData.boxHeight}</p></div>
      </div>
      <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
     </div>
   </div>
) : (  
     <>
       <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
         <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${detail.quoteData.newHeights * screen}vw + 10px)`, top: '0', position: 'relative' }}>
           <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
           <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
             <div style={{ marginLeft: '-10px', marginTop: detail.quoteData.newHeights * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof detail.quoteData.newHeights === 'number' ? detail.quoteData.newHeights.toFixed(2) : detail.quoteData.newHeights}</p></div>
           </div>
           <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
         </div>
         <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight-detail.quoteData.newHeights) * screen}vw - 20px)`, top: `calc(${detail.quoteData.newHeights * screen}vw + 20px)`, position: 'relative' }}>
           <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
           <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
             <div style={{ marginLeft: '-10px', marginTop: (detail.quoteData.boxHeight-detail.quoteData.newHeights) * ((screen/2)-(screen/8))+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof (detail.quoteData.boxHeight-detail.quoteData.newHeights) === 'number' ? (detail.quoteData.boxHeight-detail.quoteData.newHeights).toFixed(2) : (detail.quoteData.boxHeight-detail.quoteData.newHeights)}</p></div>
           </div>
           <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
         </div>
       </div>
       <div style={{ width: '30px', float: 'left', paddingLeft: '0px', textAlign: 'left' }}>
       <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}vw)`, top: '0', position: 'relative' }}>
        <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
        <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
          <div style={{ marginLeft: '-10px', marginTop: detail.quoteData.boxHeight * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof detail.quoteData.boxHeight === 'number' ? detail.quoteData.boxHeight.toFixed(2) : detail.quoteData.boxHeight}</p></div>
        </div>
        <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
       </div>
     </div>
     </>
)
)}
   
</div>
<h5 style={{ marginLeft: '20px', color:'#36454F' }}>Hardwares</h5>
<div style={{ display: 'flex', flexWrap: 'wrap' }}>
  <p style={{ fontSize: '15px',marginRight: '10px', color:'#36454F'}}>Steel Frame,</p>
  <p style={{ fontSize: '15px',marginRight: '10px', color:'#36454F'}}>Steel Shutter,</p>
  {detail.quoteData.newHeights > 0 &&(
    <>
  <p style={{ fontSize: '15px',marginRight: '10px', color:'#36454F'}}>Steel Mullion</p>
  </>
  )}
  {detail.quoteData.mesh === 'yes' && (
    <>
  <p style={{ fontSize: '15px',marginRight: '10px', color:'#36454F'}}>Mesh 30A Grade,</p>
  <p style={{ fontSize: '15px',marginRight: '10px', color:'#36454F'}}>Mesh Roller,</p>
  <p style={{ fontSize: '15px',marginRight: '10px', color:'#36454F'}}>Steel Mesh,</p>

  </>
  )}
  <p style={{ fontSize: '15px',marginRight: '10px', color:'#36454F'}}>Weather Strip,</p>
  <p style={{ fontSize: '15px',marginRight: '10px', color:'#36454F'}}>Touch Lock with SS Receiver,</p>
  <p style={{ fontSize: '15px',marginRight: '10px', color:'#36454F'}}>Grove Roller,</p>
  <p style={{ fontSize: '15px',marginRight: '10px', color:'#36454F'}}>Dust Arrester,</p>
  <p style={{ fontSize: '15px',marginRight: '10px', color:'#36454F'}}>Jump Stopper,</p>
  <p style={{ fontSize: '15px',marginRight: '10px', color:'#36454F'}}>Bump Arrester,</p>
  <p style={{ fontSize: '15px',marginRight: '10px', color:'#36454F'}}>Aluminium Track,</p>
  <p style={{ fontSize: '15px',marginRight: '10px', color:'#36454F'}}>InterLock</p>
</div>
               
             </div>
           </div>
         </div>
        )}
  
  {detail.quoteData.item === 'Ventilator' && (
           <div class="col-md-12 col-xxl-12 col-xl-12">
           <div class="card" style={{background:'#fff'}}>
             <div class="card-body mb-5">
              <div>
          {detail.quoteData.boxHeight > 1 && detail.quoteData.boxWidth > 1 && (  
           <>

           <div style={{float:'left', width: detail.quoteData.boxWidth*screen+'vw', height:detail.quoteData.boxHeight*screen+'vw', border:'1px solid #333'}}>
          
               {detail.quoteData.fanTop === 'Top' && detail.quoteData.fanLeft === 'Left' && divider === 'Vertical' && switchs === 'on' && (
                 <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                   <img src={Fan} style={{float:'left', width: .85*screen+'vw', marginLeft: '4px', marginTop: '4px'}} />
                   <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight) * screen}vw - 20px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}vw - 26px)`, margin: '5px', border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(6/detail.quoteData.boxHeight)*screen}%, lightblue ${(6/detail.quoteData.boxHeight)*screen}%)`}}></div>
                   <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(.85) * screen}vw - 0px)`, margin: '4px', marginTop: `calc(-${(detail.quoteData.boxHeight-.85) * screen}vw + 20px)`, border:'1px solid #333', background: 'lightblue'}}></div>
                 </div>
               )}
               
               {detail.quoteData.fanTop === 'Bottom' && detail.quoteData.fanLeft === 'Left'  && divider === 'Vertical' && switchs === 'on' && (
                   <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                     <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(.85) * screen}vw - 0px)`, margin: '4px', marginTop: '5px', border:'1px solid #333', background: 'lightblue'}}></div>
                     <div style={{float:'right', height: `calc(${(detail.quoteData.boxHeight) * screen}vw - 20px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}vw - 26px)`, margin: '5px', marginTop: `calc(-${(detail.quoteData.boxHeight-.85) * screen}vw + 20px)`, border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(6/detail.quoteData.boxHeight)*screen}%, lightblue ${(6/detail.quoteData.boxHeight)*screen}%)`}}></div>
                     <img src={Fan} style={{float:'left', width: .85*screen+'vw', marginLeft: '4px', marginBottom: '4px'}} />
                   </div>
               )}

               {detail.quoteData.fanTop === 'Top' && detail.quoteData.fanLeft === 'Right' &&  divider === 'Vertical' && switchs === 'on' && (
                 <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                   <img src={Fan} style={{float:'right', width: .85*screen+'vw', marginRight: '4px', marginTop: '4px'}} />
                   <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight) * screen}vw - 20px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}vw - 26px)`, margin: '5px', border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(6/detail.quoteData.boxHeight)*screen}%, lightblue ${(6/detail.quoteData.boxHeight)*screen}%)`}}></div>
                   <div style={{float:'right', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(.85) * screen}vw - 0px)`, margin: '4px', marginTop: `calc(-${(detail.quoteData.boxHeight-.85) * screen}vw + 20px)`, border:'1px solid #333', background: 'lightblue'}}></div>
                 </div>
               )}

               {detail.quoteData.fanTop === 'Bottom' && detail.quoteData.fanLeft === 'Right' &&  divider === 'Vertical' && switchs === 'on' && (
                 <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                   <div style={{float:'right', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(.85) * screen}vw - 0px)`, margin: '4px', marginTop: '5px', border:'1px solid #333', background: 'lightblue'}}></div>
                   <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight) * screen}vw - 20px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}vw - 26px)`, margin: '5px', marginTop: `calc(-${(detail.quoteData.boxHeight-.85) * screen}vw + 20px)`, border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(6/detail.quoteData.boxHeight)*screen}%, lightblue ${(6/detail.quoteData.boxHeight)*screen}%)`}}></div>
                   <img src={Fan} style={{float:'right', width: .85*screen+'vw', marginRight: '4px', marginBottom: '4px'}} />
                 </div>
               )}

               {detail.quoteData.fanTop === 'Top' && detail.quoteData.fanLeft === 'Left' &&  divider === 'Vertical' && switchs === 'off' && (
                 <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                   <img src={Fan} style={{float:'left', width: .85*screen+'vw', marginLeft: '4px', marginTop: '4px'}} />
                   <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight) * screen}vw - 20px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}vw - 26px)`, margin: '5px', border:'1px solid #333', background: 'lightblue'}}></div>
                   <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(.85) * screen}vw - 0px)`, margin: '4px', marginTop: `calc(-${(detail.quoteData.boxHeight-.85) * screen}vw + 20px)`, border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/detail.quoteData.boxHeight)*screen}%, lightblue ${(10/detail.quoteData.boxHeight)*screen}%)`}}></div>
                 </div>
               )}
               
               {detail.quoteData.fanTop === 'Bottom' && detail.quoteData.fanLeft === 'Left' && divider === 'Vertical' && switchs === 'off' && (
                   <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                     <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(.85) * screen}vw - 0px)`, margin: '4px', marginTop: '5px', border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/detail.quoteData.boxHeight)*screen}%, lightblue ${(10/detail.quoteData.boxHeight)*screen}%)`}}></div>
                     <div style={{float:'right', height: `calc(${(detail.quoteData.boxHeight) * screen}vw - 20px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}vw - 26px)`, margin: '5px', marginTop: `calc(-${(detail.quoteData.boxHeight-.85) * screen}vw + 20px)`, border:'1px solid #333', background: 'lightblue'}}></div>
                     <img src={Fan} style={{float:'left', width: .85*screen+'vw', marginLeft: '4px', marginBottom: '4px'}} />
                   </div>
               )}

               {detail.quoteData.fanTop === 'Top' && detail.quoteData.fanLeft === 'Right' &&  divider === 'Vertical' && switchs === 'off' && (
                 <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                   <img src={Fan} style={{float:'right', width: .85*screen+'vw', marginRight: '4px', marginTop: '4px'}} />
                   <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight) * screen}vw - 20px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}vw - 26px)`, margin: '5px', border:'1px solid #333', background: 'lightblue'}}></div>
                   <div style={{float:'right', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(.85) * screen}vw - 0px)`, margin: '4px', marginTop: `calc(-${(detail.quoteData.boxHeight-.85) * screen}vw + 20px)`, border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/detail.quoteData.boxHeight)*screen}%, lightblue ${(10/detail.quoteData.boxHeight)*screen}%)`}}></div>
                 </div>
               )}

               {detail.quoteData.fanTop === 'Bottom' && detail.quoteData.fanLeft === 'Right'  && divider === 'Vertical' && switchs === 'off' && (
                 <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                   <div style={{float:'right', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(.85) * screen}vw - 0px)`, margin: '4px', marginTop: '5px', border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/detail.quoteData.boxHeight)*screen}%, lightblue ${(10/detail.quoteData.boxHeight)*screen}%)`}}></div>
                   <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight) * screen}vw - 20px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}vw - 26px)`, margin: '5px', marginTop: `calc(-${(detail.quoteData.boxHeight-.85) * screen}vw + 20px)`, border:'1px solid #333', background: 'lightblue'}}></div>
                   <img src={Fan} style={{float:'right', width: .85*screen+'vw', marginRight: '4px', marginBottom: '4px'}} />
                 </div>
               )}
               
               {detail.quoteData.fanTop === 'Top' && detail.quoteData.fanLeft === 'Left' && divider === 'Horizontal' && switchs === 'on' && (
                 <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                   <img src={Fan} style={{float:'left', width: .85*screen+'vw', marginLeft: '4px', marginTop: '4px'}} />
                   <div style={{float:'right', height: `calc(${(.85) * screen}vw - 0px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}vw - 26px)`, margin: '5px',  border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/detail.quoteData.boxHeight)*screen}%, lightblue ${(10/detail.quoteData.boxHeight)*screen}%)`}}></div>
                   <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}vw - 20px)`, margin: '4px', marginTop: '0px', border:'1px solid #333', background: 'lightblue'}}></div>
                 </div>
               )}
               
               {detail.quoteData.fanTop === 'Bottom' && detail.quoteData.fanLeft === 'Left'  && divider === 'Horizontal' && switchs === 'on' && (
                 <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                   <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}vw - 20px)`, margin: '4px', marginTop: '5px', border:'1px solid #333', background: 'lightblue'}}></div>
                   <div style={{float:'right', height: `calc(${(.85) * screen}vw - 0px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}vw - 26px)`, marginRight: '5px', marginBottom: '4px',  border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/detail.quoteData.boxHeight)*screen}%, lightblue ${(10/detail.quoteData.boxHeight)*screen}%)`}}></div>
                   <img src={Fan} style={{float:'left', width: .85*screen+'vw', marginLeft: '4px', marginBottom: '4px'}} />
                 </div>
               )}
               
               {detail.quoteData.fanTop === 'Top' && detail.quoteData.fanLeft === 'Right'  && divider === 'Horizontal' && switchs === 'on' && (
                 <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                   <img src={Fan} style={{float:'right', width: .85*screen+'vw', marginRight: '4px', marginTop: '4px'}} />
                   <div style={{float:'left', height: `calc(${(.85) * screen}vw - 0px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}vw - 26px)`, margin: '5px',  border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/detail.quoteData.boxHeight)*screen}%, lightblue ${(10/detail.quoteData.boxHeight)*screen}%)`}}></div>
                   <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}vw - 20px)`, margin: '4px', marginTop: '0px', border:'1px solid #333', background: 'lightblue'}}></div>
                 </div>
               )}
               
               {detail.quoteData.fanTop === 'Bottom' && detail.quoteData.fanLeft === 'Right'  && divider === 'Horizontal' && switchs === 'on' && (
                 <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                   <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}vw - 20px)`, margin: '4px', marginTop: '5px', border:'1px solid #333', background: 'lightblue'}}></div>
                   <div style={{float:'left', height: `calc(${(.85) * screen}vw - 0px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}vw - 26px)`, marginLeft: '5px', marginBottom: '4px',  border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/detail.quoteData.boxHeight)*screen}%, lightblue ${(10/detail.quoteData.boxHeight)*screen}%)`}}></div>
                   <img src={Fan} style={{float:'right', width: .85*screen+'vw', marginRight: '4px', marginBottom: '4px'}} />
                 </div>
               )}

               {detail.quoteData.fanTop === 'Top' && detail.quoteData.fanLeft === 'Left'  && divider === 'Horizontal' && switchs === 'off' && (
                 <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                   <img src={Fan} style={{float:'left', width: .85*screen+'vw', marginLeft: '4px', marginTop: '4px'}} />
                   <div style={{float:'right', height: `calc(${(.85) * screen}vw - 0px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}vw - 26px)`, margin: '5px',  border:'1px solid #333', background: 'lightblue' }}></div>
                   <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}vw - 20px)`, margin: '4px', marginTop: '0px', border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/detail.quoteData.boxHeight)*screen}%, lightblue ${(10/detail.quoteData.boxHeight)*screen}%)`}}></div>
                 </div>
               )}
               
               {detail.quoteData.fanTop === 'Top' && detail.quoteData.fanLeft === 'Right'  && divider === 'Horizontal' && switchs === 'off' && (
                 <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                   <img src={Fan} style={{float:'right', width: .85*screen+'vw', marginRight: '4px', marginTop: '4px'}} />
                   <div style={{float:'left', height: `calc(${(.85) * screen}vw - 0px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}vw - 26px)`, margin: '5px',  border:'1px solid #333', background: 'lightblue' }}></div>
                   <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}vw - 20px)`, margin: '4px', marginTop: '0px', border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/detail.quoteData.boxHeight)*screen}%, lightblue ${(10/detail.quoteData.boxHeight)*screen}%)`}}></div>
                 </div>
               )}
               
               {detail.quoteData.fanTop === 'Bottom' && detail.quoteData.fanLeft === 'Right'  && divider === 'Horizontal' && switchs === 'off' && (
                 <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                   <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}vw - 20px)`, margin: '4px', marginTop: '5px', border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/detail.quoteData.boxHeight)*screen}%, lightblue ${(10/detail.quoteData.boxHeight)*screen}%)`}}></div>
                   <img src={Fan} style={{float:'right', width: .85*screen+'vw', marginRight: '4px', marginTop: '0px'}} />
                   <div style={{float:'left', height: `calc(${(.85) * screen}vw - 0px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}vw - 26px)`, marginLeft: '4px',  border:'1px solid #333', background: 'lightblue' }}></div>
                 </div>
               )}

               {detail.quoteData.fanTop === 'Bottom' && detail.quoteData.fanLeft === 'Left'  && divider === 'Horizontal' && switchs === 'off' && (
                 <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                 <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}vw - 20px)`, margin: '4px', marginTop: '5px', border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/detail.quoteData.boxHeight)*screen}%, lightblue ${(10/detail.quoteData.boxHeight)*screen}%)`}}></div>
                   <img src={Fan} style={{float:'left', width: .85*screen+'vw', marginLeft: '4px', marginRight: '4px'}} />
                   <div style={{float:'right', height: `calc(${(.85) * screen}vw - 0px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}vw - 26px)`, marginRight: '5px',  border:'1px solid #333', background: 'lightblue' }}></div>
                 </div>
               )}

           </div>
               
           </>
           
         )} 

         {detail.quoteData.boxHeight == 1 && detail.quoteData.boxWidth > 1 && (
           <>
          {detail.quoteData.switchFixed === 'on' && (  
           
             <div style={{float:'left', width: detail.quoteData.boxWidth*screen+'vw', height:detail.quoteData.boxHeight*screen+'vw', border:'1px solid #333'}}>
               <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                 <img src={Fan} style={{float:'left', width: .75*screen+'vw', margin: '4px', }} />
                 <div style={{float: 'right', height: `calc(${(detail.quoteData.boxHeight)*screen}vw - 22px)`, width: `calc(${(detail.quoteData.boxWidth- .94)*screen}vw - 10px)`, marginRight: '5px', marginTop: '5px', background:'lightblue', border:'1px solid #333'}}></div>
               </div>
             </div>
           )}

           {detail.quoteData.switchFixed === 'off' && (  
           
           <div style={{float:'left', width: detail.quoteData.boxWidth*screen+'vw', height:detail.quoteData.boxHeight*screen+'vw', border:'1px solid #333'}}>
             <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
               <img src={Fan} style={{float:'left', width: .75*screen+'vw', margin: '4px', }} />
               <div style={{float: 'right', height: `calc(${(detail.quoteData.boxHeight)*screen}vw - 22px)`, width: `calc(${(detail.quoteData.boxWidth- .94)*screen}vw - 10px)`, marginRight: '5px', marginTop: '5px',  backgroundImage: `repeating-linear-gradient(lightblue, white ${(6/detail.quoteData.boxHeight)*screen}%, lightblue ${(6/detail.quoteData.boxHeight)*screen}%)`, border:'1px solid #333'}}></div>
             </div>
           </div>
         )}
           </>
         )}

         {detail.quoteData.boxHeight > 1 && detail.quoteData.boxWidth == 1 && (
           <>
            {detail.quoteData.switchFixed === 'on' && (  
             <div style={{float:'left', width: detail.quoteData.boxWidth*screen+'vw', height:detail.quoteData.boxHeight*screen+'vw', border:'1px solid #333'}}>
                 <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                 <img src={Fan} style={{float:'left', width: .75*screen+'vw', margin: '4px', }} />
                 <div style={{float: 'right', height: `calc(${(detail.quoteData.boxHeight-.75)*screen}vw - 25px)`, width: `calc(${(detail.quoteData.boxWidth)*screen}vw - 20px)`, marginRight: '5px', background:'lightblue', border:'1px solid #333'}}></div>
               </div>
             </div>
            )}
            
            {detail.quoteData.switchFixed === 'off' && (
             <div style={{float:'left', width: detail.quoteData.boxWidth*screen+'vw', height:detail.quoteData.boxHeight*screen+'vw', border:'1px solid #333'}}>
               <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
               <img src={Fan} style={{float:'left', width: .75*screen+'vw', margin: '4px', }} />
               <div style={{float: 'right', height: `calc(${(detail.quoteData.boxHeight-.75)*screen}vw - 25px)`, width: `calc(${(detail.quoteData.boxWidth)*screen}vw - 20px)`, marginRight: '5px',  backgroundImage: `repeating-linear-gradient(lightblue, white ${(6/detail.quoteData.boxHeight)*screen}%, lightblue ${(6/detail.quoteData.boxHeight)*screen}%)`, border:'1px solid #333'}}></div>
               </div>
             </div>
            )}
           </>
         )}
         
         {(detail.quoteData.boxWidth > 1 || detail.quoteData.boxHeight > 1) && (
           <>
             <div className="expanding-arrow-line" style={{ 
                 position: 'absolute',
                 float:'left', 
                 width: `calc(${detail.quoteData.boxWidth * screen}vw )`,
                 bottom:'-10px', 
                 top: `calc(${detail.quoteData.boxHeight* screen}vw - 25px)`,
                 }}> 
               <div className="arrow-left"></div>
                 <div className="line" align="center">
                   <div style={{marginTop:'-7px',background:'white',maxWidth:'20px',textAlign:'center'}}><p>{typeof detail.quoteData.boxWidth === 'number' ? detail.quoteData.boxWidth.toFixed(2) : detail.quoteData.boxWidth}</p></div>
                 </div>
                 <div className="arrow-right"></div>
               </div>

           <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
             <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}vw)`, top: '0', position: 'relative' }}>
               <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                 <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                   <div style={{ marginLeft: '-10px', marginTop: detail.quoteData.boxHeight * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof detail.quoteData.boxHeight === 'number' ? detail.quoteData.boxHeight.toFixed(2) : detail.quoteData.boxHeight}</p></div>
                 </div>
               <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
             </div>
           </div>  
           </>
         )}
        </div>
        <h5 style={{ marginLeft: '20px', color:'#36454F' }}>Hardwares</h5>
<div style={{ display: 'flex', flexWrap: 'wrap' }}>
  <p style={{ fontSize: '15px',marginRight: '10px', color:'#36454F'}}>Screws,</p>
  <p style={{ fontSize: '15px',marginRight: '10px', color:'#36454F'}}>Glass,</p>
  {(detail.quoteData.boxHeight > 1 && detail.quoteData.boxWidth > 1) || (detail.quoteData.switchFixed === 'off') ? (
  <p style={{ fontSize: '15px',marginRight: '10px', color:'#36454F'}}>Louver Blades</p>
):(<></>)}
</div>
            </div>
           </div>
         </div>
        )}
  
  
 
        </td>
       
          <td>{detail.quoteData.qty}</td>
          <td>Area: {detail.quoteData.frameArea} SqFt<br></br>
          RATE: ₹ {numeral(detail.quoteData.grandTotal).format('0,0.00')}<br></br>
          RATE/SQFT: ₹ {numeral(detail.quoteData.grandTotal/detail.quoteData.frameArea).format('0,0.00')}</td>
          {detail.quoteData.item === 'CasementDoor' && (
          <td>
            <a href={`editcasementDoor?id=${quoteIds}&arrayValues=${detailIndex}`} class="btn btn-primary w-100" >Edit</a>
          </td>
          )}
          {detail.quoteData.item === 'SlidingDoor' && (
          <td>
            <a href={`editslidingDoor?id=${quoteIds}&arrayValues=${detailIndex}`} class="btn btn-primary w-100" >Edit</a>
          </td>
          )}
          {detail.quoteData.item === 'CasementWindow' && (
          <td>
            <a href={`editcasementWindow?id=${quoteIds}&arrayValues=${detailIndex}`} class="btn btn-primary w-100" >Edit</a>
          </td>
          )}
          {detail.quoteData.item === 'SlidingWindow' && (
          <td>
            <a href={`editslidingWindow?id=${quoteIds}&arrayValues=${detailIndex}`} class="btn btn-primary w-100" >Edit</a>
          </td>
          )}
          {detail.quoteData.item === 'Ventilator' && (
          <td>
            <a href={`editventilator?id=${quoteIds}&arrayValues=${detailIndex}`} class="btn btn-primary w-100" >Edit</a>
          </td>
          )}
          <td>
            <a href={`quotees?id=${quoteIds}&arrayValues=${detailIndex}`} class="btn btn-success w-100" >View</a>
          </td>
         

        </tr>
         
    
    ))}

  </tbody>
))}
                  </table>
                </div>
                <hr />
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}



export default Quotesdet;







