import React, { useEffect, useState } from 'react';
import { ReactSession } from 'react-client-session';
import axios from 'axios';

 function Downlaod({quote}) {
    const urlParams = new URLSearchParams(window.location.search);
    const quoteIds = urlParams.get('id');
    const arrayValues = urlParams.get('arrayValues');
    console.log(arrayValues);
    const userId = ReactSession.get('userId');

const Number = ReactSession.get('Number');
const Address = ReactSession.get('Address');
const Name = ReactSession.get('userName');
const Image = ReactSession.get('Image');


    const [quoteData, setQuoteData] = useState(null);
    const [filteredArray, setFilteredArray] = useState([]);
  
    const [bottomFrame, setBottomFrame] = useState('yes');
    const [mesh, setMesh] = useState('no');
    const [panelHeights, setpanelHeight] = useState(0); 
    const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
    const [screen, setScreen] = useState(deviceWidth > 700 ? 40 : 40);
    const [fanTop, setfanTop] = useState('Top');
    const [fanLeft, setfanLeft] = useState('Left');
    const [divider, setDevider] = useState('Vertical');
    const [switchs, setSwitch] = useState('on');
    const [switchFixed, setFixed] = useState('on');
    const [fanType, setfanType] = useState(1);
    const [total, setTotal] = useState(0);
    const [gst, setGst] = useState(0);
    const [user, setUser]= useState([]);

  
   
  
    
    
    useEffect(() => {
      const updateDeviceWidth = () => {
        setDeviceWidth(window.innerWidth);
        setScreen(window.innerWidth > 700 ? 40 : 40);
      };
  
      window.addEventListener('resize', updateDeviceWidth);
  
      return () => {
        window.removeEventListener('resize', updateDeviceWidth);
      };
    }, []);


    
   
  
    const fetchData = async () => {
      try {
        const response = await axios.post(`https://upvcapi.sninfoserv.com/all-quotes/`, { user: userId });
        setQuoteData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    useEffect(() => {
      fetchData();
    }, []);
  
    // Ensure quoteData is valid before using filter method
    const filteredQuotes = quoteData && quoteData.data && quoteData.data.filter(quote => quote.quoteId === quoteIds);
    console.log(filteredQuotes);


    useEffect(() => {
      if (filteredQuotes && filteredQuotes.length > 0) {
        let sum = 0;
        filteredQuotes.forEach(quote => {
          quote.quoteDetails.forEach(detail => {
            sum += (detail.quoteData.grandTotal*detail.quoteData.qty);
           // console.log(sum);
          });
        });
        setTotal(sum);
        setGst((18/100)*sum);
      }
    }, [filteredQuotes]);
    
  
    useEffect(() => {
      if (arrayValues) {
        // Split arrayValues string into an array of values
        const valuesArray = arrayValues.split(',');
  
        // Filter quotes based on valuesArray
        const filtered = quote.filter(quote =>
          valuesArray.every(value => quote.details.includes(value))
        );
  
        setFilteredArray(filtered);
        //console.log(filtered);
      }
    }, [arrayValues, quote]);
  
    

    
  
    return (
  
      <div >

        <div class="modal fade custom-modal" id="onloadModals" tabindex="-1" aria-labelledby="onloadModalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content" align="center">
               <div class="mt-3 mb-3">
                <h5>Preparing Cutting Schedule...</h5>
               </div>
                
            </div>
          </div>
        </div>


     
      <div className='hide1' id="pdf-schedule" >        
	    <table width='100%'>
		  <th width='30%' style={{padding: '5px',textAlign: 'left'}}>
		  <img src="assets/images/favicon.svg"  width='20%'  alt='Logo' /> <br />
		 
		 
     </th> 
		 <th width='40%'  style={{padding: '5px',textAlign: 'center',}}>
		 <img src="assets/images/favicon.svg" width='30%'  alt='Logo' /><br /><br />
        <p> {Name} <br />
		     {Address}<br />
		     {Number}
        </p>
		 </th>
     <th width='30%' style={{padding: '5px',textAlign: 'right'}}>
		  <img src="assets/images/logo.png"  width='35%'  alt='Logo' /> <br />
		 
     </th>
	    </table>
      <hr></hr>
      <table width='100%'>
		 <th width='50%' style={{padding: '5px',textAlign: 'centre', paddingBottom:"20px"}}>
     {filteredQuotes?.map((quote, index) => (
    <div key={index}>
      <h5>Client : {quote.client.name}</h5>
      <h5>Location : {quote.client.location}</h5>
      <h5>Contact : {quote.client.number}</h5>
      
    </div>
  ))}
     </th>
		 <th width='50%'  style={{padding: '5px',textAlign: 'right'}}>
     {filteredQuotes?.map((quote, index) => (
    <div key={index}>
      <h5>Date : {quote.date}</h5>
      <h5>Quote No : {quote.quoteId}</h5>
      <h5>Cutting Schedule for {quote.quoteDetails[0].quoteData.selectedProfile} Profile<br></br></h5>
      
    </div>
  ))}
		 </th>
	    </table>


      <table width="100%" style={{width:"100%", border:'1px solid #d4d4d4',  borderCollapse: 'collapse'}}>
            <thead >
              <tr>
                <th rowSpan={2} width="5%" style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>S.No</th>
                <th rowSpan={2} width="15%" style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>Model</th>
                <th rowSpan={2} width="5%" style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>Qty</th>
                <th rowSpan={2} width="8%" style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>Frame</th>
                <th rowSpan={2} width="30%" style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>Profile</th>
                <th colSpan={2} width="18%" style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>Width</th>
                <th colSpan={2} width="18%" style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>Height</th>
              </tr>
              <tr>
                <th style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>Size</th>
                <th style={{padding: '5px',fontSize: '10px', border:'1px solid #d4d4d4'}}>Qty in Pieces</th>
                <th style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>Size</th>
                <th style={{padding: '5px',fontSize: '10px', border:'1px solid #d4d4d4'}}>Qty in Pieces</th>
              </tr>
            </thead>


  {filteredQuotes && filteredQuotes[0].quoteDetails[0].quoteData.selectedProfile === 'Intact' ? (

<>
{/* Intact Start */}
    {filteredQuotes && filteredQuotes?.map((quote, index) => (
    <tbody key={index}>
      {quote.quoteDetails.map((detail, detailIndex) => (
        <>
        <tr>
          <td rowSpan={8} style={{textAlign:'center', padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4',}}>{ detailIndex+1}</td>
          <td rowSpan={8} style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4',}}>
            {detail.quoteData.item} <br></br>
            {((detail.quoteData.boxWidth)*304.8).toFixed(0)} x {((detail.quoteData.boxHeight)*304.8).toFixed(0)} mm<br></br>
            {detail.quoteData.shutterCount} Sash<br></br>
            {detail.quoteData.mesh === 'yes' ? 'With Mesh':''}

          </td>
          <td rowSpan={8} style={{textAlign:'center', padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4',}}>{detail.quoteData.qty}</td>
        </tr>
        <tr>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>Outer</td>
          <td style={{padding: '5px',fontSize: '10px', border:'1px solid #d4d4d4'}}>{detail.quoteData.data.frame.name}</td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>{((detail.quoteData.boxWidth)*304.8).toFixed(0)} mm</td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>{2*detail.quoteData.qty}</td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>{((detail.quoteData.boxHeight)*304.8).toFixed(0)} mm</td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>{2*detail.quoteData.qty}</td>
        </tr>
        <tr>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>Sash</td>
          <td style={{padding: '5px',fontSize: '10px', border:'1px solid #d4d4d4'}}>{detail.quoteData.data.shutter.name}</td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>

            {detail.quoteData.item === 'CasementWindow'  && (
             detail.quoteData.data.shutter.name === 'Casement Window Sash - 60mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8) - 80)/detail.quoteData.shutterCount).toFixed(0)} mm`
              : detail.quoteData.data.shutter.name === 'Casement Sash - 50mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8) - 62)/detail.quoteData.shutterCount).toFixed(0)} mm`
              : null
            )}

            {detail.quoteData.item === 'CasementDoor' && (
              detail.quoteData.shutterCount === 1
              ? `${((((detail.quoteData.boxWidth) * 304.8)/1) - 82).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 2
              ? `${((((detail.quoteData.boxWidth) * 304.8) / 2) - 86).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 3
              ? `${((((detail.quoteData.boxWidth) * 304.8) / 3) - 90).toFixed(0)} mm`
              : null
            )}

            {detail.quoteData.item === 'SlidingDoor' && (
              detail.quoteData.shutterCount === 1
              ? `${(((detail.quoteData.boxWidth) * 304.8) - 0).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 2
              ? `${(((((detail.quoteData.boxWidth) * 304.8) / 2) - 0).toFixed(0))} mm`
              : detail.quoteData.shutterCount === 3
              ? `${(((((detail.quoteData.boxWidth) * 304.8) / 3) - 0).toFixed(0))} mm`
              : null
            )}

            {detail.quoteData.item === 'SlidingWindow' && (
              detail.quoteData.mesh === 'yes' ? (
                  detail.quoteData.data.shutter.name === 'Sliding door sash - 66mm'
                  ? `${((((detail.quoteData.boxWidth) * 304.8)-18)/detail.quoteData.shutterCount).toFixed(0)} mm`
                  :  detail.quoteData.data.shutter.name === 'Sliding Sash - 58mm'
                  ? `${((((detail.quoteData.boxWidth) * 304.8)-18)/detail.quoteData.shutterCount).toFixed(0)} mm`
                  : null) : 

              detail.quoteData.mesh === 'no' && (
                  detail.quoteData.data.shutter.name === 'Sliding door sash - 66mm'
                  ? `${((((detail.quoteData.boxWidth) * 304.8)-20)/detail.quoteData.shutterCount).toFixed(0)} mm`
                  :  detail.quoteData.data.shutter.name === 'Sliding Sash - 58mm'
                  ? `${((((detail.quoteData.boxWidth) * 304.8)-20)/detail.quoteData.shutterCount).toFixed(0)} mm`
                  : null)
              )}

          </td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>{2*detail.quoteData.qty*detail.quoteData.shutterCount}</td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>

          {detail.quoteData.item === 'CasementWindow' && (
              detail.quoteData.data.shutter.name === 'Casement Window Sash - 60mm'
              ? `${((((detail.quoteData.boxHeight) * 304.8)) - 64).toFixed(0)} mm`
              : detail.quoteData.data.shutter.name === 'Casement Sash - 50mm'
              ? `${((((detail.quoteData.boxHeight) * 304.8)) - 54).toFixed(0)} mm`
              : null
            )}

            {detail.quoteData.item === 'SlidingWindow' && (
              detail.quoteData.mesh === 'yes' ? (
                  detail.quoteData.data.shutter.name === 'Sliding door sash - 66mm'
                  ? `${((((detail.quoteData.boxHeight) * 304.8)-90)).toFixed(0)} mm`
                  :  detail.quoteData.data.shutter.name === 'Sliding Sash - 58mm'
                  ? `${((((detail.quoteData.boxHeight) * 304.8)-90)).toFixed(0)} mm`
                  : null) : 

              detail.quoteData.mesh === 'no' && (
                  detail.quoteData.data.shutter.name === 'Sliding door sash - 66mm'
                  ? `${((((detail.quoteData.boxHeight) * 304.8)-90)).toFixed(0)} mm`
                  :  detail.quoteData.data.shutter.name === 'Sliding Sash - 58mm'
                  ? `${((((detail.quoteData.boxHeight) * 304.8)-90)).toFixed(0)} mm`
                  : null)
              )}

            {detail.quoteData.item === 'CasementDoor' && (
              detail.quoteData.shutterCount === 1
              ? `${(((detail.quoteData.boxHeight) * 304.8) - 82).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 2
              ? `${((((detail.quoteData.boxHeight) * 304.8) - 82).toFixed(0))} mm`
              : detail.quoteData.shutterCount === 3
              ? `${((((detail.quoteData.boxHeight) * 304.8) -82).toFixed(0))} mm`
              : null
            )}

            {detail.quoteData.item === 'SlidingDoor' && (
              detail.quoteData.shutterCount === 1
              ? `${(((detail.quoteData.boxHeight) * 304.8) - 0).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 2
              ? `${((((detail.quoteData.boxHeight) * 304.8) - 0).toFixed(0))} mm`
              : detail.quoteData.shutterCount === 3
              ? `${((((detail.quoteData.boxHeight) * 304.8) - 0).toFixed(0))} mm`
              : null
            )}
          </td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>{2*detail.quoteData.qty*detail.quoteData.shutterCount}</td>
        </tr>
        <tr>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>Bead</td>
          <td style={{padding: '5px',fontSize: '10px', border:'1px solid #d4d4d4'}}>{detail.quoteData.data.beeding.name}</td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}></td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}></td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}></td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}></td>
        </tr>
        <tr>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4',}}>Mesh</td>
          <td style={{padding: '5px',fontSize: '10px', border:'1px solid #d4d4d4',}}>{detail.quoteData.data?.meshFrame?.name==='Loading'? '': detail.quoteData.data?.meshFrame?.name}</td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>
           
          {detail.quoteData.mesh === 'yes' ? (

            detail.quoteData.item === 'CasementWindow' ? (
              detail.quoteData.data.shutter.name === 'Casement Window Sash - 60mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8) - 80)/detail.quoteData.shutterCount).toFixed(0)} mm`
              : detail.quoteData.data.shutter.name === 'Casement Sash - 50mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8) - 62)/detail.quoteData.shutterCount).toFixed(0)} mm`
              : null
            ) : 
            detail.quoteData.item === 'CasementDoor' ? (
              detail.quoteData.data.shutter.name === 'Casement Window Sash - 60mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8) - 80)/detail.quoteData.shutterCount).toFixed(0)} mm`
              : detail.quoteData.data.shutter.name === 'Casement Sash - 50mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8) - 62)/detail.quoteData.shutterCount).toFixed(0)} mm`
              : null
            ) : 
            detail.quoteData.item === 'SlidingDoor' ? (
              detail.quoteData.shutterCount === 1
              ? `${(((detail.quoteData.boxWidth) * 304.8) - 0).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 2
              ? `${(((((detail.quoteData.boxWidth) * 304.8) / 2) - 0).toFixed(0))} mm`
              : detail.quoteData.shutterCount === 3
              ? `${(((((detail.quoteData.boxWidth) * 304.8) / 3) - 0).toFixed(0))} mm`
              : null
            ) : 
            detail.quoteData.item === 'SlidingWindow' ? (
              detail.quoteData.data.shutter.name === 'Sliding door sash - 66mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8)-18)/detail.quoteData.shutterCount).toFixed(0)} mm`
              :  detail.quoteData.data.shutter.name === 'Sliding Sash - 58mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8)-18)/detail.quoteData.shutterCount).toFixed(0)} mm`
              : null
             ) : null

             
            ) : null
          }

          </td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>
            {detail.quoteData.mesh === 'yes' ? ( 

              detail.quoteData.item === 'SlidingWindow' ? (
              2*detail.quoteData.qty*(detail.quoteData.shutterCount-1)
              ):(
              2*detail.quoteData.qty*(detail.quoteData.shutterCount)
              )

              ) : (null)}
            </td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>

          {detail.quoteData.mesh === 'yes' ? (

            detail.quoteData.item === 'CasementWindow' ? (
              detail.quoteData.data.shutter.name === 'Casement Window Sash - 60mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8) - 80)/detail.quoteData.shutterCount).toFixed(0)} mm`
              : detail.quoteData.data.shutter.name === 'Casement Sash - 50mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8) - 62)/detail.quoteData.shutterCount).toFixed(0)} mm`
              : null
            ) : 
            detail.quoteData.item === 'SlidingWindow' ? (
              detail.quoteData.data.shutter.name === 'Sliding door sash - 66mm'
              ? `${((((detail.quoteData.boxHeight) * 304.8)-90)).toFixed(0)} mm`
              :  detail.quoteData.data.shutter.name === 'Sliding Sash - 58mm'
              ? `${((((detail.quoteData.boxHeight) * 304.8)-90)).toFixed(0)} mm`
              : null
            ) : 
            detail.quoteData.item === 'CasementDoor' ? (
              detail.quoteData.shutterCount === 1
              ? `${(((detail.quoteData.boxHeight) * 304.8) - 82).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 2
              ? `${((((detail.quoteData.boxHeight) * 304.8) - 82).toFixed(0))} mm`
              : detail.quoteData.shutterCount === 3
              ? `${((((detail.quoteData.boxHeight) * 304.8) -82).toFixed(0))} mm`
              : null
            ) : 
            detail.quoteData.item === 'SlidingDoor' ? (
              detail.quoteData.shutterCount === 1
              ? `${(((detail.quoteData.boxHeight) * 304.8) - 0).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 2
              ? `${((((detail.quoteData.boxHeight) * 304.8) - 0).toFixed(0))} mm`
              : detail.quoteData.shutterCount === 3
              ? `${((((detail.quoteData.boxHeight) * 304.8) - 0).toFixed(0))} mm`
              : null
            ) : null
            ) : null
          }
          </td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>
            {detail.quoteData.mesh === 'yes' ? ( 

              detail.quoteData.item === 'SlidingWindow' ? (
                2*detail.quoteData.qty*(detail.quoteData.shutterCount-1)
              ):(
                2*detail.quoteData.qty*(detail.quoteData.shutterCount)
              )
              ) : (null)}
            </td>
        </tr>
        <tr>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4',}}>Mullion</td>
          <td style={{padding: '5px',fontSize: '10px', border:'1px solid #d4d4d4',}}>
            {detail.quoteData.shutterCount > 1 ? (detail.quoteData.data?.mullion?.name ==='Loading'? '': detail.quoteData.data?.mullion?.name):(null)}
          </td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}></td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}></td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>
            {detail.quoteData.shutterCount > 1 ?
            (detail.quoteData.data.shutter.name === 'Casement Window Sash - 60mm'
              ? ((((detail.quoteData.boxHeight) * 304.8) +36).toFixed(0))
              : detail.quoteData.data.shutter.name === 'Casement Sash - 50mm'
              ? ((((detail.quoteData.boxHeight) * 304.8) +40).toFixed(0))
              :(null)
            ):(null)}
          </td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>
            {detail.quoteData.shutterCount > 1 ? (detail.quoteData.item === 'CasementWindow' ? (detail.quoteData.shutterCount-1):(null)):(null)}
            {detail.quoteData.shutterCount > 1 ? (detail.quoteData.item === 'CasementDoor' ? (detail.quoteData.shutterCount-1):(null)):(null)}
            </td>
        </tr>
        <tr>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4',}}>InterLock</td>
          <td style={{padding: '5px',fontSize: '10px', border:'1px solid #d4d4d4',}}>{detail.quoteData.data?.interLock?.name || ' '}</td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}></td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}></td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>
          
          {detail.quoteData.item === 'SlidingWindow' ? (
              detail.quoteData.mesh === 'yes' ?(
                detail.quoteData.data.shutter.name === 'Sliding door sash - 66mm'
                ? `${((((detail.quoteData.boxHeight) * 304.8)-10-90)).toFixed(0)} mm`
                :  detail.quoteData.data.shutter.name === 'Sliding Sash - 58mm'
                ? `${((((detail.quoteData.boxHeight) * 304.8)-10-90)).toFixed(0)} mm`
                : detail.quoteData.data.shutter.name === ' Sliding Sash Interlock-Big'
                ? `${((((detail.quoteData.boxHeight) * 304.8)-10-90)).toFixed(0)} mm`
                : detail.quoteData.data.shutter.name === 'Sliding big door sash Interlock'
                ? `${((((detail.quoteData.boxHeight) * 304.8)-10-90)).toFixed(0)} mm`
                : detail.quoteData.data.shutter.name === 'Sash Inter Lock (Sliding Small)'
                ? `${((((detail.quoteData.boxHeight) * 304.8)-10-90)).toFixed(0)} mm`
                : null ): 

              detail.quoteData.mesh === 'no' && (
                detail.quoteData.data.shutter.name === 'Sliding door sash - 66mm'
                ? `${((((detail.quoteData.boxHeight) * 304.8)-10-90)).toFixed(0)} mm`
                :  detail.quoteData.data.shutter.name === 'Sliding Sash - 58mm'
                ? `${((((detail.quoteData.boxHeight) * 304.8)-10-90)).toFixed(0)} mm`
                : detail.quoteData.data.shutter.name === ' Sliding Sash Interlock-Big'
                ? `${((((detail.quoteData.boxHeight) * 304.8)-10-90)).toFixed(0)} mm`
                : detail.quoteData.data.shutter.name === 'Sliding big door sash Interlock'
                ? `${((((detail.quoteData.boxHeight) * 304.8)-10-90)).toFixed(0)} mm`
                : detail.quoteData.data.shutter.name === 'Sash Inter Lock (Sliding Small)'
                ? `${((((detail.quoteData.boxHeight) * 304.8)-10-90)).toFixed(0)} mm`
                : null
              )
            ):(null)}
          </td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>{detail.quoteData.item === 'SlidingWindow' ? (detail.quoteData.shutterCount-1):(null)}</td>
        </tr>
        <tr>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4',}}>Glass</td>
          <td style={{padding: '5px',fontSize: '10px', border:'1px solid #d4d4d4',}}>{detail.quoteData.data?.glass?.name || ' '}</td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>

            {detail.quoteData.item === 'CasementWindow'  && (
             detail.quoteData.data.shutter.name === 'Casement Window Sash - 60mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8) /detail.quoteData.shutterCount) - 125).toFixed(0)} mm`
              : detail.quoteData.data.shutter.name === 'Casement Sash - 50mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8) /detail.quoteData.shutterCount) - 115).toFixed(0)} mm`
              : null
            )}

            {detail.quoteData.item === 'CasementDoor' && (
              detail.quoteData.data.shutter.name === 'Casement Window Sash - 60mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8) /detail.quoteData.shutterCount) - 125).toFixed(0)} mm`
              : detail.quoteData.data.shutter.name === 'Casement Sash - 50mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8) /detail.quoteData.shutterCount) - 115).toFixed(0)} mm`
              : null
            )}

            {detail.quoteData.item === 'SlidingDoor' && (
              detail.quoteData.data.shutter.name === 'Sliding Sash - 58mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8) /detail.quoteData.shutterCount) - 112).toFixed(0)} mm`
              : detail.quoteData.data.shutter.name === 'Sliding door sash - 66mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8) /detail.quoteData.shutterCount) - 112).toFixed(0)} mm`
              : detail.quoteData.data.shutter.name === 'Sliding big door sash -92mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8) /detail.quoteData.shutterCount) - 112).toFixed(0)} mm`
              : detail.quoteData.data.shutter.name === 'Sliding Sash - 57mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8) /detail.quoteData.shutterCount) - 112).toFixed(0)} mm`
              : null
            )}

            {detail.quoteData.item === 'SlidingWindow' && (
              detail.quoteData.mesh === 'yes' ? (
                detail.quoteData.data.shutter.name === 'Sliding door sash - 66mm'
                ? `${(((((detail.quoteData.boxWidth) * 304.8)-18)/detail.quoteData.shutterCount)-112).toFixed(0)} mm`
                :  detail.quoteData.data.shutter.name === 'Sliding Sash - 58mm'
                ? `${(((((detail.quoteData.boxWidth) * 304.8)-18)/detail.quoteData.shutterCount)-112).toFixed(0)} mm`
                :  detail.quoteData.data.shutter.name === 'Sliding big door sash -92mm'
                ? `${(((((detail.quoteData.boxWidth) * 304.8)-18)/detail.quoteData.shutterCount)-112).toFixed(0)} mm`
                :  detail.quoteData.data.shutter.name === 'Sliding Sash - 57mm'
                ? `${(((((detail.quoteData.boxWidth) * 304.8)-18)/detail.quoteData.shutterCount)-112).toFixed(0)} mm`
                : null) : 

            detail.quoteData.mesh === 'no' && (
                detail.quoteData.data.shutter.name === 'Sliding door sash - 66mm'
                ? `${(((((detail.quoteData.boxWidth) * 304.8)-20)/detail.quoteData.shutterCount)-112).toFixed(0)} mm`
                :  detail.quoteData.data.shutter.name === 'Sliding Sash - 58mm'
                ? `${(((((detail.quoteData.boxWidth) * 304.8)-20)/detail.quoteData.shutterCount)-112).toFixed(0)} mm`
                :  detail.quoteData.data.shutter.name === 'Sliding big door sash -92mm'
                ? `${(((((detail.quoteData.boxWidth) * 304.8)-20)/detail.quoteData.shutterCount)-112).toFixed(0)} mm`
                :  detail.quoteData.data.shutter.name === 'Sliding Sash - 57mm'
                ? `${(((((detail.quoteData.boxWidth) * 304.8)-20)/detail.quoteData.shutterCount)-112).toFixed(0)} mm`
                : null)
            )}
          </td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>{detail.quoteData.shutterCount*detail.quoteData.qty}</td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>

            {detail.quoteData.item === 'CasementWindow'  && (
             detail.quoteData.data.shutter.name === 'Casement Window Sash - 60mm'
              ? `${((((detail.quoteData.boxHeight) * 304.8) - 125)).toFixed(0)} mm`
              : detail.quoteData.data.shutter.name === 'Casement Sash - 50mm'
              ? `${((((detail.quoteData.boxHeight) * 304.8) - 115)).toFixed(0)} mm`
              : null
            )}

            {detail.quoteData.item === 'SlidingWindow' && (
              detail.quoteData.mesh === 'yes' ?(
                detail.quoteData.data.shutter.name === 'Sliding Sash - 58mm'
                  ? `${((((detail.quoteData.boxHeight) * 304.8) ) - 112-90).toFixed(0)} mm`
                  : detail.quoteData.data.shutter.name === 'Sliding door sash - 66mm'
                  ? `${((((detail.quoteData.boxHeight) * 304.8) ) - 112-90).toFixed(0)} mm`
                  : detail.quoteData.data.shutter.name === 'Sliding big door sash -92mm'
                  ? `${((((detail.quoteData.boxHeight) * 304.8) ) - 112-90).toFixed(0)} mm`
                  : detail.quoteData.data.shutter.name === 'Sliding Sash - 57mm'
                  ? `${((((detail.quoteData.boxHeight) * 304.8) ) - 112-90).toFixed(0)} mm`
                  : null ): 

              detail.quoteData.mesh === 'no' && (
                detail.quoteData.data.shutter.name === 'Sliding Sash - 58mm'
                ? `${((((detail.quoteData.boxHeight) * 304.8) ) - 112-90).toFixed(0)} mm`
                : detail.quoteData.data.shutter.name === 'Sliding door sash - 66mm'
                ? `${((((detail.quoteData.boxHeight) * 304.8) ) - 112-90).toFixed(0)} mm`
                : detail.quoteData.data.shutter.name === 'Sliding big door sash -92mm'
                ? `${((((detail.quoteData.boxHeight) * 304.8) ) - 112-90).toFixed(0)} mm`
                : detail.quoteData.data.shutter.name === 'Sliding Sash - 57mm'
                ? `${((((detail.quoteData.boxHeight) * 304.8) ) - 112-90).toFixed(0)} mm`
                : null
              )
            )}

            {detail.quoteData.item === 'CasementDoor' && (
             detail.quoteData.data.shutter.name === 'Casement Window Sash - 60mm'
             ? `${((((detail.quoteData.boxHeight) * 304.8) - 125)).toFixed(0)} mm`
             : detail.quoteData.data.shutter.name === 'Casement Sash - 50mm'
             ? `${((((detail.quoteData.boxHeight) * 304.8) - 115)).toFixed(0)} mm`
             : null
            )}

            {detail.quoteData.item === 'SlidingDoor' && (
             detail.quoteData.data.shutter.name === 'Casement Window Sash - 60mm'
             ? `${((((detail.quoteData.boxHeight) * 304.8) - 112)).toFixed(0)} mm`
             : detail.quoteData.data.shutter.name === 'Casement Sash - 50mm'
             ? `${((((detail.quoteData.boxHeight) * 304.8) - 112)).toFixed(0)} mm`
             : null
            )}
          </td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>{detail.quoteData.shutterCount*detail.quoteData.qty}</td>
        </tr>
        <tr> <td colspan={9} style={{padding:'5px', fontSize: '12px', border:'1px solid #d4d4d4', paddingBottom:'120px'}}> Notes</td></tr>
        <tr><td style={{padding:'4px'}}></td></tr>
    </>
    ))}
   </tbody>
  ))}
{/* Intact End */}

</>
):(
<>
{/* WintowTech Start */}

    {filteredQuotes && filteredQuotes?.map((quote, index) => (
    <tbody key={index}>
      {quote.quoteDetails.map((detail, detailIndex) => (
        <>
        <tr>
          <td rowSpan={8} style={{textAlign:'center', padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4',}}>{ detailIndex+1}</td>
          <td rowSpan={8} style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4',}}>
            {detail.quoteData.item} <br></br>
            {((detail.quoteData.boxWidth)*304.8).toFixed(0)} x {((detail.quoteData.boxHeight)*304.8).toFixed(0)} mm<br></br>
            {detail.quoteData.shutterCount} Sash<br></br>
            {detail.quoteData.mesh === 'yes' ? 'With Mesh':''}

          </td>
          <td rowSpan={8} style={{textAlign:'center', padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4',}}>{detail.quoteData.qty}</td>
        </tr>
        <tr>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>Outer</td>
          <td style={{padding: '5px',fontSize: '10px', border:'1px solid #d4d4d4'}}>{detail.quoteData.data.frame.name}</td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>{((detail.quoteData.boxWidth)*304.8).toFixed(0)} mm</td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>{2*detail.quoteData.qty}</td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>{((detail.quoteData.boxHeight)*304.8).toFixed(0)} mm</td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>{2*detail.quoteData.qty}</td>
        </tr>
        <tr>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>Sash</td>
          <td style={{padding: '5px',fontSize: '10px', border:'1px solid #d4d4d4'}}>{detail.quoteData.data.shutter.name}</td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>

            {detail.quoteData.item === 'CasementWindow'  && (
              detail.quoteData.shutterCount === 1
              ? `${(((detail.quoteData.boxWidth) * 304.8) - 66).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 2
              ? `${(((((detail.quoteData.boxWidth) * 304.8) / 2) - 39).toFixed(0))} mm`
              : detail.quoteData.shutterCount === 3
              ? `${(((((detail.quoteData.boxWidth) * 304.8) / 3) - 31).toFixed(0))} mm`
              : null
            )}

            {detail.quoteData.item === 'CasementDoor' && (
              detail.quoteData.shutterCount === 1
              ? `${((((detail.quoteData.boxWidth) * 304.8)/1) - 82).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 2
              ? `${((((detail.quoteData.boxWidth) * 304.8) / 2) - 86).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 3
              ? `${((((detail.quoteData.boxWidth) * 304.8) / 3) - 90).toFixed(0)} mm`
              : null
            )}

            {detail.quoteData.item === 'SlidingDoor' && (
              detail.quoteData.shutterCount === 1
              ? `${(((detail.quoteData.boxWidth) * 304.8) - 0).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 2
              ? `${(((((detail.quoteData.boxWidth) * 304.8) / 2) - 0).toFixed(0))} mm`
              : detail.quoteData.shutterCount === 3
              ? `${(((((detail.quoteData.boxWidth) * 304.8) / 3) - 0).toFixed(0))} mm`
              : null
            )}

            {detail.quoteData.item === 'SlidingWindow' && (
              detail.quoteData.mesh === 'yes' ? (
                detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 42X59 mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8)/1)-2).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING SUPER SASH 42X75 mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8)/1)+5).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING DOOR SASH 42X90 mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8)/1)+13).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 37x56 mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8)/1)-1).toFixed(0)} mm`

              : detail.quoteData.shutterCount === 2 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 42X59 mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8)/2)-2).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 2 && detail.quoteData.data.shutter.name === 'SLIDING SUPER SASH 42X75 mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8)/2)+5).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 2 && detail.quoteData.data.shutter.name === 'SLIDING DOOR SASH 42X90 mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8)/2)+13).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 2 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 37x56 mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8)/2)-1).toFixed(0)} mm`

              : detail.quoteData.shutterCount === 3 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 42X59 mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8)/3)-2).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 3 && detail.quoteData.data.shutter.name === 'SLIDING SUPER SASH 42X75 mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8)/3)+5).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 3 && detail.quoteData.data.shutter.name === 'SLIDING DOOR SASH 42X90 mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8)/3)+13).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 3 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 37x56 mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8)/3)-1).toFixed(0)} mm`
              
              : null) : 

              detail.quoteData.mesh === 'no' &&(
                detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 42X59 mm'
                ? `${((((detail.quoteData.boxWidth) * 304.8)/2)-7).toFixed(0)} mm`
                : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING SUPER SASH 42X75 mm'
                ? `${((((detail.quoteData.boxWidth) * 304.8)/2)).toFixed(0)} mm`
                : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING DOOR SASH 42X90 mm'
                ? `${((((detail.quoteData.boxWidth) * 304.8)/2)+13).toFixed(0)} mm`
                : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 37x56 mm'
                ? `${((((detail.quoteData.boxWidth) * 304.8)/2)-1).toFixed(0)} mm`

                : detail.quoteData.shutterCount === 2 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 42X59 mm'
                ? `${((((detail.quoteData.boxWidth) * 304.8)/2)-7).toFixed(0)} mm`
                : detail.quoteData.shutterCount === 2 && detail.quoteData.data.shutter.name === 'SLIDING SUPER SASH 42X75 mm'
                ? `${((((detail.quoteData.boxWidth) * 304.8)/2)).toFixed(0)} mm`
                : detail.quoteData.shutterCount === 2 && detail.quoteData.data.shutter.name === 'SLIDING DOOR SASH 42X90 mm'
                ? `${((((detail.quoteData.boxWidth) * 304.8)/2)+13).toFixed(0)} mm`
                : detail.quoteData.shutterCount === 2 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 37x56 mm'
                ? `${((((detail.quoteData.boxWidth) * 304.8)/2)-1).toFixed(0)} mm`

                : detail.quoteData.shutterCount === 3 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 42X59 mm'
                ? `${((((detail.quoteData.boxWidth) * 304.8)/3)-7).toFixed(0)} mm`
                : detail.quoteData.shutterCount === 3 && detail.quoteData.data.shutter.name === 'SLIDING SUPER SASH 42X75 mm'
                ? `${((((detail.quoteData.boxWidth) * 304.8)/3)).toFixed(0)} mm`
                : detail.quoteData.shutterCount === 3 && detail.quoteData.data.shutter.name === 'SLIDING DOOR SASH 42X90 mm'
                ? `${((((detail.quoteData.boxWidth) * 304.8)/3)+13).toFixed(0)} mm`
                : detail.quoteData.shutterCount === 3 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 37x56 mm'
                ? `${((((detail.quoteData.boxWidth) * 304.8)/3)-1).toFixed(0)} mm`

                : null
              )
            )}

          </td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>{2*detail.quoteData.qty*detail.quoteData.shutterCount}</td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>

          {detail.quoteData.item === 'CasementWindow' && (
              detail.quoteData.shutterCount === 1
              ? `${(((detail.quoteData.boxHeight) * 304.8) - 66).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 2
              ? `${(((((detail.quoteData.boxHeight) * 304.8)) - 66).toFixed(0))} mm`
              : detail.quoteData.shutterCount === 3
              ? `${(((((detail.quoteData.boxHeight) * 304.8)) - 66).toFixed(0))} mm`
              : null
            )}

            {detail.quoteData.item === 'SlidingWindow' && (
              detail.quoteData.mesh === 'yes' ?(
                detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 42X59 mm'
              ? `${(((detail.quoteData.boxHeight) * 304.8) - 72).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING SUPER SASH 42X75 mm'
              ? `${(((detail.quoteData.boxHeight) * 304.8) - 72).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING DOOR SASH 42X90 mm'
              ? `${((((detail.quoteData.boxHeight) * 304.8))-72).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 37x56 mm'
              ? `${((((detail.quoteData.boxHeight) * 304.8))-66).toFixed(0)} mm`

              : detail.quoteData.shutterCount === 2 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 42X59 mm'
              ? `${(((((detail.quoteData.boxHeight) * 304.8)) - 72).toFixed(0))} mm`
              : detail.quoteData.shutterCount === 2 && detail.quoteData.data.shutter.name === 'SLIDING SUPER SASH 42X75 mm'
              ? `${(((((detail.quoteData.boxHeight) * 304.8))- 72).toFixed(0))} mm`
              : detail.quoteData.shutterCount === 2 && detail.quoteData.data.shutter.name === 'SLIDING DOOR SASH 42X90 mm'
              ? `${((((detail.quoteData.boxHeight) * 304.8))-72).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 2 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 37x56 mm'
              ? `${((((detail.quoteData.boxHeight) * 304.8))-66).toFixed(0)} mm`

              : detail.quoteData.shutterCount === 3 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 42X59 mm'
              ? `${(((((detail.quoteData.boxHeight) * 304.8)) - 72).toFixed(0))} mm`
              : detail.quoteData.shutterCount === 3 && detail.quoteData.data.shutter.name === 'SLIDING SUPER SASH 42X75 mm'
              ? `${(((((detail.quoteData.boxHeight) * 304.8)) - 72).toFixed(0))} mm`
              : detail.quoteData.shutterCount === 3 && detail.quoteData.data.shutter.name === 'SLIDING DOOR SASH 42X90 mm'
              ? `${((((detail.quoteData.boxHeight) * 304.8))-72).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 3 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 37x56 mm'
              ? `${((((detail.quoteData.boxHeight) * 304.8))-66).toFixed(0)} mm`
              : null ): 

              detail.quoteData.mesh === 'no' && (
                detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 42X59 mm'
                ? `${(((detail.quoteData.boxHeight) * 304.8) - 79).toFixed(0)} mm`
                : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING SUPER SASH 42X75 mm'
                ? `${(((detail.quoteData.boxHeight) * 304.8) - 79).toFixed(0)} mm`
                : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING DOOR SASH 42X90 mm'
                ? `${(((detail.quoteData.boxHeight) * 304.8) - 79).toFixed(0)} mm`
                : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 37x56 mm'
                ? `${(((detail.quoteData.boxHeight) * 304.8) - 79).toFixed(0)} mm`

                : detail.quoteData.shutterCount === 2 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 42X59 mm'
                ? `${(((((detail.quoteData.boxHeight) * 304.8)) - 79).toFixed(0))} mm`
                : detail.quoteData.shutterCount === 2 && detail.quoteData.data.shutter.name === 'SLIDING SUPER SASH 42X75 mm'
                ? `${(((((detail.quoteData.boxHeight) * 304.8)) - 79).toFixed(0))} mm`
                : detail.quoteData.shutterCount === 2 && detail.quoteData.data.shutter.name === 'SLIDING DOOR SASH 42X90 mm'
                ? `${(((detail.quoteData.boxHeight) * 304.8) - 79).toFixed(0)} mm`
                : detail.quoteData.shutterCount === 2 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 37x56 mm'
                ? `${(((detail.quoteData.boxHeight) * 304.8) - 79).toFixed(0)} mm`

                : detail.quoteData.shutterCount === 3 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 42X59 mm'
                ? `${(((((detail.quoteData.boxHeight) * 304.8)) - 79).toFixed(0))} mm`
                : detail.quoteData.shutterCount === 3 && detail.quoteData.data.shutter.name === 'SLIDING SUPER SASH 42X75 mm'
                ? `${(((((detail.quoteData.boxHeight) * 304.8) - 79)).toFixed(0))} mm`
                : detail.quoteData.shutterCount === 3 && detail.quoteData.data.shutter.name === 'SLIDING DOOR SASH 42X90 mm'
                ? `${(((detail.quoteData.boxHeight) * 304.8) - 79).toFixed(0)} mm`
                : detail.quoteData.shutterCount === 3 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 37x56 mm'
                ? `${(((detail.quoteData.boxHeight) * 304.8) - 79).toFixed(0)} mm`

                : null
              )
            )}

            {detail.quoteData.item === 'CasementDoor' && (
              detail.quoteData.shutterCount === 1
              ? `${(((detail.quoteData.boxHeight) * 304.8) - 82).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 2
              ? `${((((detail.quoteData.boxHeight) * 304.8) - 82).toFixed(0))} mm`
              : detail.quoteData.shutterCount === 3
              ? `${((((detail.quoteData.boxHeight) * 304.8) -82).toFixed(0))} mm`
              : null
            )}

            {detail.quoteData.item === 'SlidingDoor' && (
              detail.quoteData.shutterCount === 1
              ? `${(((detail.quoteData.boxHeight) * 304.8) - 0).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 2
              ? `${((((detail.quoteData.boxHeight) * 304.8) - 0).toFixed(0))} mm`
              : detail.quoteData.shutterCount === 3
              ? `${((((detail.quoteData.boxHeight) * 304.8) - 0).toFixed(0))} mm`
              : null
            )}
          </td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>{2*detail.quoteData.qty*detail.quoteData.shutterCount}</td>
        </tr>
        <tr>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>Bead</td>
          <td style={{padding: '5px',fontSize: '10px', border:'1px solid #d4d4d4'}}>{detail.quoteData.data.beeding.name}</td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}></td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}></td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}></td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}></td>
        </tr>
        <tr>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4',}}>Mesh</td>
          <td style={{padding: '5px',fontSize: '10px', border:'1px solid #d4d4d4',}}>{detail.quoteData.data?.meshFrame?.name==='Loading'? '': detail.quoteData.data?.meshFrame?.name}</td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>
           
          {detail.quoteData.mesh === 'yes' ? (

            detail.quoteData.item === 'CasementWindow' ? (
              detail.quoteData.shutterCount === 1
              ? `${(((detail.quoteData.boxWidth) * 304.8) - 66).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 2
              ? `${(((detail.quoteData.boxWidth) * 304.8) / 2 - 39).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 3
              ? `${(((detail.quoteData.boxWidth) * 304.8) / 3 - 31).toFixed(0)} mm`
              : null
            ) : 
            detail.quoteData.item === 'CasementDoor' ? (
              detail.quoteData.shutterCount === 1
              ? `${(((detail.quoteData.boxWidth) * 304.8) - 82).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 2
              ? `${(((detail.quoteData.boxWidth) * 304.8) / 2 - 86).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 3
              ? `${(((detail.quoteData.boxWidth) * 304.8) / 3 - 90).toFixed(0)} mm`
              : null
            ) : 
            detail.quoteData.item === 'SlidingDoor' ? (
              detail.quoteData.shutterCount === 1
              ? `${(((detail.quoteData.boxWidth) * 304.8) - 0).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 2
              ? `${(((detail.quoteData.boxWidth) * 304.8) / 2 - 0).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 3
              ? `${(((detail.quoteData.boxWidth) * 304.8) / 3 - 0).toFixed(0)} mm`
              : null
            ) : 
            detail.quoteData.item === 'SlidingWindow' ? (
              detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 42X59 mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8)/2)-2).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING SUPER SASH 42X75 mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8)/2)+5).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING DOOR SASH 42X90 mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8)/2)+13).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 37x56 mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8)/2)-1).toFixed(0)} mm`

              : detail.quoteData.shutterCount === 2 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 42X59 mm'
              ? `${((((((detail.quoteData.boxWidth) * 304.8))/2)-2).toFixed(0))} mm`
              : detail.quoteData.shutterCount === 2 && detail.quoteData.data.shutter.name === 'SLIDING SUPER SASH 42X75 mm'
              ? `${((((((detail.quoteData.boxWidth) * 304.8))/2)+5).toFixed(0))} mm`
              : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING DOOR SASH 42X90 mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8)/2)+13).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 37x56 mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8)/2)-1).toFixed(0)} mm`

              : detail.quoteData.shutterCount === 3 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 42X59 mm'
              ? `${((((((detail.quoteData.boxWidth) * 304.8))/2)-2).toFixed(0))} mm`
              : detail.quoteData.shutterCount === 3 && detail.quoteData.data.shutter.name === 'SLIDING SUPER SASH 42X75 mm'
              ? `${((((((detail.quoteData.boxWidth) * 304.8))/2)+5).toFixed(0))} mm`
              : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING DOOR SASH 42X90 mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8)/2)+13).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 37x56 mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8)/2)-1).toFixed(0)} mm`
              : null
             ) : null
            ) : null
          }

          </td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>
            {detail.quoteData.mesh === 'yes' ? ( 

              detail.quoteData.item === 'SlidingWindow' ? (
              2*detail.quoteData.qty*(detail.quoteData.shutterCount-1)
              ):(
              2*detail.quoteData.qty*(detail.quoteData.shutterCount)
              )

              ) : (null)}
            </td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>

          {detail.quoteData.mesh === 'yes' ? (
            detail.quoteData.item === 'CasementWindow' ? (
              detail.quoteData.shutterCount === 1
              ? `${(((detail.quoteData.boxHeight) * 304.8) - 66).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 2
              ? `${(((detail.quoteData.boxHeight) * 304.8) - 66).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 3
              ? `${(((detail.quoteData.boxHeight) * 304.8) - 66).toFixed(0)} mm`
              : null
            ) : 
            detail.quoteData.item === 'SlidingWindow' ? (
              detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 42X59 mm'
              ? `${(((detail.quoteData.boxHeight) * 304.8) - 72).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING SUPER SASH 42X75 mm'
              ? `${(((detail.quoteData.boxHeight) * 304.8) - 72).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING DOOR SASH 42X90 mm'
              ? `${((((detail.quoteData.boxHeight) * 304.8))-72).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 37x56 mm'
              ? `${((((detail.quoteData.boxHeight) * 304.8))-66).toFixed(0)} mm`

              : detail.quoteData.shutterCount === 2 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 42X59 mm'
              ? `${(((((detail.quoteData.boxHeight) * 304.8)) - 72).toFixed(0))} mm`
              : detail.quoteData.shutterCount === 2 && detail.quoteData.data.shutter.name === 'SLIDING SUPER SASH 42X75 mm'
              ? `${(((((detail.quoteData.boxHeight) * 304.8))- 72).toFixed(0))} mm`
              : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING DOOR SASH 42X90 mm'
              ? `${((((detail.quoteData.boxHeight) * 304.8))-72).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 37x56 mm'
              ? `${((((detail.quoteData.boxHeight) * 304.8))-66).toFixed(0)} mm`

              : detail.quoteData.shutterCount === 3 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 42X59 mm'
              ? `${(((((detail.quoteData.boxHeight) * 304.8)) - 72).toFixed(0))} mm`
              : detail.quoteData.shutterCount === 3 && detail.quoteData.data.shutter.name === 'SLIDING SUPER SASH 42X75 mm'
              ? `${(((((detail.quoteData.boxHeight) * 304.8)) - 72).toFixed(0))} mm`
              : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING DOOR SASH 42X90 mm'
              ? `${((((detail.quoteData.boxHeight) * 304.8))-72).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 37x56 mm'
              ? `${((((detail.quoteData.boxHeight) * 304.8))-66).toFixed(0)} mm`
              : null
            ) : 
            detail.quoteData.item === 'CasementDoor' ? (
              detail.quoteData.shutterCount === 1
              ? `${(((detail.quoteData.boxHeight) * 304.8) - 82).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 2
              ? `${(((detail.quoteData.boxHeight) * 304.8) - 82).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 3
              ? `${(((detail.quoteData.boxHeight) * 304.8) - 82).toFixed(0)} mm`
              : null
            ) : 
            detail.quoteData.item === 'SlidingDoor' ? (
              detail.quoteData.shutterCount === 1
              ? `${(((detail.quoteData.boxHeight) * 304.8) - 0).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 2
              ? `${(((detail.quoteData.boxHeight) * 304.8) - 0).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 3
              ? `${(((detail.quoteData.boxHeight) * 304.8) - 0).toFixed(0)} mm`
              : null
            ) : null
            ) : null
          }
          </td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>
            {detail.quoteData.mesh === 'yes' ? ( 

              detail.quoteData.item === 'SlidingWindow' ? (
                2*detail.quoteData.qty*(detail.quoteData.shutterCount-1)
              ):(
                2*detail.quoteData.qty*(detail.quoteData.shutterCount)
              )
              ) : (null)}
            </td>
        </tr>
        <tr>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4',}}>Mullion</td>
          <td style={{padding: '5px',fontSize: '10px', border:'1px solid #d4d4d4',}}>{detail.quoteData.shutterCount > 1 ? (detail.quoteData.data?.mullion?.name ==='Loading'? '': detail.quoteData.data?.mullion?.name):(null)}</td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}></td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>
          {detail.quoteData.item === 'CasementWindow' ? (detail.quoteData.shutterCount-1):(null)}
          {detail.quoteData.item === 'CasementDoor' ? (detail.quoteData.shutterCount-1):(null)}
          </td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}></td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>
            {detail.quoteData.item === 'CasementWindow' ? (detail.quoteData.shutterCount-1):(null)}
            {detail.quoteData.item === 'CasementDoor' ? (detail.quoteData.shutterCount-1):(null)}
            </td>
        </tr>
        <tr>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4',}}>InterLock</td>
          <td style={{padding: '5px',fontSize: '10px', border:'1px solid #d4d4d4',}}>{detail.quoteData.data?.interLock?.name || ' '}</td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}></td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}></td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>
          
          {detail.quoteData.item === 'SlidingWindow' ? (
              detail.quoteData.mesh === 'yes' ?(
                detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 42X59 mm'
              ? `${(((detail.quoteData.boxHeight) * 304.8) - 72 -5).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING SUPER SASH 42X75 mm'
              ? `${(((detail.quoteData.boxHeight) * 304.8) - 72 -5).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING DOOR SASH 42X90 mm'
              ? `${((((detail.quoteData.boxHeight) * 304.8))-72 -5).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 37x56 mm'
              ? `${((((detail.quoteData.boxHeight) * 304.8))-66 -5).toFixed(0)} mm`

              : detail.quoteData.shutterCount === 2 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 42X59 mm'
              ? `${(((((detail.quoteData.boxHeight) * 304.8)) - 72 -5).toFixed(0))} mm`
              : detail.quoteData.shutterCount === 2 && detail.quoteData.data.shutter.name === 'SLIDING SUPER SASH 42X75 mm'
              ? `${(((((detail.quoteData.boxHeight) * 304.8))- 72 -5).toFixed(0))} mm`
              : detail.quoteData.shutterCount === 2 && detail.quoteData.data.shutter.name === 'SLIDING DOOR SASH 42X90 mm'
              ? `${((((detail.quoteData.boxHeight) * 304.8))-72 -5).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 2 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 37x56 mm'
              ? `${((((detail.quoteData.boxHeight) * 304.8))-66 -5).toFixed(0)} mm`

              : detail.quoteData.shutterCount === 3 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 42X59 mm'
              ? `${(((((detail.quoteData.boxHeight) * 304.8)) - 72 -5).toFixed(0))} mm`
              : detail.quoteData.shutterCount === 3 && detail.quoteData.data.shutter.name === 'SLIDING SUPER SASH 42X75 mm'
              ? `${(((((detail.quoteData.boxHeight) * 304.8)) - 72 -5).toFixed(0))} mm`
              : detail.quoteData.shutterCount === 3 && detail.quoteData.data.shutter.name === 'SLIDING DOOR SASH 42X90 mm'
              ? `${((((detail.quoteData.boxHeight) * 304.8))-72 -5).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 3 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 37x56 mm'
              ? `${((((detail.quoteData.boxHeight) * 304.8))-66 -5).toFixed(0)} mm`
              : null ): 

              detail.quoteData.mesh === 'no' && (
                detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 42X59 mm'
                ? `${(((detail.quoteData.boxHeight) * 304.8) - 79 -5).toFixed(0)} mm`
                : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING SUPER SASH 42X75 mm'
                ? `${(((detail.quoteData.boxHeight) * 304.8) - 79 -5).toFixed(0)} mm`
                : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING DOOR SASH 42X90 mm'
                ? `${(((detail.quoteData.boxHeight) * 304.8) - 79 -5).toFixed(0)} mm`
                : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 37x56 mm'
                ? `${(((detail.quoteData.boxHeight) * 304.8) - 79 -5).toFixed(0)} mm`

                : detail.quoteData.shutterCount === 2 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 42X59 mm'
                ? `${(((((detail.quoteData.boxHeight) * 304.8)) - 79 -5).toFixed(0))} mm`
                : detail.quoteData.shutterCount === 2 && detail.quoteData.data.shutter.name === 'SLIDING SUPER SASH 42X75 mm'
                ? `${(((((detail.quoteData.boxHeight) * 304.8)) - 79 -5).toFixed(0))} mm`
                : detail.quoteData.shutterCount === 2 && detail.quoteData.data.shutter.name === 'SLIDING DOOR SASH 42X90 mm'
                ? `${(((detail.quoteData.boxHeight) * 304.8) - 79 -5).toFixed(0)} mm`
                : detail.quoteData.shutterCount === 2 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 37x56 mm'
                ? `${(((detail.quoteData.boxHeight) * 304.8) - 79 -5).toFixed(0)} mm`

                : detail.quoteData.shutterCount === 3 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 42X59 mm'
                ? `${(((((detail.quoteData.boxHeight) * 304.8)) - 79 -5).toFixed(0))} mm`
                : detail.quoteData.shutterCount === 3 && detail.quoteData.data.shutter.name === 'SLIDING SUPER SASH 42X75 mm'
                ? `${(((((detail.quoteData.boxHeight) * 304.8) - 79 -5)).toFixed(0))} mm`
                : detail.quoteData.shutterCount === 3 && detail.quoteData.data.shutter.name === 'SLIDING DOOR SASH 42X90 mm'
                ? `${(((detail.quoteData.boxHeight) * 304.8) - 79 -5).toFixed(0)} mm`
                : detail.quoteData.shutterCount === 3 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 37x56 mm'
                ? `${(((detail.quoteData.boxHeight) * 304.8) - 79 -5).toFixed(0)} mm`

                : null
              )
            ):(null)}
          </td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>{detail.quoteData.item === 'SlidingWindow' ? (detail.quoteData.shutterCount-1):(null)}</td>
        </tr>
        <tr>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4',}}>Glass</td>
          <td style={{padding: '5px',fontSize: '10px', border:'1px solid #d4d4d4',}}>{detail.quoteData.data?.glass?.name || ' '}</td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>

          {detail.quoteData.item === 'CasementWindow'  && (
              detail.quoteData.shutterCount === 1
              ? `${(((detail.quoteData.boxWidth) * 304.8) - 66 - 123).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 2
              ? `${(((((detail.quoteData.boxWidth) * 304.8) / 2) - 39 -123).toFixed(0))} mm`
              : detail.quoteData.shutterCount === 3
              ? `${(((((detail.quoteData.boxWidth) * 304.8) / 3) - 31 -123).toFixed(0))} mm`
              : null
            )}

            {detail.quoteData.item === 'CasementDoor' && (
              detail.quoteData.shutterCount === 1
              ? `${(((detail.quoteData.boxWidth) * 304.8) - 82 -123).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 2
              ? `${(((((detail.quoteData.boxWidth) * 304.8) / 2) - 86 - 123).toFixed(0))} mm`
              : detail.quoteData.shutterCount === 3
              ? `${(((((detail.quoteData.boxWidth) * 304.8) / 3) - 90 -123).toFixed(0))} mm`
              : null
            )}

            {detail.quoteData.item === 'SlidingDoor' && (
              detail.quoteData.shutterCount === 1
              ? `${(((detail.quoteData.boxWidth) * 304.8) - 94 -91).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 2
              ? `${(((((detail.quoteData.boxWidth) * 304.8) / 2) - 94-91).toFixed(0))} mm`
              : detail.quoteData.shutterCount === 3
              ? `${(((((detail.quoteData.boxWidth) * 304.8) / 3) - 94-91).toFixed(0))} mm`
              : null
            )}

            {detail.quoteData.item === 'SlidingWindow' && (
              detail.quoteData.mesh === 'yes' ? (
                detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 42X59 mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8)/2)-2-94).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING SUPER SASH 42X75 mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8)/2)+5-94).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING DOOR SASH 42X90 mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8)/2)+13-91).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 37x56 mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8)/2)-1-91).toFixed(0)} mm`

              : detail.quoteData.shutterCount === 2 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 42X59 mm'
              ? `${((((((detail.quoteData.boxWidth) * 304.8))/2)-2-94).toFixed(0))} mm`
              : detail.quoteData.shutterCount === 2 && detail.quoteData.data.shutter.name === 'SLIDING SUPER SASH 42X75 mm'
              ? `${((((((detail.quoteData.boxWidth) * 304.8))/2)+5-94).toFixed(0))} mm`
              : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING DOOR SASH 42X90 mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8)/2)+13-91).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 37x56 mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8)/2)-1-91).toFixed(0)} mm`

              : detail.quoteData.shutterCount === 3 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 42X59 mm'
              ? `${((((((detail.quoteData.boxWidth) * 304.8))/2)-2-94).toFixed(0))} mm`
              : detail.quoteData.shutterCount === 3 && detail.quoteData.data.shutter.name === 'SLIDING SUPER SASH 42X75 mm'
              ? `${((((((detail.quoteData.boxWidth) * 304.8))/2)+5-94).toFixed(0))} mm`
              : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING DOOR SASH 42X90 mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8)/2)+13-91).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 37x56 mm'
              ? `${((((detail.quoteData.boxWidth) * 304.8)/2)-1-94).toFixed(0)} mm`
              : null) : 

              detail.quoteData.mesh === 'no' &&(
                detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 42X59 mm'
                ? `${((((detail.quoteData.boxWidth) * 304.8)/2)-7-94).toFixed(0)} mm`
                : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING SUPER SASH 42X75 mm'
                ? `${(((detail.quoteData.boxWidth) * 304.8)/2-94).toFixed(0)} mm`
                : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING DOOR SASH 42X90 mm'
                ? `${((((detail.quoteData.boxWidth) * 304.8)/2)+13-91).toFixed(0)} mm`
                : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 37x56 mm'
                ? `${((((detail.quoteData.boxWidth) * 304.8)/2)-1-91).toFixed(0)} mm`

                : detail.quoteData.shutterCount === 2 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 42X59 mm'
                ? `${((((((detail.quoteData.boxWidth) * 304.8))/2)-7-94).toFixed(0))} mm`
                : detail.quoteData.shutterCount === 2 && detail.quoteData.data.shutter.name === 'SLIDING SUPER SASH 42X75 mm'
                ? `${(((((detail.quoteData.boxWidth) * 304.8))/2-94).toFixed(0))} mm`
                : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING DOOR SASH 42X90 mm'
                ? `${((((detail.quoteData.boxWidth) * 304.8)/2)+13-91).toFixed(0)} mm`
                : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 37x56 mm'
                ? `${((((detail.quoteData.boxWidth) * 304.8)/2)-1-91).toFixed(0)} mm`

                : detail.quoteData.shutterCount === 3 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 42X59 mm'
                ? `${((((((detail.quoteData.boxWidth) * 304.8))/2)-7-94).toFixed(0))} mm`
                : detail.quoteData.shutterCount === 3 && detail.quoteData.data.shutter.name === 'SLIDING SUPER SASH 42X75 mm'
                ? `${(((((detail.quoteData.boxWidth) * 304.8)/2-94)).toFixed(0))} mm`
                : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING DOOR SASH 42X90 mm'
                ? `${((((detail.quoteData.boxWidth) * 304.8)/2)+13-91).toFixed(0)} mm`
                : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 37x56 mm'
                ? `${((((detail.quoteData.boxWidth) * 304.8)/2)-13-91).toFixed(0)} mm`
                : null
              )
            )}
          </td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>{detail.quoteData.shutterCount*detail.quoteData.qty}</td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>

          {detail.quoteData.item === 'CasementWindow' && (
              detail.quoteData.shutterCount === 1
              ? `${(((detail.quoteData.boxHeight) * 304.8) - 66-123).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 2
              ? `${(((((detail.quoteData.boxHeight) * 304.8)) - 66-123).toFixed(0))} mm`
              : detail.quoteData.shutterCount === 3
              ? `${(((((detail.quoteData.boxHeight) * 304.8)) - 66-123).toFixed(0))} mm`
              : null
            )}

            {detail.quoteData.item === 'SlidingWindow' && (
              detail.quoteData.mesh === 'yes' ?(
                detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 42X59 mm'
              ? `${(((detail.quoteData.boxHeight) * 304.8) - 72-94).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING SUPER SASH 42X75 mm'
              ? `${(((detail.quoteData.boxHeight) * 304.8) - 72-94).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING DOOR SASH 42X90 mm'
              ? `${((((detail.quoteData.boxHeight) * 304.8))-72-91).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 37x56 mm'
              ? `${((((detail.quoteData.boxHeight) * 304.8))-66-91).toFixed(0)} mm`

              : detail.quoteData.shutterCount === 2 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 42X59 mm'
              ? `${(((((detail.quoteData.boxHeight) * 304.8)) - 72-94).toFixed(0))} mm`
              : detail.quoteData.shutterCount === 2 && detail.quoteData.data.shutter.name === 'SLIDING SUPER SASH 42X75 mm'
              ? `${(((((detail.quoteData.boxHeight) * 304.8))- 72-91).toFixed(0))} mm`
              : detail.quoteData.shutterCount === 2 && detail.quoteData.data.shutter.name === 'SLIDING DOOR SASH 42X90 mm'
              ? `${((((detail.quoteData.boxHeight) * 304.8))-72-94).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 2 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 37x56 mm'
              ? `${((((detail.quoteData.boxHeight) * 304.8))-66-91).toFixed(0)} mm`

              : detail.quoteData.shutterCount === 3 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 42X59 mm'
              ? `${(((((detail.quoteData.boxHeight) * 304.8)) - 72-94).toFixed(0))} mm`
              : detail.quoteData.shutterCount === 3 && detail.quoteData.data.shutter.name === 'SLIDING SUPER SASH 42X75 mm'
              ? `${(((((detail.quoteData.boxHeight) * 304.8)) - 72-94).toFixed(0))} mm`
              : detail.quoteData.shutterCount === 3 && detail.quoteData.data.shutter.name === 'SLIDING DOOR SASH 42X90 mm'
              ? `${((((detail.quoteData.boxHeight) * 304.8))-72-91).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 3 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 37x56 mm'
              ? `${((((detail.quoteData.boxHeight) * 304.8))-66-91).toFixed(0)} mm`
              : null ): 

              detail.quoteData.mesh === 'no' && (
                detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 42X59 mm'
                ? `${(((detail.quoteData.boxHeight) * 304.8) - 79-94).toFixed(0)} mm`
                : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING SUPER SASH 42X75 mm'
                ? `${(((detail.quoteData.boxHeight) * 304.8) - 79-94).toFixed(0)} mm`
                : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING DOOR SASH 42X90 mm'
                ? `${(((detail.quoteData.boxHeight) * 304.8) - 79-91).toFixed(0)} mm`
                : detail.quoteData.shutterCount === 1 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 37x56 mm'
                ? `${(((detail.quoteData.boxHeight) * 304.8) - 79-91).toFixed(0)} mm`

                : detail.quoteData.shutterCount === 2 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 42X59 mm'
                ? `${(((((detail.quoteData.boxHeight) * 304.8)) - 79-94).toFixed(0))} mm`
                : detail.quoteData.shutterCount === 2 && detail.quoteData.data.shutter.name === 'SLIDING SUPER SASH 42X75 mm'
                ? `${(((((detail.quoteData.boxHeight) * 304.8)) - 79-94).toFixed(0))} mm`
                : detail.quoteData.shutterCount === 2 && detail.quoteData.data.shutter.name === 'SLIDING DOOR SASH 42X90 mm'
                ? `${(((detail.quoteData.boxHeight) * 304.8) - 79-91).toFixed(0)} mm`
                : detail.quoteData.shutterCount === 2 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 37x56 mm'
                ? `${(((detail.quoteData.boxHeight) * 304.8) - 79-91).toFixed(0)} mm`

                : detail.quoteData.shutterCount === 3 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 42X59 mm'
                ? `${(((((detail.quoteData.boxHeight) * 304.8)) - 79-94).toFixed(0))} mm`
                : detail.quoteData.shutterCount === 3 && detail.quoteData.data.shutter.name === 'SLIDING SUPER SASH 42X75 mm'
                ? `${(((((detail.quoteData.boxHeight) * 304.8) - 79-94)).toFixed(0))} mm`
                : detail.quoteData.shutterCount === 3 && detail.quoteData.data.shutter.name === 'SLIDING DOOR SASH 42X90 mm'
                ? `${(((detail.quoteData.boxHeight) * 304.8) - 79-91).toFixed(0)} mm`
                : detail.quoteData.shutterCount === 3 && detail.quoteData.data.shutter.name === 'SLIDING WINDOW SASH 37x56 mm'
                ? `${(((detail.quoteData.boxHeight) * 304.8) - 79-91).toFixed(0)} mm`

                : null
              )
            )}

            {detail.quoteData.item === 'CasementDoor' && (
              detail.quoteData.shutterCount === 1
              ? `${(((detail.quoteData.boxHeight) * 304.8) - 82-123).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 2
              ? `${((((detail.quoteData.boxHeight) * 304.8) - 82-123).toFixed(0))} mm`
              : detail.quoteData.shutterCount === 3
              ? `${((((detail.quoteData.boxHeight) * 304.8) -82-123).toFixed(0))} mm`
              : null
            )}

            {detail.quoteData.item === 'SlidingDoor' && (
              detail.quoteData.shutterCount === 1
              ? `${(((detail.quoteData.boxHeight) * 304.8) - 91).toFixed(0)} mm`
              : detail.quoteData.shutterCount === 2
              ? `${((((detail.quoteData.boxHeight) * 304.8) - 91).toFixed(0))} mm`
              : detail.quoteData.shutterCount === 3
              ? `${((((detail.quoteData.boxHeight) * 304.8) - 91).toFixed(0))} mm`
              : null
            )}
          </td>
          <td style={{padding: '5px',fontSize: '12px', border:'1px solid #d4d4d4'}}>{detail.quoteData.shutterCount*detail.quoteData.qty}</td>
        </tr>
        <tr> <td colspan={9} style={{padding:'5px', fontSize: '12px', border:'1px solid #d4d4d4', paddingBottom:'120px'}}> Notes</td></tr>
        <tr><td style={{padding:'4px'}}></td></tr>
        </>
      ))}
             

    </tbody>
  ))}
  </>
)}        
         
        </table>
      </div>
     
    </div>
   
  )
}
export default Downlaod;
