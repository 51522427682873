import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Navbar from "./navbar";
import Header from "./header";
import axios from "axios";
import { ReactSession } from 'react-client-session';

ReactSession.setStoreType("localStorage");

function Account() {
    const userId = ReactSession.get("userId");
    const userType = ReactSession.get("userType");
    const userName = ReactSession.get("userName");
    const address = ReactSession.get("Address");
    const number = ReactSession.get("Number");
    const image = ReactSession.get("Image");
    const location = ReactSession.get("location");
    const email = ReactSession.get("email");
    const userQuotes = ReactSession.get("userQuotes");
    const userEngineers = ReactSession.get("userEngineers");
    const userClients = ReactSession.get("userClients");
    const userProfiles = ReactSession.get("userProfiles");


    const navigate = useNavigate();

    const [formvalue, setFormValue] = useState({
        id:userId,
        name: userName,
        location: location,
        phone: number,
        address: address,
        image: null
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValue(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleChangeFile = (e) => {
        const { name, files } = e.target;
        setFormValue(prevState => ({
            ...prevState,
            [name]: files[0]
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        for (const key in formvalue) {
            formData.append(key, formvalue[key]);
        }
        console.log(formData);

        try {
            const response = await axios.post('https://upvcapi.sninfoserv.com/edit-user/', formData);
            if(response.data.status === 'success' ){
              ReactSession.set("Address",);
          ReactSession.set("Number",formvalue.phone);
          ReactSession.set("Image", response.data.image);
          ReactSession.set("userName",formvalue.name);
          ReactSession.set("location", formvalue.location);
          ReactSession.set("Address", formvalue.address);
              console.log(response.data);

              navigate("/account");
             
              
            }

            console.log(response.data);
        } catch (error) {
            console.error('Update failed!', error);
        }
    };

    return (
        <div>
            <Navbar />
            <Header />
            <div className="pc-container">
                <div className="pc-content">
                    <div className="row">
                    <div className="col-md-12 col-xxl-3 col-xl-3">
                          <div className="card">
                              <div className="card-body" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                  <img src={`https://upvcapi.sninfoserv.com/user/images/${image}`} alt={userName} className="user-avtar wid-45 rounded-circle" style={{ marginBottom: '15px' }} />
                                  <div className="pt-3" style={{ textAlign: 'center' }}>
                                      <ul style={{ listStyleType: 'none', padding: 0 }}>
                                          <li><h3>{userName}</h3></li>
                                          <li class='mb-4'><h5>{email}</h5></li>
                                          <li class='mb-4'><h5>{number}</h5></li>
                                          {/* <li style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <h6 style={{ margin: 0 }}>Mobile:</h6>
        <span style={{ fontSize: '80%', color: 'gray' }}>  {number}</span>
    </li> */}
    <li class='mb-4' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <h6 style={{ margin: 0 }}>All Quotes:</h6>
        <span style={{ fontSize: '80%', color: 'gray',marginLeft:'10px' }}>{userQuotes}</span>
    </li>
    <li class='mb-4' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <h6 style={{ margin: 0 }}>Engineers:</h6>
        <span style={{ fontSize: '80%', color: 'gray' }}>{userEngineers}</span>
    </li>
    <li class='mb-4' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <h6 style={{ margin: 0 }}>Clients:</h6>
        <span style={{ fontSize: '80%', color: 'gray' }}>{userClients}</span>
    </li>
    <li class='mb-4' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <h6 style={{ margin: 0 }}>Profiles:</h6>
        <span style={{ fontSize: '80%', color: 'gray' }}>{userProfiles}</span>
    </li>
                                           </ul>
                                  </div>
                              </div>
                          </div>
                      </div>


                        <div className="col-md-12 col-xxl-9 col-xl-9">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Edit Profile</h5>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="name">Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="name"
                                                        placeholder="Enter name..."
                                                        name="name"
                                                        required
                                                        value={formvalue.name || ''}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="location">Location</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="location"
                                                        placeholder="Enter location..."
                                                        name="location"
                                                        required
                                                        value={formvalue.location || ''}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="image">Image</label>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        id="image"
                                                        name="image"
                                                        accept="image/*"
                                                        onChange={handleChangeFile}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="phone">Phone</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="phone"
                                                        placeholder="Enter phone..."
                                                        name="phone"
                                                        required
                                                        value={formvalue.phone || ''}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="address">Address</label>
                                                    <textarea
                                                        className="form-control"
                                                        id="address"
                                                        rows="3"
                                                        name="address"
                                                        value={formvalue.address || ''}
                                                        onChange={handleChange}
                                                    ></textarea>
                                                </div>
                                            </div>
                                            <div className="col-md-12" align="center">
                                                <button type="submit" className="btn btn-primary mb-4 w-25">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Account;
